import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import style from "./style.module.css";

const SupervisoryBoard = () => {
  const [supervisors, setSupervisors] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios
        .get("https://api-it-sprout.a-rogovsky1276.workers.dev/Supervisors")
        .then((res) => res.data);
      const data = response.records.map((record) => record.fields);
      setSupervisors(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <section
        style={{
          maxWidth: "1200px",
          margin: "0 auto",
          padding: "120px 0 144px 0",
        }}
      >
        <div
          className="flex flex-col justify-center items-center"
          style={{ padding: "0 25px 80px 25px", textAlign: "center" }}
        >
          <h1 style={{ fontSize: "56px", color: "#165AAA", fontWeight: 300 }}>
            Наглядова рада
          </h1>
          <h3 style={{ color: "#EAC131", fontSize: "28px", marginTop: "10px" }}>
            для покращення освітніх стандартів
          </h3>
        </div>
        <div>
          <div
            className="flex justify-center flex-wrap"
            style={{ gap: "24px", color: "#252A38", padding: "0 25px" }}
          >
            {supervisors &&
              supervisors.map((supervisor) => {
                const { Name, Image, Role, Company, Link } = supervisor;
                return (
                  <>
                    <div
                      style={{
                        maxWidth: "360px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ height: "120px", width: "120px" }}>
                        <img
                          src={Image}
                          style={{ width: "120px", borderRadius: "50%" }}
                        />
                      </div>
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: 600,
                          marginTop: "24px",
                        }}
                      >
                        {Name}
                      </div>
                      <div
                        className={style["text-rows-1"]}
                        style={{
                          fontSize: "20px",
                          fontWeight: 500,
                          marginTop: "24px",
                        }}
                      >
                        {Role}
                      </div>
                      <div
                        style={{ padding: "0 25px 0 0" }}
                        className={style["text-rows-3"]}
                      >
                        {Company}
                      </div>
                      {Link && (
                        <>
                          <div
                            style={{
                              fontSize: "20px",
                              fontWeight: 600,
                              marginTop: "24px",
                            }}
                          >
                            <a href={Link}>{Link}</a>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                );
              })}
          </div>
          {/* <div
            style={{
              color: "#252A38",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "120px",
            }}
          >
            <div style={{ fontWeight: 400, fontSize: "28px" }}>
              Хочете приеднатися до наглядової ради?
            </div>
            <Link to="/">
              <button
                className="hover-yellow"
                style={{
                  backgroundColor: "#165AAA",
                  color: "white",
                  padding: "13px 20px",
                  maxWidth: "340px",
                  width: "100%",
                  borderRadius: "8px",
                  margin: "32px 0 0 0",
                  textTransform: "uppercase",
                }}
              >
                Приєднатися
              </button>{" "}
            </Link>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default SupervisoryBoard;
