import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import axios from "axios";
import { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import DesignForGoodCard from "./DesignForGoodCard";
import uuid from "react-uuid/uuid";
import useWindowWidth from "../../custom-hooks/useWindowWidth";
import { GlobalContext } from "../../App";
import { Link } from "react-router-dom";

const BestDesign = ({ contestID }) => {
  const { isLikeClicked, setIsLikeClicked } = useContext(GlobalContext);
  const [designWorks, setDesignWorks] = useState(null);

  const [isLeftBtn, setIsLeftBtn] = useState(false);
  const [isRightBtn, setIsRightBtn] = useState(true);

  const width = useWindowWidth();

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const fetchData = async () => {
    try {
      await axios
        .get(
          `https://api-it-sprout.a-rogovsky1276.workers.dev/Contests_works?filterByFormula=AND({Contest_ID}='${contestID}',{Active}=1)&sort%5B0%5D%5Bfield%5D=Likes&sort%5B0%5D%5Bdirection%5D=desc&maxRecords=9`
        )
        .then((res) => res.data)
        .then((res) => {
          const data = res.records.map((record) => record.fields);
          setDesignWorks(data);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (isLikeClicked === false || designWorks === null) {
      fetchData();
      setIsLikeClicked(null);
    }
  }, [isLikeClicked]);

  const params = {
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      900: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 48,
      },
    },
  };

  return (
    <>
      <section
        style={{
          maxWidth: "1200px",
          width: "100%",
          margin: "0 auto",
          padding: "120px 0 104px 0",
        }}
      >
        <div
          className="flex flex-col justify-center items-center"
          style={{ padding: "0 25px 80px 25px" }}
        >
          <h1
            style={{
              fontSize: "56px",
              color: "#165AAA",
              fontWeight: 300,
              textAlign: "center",
            }}
          >
            Кращі конкурсні роботи
          </h1>
        </div>
        <div
          style={{
            maxWidth: `${width < 640 ? "400px" : "100%"}`,
            margin: "0 auto",
            position: "relative",
            display: "flex",
            alignItems: "center",
            padding: "0 40px",
          }}
          className="swiper-design"
        >
          <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={20}
            {...params}
            style={{ padding: "0 5px", maxWidth: "95%", width: "100%" }}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            onSlideChange={(swiper) => {
              setIsLeftBtn(!swiper.isBeginning);
              setIsRightBtn(!swiper.isEnd);
            }}
            onSwiper={(swiper) => {
              setIsLeftBtn(!swiper.isBeginning);
              setIsRightBtn(swiper.isEnd);
            }}
          >
            {designWorks &&
              designWorks.map((data) => {
                return (
                  <>
                    <SwiperSlide key={uuid()}>
                      <DesignForGoodCard key={data["Location"]} data={data} />
                    </SwiperSlide>
                  </>
                );
              })}
          </Swiper>
          <div
            style={{
              position: "absolute",
              width: `100%`,
              left: 0,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              ref={prevRef}
              style={{ backgroundColor: "transparent", marginLeft: "15px" }}
            >
              <svg
                style={{ transform: "rotate(180deg)" }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="32"
                viewBox="0 0 16 32"
                fill="none"
              >
                <path
                  d="M0 0L-5.59506e-06 32L16 16L0 0Z"
                  fill={`${isLeftBtn ? "#EAC131" : "#E0E0E0"} `}
                />
              </svg>
            </button>
            <button
              ref={nextRef}
              style={{
                backgroundColor: "transparent",
                marginRight: "15px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="32"
                viewBox="0 0 16 32"
                fill="none"
              >
                <path
                  d="M0 0L-5.59506e-06 32L16 16L0 0Z"
                  fill={`${isRightBtn ? "#EAC131" : "#E0E0E0"} `}
                />
              </svg>
            </button>
          </div>
        </div>

        <div
          style={{
            marginTop: "88px",
            padding: "0 25px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Link
            to={`/contest/${contestID.slice(3)}/review?scrollTo=contest-works`}
          >
            <button
              className="hover-yellow"
              style={{
                backgroundColor: "#165AAA",
                color: "white",
                padding: "13px 32px",
                maxWidth: "340px",
                width: "100%",
                fontWeight: 700,
                borderRadius: "8px",
                margin: "32px 0 0 0",
                textTransform: "uppercase",
              }}
            >
              Переглянути всі
            </button>
          </Link>
        </div>
      </section>
    </>
  );
};

export default BestDesign;
