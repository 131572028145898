import { useRef } from "react";
import useIsViewport from "../../../custom-hooks/useIsViewport";

// import WhatWillGetAccordion from "./WhatWillGetAccordion";
import style from "./style.module.scss";
import MentorsBlock from "./MentorsBlock/MentorsBlock";

const HowItWorks = () => {
  const block = useRef();
  useIsViewport(block);

  return (
    <>
      <section className={style["section"]} ref={block} id="how_it_works">
        <MentorsBlock />
        {/* <div style={{ padding: "0 25px", marginTop: "80px" }}>
        <h1
          style={{
            fontWeight: 300,
            fontSize: "56px",
            color: "#165AAA",
            textAlign: "center",
          }}
        >
          Що я отримаю?
        </h1>
      </div>
      <div style={{ padding: "0 25px", marginTop: "64px" }}>
        <WhatWillGetAccordion />
      </div> */}
      </section>
    </>
  );
};

export default HowItWorks;
