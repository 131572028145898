import { Link } from "react-router-dom";
import style from "./style.module.scss";

const Footer = () => {
  return (
    <>
      <div className={style["footer-wrapper"]}>
        <footer className={style["footer"]}>
          <div
            style={{
              fontSize: "16px",
              color: "#252A38",
              textAlign: "center",
            }}
          >
            @2023 - IT-Sprout. Усі права захищено
          </div>
          <a
            href="https://www.education.ua/abroad/"
            target="_blank"
            style={{
              padding: "10px 20px",
              textAlign: "center",
            }}
          >
            Education.ua — освіта за кордоном
          </a>
          <Link to={"/privacy-policy"} onClick={() => window.scrollTo(0, 0)}>
            <span className="c-dark text-center">
              Політика конфіденційності
            </span>
          </Link>
        </footer>
      </div>
    </>
  );
};

export default Footer;
