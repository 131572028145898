const SidenavItem = ({ target, ...props }) => {
  const { selectedNavItem } = props;

  return (
    <>
      <a
        href={target}
        className={`${selectedNavItem === target ? "active-dot" : ""}`}
      >
        <span />
      </a>
    </>
  );
};

export default SidenavItem;
