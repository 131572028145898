import img1 from "../../assets/img/📌.png";
import img2 from "../../assets/img/📆.png";
import img3 from "../../assets/img/🏁.png";
import img4 from "../../assets/img/🏆.png";
import img5 from "../../assets/img/📹.png";
import { useContext } from "react";
import { ContestData } from "../ContestOverviewPage/ContestOverviewPage";

const StageOfContest = () => {
  const { contestData } = useContext(ContestData);
  const Stages = contestData["Stages"]
    ? JSON.parse(contestData["Stages"])
    : null;

  if (!Stages) {
    return;
  }

  return (
    <section id="howwork" className="bg-blue">
      <h2 className="p-title c-white">Етапи конкурсу</h2>
      <div className="s-100"></div>
      <div className="s-40"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="card-left">
              <div className="label">{Stages[1].day} день</div>
              <div className="card-in">
                <img className="p-2" src={img2} />
                <p className="text-black f-500">
                  Кінцевий термін подачі заявок до якого дизайнери мають
                  можливість подати заявки
                </p>
              </div>
            </div>
            <div className="card-left">
              <div className="label">{Stages[3].day} день</div>
              <div className="card-in">
                <img src={img4} />
                <p className="py-1 text-black f-500">
                  Нагородження переможців, які отримають свої нагороди та
                  сертіфікати
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card-right">
              <div className="label first">{Stages[0].day} день</div>
              <div className="card-in">
                <img className="p-2" src={img1} />
                <p className="py-1 text-black f-500">
                  Початок конкурсу: дизайнери з усього світу можуть подавати
                  свої роботи
                </p>
              </div>
            </div>
            <div className="card-right">
              <div className="label">{Stages[2].day} день</div>
              <div className="card-in">
                <img src={img3} />
                <p className="py-2 text-black f-500">
                  Закінчення конкурсу та початок остаточного вибору переможців
                </p>
              </div>
            </div>
            <div className="card-right">
              <div className="label">{Stages[4].day} день</div>
              <div className="card-in">
                <img src={img5} className="p-2" />
                <p className="text-black f-500">
                  Онлайн зустріч у Zoom - круглий стіл для обговорення
                  результатів конкурсу
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StageOfContest;
