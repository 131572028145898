import useArrowPagination from "../../custom-hooks/useArrowPagination";
import DesignForGoodCard from "./DesignForGoodCard";
import button from "../../styles/modules/Button.module.css";
import { ContestData } from "../ContestOverviewPage/ContestOverviewPage";
import { useContext, useEffect } from "react";
import { GlobalContext } from "../../App";
import { Element, Link, scroller } from "react-scroll";
import { removeQueryParam } from "../../functions";
import { useLocation } from "react-router-dom";

const DesignForGood = () => {
  const { isLikeClicked } = useContext(GlobalContext);
  const { contestData } = useContext(ContestData);
  const { Title, Subtitle, Location } = contestData;
  const location = useLocation();

  const [designWorks, setCurrentPage, isLeftBtn, isRightBtn] =
    useArrowPagination(
      "Contests_works",
      `filterByFormula=AND({Contest_ID}='${Location}',{Active}=1)&`,
      9,
      [isLikeClicked]
    );

  useEffect(() => {
    const targetElementId = new URLSearchParams(location.search).get(
      "scrollTo"
    );
    if (targetElementId) {
      scroller.scrollTo(targetElementId, {
        offset: -150,
        duration: 400,
        delay: 0,
      });
      removeQueryParam("scrollTo");
    }
  }, [location]);

  return (
    <>
      <section
        style={{
          maxWidth: "1200px",
          width: "100%",
          margin: "0 auto",
          padding: "120px 0 142px 0",
        }}
      >
        <div
          className="flex flex-col justify-center items-center"
          style={{ padding: "0 25px 80px 25px", textAlign: "center" }}
        >
          <h1 style={{ fontSize: "56px", color: "#165AAA", fontWeight: 300 }}>
            {Title}
          </h1>
          <h3 style={{ color: "#EAC131", fontSize: "28px", marginTop: "10px" }}>
            {Subtitle}
          </h3>
        </div>
        <Element name="contest-works">
          <div className="grid-design">
            {designWorks &&
              designWorks.map((data) => {
                return <DesignForGoodCard key={data["Location"]} data={data} />;
              })}
          </div>
          {isLeftBtn || isRightBtn ? (
            <div
              className="flex justify-center"
              style={{
                padding: "0 40px",
                gap: "8px",
                marginTop: "60px",
              }}
            >
              {isLeftBtn ? (
                <Link to="contest-works">
                  <button
                    className={button["arrow-pagination"]}
                    onClick={() => {
                      setCurrentPage((prev) => prev - 1);
                    }}
                  >
                    Назад
                  </button>
                </Link>
              ) : (
                <div />
              )}
              {isRightBtn ? (
                <Link to="contest-works">
                  <button
                    className={button["arrow-pagination"]}
                    onClick={() => {
                      setCurrentPage((prev) => prev + 1);
                    }}
                  >
                    Вперед
                  </button>
                </Link>
              ) : (
                <div />
              )}
            </div>
          ) : (
            <></>
          )}
        </Element>
      </section>
    </>
  );
};

export default DesignForGood;
