const CountupElement = ({ children, number }) => {
  return (
    <>
      <div
        className="flex flex-col"
        style={{
          maxWidth: "360px",
          width: "100%",
          minHeight: "253px",
          borderRadius: "16px",
          background: "#252A38",
          padding: "35px 40px 40px",
          alignItems: "center",
          border: "6px solid #EAC131",
          borderLeft: "none",
          borderRight: "none",
        }}
      >
        {children}
      </div>
    </>
  );
};

export default CountupElement;
