import { useContext, useState } from "react";
import defaultImg from "../../../../assets/img/Subtract.png";
import { PageContext } from "../../InternshipPage";
import style from "./style.module.scss";
import useResizeObserver from "../../../../custom-hooks/useResizeObserver";
import MentorsAccordionItem from "./MentorsAccordionItem";
import useWindowWidth from "../../../../custom-hooks/useWindowWidth";
import { ReactComponent as LinkedInIcon } from "../../../../assets/img/svg/LinkedIn.svg";

const blocksDefault = [
  {
    image: defaultImg,
    title: "",
    text: "Триває пошук ментора ...",
    linkedin_url: "",
  },
  {
    image: defaultImg,
    title: "",
    text: "Триває пошук ментора ...",
    linkedin_url: "",
  },
  {
    image: defaultImg,
    title: "",
    text: "Триває пошук ментора ...",
    linkedin_url: "",
  },
  {
    image: defaultImg,
    title: "",
    text: "Триває пошук ментора ...",
    linkedin_url: "",
  },
];

const MentorsAccordion_more1028px = (props) => {
  const [currentSlide, setCurrentSlide] = useState(null);
  const [blocksWrapper, dimensions] = useResizeObserver();
  const { blocks } = props;
  return (
    <>
      <div className={style["accordion-wrapper"]} ref={blocksWrapper}>
        <div
          className={style["accordion"]}
          style={{
            transform: `translateX(-${
              currentSlide ? ((currentSlide - 1) * dimensions.width) / 4 : 0
            }px)`,
          }}
        >
          {blocks.map((block, index) => {
            return (
              <MentorsAccordionItem
                key={index}
                index={index}
                data={block}
                wrapperLength={dimensions.width}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
                totalBlocksCount={blocks.length}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

const MentorsAccordion_less1028px = (props) => {
  const { blocks } = props;
  return (
    <>
      <div className={style["blocks-wrapper"]}>
        {blocks.map((block, index) => {
          const { image, title, text, linkedin_url } = block;
          return (
            <div className={style["blocks__item"]} key={index}>
              <img src={image} alt="mentor" />
              <div className={style["blocks__item-content"]}>
                {title && <h1>{title}</h1>}
                {linkedin_url && (
                  <a className={style["linkedin-url"]} href={linkedin_url}>
                    <LinkedInIcon className={style["linkedin-url__icon"]} />
                    <span className={style["linkedin-url__text"]}>
                      LinkedIn
                    </span>
                  </a>
                )}
                <p>{text}</p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const MentorsAccordion = () => {
  const { pageData } = useContext(PageContext);

  const blocks = pageData["Mentors_accordion"]
    ? [...JSON.parse(pageData["Mentors_accordion"]), ...blocksDefault].slice(
        0,
        4
      )
    : blocksDefault;

  const width = useWindowWidth();

  if (width >= 1028) {
    return <MentorsAccordion_more1028px blocks={blocks} />;
  } else {
    return <MentorsAccordion_less1028px blocks={blocks} />;
  }
};

export default MentorsAccordion;
