import style from "./style.module.scss";

const InternshipStagesAccordionItem = (props) => {
  const {
    data: { title, text },
    bgColor,
    selectedBlock,
    setSelectedBlock,
    index,
    wrapperLength,
    totalBlocksCount,
  } = props;

  return (
    <>
      <div
        className={style["accordion__item"]}
        onMouseEnter={() => setSelectedBlock(index)}
        onMouseLeave={() => setSelectedBlock(null)}
        style={{
          minWidth: `${
            selectedBlock === index
              ? `${(100 / totalBlocksCount) * 3}%`
              : `${100 / totalBlocksCount}%`
          }`,
        }}
      >
        <svg
          width={`${wrapperLength / totalBlocksCount}px`}
          viewBox="0 0 156 54"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M63.3669 5.25621C71.9938 -1.37982 84.0062 -1.37983 92.6331 5.2562L156 54H0L63.3669 5.25621Z"
            fill={bgColor}
          />
        </svg>

        <div
          className={style["accordion__item-content"]}
          style={{
            background: bgColor,
          }}
        >
          <div
            className={style["accordion__item-title__wrapper"]}
            style={{
              minWidth: `${wrapperLength / 8}px`,
            }}
          >
            <div className={style["accordion__item-title"]}>{title}</div>
          </div>
          <div
            className={style["accordion__item-text"]}
            style={{
              minWidth: `${(wrapperLength / 6) * 1.8}px`,
            }}
          >
            {text}
          </div>
        </div>
      </div>
    </>
  );
};

export default InternshipStagesAccordionItem;
