import QualifyingRoundAccordion from "./QualifyingRoundAccordion/QualifyingRoundAccordion";
import style from "./style.module.scss";

const QualifyingRoundBlock = () => {
  return (
    <>
      <div className={style["block-wrapper"]}>
        <div className={style["block-title__wrapper"]}>
          <h1>Відбірковий тур</h1>
        </div>
        <div className={style["qualifying-accordion__wrapper"]}>
          <QualifyingRoundAccordion />
        </div>
      </div>
    </>
  );
};

export default QualifyingRoundBlock;
