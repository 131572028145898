import style from "./style.module.scss";

const IntroductionAccordionItem = (props) => {
  const {
    data: { title, text, icon },
    index,
    setCurrentBlock,
    currentBlock,
    totalBlocksCount,
  } = props;

  return (
    <>
      <div
        className={style["accordion__item"]}
        onMouseEnter={() => setCurrentBlock(index)}
        onMouseLeave={() => setCurrentBlock(null)}
        style={{
          background: `${index % 2 === 0 ? "#204E84" : "#165AAA"}`,
          minWidth: `${
            currentBlock === index ? `100%` : `${100 / totalBlocksCount}%`
          }`,
        }}
      >
        <div
          className={`${style["accordion__item-title__wrapper"]} ${
            currentBlock === index && style["slow-appear-animation"]
          }`}
        >
          {icon}
          <div className={style["accordion__item-title"]}>
            {currentBlock !== index && (
              <>
                {index === 0 && (
                  <>
                    <div>{title.slice(0, 10) + "..."}</div>
                  </>
                )}
                {index !== 0 && <>{title.split(" ")[0]}</>}
              </>
            )}
            {currentBlock === index && <>{title}</>}
          </div>
        </div>
        {currentBlock === index && (
          <>
            <p
              className={
                currentBlock === index && style["slow-appear-animation"]
              }
            >
              {text}
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default IntroductionAccordionItem;
