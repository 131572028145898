import styles from './LittlePreloader.module.scss';

const LittlePreloader = () => {
  return (
    <div className={styles.holder}>
      <div className={styles.preloader}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default LittlePreloader;
