import About from "../../components/About";
import PopUp from "../../components/PopUp";
import video1 from "../../assets/img/Круглий стіл IT-Sprout.mp4";
import { useState, useRef } from "react";
import { useEffect } from "react";
import CountupSection from "./Footer/FooterCountup/CountupSection";
import { formatTelephone } from "../../functions";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import InternshipCardsSlider from "./InternshipCardsSlider";
import { TechProgram } from "./TechProgram";
import { ModulesMap } from "./ModulesMap/ModulesMap";
import HaveQuetions from "../../components/General/HaveQuetions";
import Footer from "./Footer/Footer";
import HeaderHome from "./Header/HeaderHome";

const HomePage = ({ setIsThankPage }) => {
  const [registrForm, setRegistrForm] = useState({
    name: "",
    email: "",
    organization: "",
    telephone: "",
  });
  const [isFormLoading, setIsFormLoading] = useState();
  const [status, setStatus] = useState(false);
  const video = useRef();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (video.current) {
  //     if (!status) {
  //       video.current.pause();
  //     }
  //   }
  // }, [status, video]);

  // const postData = async () => {
  //   setIsFormLoading(true);
  //   try {
  //     await axios.post(
  //       "https://hooks.zapier.com/hooks/catch/10026767/33xae70",
  //       JSON.stringify(registrForm)
  //     );
  //     setIsThankPage(true);
  //     navigate("/thank-page");
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   setRegistrForm({
  //     name: "",
  //     email: "",
  //     organization: "",
  //     telephone: "",
  //   });
  //   setIsFormLoading(false);
  //   setStatus(false);
  // };

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if (!localStorage.getItem("round-desk")) {
  //       setStatus(true);
  //     }
  //   }, 5000);
  //   return () => clearTimeout(timeout);
  // }, []);

  // useEffect(() => {
  //   if (status && !localStorage.getItem("round-desk")) {
  //     localStorage.setItem("round-desk", "переглянуто");
  //   }
  // }, [status]);

  return (
    <>
      <HeaderHome />
      <InternshipCardsSlider />
      <TechProgram />
      <div style={{ backgroundColor: "#165aaa", paddingBottom: "236px" }}>
        <ModulesMap />
        <div
          className="achiements-block"
          style={{
            maxWidth: "1200px",
            width: "90%",
            margin: "0 auto",
            display: "flex",
            flexWrap: "wrap",
            gap: "120px",
          }}
        >
          <div style={{ minWidth: "300px" }}>
            <div
              style={{ fontWeight: 700, fontSize: "24px", color: "#EAC131" }}
            >
              Наші досягнення та плани
            </div>
            <ul
              style={{
                margin: "0",
                padding: "0",
                marginTop: "40px",
                color: "#fff",
                fontWeight: 500,
                fontSize: "16px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <li style={{ color: "#B1C3FF" }}>
                ☑ Q1: Три інтерни створили першу версію сайту
              </li>
              <li style={{ color: "#B1C3FF" }}>
                ☑ Q2: Ми отримали понад 2000 заявок
              </li>
              <li style={{ color: "#B1C3FF" }}>
                ☑ Q3: Початок військового стану
              </li>
              <li style={{ color: "#B1C3FF" }}>
                ☑ Q4: Отримали понад 5000 заявок
              </li>
              <li style={{ color: "#B1C3FF" }}>
                ☑ Q5: Набір журі на конкурс членів наглядової ради
              </li>
              <li style={{ color: "#B1C3FF" }}>
                ☑ Q6: Формування наглядової ради
              </li>
              <li>☐ Q7: Фандрайзинг за рахунок грантів та донатів</li>
              <li>☐ Q8: Корпоративне партнерство з #DIGITALEUROPE</li>
              <li>☐ Q9: Вихід на європейські регіони</li>
            </ul>
          </div>
          <div
            style={{
              maxWidth: "468px",
              width: "100%",
              background: "#eac131",
              borderRadius: "24px",
              paddingBottom: "12px",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                background: "#DFE6FF",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  padding: "20px 34.5px",
                  textAlign: "center",
                  background: "#252A38",
                  borderRadius: "24px 24px 0 0",
                  fontWeight: 600,
                  fontSize: "24px",
                  color: "#EAC131",
                }}
              >
                Зареєструватися
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  padding: "30px 53px",
                }}
              >
                <div
                  style={{
                    fontWeight: 500,
                    color: "#252A38D9",
                  }}
                >
                  Бажаєте прийняті участь у нашій програмі? Надішліть нам свою
                  електронну адресу
                </div>
                <a
                  style={{ marginTop: "25px" }}
                  target="_blank"
                  href="https://airtable.com/shrCDpEZJQj20gxzA?hide_fldTEuMREkCx4jmRA=true&hide_fldV8JORzKKRWChYY=true&hide_fldaG2FMSMxGxaIoY=true"
                >
                  <button
                    className="hover-yellow"
                    style={{
                      maxWidth: "262px",
                      width: "100%",
                      textTransform: "uppercase",
                      padding: "13px 50.5px",
                      background: "#165aaa",
                      borderRadius: "8px",
                      fontWeight: 700,
                      color: "#fff",
                    }}
                  >
                    Перейти до форми
                  </button>
                </a>
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: "13px",
                    color: "#165AAA",
                    marginTop: "22px",
                  }}
                >
                  Деталі щодо участi у програмi ви отримаєте на вашу електронну
                  адресу
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <About />
      <CountupSection />
      <HaveQuetions formId={"recVMV209hMG37wfw"} />
      <Footer />
      {/* <About /> */}
      {/* <Footer setPopUp={setStatus} /> */}
      {/* <button
        onClick={() => {
          setStatus(true);
        }}
        className="scale-125-animation"
        style={{
          padding: "6px 0 0 0",
          width: "80px",
          height: "80px",
          position: "fixed",
          bottom: "30px",
          right: "30px",
          borderRadius: "50%",
          zIndex: "10",
          background: "#165AAA",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#EAC131",
          fontWeight: "600",
        }}
      >
        Круглий
        <br /> стіл
      </button> */}
      {/* <PopUp status={status} setStatus={setStatus}>
        <div
          style={{
            maxWidth: "500px",
            width: "100%",
            background: "#fff",
            minHeight: "300px",
            borderRadius: "12px",
            padding: "15px",
          }}
        >
          <div
            style={{
              color: "#282828",
              textAlign: "center",
            }}
          >
            <div
              style={{ fontSize: "28px", fontWeight: 500, color: "#165AAA" }}
            >
              Круглий стіл від IT-Паросток
            </div>
            <div
              style={{ fontSize: "18px", marginTop: "10px", fontWeight: 400 }}
            >
              “Безкоштовні IT-інструменти для автоматизації освітнього процесу
              та оцінки якості засвоєння матеріалу студентами.”
            </div>
            <div
              style={{ fontSize: "21px", marginTop: "15px", fontWeight: 600 }}
            >
              27 квітня о 14:00 за Києвом
            </div>
          </div>

          <video
            src={video1}
            controls
            style={{ marginTop: "20px" }}
            ref={video}
          />

          <form
            onSubmit={(e) => {
              e.preventDefault();
              postData();
            }}
          >
            <input
              style={{
                width: "100%",
                color: "#252A38",
                background: "#fff",
                padding: "10px 15px",
                fontSize: "16px",
                fontWeight: 500,
                borderRadius: "8px",
                marginTop: "10px",
                border: "1px solid rgb(0,0,0,0.15)",
              }}
              type={"text"}
              value={registrForm["name"]}
              onChange={(e) =>
                setRegistrForm((prev) => {
                  const copy = { ...prev };
                  copy["name"] = e.target.value;
                  return copy;
                })
              }
              placeholder="Ім’я"
              required
            />
            <input
              style={{
                width: "100%",
                color: "#252A38",
                background: "#fff",
                padding: "10px 15px",
                fontSize: "16px",
                fontWeight: 500,
                borderRadius: "8px",
                marginTop: "10px",
                border: "1px solid rgb(0,0,0,0.15)",
              }}
              type={"text"}
              value={registrForm["organization"]}
              onChange={(e) =>
                setRegistrForm((prev) => {
                  const copy = { ...prev };
                  copy["organization"] = e.target.value;
                  return copy;
                })
              }
              placeholder="Організація"
              required
            />
            <input
              style={{
                width: "100%",
                color: "#252A38",
                background: "#fff",
                padding: "10px 15px",
                fontSize: "16px",
                fontWeight: 500,
                borderRadius: "8px",
                marginTop: "10px",
                border: "1px solid rgb(0,0,0,0.15)",
              }}
              type={"email"}
              value={registrForm["email"]}
              onChange={(e) =>
                setRegistrForm((prev) => {
                  const copy = { ...prev };
                  copy["email"] = e.target.value;
                  return copy;
                })
              }
              placeholder="Електронна адреса"
              required
            />

            <input
              style={{
                width: "100%",
                color: "#252A38",
                background: "#fff",
                padding: "10px 15px",
                fontSize: "16px",
                fontWeight: 500,
                borderRadius: "8px",
                marginTop: "10px",
                border: "1px solid rgb(0,0,0,0.15)",
              }}
              type={"tel"}
              pattern="\+380 \d{2} \d{3} \d{2} \d{2}"
              placeholder="+380 XX XXX XX XX"
              value={formatTelephone(registrForm["telephone"])}
              onChange={(e) =>
                setRegistrForm((prev) => {
                  const copy = { ...prev };
                  copy["telephone"] = e.target.value;
                  return copy;
                })
              }
              required
            />
            <button
              className="hover-yellow"
              type="submit"
              style={{
                width: "100%",
                color: "#252A38",
                padding: "12px 15px",
                fontSize: "16px",
                fontWeight: 700,
                color: "#fff",
                background: "#165AAA",
                borderRadius: "8px",
                marginTop: "30px",
              }}
            >
              {!isFormLoading ? "Зареєструватись" : "Реєструємо..."}
            </button>
          </form>
        </div>
      </PopUp> */}
    </>
  );
};

export default HomePage;
