import { ReactComponent as Minus } from "../../assets/img/svg/minus.svg";
import { ReactComponent as Plus } from "../../assets/img/svg/plus.svg";
import style from "./style.module.scss";

const FAQQuestion = (props) => {
  const { question, answer, selected, setSelected } = props;

  const clickHandler = () => {
    if (selected === question) {
      setSelected("");
    } else {
      setSelected(question);
    }
  };
  return (
    <>
      <div className={`${style["faq__question-container"]}`}>
        <div className="col-md-10" style={{ display: "flex", flex: "1 1 0%" }}>
          <h3>{question}</h3>
        </div>
        <div
          onClick={() => {
            clickHandler();
          }}
          className={style["faq__dropdown-svg"]}
        >
          {!(selected === question) ? (
            <>
              <Plus alt={"Show"} />
            </>
          ) : (
            <>
              <Minus alt={"Hide"} />
            </>
          )}
        </div>
      </div>
      <div
        className={`${style["faq__question-answer"]} ${
          selected === question ? style["faq__question-answer--active"] : ""
        }`}
      >
        <p className="col-md-10 text-black">{answer}</p>
      </div>
    </>
  );
};

export default FAQQuestion;
