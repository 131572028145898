import Header from "../../components/General/HeaderSection/Header/Header";
import ProgramDescription from "./ProgramDescription";
import Registration from "./Registration";
import StagesOfContest from "./StagesOfContest";
import image1 from "../../assets/img/Group_ContestOverview.png";
import ContestWithFetchData from "../../components/General/ContestWithFetchData";
import Footer from "../HomePage/Footer/Footer";
import Main from "../../components/General/Main";
import { ContestData } from "../ContestOverviewPage/ContestOverviewPage";

const ContestRegistrationPage = ({ contest }) => {
  return (
    <>
      <Header image={image1}>
        <h3>Інтернатура IT-Паросток </h3>
        <h1>Реєстрація</h1>
        <p>
          Сторінка реєстрації на конкурс:
          {<span style={{ whiteSpace: "nowrap" }}>"{contest["Title"]}"</span>}
        </p>
      </Header>
      <Main>
        <ContestData.Provider value={{ contestData: contest }}>
          <ProgramDescription />
          <StagesOfContest />
          <Registration />
        </ContestData.Provider>
      </Main>
      <Footer />
    </>
  );
};

export default ContestWithFetchData(ContestRegistrationPage);
