import { useContext, useRef } from "react";
import useIsViewport from "../../../custom-hooks/useIsViewport";
import { PageContext } from "../InternshipPage";
import QualifyingRoundBlock from "./QualifyingRoundBlock/QualifyingRoundBlock";
import InternshipStagesBlock from "./InternshipStagesBlock/InternshipStagesBlock";
import ExpectationsBlock from "./ExpectationsBlock/ExpectationsBlock";
import EduResultsBlock from "./EduResultsBlock/EduResultsBlock";
import style from "./style.module.scss";

const GuideMap = () => {
  const block = useRef();
  useIsViewport(block);

  const { pageData } = useContext(PageContext);
  const { Headline: headLine } = pageData;

  return (
    <>
      <section ref={block} id="features" className={style["section"]}>
        <div className={style["section__inner-content"]}>
          <QualifyingRoundBlock />
          <InternshipStagesBlock headLine={headLine} />
          <ExpectationsBlock />
          <EduResultsBlock />
        </div>
      </section>
    </>
  );
};

export default GuideMap;
