import FAQ from "../../components/FAQ/FAQ";
import Features from "../../components/General/Features/Features";
import Footer from "../../components/General/Footer/Footer";
import HaveQuetions from "../../components/General/HaveQuetions";
import Header from "../../components/General/HeaderSection/Header/Header";
import Main from "../../components/General/Main";

import SupervisoryBoard from "./SupervisoryBoard";

const SupervisoryBoardPage = () => {
  return (
    <>
      <Header>
        <h3>Інтернатура IT-Паросток </h3>
        <h1>Наглядова рада</h1>
        <p>
          Наглядова рада - це згуртована команда видатних викладачів, професорів
          та науковців, що поєднує свої знання та досвід з метою покращення
          освітніх стандартів в Україні. Відкрийте для себе наших експертів та
          їхні досягнення.
        </p>
      </Header>
      <Main>
        <SupervisoryBoard />
        <Features />
        <FAQ title={"Відповіді на поширені питання"} />
        <HaveQuetions />
      </Main>
      <div style={{ marginTop: "100px" }} />
      <Footer />
    </>
  );
};

export default SupervisoryBoardPage;
