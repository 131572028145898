import { useContext, useEffect } from "react";
import useWindowWidth from "../../custom-hooks/useWindowWidth";
import { EventContext } from "./EventPage";
import { removeQueryParam } from "../../functions";
import { useLocation } from "react-router-dom";
import { Element, scroller } from "react-scroll";

const WatchVideo = () => {
  const width = useWindowWidth();

  const { pageData } = useContext(EventContext);
  const { Title, Subtitle, Image, Video_URL } = pageData;
  const location = useLocation();

  useEffect(() => {
    const targetElementId = new URLSearchParams(location.search).get(
      "scrollTo"
    );
    if (targetElementId) {
      scroller.scrollTo(targetElementId, {
        offset: 0,
        duration: 400,
        delay: 0,
      });
    }
  }, [location]);

  return (
    <>
      <section
        id="event-video"
        style={{
          maxWidth: "1200px",
          margin: "0 auto",
          padding: "120px 0 200px 0",
          color: "#252A38",
        }}
      >
        <Element name="event-video">
          <div
            style={{
              maxWidth: "900px",
              width: "100%",
              margin: "0 auto",
              padding: "0 25px",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "center",
              gap: "50px",
            }}
          >
            <div style={{ textAlign: "start" }}>
              <h1
                style={{ fontWeight: 300, fontSize: "56px", color: "#165AAA" }}
              >
                {Title}
              </h1>
              <h3
                style={{
                  fontWeight: 500,
                  fontSize: "28px",
                  color: "#EAC131",
                  marginTop: "8px",
                  maxWidth: "600px",
                }}
              >
                {Subtitle}
              </h3>
            </div>
            {Image && <img src={Image} style={{ width: "200px" }} />}
          </div>
        </Element>
        {Video_URL && (
          <div style={{ padding: "0 25px", marginTop: "160px" }}>
            <iframe
              style={{
                width: `${width - 50}px`,
                height: `${(width - 50) * 0.5}px`,
                maxHeight: "600px",
                resize: "both",
              }}
              src={`https://www.youtube.com/embed/${
                Video_URL.split("=")[1]
              }?rel=0`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
            />
          </div>
        )}
      </section>
    </>
  );
};

export default WatchVideo;
