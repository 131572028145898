import axios from "axios";
import { useState } from "react";
import { formatTelephone } from "../../../functions";
import style from "./style.module.scss";

const SendInviteForm = ({ setStatus }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");

  const postData = async () => {
    await axios
      .post("https://api-naimai.a-rogovsky1276.workers.dev/Vacancies", {
        records: [
          {
            fields: {},
          },
        ],
      })
      .then((res) => console.log(res.data));

    setName("");
    setEmail("");
    setTelephone("");
    setStatus(false);
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          postData();
        }}
        className={style["form__container"]}
      >
        <h1>Надішли інвайт</h1>
        <h5>давайте запросимо вашого знайомого</h5>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Ім’я знайомого"
          required
        />
        <input
          type={"tel"}
          pattern="\+380 \d{2} \d{3} \d{2} \d{2}"
          placeholder="+380 XX XXX XX XX"
          value={formatTelephone(telephone)}
          onChange={(e) => setTelephone(e.target.value)}
          required
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Електронна адреса знайомого"
        />
        <button type="submit">Приєднатися</button>
      </form>
    </>
  );
};

export default SendInviteForm;
