import { useState } from "react";
import "./ModulesMap.scss";
import { PulsedCircle } from "./PulsedCircle";
import PulsedCircleWrapper from "./PulsedCircleWrapper";

export const ModulesMap = () => {
  const [clickedCircle, setClickedCircle] = useState(null);

  const popUpContent = [
    {
      icon: "🚀",
      title: "Модуль 1",
      text: "Вступ до розробки технічних проектів",
    },
    {
      icon: "🎯",
      title: "Модуль 2",
      text: "Визначення цілей та завдань проекту. Формулювання ключової пропозиції",
    },
    {
      icon: "💡",
      title: "Модуль 3",
      text: "Розробка стратегії розвитку та масштабування проекту",
    },
    {
      icon: "📈",
      title: "Модуль 4",
      text: "Оцінка технологічного стану ринку",
    },
    {
      icon: "🔍",
      title: "Модуль 5",
      text: "Визначення потреб користувачів та валідація ідей",
    },
    {
      icon: "🛠️",
      title: "Модуль 6",
      text: "Реалізація мінімально життєздатного продукту (MVP)",
    },
    {
      icon: " 🎓",
      title: "Модуль 7",
      text: "Презентація та захист результатів проекту",
    },
  ];

  return (
    <>
      <div
        style={{
          maxWidth: "1200px",
          width: "90%",
          margin: "0 auto",
          height: "0px",
        }}
      >
        <div className="module-text__block-top">
          Пройшовши курс, ви отримаєте знання та навички, які допоможуть вам
          досягти успіху у вашій кар'єрі, а також зможете збільшити свій досвід
          та показати свою експертизу у сучасних технологіях.
        </div>
      </div>
      <div className="module_container">
        <div className="rhombus">
          <div className="div1">
            <PulsedCircleWrapper
              clickedCircle={clickedCircle}
              setClickedCircle={setClickedCircle}
              popUpPosition={{ x: 30, y: 60 }}
              popUpContent={popUpContent[0]}
            >
              <PulsedCircle>1</PulsedCircle>
            </PulsedCircleWrapper>
          </div>
          <div className="div2">
            <PulsedCircleWrapper
              clickedCircle={clickedCircle}
              setClickedCircle={setClickedCircle}
              popUpPosition={{ x: -530, y: 245 }}
              popUpContent={popUpContent[1]}
            >
              <PulsedCircle>2</PulsedCircle>
            </PulsedCircleWrapper>
          </div>
          <div className="div3">
            <p className="sqare_title">MOДУЛЬ</p>
            <p className="square_img">🚀</p>
            <p className="sqare_number">1</p>
          </div>
          <div className="div4">
            <>
              <p className="sqare_title">MOДУЛЬ</p>
              <p className="square_img">🎯</p>
              <p className="sqare_number">2</p>
            </>
          </div>
          <div className="div5">
            <p className="sqare_title">MOДУЛЬ</p>
            <p className="square_img">💡</p>
            <p className="sqare_number">3</p>
          </div>
          <div className="div6">
            <PulsedCircleWrapper
              clickedCircle={clickedCircle}
              setClickedCircle={setClickedCircle}
              popUpPosition={{ x: 240, y: -410 }}
              popUpContent={popUpContent[4]}
            >
              <PulsedCircle>5</PulsedCircle>
            </PulsedCircleWrapper>
          </div>
          <div className="div7">
            <PulsedCircleWrapper
              clickedCircle={clickedCircle}
              setClickedCircle={setClickedCircle}
              popUpPosition={{ x: -420, y: -420 }}
              popUpContent={popUpContent[6]}
            >
              <PulsedCircle>7</PulsedCircle>
            </PulsedCircleWrapper>
          </div>
          <div className="div8">
            <PulsedCircleWrapper
              clickedCircle={clickedCircle}
              setClickedCircle={setClickedCircle}
              popUpPosition={{ x: -1120, y: -450 }}
              popUpContent={popUpContent[2]}
            >
              <PulsedCircle>3</PulsedCircle>
            </PulsedCircleWrapper>
          </div>
          <div className="div9">
            <p className="sqare_title">MOДУЛЬ</p>
            <p className="square_img">🔍</p>
            <p className="sqare_number">5</p>
          </div>
          <div className="div10">
            <p className="sqare_title">MOДУЛЬ</p>
            <p className="square_img">📈</p>
            <p className="sqare_number">4</p>
          </div>
          <div className="div11">
            <PulsedCircleWrapper
              clickedCircle={clickedCircle}
              setClickedCircle={setClickedCircle}
              popUpPosition={{ x: -410, y: -1050 }}
              popUpContent={popUpContent[5]}
            >
              <PulsedCircle>6</PulsedCircle>
            </PulsedCircleWrapper>
          </div>
          <div className="div12">
            <PulsedCircleWrapper
              clickedCircle={clickedCircle}
              setClickedCircle={setClickedCircle}
              popUpPosition={{ x: -870, y: -870 }}
              popUpContent={popUpContent[3]}
            >
              <PulsedCircle>4</PulsedCircle>
            </PulsedCircleWrapper>
          </div>
          <div className="div13">
            <p className="sqare_title">MOДУЛЬ</p>
            <p className="square_img">🛠️</p>
            <p className="sqare_number">6</p>
          </div>
        </div>
      </div>
      <div
        style={{
          maxWidth: "1200px",
          width: "90%",
          margin: "0 auto",
          height: "0px",
        }}
      >
        <div className="module-text__block-bottom">
          Таким чином, програма стане солідною основою для вашого розвитку як
          IT-спеціаліста, а ваші проекти - відмінним прикладом соціального
          впливу та змін на краще у суспільстві.
        </div>
      </div>
    </>
  );
};
