import DesignForGood from "./DesignForGood";
import ShowTalentBanner from "./ShowTalentBanner";
import BestDesign from "./BestDesign";
import Header from "../../components/General/HeaderSection/Header/Header";
import Main from "../../components/General/Main";
import Footer from "../HomePage/Footer/Footer";
import { ContestData } from "../ContestOverviewPage/ContestOverviewPage";
import FAQ from "../../components/FAQ/FAQ";
import ContestWithFetchData from "../../components/General/ContestWithFetchData";
import image1 from "../../assets/img/Group_ContestOverview.png";
import { Link, useParams } from "react-router-dom";

export const RegisterPopUpBtn = () => {
  const { contestID } = useParams();

  return (
    <>
      <Link to={`/contest/${contestID}/registration?scrollTo=contest-registr`}>
        <button
          className="blue-hover scale-125-animation"
          style={{
            padding: "20px 15px",
            height: "90px",
            width: "90px",
            borderRadius: "50%",
            margin: "32px 0 0 0",
            textTransform: "uppercase",
            fontSize: "14px",
            fontWeight: 700,
            position: "fixed",
            bottom: "25px",
            right: "25px",
          }}
        >
          подати
          <br />
          заявку
        </button>
      </Link>
    </>
  );
};

const ContestReviwPage = ({ contest }) => {
  return (
    <>
      <Header image={image1}>
        <h3>Інтернатура IT-Паросток </h3>
        <h1 style={{ fontSize: "60px" }}>{contest["Title"]}</h1>
        <p>{contest["Description"]}</p>
      </Header>
      <Main>
        <ContestData.Provider value={{ contestData: contest }}>
          <DesignForGood />
          <ShowTalentBanner contestTitle={contest["Title"]} />
          <BestDesign contestID={contest["Location"]} />
          <FAQ
            title={"Відповіді на питання до конкурсів"}
            quetions={contest["FAQ"] ? JSON.parse(contest["FAQ"]) : []}
          />
        </ContestData.Provider>
      </Main>
      <Footer />

      <RegisterPopUpBtn />
    </>
  );
};

export default ContestWithFetchData(ContestReviwPage);
