import more from "../../../../assets/img/svg/more.svg";
import people from "../../../../assets/img/intro/photos.png";

import { Fragment, useEffect, useRef, useState } from "react";

import useIsViewport from "../../../../custom-hooks/useIsViewport";
import SideMenu from "../SideMenu/SideMenu";
import style from "./style.module.scss";
import Navbar from "./Navbar/Navbar";
import Logo from "./Logo";
import BurgerMenu from "./BurgerMenu";

export const navItems = [
  { link: "/", title: "Головна" },
  { link: "/internships", title: "Інтернатури" },
  { link: "/graduates", title: "Відгуки" },
  { link: "/media", title: "Медіа" },
  { link: "/supervisory-board", title: "Наглядова рада" },
  { link: "/partners", title: "Партнери" },
  { link: "/contest-list", title: "Конкурси" },
];

const Header = ({ children, image }) => {
  const [isSideMenu, setIsSideMenu] = useState(false);
  const [navbarWidth, setNavbarWidth] = useState(null);
  const [navbarHeight, setNavbarHeight] = useState(null);
  const navbarWrapper = useRef();
  const navbar = useRef();

  useEffect(() => {
    const element = navbarWrapper.current;
    const element2 = navbar.current;

    const observer = new ResizeObserver((entries) => {
      const newWidth = entries[0].contentRect.width;
      setNavbarWidth(newWidth);
    });
    const observer2 = new ResizeObserver((entries) => {
      const newHeight = entries[0].contentRect.height;
      setNavbarHeight(newHeight);
    });

    observer.observe(element);
    observer2.observe(element2);

    return () => {
      observer.unobserve(element);
      observer2.unobserve(element2);
    };
  }, []);

  const block = useRef();
  useIsViewport(block);
  return (
    <>
      <header
        id="intro"
        ref={block}
        className={`pa-1 ${style["header"]}`}
        data-aos="fade-down"
      >
        <div className="container">
          <div
            style={{ marginTop: "40px", height: navbarHeight }}
            ref={navbarWrapper}
          >
            <div
              className={style["navbar-wrapper"]}
              ref={navbar}
              style={{
                position: `${isSideMenu ? "fixed" : "relative"}`,
                zIndex: 10,
                top: `${isSideMenu ? "40px" : "0"}`,
                width: navbarWidth,
              }}
            >
              <div className={style["logo-wrapper"]}>
                <Logo />
              </div>
              <Navbar />
              <BurgerMenu
                setIsSideMenu={setIsSideMenu}
                isSideMenu={isSideMenu}
              />
            </div>
          </div>
          <div className={style["header-content"]}>
            <div
              className={`${style["header-content__left"]} ${style["header-content__left--general"]}`}
            >
              {children}
            </div>
            <div className={style["header-content__right"]}>
              <img src={image || people} alt="Photos" />
            </div>
          </div>
          <div className={style["to-footer__btn"]}>
            <a href="#footer">
              <img src={more} />
            </a>
          </div>
        </div>
      </header>
      <SideMenu
        setIsSideMenu={setIsSideMenu}
        isSideMenu={isSideMenu}
        navItems={navItems}
      />
    </>
  );
};

export default Header;
