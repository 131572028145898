import LetsMakeChangesBanner from "./LetsMakeChangesBanner";
import Sponsorship from "./Sponsorship";
import Contests from "./Contests";

import Footer from "../HomePage/Footer/Footer";
import Main from "../../components/General/Main";
import Header from "../../components/General/HeaderSection/Header/Header";
import FAQ from "../../components/FAQ/FAQ";

import image1 from "../../assets/img/Group_ContestList.png";

const ContestListPage = () => {
  return (
    <>
      <Header image={image1}>
        <h3>Інтернатура IT-Паросток</h3>
        <h1>Конкурси</h1>
        <p>
          Конкурси - це майданчик, де творчі особистості можуть продемонструвати
          свої здібності та отримати визнання від інших учасників. Приєднуйтесь
          до наших конкурсів, представляйте свої роботи та заряджайтеся
          натхненням від талановитих людей з усього світу.
        </p>
      </Header>
      <Main>
        <Contests />
        {/* <Sponsorship /> */}
        {/* <LetsMakeChangesBanner /> */}
        <FAQ title={"Відповіді на Поширені питання"} />
      </Main>
      <Footer />
    </>
  );
};

export default ContestListPage;
