import "../../App.scss";
import "../../styles/custom.sass";

import { useEffect, useState, useContext, createContext } from "react";
import SidenavBar from "../../components/Sidenav/SidenavBar";
import { useNavigate, useParams } from "react-router-dom";

import LittlePreloader from "../../components/LittlePreloader/LittlePreloader";
import axios from "axios";

import Introduction from "./Introduction/Introduction";
import HaveQuetions from "../../components/General/HaveQuetions";
import GrowthLevels from "./GrowthLevels/GrowthLevels";
import GuideMap from "./GuideMap/GuideMap";
import HowItWorks from "./HowItWorks/HowItWorks";
import Reviews from "./Reviews";
import Registration from "./Registration";
import Main from "../../components/General/Main";
import Header from "../../components/General/HeaderSection/Header/Header";
import Footer from "../../components/General/Footer/Footer";
import FAQ from "../../components/FAQ/FAQ";
import { useQuery } from "react-query";

export const PageContext = createContext();

const InternshipPage = () => {
  const [pageData, setPageData] = useState(null);
  const [selectedNavItem, setSelectedNavItem] = useState("#header");
  const { option } = useParams();
  const navigate = useNavigate();

  const fetchInternship = async () => {
    const status = encodeURIComponent(`Status = 'Active'`);
    let data = await axios
      .get(
        `https://api-it-sprout.a-rogovsky1276.workers.dev/Internships?filterByFormula=${status}`
      )
      .then((res) => res.data.records);
    data = data.map((record) => record.fields);

    const internship = data.find(
      (internship) =>
        internship["URL"].toLowerCase() ===
        `/internship/${option}`.toLowerCase()
    );

    return internship;
  };

  const { isLoading } = useQuery("fetch-internship", fetchInternship, {
    onSuccess: (responseData) => {
      if (!responseData) navigate("/404");
      setPageData(responseData);
    },
  });

  if (isLoading || !pageData) {
    return <LittlePreloader />;
  }

  return (
    <>
      <PageContext.Provider
        value={{ selectedNavItem, setSelectedNavItem, pageData }}
      >
        <Header
          image={
            pageData["Mentors"]
              ? `https://drive.google.com/uc?export=view&id=${pageData["Mentors"]}`
              : ""
          }
        >
          <h3>Інтернатура IT-Паросток</h3>
          <h1>{pageData["Headline"]}</h1>
          <p>
            Мрієте про хорошу роботу, але бракує досвіду? Не впадайте у відчай,
            IT-Паросток відкриває безкоштовнуn інтернатуру для випускників
            курсів та ВУЗів!
          </p>
        </Header>
        <Main>
          <SidenavBar />
          <Introduction />
          <HaveQuetions formId={pageData["Internship_Form_ID"]} />
          <GrowthLevels />
          <GuideMap />
          <HowItWorks />
          {/* <Reviews /> */}
          <Registration />
          <FAQ
            title={"Відповіді на поширені питання"}
            quetions={pageData["FAQ"] ? JSON.parse(pageData["FAQ"]) : []}
          />
        </Main>
        <Footer />
      </PageContext.Provider>
    </>
  );
};
export default InternshipPage;
