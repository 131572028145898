import IsSuitableAccordion from "./IsSuitableAccordion/IsSuitableAccordion";
import style from "./style.module.scss";

const SuitableBlock = () => {
  return (
    <>
      <div className={style["block-title__wrapper"]}>
        <h1>Чи підходить мені ця інтернатура?</h1>
        <h4
          style={{
            fontWeight: 500,
            fontSize: "20px",
            color: "#165AAA",
            marginTop: "8px",
          }}
        >
          Інтернатура підходить вам, якщо:
        </h4>
      </div>
      <div className={style["issuitable-accordion__wrapper"]}>
        <IsSuitableAccordion />
      </div>
    </>
  );
};

export default SuitableBlock;
