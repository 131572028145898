import { useRef, useState } from "react";
import uuid from "react-uuid";
import FAQQuestion from "./FAQQuestion";
import useIsViewport from "../../custom-hooks/useIsViewport";
import style from "./style.module.scss";

const FAQ = (props) => {
  const { title, subtitle, quetions = [] } = props;
  const [selected, setSelected] = useState("");

  const defaultQuestions = [
    {
      que: "Навчання дистанційне?",
      ans: "Аби потрапити до нас, необхідно пройти конкурс. Конкурс проводиться у формі успішного виконання тестового завдання. Успішне проходження завдання гарантує прийняття Кандидата на стажування. Критерій успішності — кандидати, котрі зможуть як мінімум відтворити перевірені прийоми, які застосовують світові IT-лідери, а якнайбільше – внести свою евристику. Більше деталей дізнаєтеся пізніше.",
    },
    {
      que: "Скільки часу займає інтернатура?",
      ans: "Аби потрапити до нас, необхідно пройти конкурс. Конкурс проводиться у формі успішного виконання тестового завдання. Успішне проходження завдання гарантує прийняття Кандидата на стажування. Критерій успішності — кандидати, котрі зможуть як мінімум відтворити перевірені прийоми, які застосовують світові IT-лідери, а якнайбільше – внести свою евристику. Більше деталей дізнаєтеся пізніше.",
    },
    {
      que: "Ви гарантуєте працевлаштування?",
      ans: "Аби потрапити до нас, необхідно пройти конкурс. Конкурс проводиться у формі успішного виконання тестового завдання. Успішне проходження завдання гарантує прийняття Кандидата на стажування. Критерій успішності — кандидати, котрі зможуть як мінімум відтворити перевірені прийоми, які застосовують світові IT-лідери, а якнайбільше – внести свою евристику. Більше деталей дізнаєтеся пізніше.",
    },
    {
      que: "Який досвід потрібен для інтернатури?",
      ans: "Аби потрапити до нас, необхідно пройти конкурс. Конкурс проводиться у формі успішного виконання тестового завдання. Успішне проходження завдання гарантує прийняття Кандидата на стажування. Критерій успішності — кандидати, котрі зможуть як мінімум відтворити перевірені прийоми, які застосовують світові IT-лідери, а якнайбільше – внести свою евристику. Більше деталей дізнаєтеся пізніше.",
    },
    {
      que: "Чи виплачуються інтернам стипендія?",
      ans: "Аби потрапити до нас, необхідно пройти конкурс. Конкурс проводиться у формі успішного виконання тестового завдання. Успішне проходження завдання гарантує прийняття Кандидата на стажування. Критерій успішності — кандидати, котрі зможуть як мінімум відтворити перевірені прийоми, які застосовують світові IT-лідери, а якнайбільше – внести свою евристику. Більше деталей дізнаєтеся пізніше.",
    },
  ];

  const block = useRef();
  useIsViewport(block);

  return (
    <section className={style["faq__section"]} id="faq" ref={block}>
      <h2 className={`${style["faq__title"]} p-title`}>{title}</h2>
      {subtitle && (
        <h3 className={`text-center ${style["faq__subtitle"]}`}>{subtitle}</h3>
      )}
      <div
        className={`container  ${style["faq__questions-container"]}`}
        style={{ marginTop: `${subtitle ? "72px" : "144px"}` }}
        onMouseLeave={() => setSelected(null)}
      >
        {quetions && (
          <>
            {quetions.map((item) => {
              const { ans, que } = item;
              return (
                <FAQQuestion
                  key={uuid()}
                  answer={ans}
                  question={que}
                  selected={selected}
                  setSelected={setSelected}
                />
              );
            })}
          </>
        )}
        {defaultQuestions.map((item) => {
          const { ans, que } = item;
          return (
            <FAQQuestion
              key={uuid()}
              answer={ans}
              question={que}
              selected={selected}
              setSelected={setSelected}
            />
          );
        })}
      </div>
    </section>
  );
};

export default FAQ;
