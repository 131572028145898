import style from "./style.module.scss";
import { ReactComponent as LinkedInIcon } from "../../../../assets/img/svg/LinkedIn.svg";

const MentorsAccordionItem = (props) => {
  const {
    data: { image, title, text, linkedin_url },
    currentSlide,
    setCurrentSlide,
    index,
    totalBlocksCount,
    wrapperLength,
  } = props;

  return (
    <>
      <div
        className={style["accordion__item"]}
        style={{
          minWidth: `${currentSlide == index ? "76%" : "25%"}`,
          borderRight: `${
            !(index === totalBlocksCount - 1) ? "3px solid #165AAA" : "none"
          }`,
        }}
        onMouseEnter={() => {
          setCurrentSlide(index);
        }}
        onMouseLeave={() => {
          setCurrentSlide(null);
        }}
      >
        <div
          className={style["accordion__item-title__wrapper"]}
          style={{ minWidth: `${wrapperLength / 4 - 48}px` }}
        >
          <img src={image} />
          <h1>{title}</h1>
          {linkedin_url && (
            <a className={style["linkedin-url"]} href={linkedin_url}>
              <LinkedInIcon className={style["linkedin-url__icon"]} />
              <span className={style["linkedin-url__text"]}>LinkedIn</span>
            </a>
          )}
        </div>
        <div
          className={style["accordion__item-text"]}
          style={{ minWidth: `${(wrapperLength / 4) * 2 - 48}px` }}
        >
          {text}
        </div>
      </div>
    </>
  );
};

export default MentorsAccordionItem;
