import React, { useRef } from "react";
import useIsViewport from "../../custom-hooks/useIsViewport";

const HaveQuetions = ({ formId }) => {
  const block = useRef();
  useIsViewport(block);
  return (
    <>
      <section
        id="have_quetion"
        ref={block}
        style={{
          width: "100%",
          padding: "200px 0 240px 0",
          background: "#252A38",
        }}
      >
        <div
          style={{
            maxWidth: "1200px",
            margin: "0 auto",
          }}
        >
          <div style={{ textAlign: "center", padding: "0 25px" }}>
            <h1 style={{ fontSize: "56px", fontWeight: 300, color: "#EAC131" }}>
              Маєте запитання?
            </h1>
            <h2
              style={{
                fontSize: "32px",
                fontWeight: 400,
                color: "#FFFFFF",
                marginTop: "24px",
              }}
            >
              Заповніть анкету для зв’язку з рекрутером
            </h2>
            <a
              href={`https://airtable.com/shrqWUhjr28faYq4r?prefill_Internship=${formId}&hide_Internship=true`}
              target={"_blank"}
            >
              <button
                className="hover-btn"
                style={{
                  maxWidth: "340px",
                  width: "100%",
                  marginTop: "64px",

                  borderRadius: "8px",
                  padding: "13px 25px",
                }}
              >
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: 700,
                  }}
                >
                  Задати питання
                </span>
              </button>
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default HaveQuetions;
