import { Link } from "react-router-dom";
import style from "./style.module.scss";
import { truncateString } from "../../../functions";

const InternshipsItem = (props) => {
  const {
    data: { title, description, link },
  } = props;

  return (
    <Link className={style["internships-table__item"]} to={link}>
      <div className={style["internships-table__item-title"]}>
        {truncateString(title)}
      </div>
      <div className={style["internships-table__item-description"]}>
        <p>{description}</p>
      </div>
    </Link>
  );
};

export default InternshipsItem;
