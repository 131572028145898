import { Link } from "react-router-dom";
import style from "../style.module.scss";

const NavbarItem = (props) => {
  const { link, children } = props;
  return (
    <li className={`${style["navbar__list-item"]} hover-yellow`}>
      <Link to={link}>
        <span className="nowrap">{children}</span>
      </Link>
    </li>
  );
};

export default NavbarItem;
