import bankInfoImg from "../../../assets/img/invest/bank.png";
import mastercardImg from "../../../assets/img/invest/mastercard.png";
import visaImg from "../../../assets/img/invest/visa.png";
import paymentInfoImg from "../../../assets/img/invest/PaymentInfo.png";
import uuid from "react-uuid";

const Title = () => {
  return (
    <>
      <div style={{ width: "100%", textAlign: "center" }}>
        <h1 style={{ fontWeight: 300, fontSize: "56px", color: "#165AAA" }}>
          Реквізити для оплати
        </h1>
      </div>
    </>
  );
};

const BankInfo = () => {
  return (
    <>
      <div style={{ width: "100%", marginTop: "104px" }}>
        <div className="bankInfo-wrapper" style={{ width: "100%" }}>
          <img style={{ width: "100%", minWidth: "600px" }} src={bankInfoImg} />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "47px",
            marginTop: "20px",
          }}
        >
          <span
            style={{
              fontWeight: 500,
              fontSize: "20px",
              color: "#252A38",
              textAlign: "center",
            }}
          >
            Приймаємо оплату за допомогою:
          </span>
          <div
            style={{
              display: "flex",
              gap: "29px",
              alignItems: "center",
            }}
          >
            <img src={mastercardImg} />
            <img src={visaImg} />
          </div>
        </div>
      </div>
    </>
  );
};

const PaymentInfo = () => {
  const infoList = [
    {
      title: "Найменування організації: ",
      value: "Благодійна Організація «Благодійний  Фонд IT Паросток»",
    },
    {
      title: "Код отримувача:",
      value: "44550531",
    },
    {
      title: "Рахунок отримувача у форматі IBAN: АТ КБ «ПриватБанк»",
      value: "UA883052990000026007014909319",
    },
    {
      title: "Послуга:",
      value: "вкажіть обрану послугу",
    },
    {
      title: "Валюта:",
      value: "гривня",
    },
  ];
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            width: "100%",
            backgroundColor: "#E0E0E040",
            borderRadius: "16px",
            padding: "40px 23px",
            display: "flex",
            flexDirection: "column",
            marginTop: "72px",
          }}
        >
          <ul style={{ display: "flex", flexDirection: "column", gap: "18px" }}>
            {infoList.map((listItem) => {
              const { title, value } = listItem;
              return (
                <li
                  className="paymentInfo-listItem"
                  key={uuid()}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    columnGap: "30px",
                    rowGap: "10px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: 700,
                      fontSize: "14px",
                      color: "#252A38D9",
                    }}
                  >
                    {title}
                  </div>
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#252A38D9",
                    }}
                  >
                    {value}
                  </div>
                </li>
              );
            })}
          </ul>
          <p
            style={{
              textAlign: "center",
              fontWeight: 400,
              fontSize: "14px",
              marginTop: "97px",
            }}
          >
            <span style={{ fontWeight: 700 }}>
              Благодійна Організація «Благодійний  Фонд IT Паросток»
            </span>
             
            <br />
            <br />
            Ідентифікаційний код юридичної особи:  44550531
            <br /> Інформація для здійснення зв’язку: admin@it-sprout.org |
            телефон 0-800-358-497 (безкоштовно)
            <br /> Місцезнаходження юридичної особи: Україна, 65007, Одеська
            обл., місто Одеса, вул.Старопортофранківська, будинок 107, квартира
            21
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "80px",
          }}
        >
          <a href="http://pb.ua/p24b" target="_blank">
            <button
              className="hover-yellow"
              style={{
                backgroundColor: "#165AAA",
                color: "white",
                padding: "13px 32px",
                maxWidth: "340px",
                width: "100%",
                fontWeight: 700,
                borderRadius: "8px",
                textTransform: "uppercase",
              }}
            >
              задонатити
            </button>
          </a>
        </div>
      </div>
    </>
  );
};

const PaymentSection = () => {
  return (
    <>
      <section
        style={{
          maxWidth: "1200px",
          width: "100%",
          margin: "0 auto",
          padding: "120px 25px 200px 25px",
        }}
      >
        <Title />
        <BankInfo />
        <PaymentInfo />
      </section>
    </>
  );
};

export default PaymentSection;
