import { useState } from "react";
import { useContext } from "react";

import { PageContext } from "../../InternshipPage";
import style from "./style.module.scss";
import useResizeObserver from "../../../../custom-hooks/useResizeObserver";
import GrowthLevelsAccordionItem from "./GrowthLevelsAccordionItem";
import useWindowWidth from "../../../../custom-hooks/useWindowWidth";

const LevelSVG = {
  "Trainee": (
    <svg
      style={{ minWidth: "44px" }}
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.5781 0.890384H30.3016C29.35 0.890384 28.5023 1.54117 28.2781 2.49273C27.5945 5.38023 25.0078 7.45288 22 7.45288C18.9922 7.45288 16.4055 5.38023 15.7219 2.49273C15.6152 2.03632 15.3572 1.62949 14.9897 1.33851C14.6223 1.04754 14.1671 0.889583 13.6984 0.890384H2.42188C1.7837 0.890384 1.17166 1.1439 0.720399 1.59516C0.26914 2.04642 0.015625 2.65846 0.015625 3.29663V17.0779C0.015625 17.7161 0.26914 18.3281 0.720399 18.7794C1.17166 19.2306 1.7837 19.4841 2.42188 19.4841H6.52344V40.7029C6.52344 41.3411 6.77695 41.9531 7.22821 42.4044C7.67947 42.8556 8.29151 43.1091 8.92969 43.1091H35.0703C35.7085 43.1091 36.3205 42.8556 36.7718 42.4044C37.223 41.9531 37.4766 41.3411 37.4766 40.7029V19.4841H41.5781C42.2163 19.4841 42.8283 19.2306 43.2796 18.7794C43.7309 18.3281 43.9844 17.7161 43.9844 17.0779V3.29663C43.9844 2.65846 43.7309 2.04642 43.2796 1.59516C42.8283 1.1439 42.2163 0.890384 41.5781 0.890384ZM40.0469 15.5466H33.5391V39.1716H10.4609V15.5466H3.95312V4.82788H12.3367C13.8789 8.72163 17.6688 11.3904 22 11.3904C26.3312 11.3904 30.1211 8.72163 31.6633 4.82788H40.0469V15.5466Z"
        fill="white"
      />
    </svg>
  ),
  "Junior": (
    <svg
      style={{ minWidth: "42px" }}
      width="42"
      height="49"
      viewBox="0 0 42 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.40625 14.4375C9.40625 15.3077 9.75195 16.1423 10.3673 16.7577C10.9827 17.373 11.8173 17.7188 12.6875 17.7188C13.5577 17.7188 14.3923 17.373 15.0077 16.7577C15.623 16.1423 15.9688 15.3077 15.9688 14.4375C15.9688 13.5673 15.623 12.7327 15.0077 12.1173C14.3923 11.502 13.5577 11.1562 12.6875 11.1562C11.8173 11.1562 10.9827 11.502 10.3673 12.1173C9.75195 12.7327 9.40625 13.5673 9.40625 14.4375ZM39.5938 0H2.40625C1.43828 0 0.65625 0.782031 0.65625 1.75V37.8438C0.65625 38.8117 1.43828 39.5938 2.40625 39.5938H39.5938C40.5617 39.5938 41.3438 38.8117 41.3438 37.8438V1.75C41.3438 0.782031 40.5617 0 39.5938 0ZM37.8438 36.0938H4.15625V3.5H37.8438V36.0938ZM26.0312 14.4375C26.0312 15.3077 26.377 16.1423 26.9923 16.7577C27.6077 17.373 28.4423 17.7188 29.3125 17.7188C30.1827 17.7188 31.0173 17.373 31.6327 16.7577C32.248 16.1423 32.5938 15.3077 32.5938 14.4375C32.5938 13.5673 32.248 12.7327 31.6327 12.1173C31.0173 11.502 30.1827 11.1562 29.3125 11.1562C28.4423 11.1562 27.6077 11.502 26.9923 12.1173C26.377 12.7327 26.0312 13.5673 26.0312 14.4375ZM39.7141 44.8438H2.28594C1.38359 44.8438 0.65625 45.6258 0.65625 46.5938V48.5625C0.65625 48.8031 0.836718 49 1.06094 49H40.9336C41.1578 49 41.3383 48.8031 41.3383 48.5625V46.5938C41.3438 45.6258 40.6164 44.8438 39.7141 44.8438ZM29.3125 24.2812H12.6875C12.4469 24.2812 12.25 24.4781 12.25 24.7188V28C12.25 28.2406 12.4469 28.4375 12.6875 28.4375H29.3125C29.5531 28.4375 29.75 28.2406 29.75 28V24.7188C29.75 24.4781 29.5531 24.2812 29.3125 24.2812Z"
        fill="white"
      />
    </svg>
  ),
  "Middle": (
    <svg
      style={{ minWidth: "42px" }}
      width="42"
      height="50"
      viewBox="0 0 42 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 0.5L0 7.5V28.5C0 40.0992 9.40078 49.5 21 49.5C32.5992 49.5 42 40.0992 42 28.5V7.5L21 0.5ZM38.0625 28.5C38.0625 37.9227 30.4227 45.5625 21 45.5625C11.5773 45.5625 3.9375 37.9227 3.9375 28.5V10.4531L21 4.4375L38.0625 10.4531V28.5Z"
        fill="white"
      />
    </svg>
  ),

  "Senior": (
    <svg
      style={{ minWidth: "46px" }}
      width="46"
      height="56"
      viewBox="0 0 46 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.4667 42.6542C45.4667 35.3533 41.3048 29.0467 35.2848 26.1093V15.2626C35.2848 14.2748 34.9476 13.3196 34.3239 12.5673L24.6257 0.745794C24.2185 0.248598 23.6394 0 23.0667 0C22.4939 0 21.9148 0.248598 21.5076 0.745794L11.8094 12.5673C11.19 13.3212 10.8496 14.2758 10.8485 15.2626V26.1093C4.82847 29.0467 0.666656 35.3533 0.666656 42.6542H10.6257C10.4794 43.1252 10.403 43.6355 10.403 44.2112C10.403 45.657 10.8867 47.0701 11.7648 48.1888C12.4817 49.1037 13.431 49.7966 14.5076 50.1907C15.9776 53.7234 19.3185 56 23.0667 56C24.9185 56 26.713 55.4374 28.2467 54.3776C29.7485 53.3439 30.913 51.8981 31.6194 50.1907C32.6955 49.7991 33.6449 49.1084 34.3621 48.1953C35.2415 47.0665 35.7216 45.664 35.7239 44.2178C35.7239 43.6682 35.6539 43.1449 35.5267 42.6607H45.4667V42.6542ZM38.9948 35.157C39.593 36.1122 40.0767 37.1393 40.4267 38.2056H35.0303V31.0551C36.618 32.1203 37.9689 33.5181 38.9948 35.157ZM15.1757 26.1093V15.3607L23.0667 5.74393L30.9576 15.3607V38.2056H15.1757V26.1093ZM5.70666 38.2056C6.05666 37.1393 6.54029 36.1122 7.13848 35.157C8.17575 33.5084 9.52484 32.115 11.103 31.0551V38.2056H5.70666ZM30.4548 45.8075C30.1239 46.0037 29.7421 46.0822 29.3667 46.0299L28.1257 45.8729L27.9476 47.1421C27.6039 49.6215 25.5039 51.4925 23.0667 51.4925C20.6294 51.4925 18.5294 49.6215 18.1857 47.1421L18.0076 45.8664L16.7667 46.0299C16.3894 46.0761 16.0077 45.9958 15.6785 45.8009C15.1248 45.4738 14.7812 44.8654 14.7812 44.2047C14.7812 43.5112 15.1567 42.9355 15.7103 42.6477H30.4294C30.9894 42.9421 31.3585 43.5178 31.3585 44.2047C31.3521 44.872 31.0085 45.4869 30.4548 45.8075ZM20.0121 20.6729C20.0121 21.5057 20.3339 22.3044 20.9068 22.8933C21.4796 23.4822 22.2565 23.8131 23.0667 23.8131C23.8768 23.8131 24.6537 23.4822 25.2265 22.8933C25.7994 22.3044 26.1212 21.5057 26.1212 20.6729C26.1212 19.8401 25.7994 19.0413 25.2265 18.4524C24.6537 17.8636 23.8768 17.5327 23.0667 17.5327C22.2565 17.5327 21.4796 17.8636 20.9068 18.4524C20.3339 19.0413 20.0121 19.8401 20.0121 20.6729Z"
        fill="white"
      />
    </svg>
  ),
};

const GrowthLevelsAccordion_more1028px = (props) => {
  const { blocks } = props;
  const [currentSlide, setCurrentSlide] = useState(null);
  const [blocksWrapper, dimensions] = useResizeObserver();
  return (
    <>
      <div ref={blocksWrapper} className={style["accordion-wrapper"]}>
        <div
          className={style["accordion"]}
          style={{
            transform: `translateX(-${
              currentSlide
                ? (currentSlide - 2) * (dimensions.width / blocks.length)
                : 0
            }px)`,
          }}
        >
          {blocks.map((block, index) => {
            const { level, salary } = block;
            return (
              <GrowthLevelsAccordionItem
                key={index}
                index={index}
                icon={LevelSVG[level]}
                salary={salary}
                level={level}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
                totalBlocksCount={blocks.length}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

const GrowthLevelsAccordion_less1028px = (props) => {
  const { blocks } = props;
  const [blocksWrapper, dimensions] = useResizeObserver();
  return (
    <>
      <div className={style["blocks-wrapper"]}>
        {blocks.map((block, index) => {
          const { level, salary } = block;
          return (
            <div key={index} className={style["blocks__item"]}>
              {LevelSVG[level]}
              <div className={style["blocks__item-content"]}>
                <h1>{salary}</h1>
                <h2>{level}</h2>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const GrowthLevelsAccordion = () => {
  const { pageData } = useContext(PageContext);
  const blocks = JSON.parse(pageData["GrowthLevels_accordion"]);
  const width = useWindowWidth();

  if (width >= 1028) {
    return <GrowthLevelsAccordion_more1028px blocks={blocks} />;
  } else {
    return <GrowthLevelsAccordion_less1028px blocks={blocks} />;
  }
};

export default GrowthLevelsAccordion;
