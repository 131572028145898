import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LittlePreloader from "../LittlePreloader/LittlePreloader";

const ContestWithFetchData = (Component) => {
  const ContestWithFetchDataComponent = (props) => {
    const [contest, setContest] = useState(null);
    const navigate = useNavigate();
    const { contestID } = useParams();

    const fetchData = async () => {
      try {
        const data = await axios
          .get(
            `https://api-it-sprout.a-rogovsky1276.workers.dev/Contests/rec${contestID}`
          )
          .then((res) => res.data);

        data.error ? navigate("/404") : setContest(data.fields);
      } catch (e) {
        console.log(e);
        navigate("/404");
      }
    };

    useEffect(() => {
      fetchData();
    }, []);

    if (!contest) {
      return <LittlePreloader />;
    }

    return <Component {...props} contest={contest} />;
  };
  return ContestWithFetchDataComponent;
};

export default ContestWithFetchData;
