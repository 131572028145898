import { Link as LinkRouter, useParams } from "react-router-dom";
import { ContestData } from "../ContestOverviewPage/ContestOverviewPage";
import { useContext } from "react";

const ShowTalentBanner = ({ contestTitle }) => {
  const { contestID } = useParams();
  const { contestData } = useContext(ContestData);

  const { Reward } = contestData;

  return (
    <div style={{ width: "100%", backgroundColor: "#252A38" }}>
      <section style={{ maxWidth: "1200px", width: "100%", margin: "0 auto" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "200px 25px 240px 25px",
          }}
        >
          <div
            style={{
              fontWeight: 300,
              fontSize: "56px",
              color: "#EAC131",
              textAlign: "center",
            }}
          >
            Проявіть свій талант
          </div>
          <div
            style={{
              fontSize: "32px",
              fontWeight: 400,
              color: "#fff",
              marginTop: "24px",
              textAlign: "center",
              maxWidth: "1000px",
              width: "100%",
            }}
          >
            долучайтеся до конкурсу «{contestTitle}»!
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              gap: "24px",
              flexWrap: "wrap",
              justifyContent: "center",
              marginTop: "64px",
            }}
          >
            <LinkRouter
              to={`/contest/${contestID}/registration?scrollTo=contest-registr`}
            >
              <button
                className="blue-hover"
                style={{
                  padding: "13px 20px",
                  maxWidth: "340px",
                  width: "100%",
                  borderRadius: "8px",
                  margin: "32px 0 0 0",
                  textTransform: "uppercase",
                  fontSize: "16px",
                  fontWeight: 700,
                  height: "60px",
                }}
              >
                подати заявку
              </button>
            </LinkRouter>
          </div>
          <div
            style={{
              maxWidth: "842px",
              width: "100%",
              margin: "70px auto 0 auto",
              textAlign: "center",
              fontSize: "16px",
              fontWeight: 500,
              color: "#fff",
            }}
          >
            {Reward}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ShowTalentBanner;
