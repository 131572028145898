import style from "./style.module.scss";
import EduResultsAccordion from "./EduResultsAccordion/EduResultsAccordion";

const EduResultsBlock = () => {
  return (
    <div className={style["block-wrapper"]}>
      <div className={style["block-title__wrapper"]}>
        <h1>Чому я в результаті навчусь?</h1>
      </div>
      <div className={style["eduResults-accordion__wrapper"]}>
        <EduResultsAccordion />
      </div>
    </div>
  );
};

export default EduResultsBlock;
