import uuid from "react-uuid";
import { ReactComponent as Icon1 } from "../../../assets/img/svg/icons/feat-001.svg";
import { ReactComponent as Icon2 } from "../../../assets/img/svg/icons/feat-002.svg";
import { ReactComponent as Icon3 } from "../../../assets/img/svg/icons/feat-003.svg";
import style from "./style.module.scss";
import InternshipPros from "./InternshipPros/InternshipPros";
import InternshipProsItem from "./InternshipPros/InternshipProsItem";
import Achievements from "./Achievements/Achievements";
import AchievementsItem from "./Achievements/AchievementsItem";

const Features = () => {
  const blocks1 = [
    {
      icon: <Icon1 />,
      title: "Старт Інтернатури",
      text: "Участь у проєктах які стартуються з нуля. Ви отримуєте безцінний досвід, в якому зацікавлені роботодавці",
    },
    {
      icon: <Icon2 />,
      title: "Навчання та Прогрес",
      text: "Ви отримаєте точне розуміння того, що прагне потенційний замовник. Ці знання залишаться з вами назавжди.",
    },
    {
      icon: <Icon3 />,
      title: "Запрошення на роботу",
      text: " Інтернатура IT-Паростку - це проєкти погоджуються з іншими організаціями, в тому числі на державному рівні.",
    },
  ];
  const blocks2 = [
    {
      result: "52%",
      target: "Пройшли конкурс",
      achievment: "До Інтернатури!",
    },
    {
      result: "80%",
      target: "Випускників",
      achievment: "Працевлаштовано!",
    },
    {
      result: "18%",
      target: "Закінчіли з відзнакою",
      achievment: "Відмінно!",
    },
  ];
  return (
    <section className={style["features__section"]} id="features">
      <div className={style["internship-pros__wrapper"]}>
        <div className={style["internship-pros__title-wrapper"]}>
          <h1>Переваги Інтернатури</h1>
        </div>
        <InternshipPros>
          {blocks1.map((block) => {
            return <InternshipProsItem key={uuid()} data={block} />;
          })}
        </InternshipPros>
      </div>
      <div className={style["achievements__wrapper"]}>
        <div className={style["achievements__title-wrapper"]}>
          <h1>Досягнення IT-Паросток у Воєнний Час</h1>
        </div>
        <Achievements>
          {blocks2.map((block) => {
            return <AchievementsItem key={uuid()} data={block} />;
          })}
        </Achievements>
      </div>
    </section>
  );
};

export default Features;
