import axios from "axios";
import { Fragment, useEffect, useMemo, useState } from "react";
import uuid from "react-uuid/uuid";
import { removeSpaces } from "../../functions";
import style from "./style.module.css";
import button from "../../styles/modules/Button.module.css";
import { Link } from "react-router-dom";

const Contests = () => {
  const [contestsAll, setContestsAll] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [contests, setContests] = useState(null);
  const [filterObj, setFilterObj] = useState({ input: "", type: [] });

  const totalPages = useMemo(() => {
    if (contests) {
      return Math.ceil(contests.length / 9);
    }
  }, [contests]);

  const filterBtns = [
    { status: "Active", text: "Активні" },
    { status: "Preview", text: "Анонси" },
    { status: "Gone", text: "Минулі" },
  ];

  const fetchData = async () => {
    let offset = null;
    while (true) {
      const response = await axios
        .get(
          `https://api-it-sprout.a-rogovsky1276.workers.dev/Contests?filterByFormula={Active}=1&${
            offset ? `offset=${offset}` : ""
          }`
        )
        .then((res) => res.data);

      offset = response.offset;
      const data = response.records.map((record) => record.fields);
      setContestsAll((prev) => [...prev, ...data]);
      if (!offset) {
        break;
      }
    }
  };

  useEffect(() => {
    const filteredContests = contestsAll.filter((contest) => {
      setCurrentPage(1);
      if (filterObj.input === "" && filterObj.type.length === 0) return contest;

      if (
        contest["Title"]
          .toLowerCase()
          .includes(removeSpaces(filterObj.input).toLowerCase()) &&
        filterObj.type.indexOf(contest["Status"]) !== -1
      ) {
        return contest;
      }

      if (filterObj.type.length === 0 && filterObj.input !== "") {
        if (
          contest["Title"]
            .toLowerCase()
            .includes(removeSpaces(filterObj.input).toLowerCase())
        ) {
          return contest;
        }
      }
    });

    setContests(filteredContests);
  }, [filterObj, contestsAll]);

  useEffect(() => {
    fetchData();
  }, []);

  const searchIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M16.4519 14.8499L11.258 9.65596C12.064 8.61396 12.4999 7.33997 12.4999 5.99997C12.4999 4.39598 11.874 2.89199 10.742 1.75799C9.60996 0.623997 8.10197 0 6.49997 0C4.89798 0 3.38999 0.625997 2.25799 1.75799C1.124 2.88999 0.5 4.39598 0.5 5.99997C0.5 7.60197 1.126 9.10996 2.25799 10.242C3.38999 11.376 4.89598 11.9999 6.49997 11.9999C7.83997 11.9999 9.11196 11.564 10.154 10.76L15.3479 15.9519C15.3632 15.9672 15.3812 15.9793 15.4012 15.9875C15.4211 15.9958 15.4424 16 15.4639 16C15.4855 16 15.5068 15.9958 15.5267 15.9875C15.5466 15.9793 15.5647 15.9672 15.5799 15.9519L16.4519 15.0819C16.4672 15.0667 16.4793 15.0486 16.4875 15.0287C16.4958 15.0088 16.5 14.9875 16.5 14.9659C16.5 14.9444 16.4958 14.9231 16.4875 14.9032C16.4793 14.8832 16.4672 14.8652 16.4519 14.8499V14.8499ZM9.66796 9.16796C8.81996 10.014 7.69597 10.48 6.49997 10.48C5.30398 10.48 4.17998 10.014 3.33199 9.16796C2.48599 8.31996 2.01999 7.19597 2.01999 5.99997C2.01999 4.80398 2.48599 3.67798 3.33199 2.83199C4.17998 1.98599 5.30398 1.51999 6.49997 1.51999C7.69597 1.51999 8.82196 1.98399 9.66796 2.83199C10.514 3.67998 10.98 4.80398 10.98 5.99997C10.98 7.19597 10.514 8.32196 9.66796 9.16796Z"
        fill="#0074FC"
        fillOpacity="0.5"
      />
    </svg>
  );

  const displayRange = () => {
    const range = contests.slice((currentPage - 1) * 9, currentPage * 9);
    return range;
  };

  return (
    <section className={style["contests-section"]}>
      <div className={style["contests-section__title"]}>
        <h1>Наші конкурси</h1>
        <h3>Приймайте участь у наших конкурсах</h3>
      </div>
      <div className={style["contests-section__content"]}>
        <div className={style["contests-filter__wrapper"]}>
          <div className="relative">
            <span className={style["contests-filter__input-icon"]}>
              {searchIcon}
            </span>
            <input
              className={style["contests-filter__input"]}
              value={filterObj.input}
              onChange={(e) => {
                setFilterObj((prev) => {
                  const copy = { ...prev };
                  copy.input = e.target.value;
                  return copy;
                });
              }}
              type="text"
              placeholder="Пошук"
            />
          </div>
          <div className={style["contests-filter__btns"]} id="contests-filter">
            {filterBtns.map((btn) => {
              const { status, text } = btn;
              return (
                <button
                  className={style["contests-filter__btns"]}
                  key={uuid()}
                  onClick={() => {
                    if (filterObj.type.indexOf(status) === -1) {
                      setFilterObj((prev) => {
                        const copy = { ...prev };
                        copy.type.push(status);
                        return copy;
                      });
                    } else {
                      setFilterObj((prev) => {
                        const copy = { ...prev };
                        copy.type = copy.type.filter(
                          (option) => option !== status
                        );
                        return copy;
                      });
                    }
                  }}
                  style={{
                    border: `1px solid ${
                      filterObj.type.includes(status) ? "#165aaa" : "#B1C3FF"
                    }`,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.6"
                    height="16"
                    viewBox="0 0 18 24"
                    fill="none"
                  >
                    <path
                      d="M9.3001 24C8.7801 24 8.3101 23.8748 7.8901 23.6244C7.4701 23.374 7.1401 23.0392 6.9001 22.62C6.2401 22.62 5.6749 22.3848 5.2045 21.9144C4.7341 21.444 4.4993 20.8792 4.5001 20.22V15.96C3.3201 15.18 2.3749 14.15 1.6645 12.87C0.954099 11.59 0.599299 10.2 0.600099 8.7C0.600099 6.28 1.4453 4.2248 3.1357 2.5344C4.8261 0.844 6.8809 -0.000799432 9.3001 5.67644e-07C11.7201 5.67644e-07 13.7753 0.8452 15.4657 2.5356C17.1561 4.226 18.0009 6.2808 18.0001 8.7C18.0001 10.24 17.6449 11.64 16.9345 12.9C16.2241 14.16 15.2793 15.18 14.1001 15.96V20.22C14.1001 20.88 13.8649 21.4452 13.3945 21.9156C12.9241 22.386 12.3593 22.6208 11.7001 22.62C11.4601 23.04 11.1301 23.3752 10.7101 23.6256C10.2901 23.876 9.8201 24.0008 9.3001 24ZM6.9001 17.37C6.9001 17.6848 7.1553 17.94 7.4701 17.94H11.1301C11.4449 17.94 11.7001 17.6848 11.7001 17.37C11.7001 17.0552 11.4449 16.8 11.1301 16.8H7.4701C7.15529 16.8 6.9001 17.0552 6.9001 17.37Z"
                      fill={`${
                        status === "Active"
                          ? "#EAC131"
                          : status === "Preview"
                          ? "#A8C337"
                          : status === "Gone" && "#FE6C6F"
                      }`}
                    />
                  </svg>
                  <span>{text}</span>
                </button>
              );
            })}
          </div>
        </div>
        <div className={style["contests-table"]}>
          {contests &&
            displayRange(contests).map((contest) => {
              const { Title, Works, Status, Image, Location } = contest;
              return (
                <Fragment key={uuid()}>
                  <Link
                    className={"constests-table__card"}
                    to={`/contest/${Location.slice(3)}/overview`}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: "360px",
                      justifySelf: "center",
                    }}
                  >
                    <img src={Image[0].url} style={{ borderRadius: "24px" }} />
                    <div className={style["constests-table__card-content"]}>
                      <div className={style["constests-table__card-title"]}>
                        {Title}
                      </div>
                      <div className="flex justify-between">
                        <div style={{ fontWeight: 500, fontSize: "26px" }}>
                          Подано робіт
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "16px",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 700,
                              fontSize: "28px",
                              color: "#165AAA",
                            }}
                          >
                            {Works ? Works.length : 0}
                          </span>

                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="24"
                            viewBox="0 0 18 24"
                            fill="none"
                          >
                            <path
                              d="M9.3001 24C8.7801 24 8.3101 23.8748 7.8901 23.6244C7.4701 23.374 7.1401 23.0392 6.9001 22.62C6.2401 22.62 5.6749 22.3848 5.2045 21.9144C4.7341 21.444 4.4993 20.8792 4.5001 20.22V15.96C3.3201 15.18 2.3749 14.15 1.6645 12.87C0.954099 11.59 0.599299 10.2 0.600099 8.7C0.600099 6.28 1.4453 4.2248 3.1357 2.5344C4.8261 0.844 6.8809 -0.000799432 9.3001 5.67644e-07C11.7201 5.67644e-07 13.7753 0.8452 15.4657 2.5356C17.1561 4.226 18.0009 6.2808 18.0001 8.7C18.0001 10.24 17.6449 11.64 16.9345 12.9C16.2241 14.16 15.2793 15.18 14.1001 15.96V20.22C14.1001 20.88 13.8649 21.4452 13.3945 21.9156C12.9241 22.386 12.3593 22.6208 11.7001 22.62C11.4601 23.04 11.1301 23.3752 10.7101 23.6256C10.2901 23.876 9.8201 24.0008 9.3001 24ZM6.9001 17.37C6.9001 17.6848 7.1553 17.94 7.4701 17.94H11.1301C11.4449 17.94 11.7001 17.6848 11.7001 17.37C11.7001 17.0552 11.4449 16.8 11.1301 16.8H7.4701C7.15529 16.8 6.9001 17.0552 6.9001 17.37Z"
                              fill={`${
                                Status === "Active"
                                  ? "#EAC131"
                                  : Status === "Preview"
                                  ? "#A8C337"
                                  : Status === "Gone" && "#FE6C6F"
                              }`}
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </Link>
                </Fragment>
              );
            })}
        </div>
        <div
          style={{
            display: "flex",
            gap: "8px",
            justifyContent: "center",
            marginTop: "62px",
          }}
        >
          {contests && contests.length !== 0 && (
            <>
              {currentPage !== 1 ? (
                <a href="#contests-filter">
                  <button
                    className={button["arrow-pagination"]}
                    onClick={() => {
                      setCurrentPage((prev) => prev - 1);
                    }}
                  >
                    Назад
                  </button>
                </a>
              ) : (
                <div />
              )}

              {currentPage !== totalPages ? (
                <a href="#contests-filter">
                  <button
                    className={button["arrow-pagination"]}
                    onClick={() => {
                      setCurrentPage((prev) => prev + 1);
                    }}
                  >
                    Вперед
                  </button>
                </a>
              ) : (
                <div />
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Contests;
//   <div
//     className="flex flex-col justify-center items-center"
//     style={{ padding: "60px 25px" }}
//   >
//     <h1 style={{ fontSize: "56px", color: "#165AAA", fontWeight: 300 }}>
//       Про нас пишуть
//     </h1>
//     <h3 style={{ color: "#EAC131", fontSize: "28px", marginTop: "10px" }}>
//       дізнайтесь більше про нашу діяльність
//     </h3>
//   </div>
//   <div>
//     <div className="grid-contests">

//     </div>
//     <div
//       className="flex justify-between"
//       style={{
//         maxWidth: "600px",
//         width: "100%",
//         margin: "0 auto 0 auto",
//         padding: "40px 60px",
//         marginTop: "60px",
//       }}
//     >

//     </div>
//   </div>
// </section>
// };
