import ExpectationsAccordion from "./ExpectationsAccordion/ExpectationsAccordion";
import style from "./style.module.scss";

const ExpectationsBlock = () => {
  return (
    <>
      <div className={style["block-wrapper"]}>
        <div className={style["block-title__wrapper"]}>
          <h1>В інтернатурі на тебе чекають</h1>
        </div>
        <div className={style["expectations-accordion__wrapper"]}>
          <ExpectationsAccordion />
        </div>
      </div>
    </>
  );
};

export default ExpectationsBlock;
