import InternshipStagesAccordion from "./InternshipStagesAccordion/InternshipStagesAccordion";
import style from "./style.module.scss";

const InternshipStagesBlock = (props) => {
  const { headLine } = props;
  return (
    <>
      <div className={style["block-wrapper"]}>
        <div className={style["block-title__wrapper"]}>
          <h1>Інтернатура за напрямком {headLine}</h1>
        </div>
        <div className={style["internshipStages-accordion__wrapper"]}>
          <InternshipStagesAccordion />
        </div>
      </div>
    </>
  );
};

export default InternshipStagesBlock;
