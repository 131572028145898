import { useRef } from "react";
import { useContext } from "react";
import useIsViewport from "../../../custom-hooks/useIsViewport";
import { PageContext } from "../InternshipPage";

import style from "./style.module.scss";
import IntroductionBlock from "./IntroductionBlock/IntroductionBlock";
import SuitableBlock from "./IsSuitableBlock/SuitableBlock";

const Introduction = () => {
  const { pageData } = useContext(PageContext);
  const { Headline: headLine } = pageData;

  const block = useRef();
  useIsViewport(block);
  return (
    <>
      <section className={style["section"]} ref={block} id="about">
        <IntroductionBlock headLine={headLine} />
        <SuitableBlock />
      </section>
    </>
  );
};

export default Introduction;
