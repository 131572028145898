import { useState, useContext } from "react";
import { PageContext } from "../../../InternshipPage";
import useResizeObserver from "../../../../../custom-hooks/useResizeObserver";
import EduResultsAccordionItem from "./EduResultsAccordionItem";
import style from "./style.module.scss";
import useWindowWidth from "../../../../../custom-hooks/useWindowWidth";

const EduResultsAccordion_more1028px = (props) => {
  const { blocks } = props;
  const [currentSlide, setCurrentSlide] = useState(null);
  const [blocksWrapper, dimensions] = useResizeObserver();

  return (
    <>
      <div ref={blocksWrapper} className={style["accordion-wrapper"]}>
        <div
          className={style["accordion"]}
          style={{
            transform: `translateX(-${
              currentSlide ? ((currentSlide - 1) * dimensions.width) / 4 : 0
            }px)`,
          }}
        >
          {blocks.map((block, index) => {
            return (
              <EduResultsAccordionItem
                key={index}
                index={index}
                data={block}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
                wrapperLength={dimensions.width}
                totalBlocksCount={block.length}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

const EduResultsAccordion_less1028px = (props) => {
  const { blocks } = props;
  return (
    <>
      <div className={style["blocks-wrapper"]}>
        {blocks.map((block, index) => {
          const { svg, title, text } = block;
          return (
            <div key={index} className={style["blocks__item"]}>
              <div className={style["blocks__item-title"]}>
                {svg && <img src={""} />}
                <h1>{title}</h1>
              </div>
              <div className={style["blocks__item-text"]}>{text}</div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const EduResultsAccordion = () => {
  const { pageData } = useContext(PageContext);
  const blocks = JSON.parse(pageData["EduResults_accordion"]);

  const width = useWindowWidth();

  if (width >= 1028) {
    return <EduResultsAccordion_more1028px blocks={blocks} />;
  } else {
    return <EduResultsAccordion_less1028px blocks={blocks} />;
  }
};

export default EduResultsAccordion;
