import style from "../style.module.scss";

const Achievements = (props) => {
  const { children } = props;
  return (
    <>
      <div className={style["achievements__list"]}>{children}</div>
    </>
  );
};

export default Achievements;
