import style from "../style.module.scss";

const InternshipProsItem = (props) => {
  const {
    data: { icon, title, text },
  } = props;
  return (
    <>
      <div className={style["internship-pros__list-item"]}>
        {icon}

        <h1>{title}</h1>
        <p>{text}</p>
      </div>
    </>
  );
};

export default InternshipProsItem;
