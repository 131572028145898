import { useQuery } from "react-query";
import Footer from "../../components/General/Footer/Footer";
import Header from "../../components/General/HeaderSection/Header/Header";
import Main from "../../components/General/Main";
import Internships from "./Internships/Internships";
import axios from "axios";
import LittlePreloader from "../../components/LittlePreloader/LittlePreloader";
import { useState } from "react";

const InternshipListPage = () => {
  const [internshipsList, setInternshipsList] = useState(null);

  const fetchInternships = async () => {
    const status = encodeURIComponent(`Status = 'Active'`);
    const responseData = await axios
      .get(
        `https://api-it-sprout.a-rogovsky1276.workers.dev/Internships?filterByFormula=${status}`
      )
      .then((res) => res.data);

    let data = responseData.records;
    data = data.map((record) => record.fields);
    data = data.map((record) => {
      return {
        link: record["URL"],
        title: record["URL"].split("/").reverse()[0],
        description: record["Description"],
      };
    });
    return data;
  };

  const { isLoading } = useQuery("fetch-internships", fetchInternships, {
    onSuccess: (responseData) => {
      setInternshipsList(responseData);
    },
  });

  if (isLoading || !internshipsList) {
    return <LittlePreloader />;
  }

  return (
    <>
      <Header image={""}>
        <h3>Інтернатура IT-Паросток</h3>
        <h1>Інтернатури</h1>
        <p>
          Мрієте про хорошу роботу, але бракує досвіду? Не впадайте у відчай,
          IT-Паросток відкриває безкоштовну інтернатуру для випускників курсів
          та ВУЗів!
        </p>
      </Header>
      <Main>
        <Internships internshipsList={internshipsList} />
      </Main>
      <Footer />
    </>
  );
};

export default InternshipListPage;
