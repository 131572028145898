import style from "../style.module.scss";

const InternshipPros = (props) => {
  const { children } = props;
  return (
    <>
      <div className={style["internship-pros__list"]}>{children}</div>
    </>
  );
};

export default InternshipPros;
