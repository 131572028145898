import { useState, useEffect } from "react";

const useScrollPosition = () => {
  const [distance, setDistance] = useState(0);

  useEffect(() => {
    const listener = () => {
      setDistance(window.pageYOffset);
    };
    window.addEventListener("scroll", listener);
    return () => window.removeEventListener("scroll", listener);
  }, []);

  return distance;
};

export default useScrollPosition;
