import uuid from "react-uuid";
import DoubtAboutChoiceForm from "../DoubtAboutChoiceForm/DoubtAboutChoiceForm";
import style from "./style.module.scss";
import InternshipsItem from "./InternshipsItem";

const Internships = (props) => {
  const { internshipsList } = props;

  return (
    <>
      <section>
        <div
          className="w-full flex flex-col custom-scroll"
          style={{ margin: "120px 0" }}
        >
          <div className={style["internships-table__title-wrapper"]}>
            <h1>Напрямки Інтернатури</h1>
          </div>
          <div className={style["internships-table__wrapper"]}>
            <div
              className={style["internships-table"]}
              style={{
                gridTemplateRow: `${
                  internshipsList.length === 12 ? "repeat(3,1fr)" : ""
                }`,

                overflowY: `${internshipsList.length > 12 ? "scroll" : "auto"}`,
              }}
            >
              {internshipsList.map((internship) => {
                return <InternshipsItem key={uuid()} data={internship} />;
              })}
            </div>
          </div>
          <DoubtAboutChoiceForm />
        </div>
      </section>
    </>
  );
};

export default Internships;
