import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/scrollbar";

import pic1 from "../assets/img/road-map/timeline-01.png";
import pic2 from "../assets/img/road-map/timeline-02.png";
import pic3 from "../assets/img/road-map/timeline-03.png";
import pic4 from "../assets/img/road-map/timeline-04.png";
import pic5 from "../assets/img/road-map/timeline-05.png";

SwiperCore.use([Autoplay]);
const About = () => {
  return (
    <section id="about" className="my-5">
      <Swiper
        modules={[Autoplay]}
        direction="horizontal"
        loop
        speed={4000}
        autoplay={{ delay: 0, disableOnInteraction: false }}
      >
        <SwiperSlide>
          <img className="w-full" src={pic1} alt="part1" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="w-full" src={pic2} alt="part2" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="w-full" src={pic3} alt="part3" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="w-full" src={pic4} alt="part4" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="w-full" src={pic5} alt="part5" />
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default About;
