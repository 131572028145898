import { useEffect, useState } from "react";
import "./style.scss";
import axios from "axios";

const Content = () => {
  const [partnersData, setPartnersData] = useState([]);
  useEffect(() => {
    fetchPartner();
  }, []);

  const fetchPartner = async () => {
    await axios
      .get("https://api-it-sprout.a-rogovsky1276.workers.dev/EduPartners")
      .then((resp) => {
        setPartnersData(resp.data.records);
      });
  };

  return (
    <section
      style={{
        maxWidth: "1200px",
        width: "100%",
        margin: "0 auto",
        padding: "120px 0 184px 0",
      }}
    >
      <h1 className="partners_title" style={{ fontSize: "56px" }}>
        Освітні Партнери
      </h1>
      <div className="parntner-container">
        {partnersData &&
          partnersData.map(({ id, fields: { TitleEN, LogoUrl } }) => {
            return (
              <div key={id} className="partner-item">
                <img
                  className="partner-item-img"
                  src={`https://drive.google.com/uc?export=view&id=${LogoUrl}`}
                  alt="univer"
                />
                <h3 className="partner-item-text">{TitleEN}</h3>
              </div>
            );
          })}
      </div>
    </section>
  );
};

export default Content;
