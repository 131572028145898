import BestDesign from "../ContestReviewPage/BestDesign";
import ShowTalentBanner from "../ContestReviewPage/ShowTalentBanner";
// import Footer from "../GraduatesPage/Footer";

import WhatWeValue from "./WhatWeValue";
import image1 from "../../assets/img/Group_ContestOverview.png";
import Header from "../../components/General/HeaderSection/Header/Header";
import { createContext, useEffect, useState } from "react";

import Footer from "../HomePage/Footer/Footer";
import About from "./About";
import Main from "../../components/General/Main";
import FAQ from "../../components/FAQ/FAQ";
import ContestWithFetchData from "../../components/General/ContestWithFetchData";
import { RegisterPopUpBtn } from "../ContestReviewPage/ContestReviwPage";

export const ContestData = createContext();

const ContestOverviewPage = ({ contest }) => {
  return (
    <>
      <Header image={image1}>
        <h3>Інтернатура IT-Паросток </h3>
        <h1 style={{ fontSize: "60px" }}>{contest["Title"]}</h1>
        <p>{contest["Description"]}</p>
      </Header>
      <Main>
        <ContestData.Provider value={{ contestData: contest }}>
          <About />
          <ShowTalentBanner contestTitle={contest["Title"]} />
          <WhatWeValue />
          <BestDesign contestID={contest["Location"]} />
          <FAQ
            title={"Відповіді на питання до конкурсів"}
            quetions={contest["FAQ"] ? JSON.parse(contest["FAQ"]) : []}
          />
        </ContestData.Provider>
      </Main>
      <Footer />
      <RegisterPopUpBtn />
    </>
  );
};

export default ContestWithFetchData(ContestOverviewPage);
