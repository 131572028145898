import IntroductionAccordion from "./IntroductionAccordion/IntroductionAccordion";
import style from "./style.module.scss";

const IntroductionBlock = (props) => {
  const { headLine } = props;
  return (
    <>
      <div className={style["block-title__wrapper"]}>
        <h3>Інтернатура за напрямком</h3>
        <h1>{headLine}</h1>
        <h4>Чим займається</h4>
      </div>
      <div className={style["introduction-accordion__wrapper"]}>
        <IntroductionAccordion />
      </div>
    </>
  );
};

export default IntroductionBlock;
