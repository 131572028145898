import { Fragment, useEffect, useRef, useState } from "react";
import logo from "../../../logo.svg";
import more from "../../../assets/img/svg/more.svg";

import people from "../../../assets/img/intro/photos.png";
import { Link } from "react-router-dom";
import SideMenu from "../../../components/General/HeaderSection/SideMenu/SideMenu";
import { navItems } from "../../../components/General/HeaderSection/Header/Header";
import uuid from "react-uuid/uuid";

const Header = () => {
  const [isSideMenu, setIsSideMenu] = useState(false);
  const [navbarWidth, setNavbarWidth] = useState(null);
  const [navbarHeight, setNavbarHeight] = useState(null);
  const navbarWrapper = useRef();
  const navbar = useRef();

  useEffect(() => {
    const element = navbarWrapper.current;
    const element2 = navbar.current;

    const observer = new ResizeObserver((entries) => {
      const newWidth = entries[0].contentRect.width;
      setNavbarWidth(newWidth);
    });
    const observer2 = new ResizeObserver((entries) => {
      const newHeight = entries[0].contentRect.height;
      setNavbarHeight(newHeight);
    });

    observer.observe(element);
    observer2.observe(element2);

    return () => {
      observer.unobserve(element);
      observer2.unobserve(element2);
    };
  }, []);

  return (
    <>
      <>
        <header
          id="intro"
          className="pa-1"
          data-aos="fade-down"
          style={{
            justifyContent: "flex-start",
            borderRadius: "0 0 240px 0",
            width: "90%",
            margin: "0 auto",
          }}
        >
          <div className="container">
            <div
              style={{ marginTop: "40px", height: navbarHeight }}
              ref={navbarWrapper}
            >
              <div
                className="navbar-wrapper"
                ref={navbar}
                style={{
                  position: `${isSideMenu ? "fixed" : "relative"}`,
                  zIndex: 101,
                  top: `${isSideMenu ? "40px" : "0"}`,
                  width: navbarWidth,
                }}
              >
                <div className="logo-wrapper">
                  <Link to="/">
                    <img src={logo} alt="Logo" />
                  </Link>
                </div>

                <nav className="navbar">
                  <ul className="navbar__list">
                    {navItems.map((item) => {
                      const { link, title } = item;
                      return (
                        <Fragment key={uuid()}>
                          <li className="navbar__list-item hover-yellow">
                            <Link to={link}>
                              <span className="nowrap">{title}</span>
                            </Link>
                          </li>
                        </Fragment>
                      );
                    })}
                  </ul>
                </nav>
                <button
                  className="burger-menu"
                  onClick={() => setIsSideMenu(!isSideMenu)}
                >
                  <div
                    className={`hamburger ${isSideMenu ? "is-active" : ""}`}
                    id="hamburger-1"
                  >
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                  </div>
                </button>
              </div>
            </div>
            <div className="header-content">
              <div className="header-content__left header-content__left--mainPage">
                <h1
                  style={{
                    fontSize: "42px",
                    color: "#fff",
                    fontWeight: 700,
                    marginTop: "24px",
                  }}
                >
                  Інвестуйте у своє майбутнє за допомогою послуг IT-Паросток!
                </h1>
              </div>
              <div className="header-content__right">
                <img
                  src={people}
                  alt="Photos"
                  style={{ width: "100%", maxWidth: "500px" }}
                />
              </div>
            </div>
            <div className="to-footer__btn">
              <a href="#footer">
                <img src={more} />
              </a>
            </div>
          </div>
        </header>
        <SideMenu
          setIsSideMenu={setIsSideMenu}
          isSideMenu={isSideMenu}
          navItems={navItems}
        />
      </>
    </>
  );
};

export default Header;
