import MentorsAccordion from "../MentorsAccordion/MentorsAccordion";
import style from "./style.module.scss";

const MentorsBlock = () => {
  return (
    <>
      <div className={style["block-wrapper"]}>
        <div className={style["block-title__wrapper"]}>
          <h1>Інтернатура проходить за участю</h1>
        </div>
        <div className={style["mentors-accordion__wrapper"]}>
          <MentorsAccordion />
        </div>
      </div>
    </>
  );
};

export default MentorsBlock;
