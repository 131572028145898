import { Link } from "react-router-dom";
import style from "./style.module.scss";

const FooterBottom = () => {
  return (
    <div className={style["footer__bottom-wrapper"]}>
      <div className={style["footer__bottom"]}>
        <h3>@2023 - IT-Sprout. Усі права захищено</h3>
        <Link to="/privacy-policy" onClick={() => window.scrollTo(0, 0)}>
          <h3>Політика конфіденційності</h3>
        </Link>
      </div>
    </div>
  );
};

export default FooterBottom;
