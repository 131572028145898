import { useContext } from "react";
import { PageContext } from "../../pages/InternshipPage/InternshipPage";
import SidenavItem from "./SidenavItem";
import uuid from "react-uuid";

const SidenavBar = () => {
  const targets = [
    "#intro",
    "#about",
    "#have_quetion",
    "#growth_levels",
    "#features",
    "#how_it_works",
    // "#reviews",
    "#registration",
    "#faq",
    "#footer",
  ];
  const sideNavContext = useContext(PageContext);

  return (
    <>
      <div id="sidenav">
        {targets.map((target) => {
          return (
            <SidenavItem key={uuid()} {...sideNavContext} target={target} />
          );
        })}
      </div>
    </>
  );
};

export default SidenavBar;
