import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ThankPage = ({ setIsThankPage }) => {
  const navigate = useNavigate();

  const handleBeforeUnload = () => {
    setTimeout(() => {
      setIsThankPage(false);
    }, 1500);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100vh",
          background: "#fff",
          padding: "25px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            marginTop: "80px",
            color: "rgba(37, 42, 56, 0.85)",
            textAlign: "center",
          }}
        >
          <h1 style={{ fontSize: "32px", lineHeight: "60px", fontWeight: 500 }}>
            Вітаємо! <br />
            Ваша реєстрація пройшла успішно
          </h1>
          <button
            onClick={() => navigate("/")}
            type="button"
            style={{
              marginTop: "40px",
              padding: "10px 25px",
              borderRadius: "8px",
              background: "transparent",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            На головну
          </button>
        </div>
      </div>
    </>
  );
};

export default ThankPage;
