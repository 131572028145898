import React from "react";

import Footer from "../../components/General/Footer/Footer";
import Main from "../../components/General/Main";
import Header from "./components/Header";
import PaymentSection from "./components/PaymentSection";

const InvestPage = () => {
  return (
    <>
      <Header />
      <Main>
        <PaymentSection />
      </Main>
      <Footer />
    </>
  );
};

export default InvestPage;
