import { useContext } from "react";
import { useState } from "react";
import style from "./style.module.scss";

import { PageContext } from "../../../InternshipPage";
import QualifyingRoundAccordionItem from "./QualifyingRoundAccordionItem";
import useWindowWidth from "../../../../../custom-hooks/useWindowWidth";

const QualifyingRoundAccordion_more1028px = (props) => {
  const [currentElement, setCurrentElement] = useState(null);
  const { blocks } = props;
  return (
    <>
      <div className={style["accordion"]}>
        {blocks.map((block, index) => {
          return (
            <QualifyingRoundAccordionItem
              key={index}
              data={block}
              index={index}
              currentElement={currentElement}
              setCurrentElement={setCurrentElement}
            />
          );
        })}
      </div>
    </>
  );
};

const QualifyingRoundAccordion_less1028px = (props) => {
  const { blocks } = props;
  return (
    <>
      <div className={style["blocks"]}>
        {blocks.map((block, index) => {
          const { title, text } = block;
          return (
            <div key={index} className={style["blocks__item"]}>
              <h1>{title}</h1>
              <p>{text}</p>
            </div>
          );
        })}
      </div>
    </>
  );
};

const QualifyingRoundAccordion = () => {
  const { pageData } = useContext(PageContext);
  const blocks = JSON.parse(pageData["QualifyingRound_accordion"]);

  const width = useWindowWidth();
  if (width >= 1028) {
    return <QualifyingRoundAccordion_more1028px blocks={blocks} />;
  } else {
    return <QualifyingRoundAccordion_less1028px blocks={blocks} />;
  }
};

export default QualifyingRoundAccordion;
