import { useEffect } from "react";

const PulsedCircleWrapper = ({
  children,
  popUpPosition,
  clickedCircle,
  setClickedCircle,
  popUpContent,
}) => {
  const { icon, title, text } = popUpContent;

  useEffect(() => {
    if (clickedCircle) {
      const timeOut = setTimeout(() => {
        setClickedCircle(null);
      }, 5000);

      return () => clearTimeout(timeOut);
    }
  }, [clickedCircle]);

  return (
    <>
      <div
        style={{ position: "relative" }}
        onClick={() => {
          if (clickedCircle !== children.props.children) {
            setClickedCircle(children.props.children);
          } else {
            setClickedCircle(null);
          }
        }}
      >
        {popUpPosition && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              position: "absolute",
              width: "1024px",
              height: "1024px",
              borderRadius: "50%",
              background: "#165AAA",
              border: "25.6px solid #EAC131",
              zIndex: "100",
              top: popUpPosition.x,
              left: popUpPosition.y,
              transition: "all 0.3s ease-in-out",
              padding: "140px",
              opacity: `${
                clickedCircle === children.props.children ? "1" : "0"
              }`,
              transform: `scale(${
                clickedCircle === children.props.children ? "1" : "0.5"
              }) rotate(-45deg)`,
              visibility: `${
                clickedCircle === children.props.children ? "visible" : "hidden"
              }`,
            }}
          >
            <div style={{ fontSize: "200px", lineHeight: "264px" }}>{icon}</div>
            <div
              style={{
                fontSize: "48px",
                fontWeight: 600,
                color: "#EAC131",
                marginTop: "32px",
              }}
            >
              {title}
            </div>
            <div
              style={{
                fontSize: "48px",
                fontWeight: 500,
                color: "#fff",
                marginTop: "26px",
              }}
            >
              {text}
            </div>
          </div>
        )}
        {children}
      </div>
    </>
  );
};

export default PulsedCircleWrapper;
