import { useContext, useRef } from "react";
import useIsViewport from "../../custom-hooks/useIsViewport";
import { PageContext } from "./InternshipPage";

const Registration = () => {
  const { pageData } = useContext(PageContext);
  const { Internship_Form_ID } = pageData;

  const block = useRef();
  useIsViewport(block);

  return (
    <>
      <section
        ref={block}
        id="registration"
        style={{
          padding: "120px 0",
          background: "#252A38",
        }}
      >
        <div
          style={{ maxWidth: "1200px", margin: "0 auto", padding: "0 25px" }}
        >
          <div className="flex flex-col justify-center items-center">
            <h1 style={{ fontSize: "56px", color: "#FFFFFF", fontWeight: 300 }}>
              Реєстрація
            </h1>
            <h3
              style={{
                color: "#EAC131",
                fontSize: "28px",
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              Заповніть форму реєстрації та приєднайтеся до інтернатури
            </h3>
          </div>
          <div
            style={{
              marginTop: "64px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a
              href={`https://airtable.com/shrXhzzsmMHxXqMOG?prefill_Internship=${Internship_Form_ID}&hide_Internship=true`}
              target={"_blank"}
            >
              <button
                className="hover-yellow"
                style={{
                  background: "#165AAA",
                  color: "#fff",
                  padding: "10px 25px",
                  fontSize: "18px",
                  borderRadius: "8px",
                  fontWeight: 500,
                }}
              >
                Перейти до форми
              </button>
            </a>
          </div>

          {/* <div
            style={{
              // minHeight: "624px",
              backgroundColor: "#fff",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "56px",
              borderRadius: "24px",
              padding: "64px",
              color: "#252A38",
              width: "fit-content",
              margin: "60px auto 0 auto",
            }}
          > */}
          {/* <div
              style={{
                minWidth: "340px",
                rowGap: "24px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flex: "1 1 0%",
                fontWeight: 500,
              }}
            >
              <div style={{ fontSize: "24px" }}>
                Зареєструйтесь на участь у конкурсі "Дизайн во благо" прямо
                зараз та отримайте можливість реалізувати свій творчий
                потенціал.
              </div>
              <i style={{ fontSize: "24px" }}>
                Не втрачайте шансу заявити про себе! До кінця реєстрації
                залишилось 5 днів.
              </i>
            </div> */}
          {/* <form
              onSubmit={(e) => {
                e.preventDefault();
                postData();
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "466px",
                width: "100%",
              }}
            >
              <input
                style={{
                  width: "100%",
                  fontWeight: 400,
                  height: "80px",
                  borderRadius: "16px",
                  padding: "25.5px 24px",
                  fontSize: "24px",
                  background: "#B1C3FF40",
                }}
                type="text"
                required
                value={form["Fullname"]}
                onChange={(e) => {
                  setForm((prev) => {
                    const copy = { ...prev };
                    copy["Fullname"] = e.target.value;
                    return copy;
                  });
                }}
                placeholder="Ваше Ім’я та прізвище"
              />
              <input
                style={{
                  width: "100%",
                  fontWeight: 400,
                  height: "80px",
                  borderRadius: "16px",
                  padding: "25.5px 24px",
                  fontSize: "24px",
                  marginTop: "24px",
                  background: "#B1C3FF40",
                }}
                type="email"
                required
                value={form["Email"]}
                onChange={(e) => {
                  setForm((prev) => {
                    const copy = { ...prev };
                    copy["Email"] = e.target.value;
                    return copy;
                  });
                }}
                placeholder="Електронна адреса"
              />
              <input
                style={{
                  width: "100%",
                  fontWeight: 400,
                  height: "80px",
                  borderRadius: "16px",
                  padding: "25.5px 24px",
                  fontSize: "24px",
                  marginTop: "24px",
                  background: "#B1C3FF40",
                }}
                value={form["Telephone"]}
                type="text"
                required
                onChange={(e) => {
                  setForm((prev) => {
                    const copy = { ...prev };
                    copy["Telephone"] = e.target.value;
                    return copy;
                  });
                }}
                placeholder="Номер телефону"
              />

              <button
                type="submit"
                style={{
                  width: "100%",
                  padding: "0 24px",
                  height: "80px",
                  backgroundColor: `${
                    isSuccess !== null
                      ? isSuccess
                        ? "green"
                        : "red"
                      : "#165AAA"
                  }`,
                  fontWeight: 600,
                  color: "#fff",
                  fontSize: "24px",
                  borderRadius: "16px",
                  marginTop: "24px",
                }}
              >
                {isSuccess !== null
                  ? isSuccess
                    ? "Успішно!"
                    : "Помилка!"
                  : isLoading
                  ? "Надсилаємо..."
                  : "Надіслати"}
              </button>
            </form> */}
          {/* </div> */}
        </div>
      </section>
    </>
  );
};

export default Registration;
