import React from "react";
import FAQ from "../../components/FAQ/FAQ";
import Features from "../../components/General/Features/Features";
import Footer from "../../components/General/Footer/Footer";

import HaveQuetions from "../../components/General/HaveQuetions";
import Header from "../../components/General/HeaderSection/Header/Header";
import Main from "../../components/General/Main";

import Publishers from "./Publishers";

const MediaPage = () => {
  return (
    <>
      <Header>
        <h3>Інтернатура IT-Паросток </h3>
        <h1>Прес-релізи</h1>
        <p>
          Наша компанія - це інноваційна організація, яка зосереджена на
          розвитку технологій та забезпеченні зручності для користувачів.
          Відкрийте для себе наші різноманітні проекти та продукти, які роблять
          життя кращим.
        </p>
      </Header>
      <Main>
        <Publishers />
        <Features />
        <FAQ title={"Відповіді на Поширені питання"} />
        <HaveQuetions formId={"recVMV209hMG37wfw"} />
      </Main>
      <div style={{ marginTop: "100px" }} />
      <Footer />
    </>
  );
};

export default MediaPage;
