import uuid from "react-uuid";
import blueCertificate from "../../assets/img/blue.png";
import goldCertificate from "../../assets/img/golden.png";
import useArrowPagination from "../../custom-hooks/useArrowPagination";
import useWindowWidth from "../../custom-hooks/useWindowWidth";
import FeedbackCard from "./FeedbackCard";
import defaultFrame from "../../assets/img/feedback_frameBlue.png";
import goldFrame from "../../assets/img/feedback_frameGold.png";
import style from "./style.module.css";
import button from "../../styles/modules/Button.module.css";

const GraduatesFeeback = () => {
  const width = useWindowWidth();
  const [graduates, setCurrentPage, isLeftBtn, isRightBtn] = useArrowPagination(
    "Graduates",
    "",
    9
  );

  return (
    <>
      <section
        style={{
          maxWidth: "1200px",
          width: "100%",
          margin: "0 auto",
          padding: "120px 0 200px 0",
        }}
      >
        <div
          className={`flex justify-between items-center flex-wrap ${style["feedback-section__title"]}`}
          style={{
            maxWidth: "1050px",
            width: "100%",
            margin: "0 auto",
            gap: "30px",
            padding: "0 25px",
            flexDirection: `${width > 1024 ? "row" : "column"}`,
          }}
        >
          <div className="flex flex-col" id="feedback-table">
            <h1 style={{ fontWeight: 300, fontSize: "56px", color: "#165AAA" }}>
              Відгуки випускників
            </h1>
            <h2
              style={{
                fontWeight: 500,
                fontSize: "28px",
                color: "#EAC131",
                marginTop: "8px",
              }}
            >
              випускники якими ми пишаємося!
            </h2>
          </div>
          <div
            className="relative"
            style={{ minWidth: "300px", maxWidth: "300px", height: "200px" }}
          >
            <img
              className="absolute"
              src={blueCertificate}
              style={{
                width: "200px",
                top: "0",
                left: "0",
                rotate: "-4.22deg",
              }}
            />
            <img
              className="absolute"
              src={goldCertificate}
              style={{
                width: "200px",
                bottom: "0",
                right: "0",
                rotate: "4.61deg",
              }}
            />
          </div>
        </div>
        {graduates && (
          <>
            <div style={{ marginTop: "80px", padding: "0 25px" }}>
              <div className={style["feedback-table"]}>
                {graduates.map((person) => {
                  const { Golden } = person;
                  return (
                    <FeedbackCard
                      key={uuid()}
                      photoFrame={Golden ? goldFrame : defaultFrame}
                      data={person}
                    />
                  );
                })}
              </div>
              {isLeftBtn || isRightBtn ? (
                <div
                  className="flex justify-center"
                  style={{
                    padding: "0 40px",
                    gap: "8px",
                    marginTop: "60px",
                  }}
                >
                  {isLeftBtn ? (
                    <a href="#feedback-table">
                      <button
                        className={button["arrow-pagination"]}
                        onClick={() => {
                          setCurrentPage((prev) => prev - 1);
                        }}
                      >
                        Назад
                      </button>
                    </a>
                  ) : (
                    <div />
                  )}
                  {isRightBtn ? (
                    <a href="#feedback-table">
                      <button
                        className={button["arrow-pagination"]}
                        onClick={() => {
                          setCurrentPage((prev) => prev + 1);
                        }}
                      >
                        Вперед
                      </button>
                    </a>
                  ) : (
                    <div />
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default GraduatesFeeback;
