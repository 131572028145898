import style from "./style.module.scss";

const DoubtAboutChoiceForm = () => {
  return (
    <>
      <div className={style["form-wrapper"]}>
        <div className={style["form-content"]}>
          <div className={style["form"]}>
            <h1 className={style["form__title"]}>Сумніваєшься у виборі?</h1>
            <h2 className={style["form__subtitle"]}>
              Заповни форму та ми тобі допоможемо!
            </h2>
            <div className={style["form_btn-wrapper"]}>
              <a
                href={`https://airtable.com/shrXhzzsmMHxXqMOG?prefill_Internship=recVMV209hMG37wfw&hide_Internship=true`}
                target={"_blank"}
              >
                <button className={style["form_btn"]}>Перейти до форми</button>
              </a>
            </div>
          </div>
          <i className={style["form__description"]}>
            Не зволікай! Кількість міст обмежена
          </i>
        </div>
      </div>
    </>
  );
};

export default DoubtAboutChoiceForm;
