import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";

const useResizeObserver = () => {
  const elementRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const timeout = setTimeout(() => {
      const handleResize = (entries) => {
        for (const entry of entries) {
          const { width, height } = entry.contentRect;
          setDimensions({ width, height });
        }
      };

      if (elementRef.current) {
        const resizeObserver = new ResizeObserver(handleResize);
        resizeObserver.observe(elementRef.current);

        return () => {
          resizeObserver.unobserve(elementRef.current);
        };
      }
    }, 10);

    return () => clearTimeout(timeout);
  }, []);

  return [elementRef, dimensions];
};

export default useResizeObserver;
