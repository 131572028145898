import Footer from "../../components/General/Footer/Footer";
import Header from "../../components/General/HeaderSection/Header/Header";
import img1 from "../../assets/img/intro/photos.png";
import Main from "../../components/General/Main";
import GraduatesFeeback from "./GraduatesFeeback";
import FAQ from "../../components/FAQ/FAQ";
import HaveQuetions from "../../components/General/HaveQuetions";
import Features from "../../components/General/Features/Features";

const AllGraduatesPage = () => {
  return (
    <>
      <Header image={img1}>
        <h3>Інтернатура IT-Паросток</h3>
        <h1>Випускники</h1>
        <p>
          За минулий рік працевлаштовано 2046 випускників з 5000. Ознайомтесь з
          досвідом наших успішних випускників та станьте одним з них!
          IT-Паросток запрошує вас на безкоштовну інтернатуру для набуття
          практичних навичок.
        </p>
      </Header>
      <Main>
        <GraduatesFeeback />
        <Features />
        <FAQ title={"Відповіді на поширені питання"} />
        <HaveQuetions formId="recVMV209hMG37wfw" />
      </Main>
      <Footer />
    </>
  );
};

export default AllGraduatesPage;
