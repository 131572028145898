import { Link } from "react-router-dom";
import style from "./style.module.scss";

const SideMenuNavItem = (props) => {
  const { setIsSideMenu, link, children } = props;
  return (
    <Link to={link}>
      <li
        onClick={() => {
          setIsSideMenu(false);
          window.scrollTo(0, 0);
        }}
        className={style["navbar__item"]}
      >
        {children}
      </li>
    </Link>
  );
};

export default SideMenuNavItem;
