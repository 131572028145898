import React from "react";
import { useState } from "react";
import { useMemo, useRef, useEffect } from "react";
import button from "../../styles/modules/Button.module.css";

const FeedbackCard = ({ data, photoFrame }) => {
  const { Photo, Feedback, Fullname, Start, End } = data;
  const [isText, setIsText] = useState(false);
  const textBlock = useRef();
  const wrapperBlock = useRef();

  const textHeight = useMemo(() => {
    if (textBlock.current) return textBlock.current.clientHeight;
    return undefined;
  }, [textBlock.current]);

  useEffect(() => {
    wrapperBlock.current.style.transition = "all 0.5s easy";
    if (isText) {
      wrapperBlock.current.style.height = `${textHeight}px`;
    } else {
      wrapperBlock.current.style.height = `72px`;
    }
  }, [isText]);

  return (
    <>
      <div
        onMouseLeave={() => {
          setIsText(false);
        }}
        className="flex flex-col items-center"
        style={{
          maxWidth: "360px",
          width: "100%",
          justifySelf: "center",
          color: "#252A38",
        }}
      >
        <div className="relative">
          <img src={photoFrame} />
          <img
            src={Photo}
            style={{
              position: "absolute",
              left: "27px",
              top: "27px",
              width: "120px",
              borderRadius: "50%",
              border: "3px solid white",
            }}
          />
        </div>
        <div
          className="text-center"
          style={{
            fontSize: "20px",
            fontWeight: 600,
            marginTop: "24px",
            lineHeight: "24px",
          }}
        >
          {Fullname}
        </div>
        <div style={{ fontWeight: 400, marginTop: "12px" }}>
          {Start} - {End}
        </div>
        <div
          className={`text-center ${
            isText && textHeight > 72 ? "" : "feedbackCard"
          }`}
          ref={wrapperBlock}
          style={{
            transition: "all 0.5s ease-in-out",
            height: `72px`,
            overflow: "hidden",
            color: "#252A38",
            marginTop: "12px",
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "24px",
          }}
        >
          <div ref={textBlock}>{Feedback}</div>
        </div>
        {Feedback && (
          <button
            className={button["read-feedback"]}
            style={{ marginTop: "26px" }}
            onClick={() => {
              setIsText(!isText);
            }}
          >
            {!isText ? "Переглянути" : "Згорнути"}
          </button>
        )}
      </div>
    </>
  );
};

export default FeedbackCard;
