import style from "./style.module.scss";

const DownloadMaterials = (props) => {
  const { setStatus, toShare } = props;
  return (
    <>
      <div className={style["form__container"]}>
        <h1>Завантажити матеріали</h1>
        <a href={toShare} target={"_blank"}>
          <button onClick={() => setStatus(false)} type="submit">
            Завантажити
          </button>
        </a>
      </div>
    </>
  );
};

export default DownloadMaterials;
