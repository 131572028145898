import { useState } from "react";
import style from "./style.module.scss";

const RateForm = () => {
  const [stars, setStars] = useState(0);
  const [starRange, setStarRange] = useState(0);
  const [isRated, setIsRated] = useState(false);

  const starsArr = [1, 2, 3, 4, 5];

  return (
    <>
      <div className={style["form__container"]}>
        <h1>Оцініть наш захід</h1>
        <div
          className={style["rate"]}
          onMouseLeave={() => {
            if (!isRated) setStars(0);
          }}
        >
          {starsArr.map((_, index) => {
            return (
              <i
                className="material-icons"
                style={{
                  color: `${
                    index + 1 <= stars || index + 1 <= starRange ? "yellow" : ""
                  }`,
                  cursor: "pointer",
                  userSelect: "none",
                }}
                onMouseEnter={() => {
                  setStarRange(index + 1);
                }}
                onMouseLeave={() => {
                  setStarRange(0);
                }}
                onClick={() => {
                  if (index + 1 === stars && isRated) {
                    setStars(0);
                    setIsRated(false);
                  } else {
                    setIsRated(true);
                    setStars(index + 1);
                  }
                }}
              >
                star
              </i>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RateForm;
