import style from "./style.module.scss";

const IsSuitableAccordionItem = (props) => {
  const {
    data: { text },
    index,
    currentBlock,
    setCurrentBlock,
    totalBlocksCount,
    icon,
  } = props;
  return (
    <>
      <div
        className={style["accordion__item"]}
        style={{
          minWidth: `${currentBlock === index ? "76%" : "25%"}`,
          borderRight: `${
            !(index === totalBlocksCount - 1) ? "3px solid #165AAA" : "none"
          }`,
        }}
        onMouseEnter={() => {
          setCurrentBlock(index);
        }}
        onMouseLeave={() => {
          setCurrentBlock(null);
        }}
      >
        {icon}
        <div className={style["accordion__item-content"]}>
          <p
            className={
              currentBlock === index
                ? `${style["slow-appear-animation"]} ${style["pre-wrap"]}`
                : ""
            }
          >
            {text}
          </p>
        </div>
      </div>
    </>
  );
};

export default IsSuitableAccordionItem;
