import axios from "axios";

import { createContext } from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PopUp from "../../components/PopUp";
import HaveQuetions from "../../components/General/HaveQuetions";

import Features from "./Features";
import Header from "../../components/General/HeaderSection/Header/Header";
import WatchVideo from "./WatchVideo";
import Main from "../../components/General/Main";
import Footer from "../HomePage/Footer/Footer";
import FAQ from "../../components/FAQ/FAQ";
import { Link } from "react-router-dom";

export const EventContext = createContext();

const EventPage = () => {
  const [pageData, setPageData] = useState(null);
  const [status, setStatus] = useState(false);
  const [formToDisplay, setFormToDisplay] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchData = async () => {
    const name = encodeURIComponent(`URL = '${id.toLowerCase()}'`);
    const data = await axios
      .get(
        `https://api-it-sprout.a-rogovsky1276.workers.dev/Events?filterByFormula=${name}`
      )
      .then((res) => res.data)
      .then((res) => res.records[0]);

    if (data) {
      setPageData(data.fields);
    } else {
      navigate("/404");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!pageData) {
    return <></>;
  }

  return (
    <>
      <EventContext.Provider value={{ pageData }}>
        <Header>
          <h3>Інтернатура IT-Паросток </h3>
          <h1>{pageData["Title"]}</h1>
          <p>Сподіваємося, що вам сподобався наш захід. </p>
          <Link
            className="btn btn--small"
            to={`/events/${id}?scrollTo=event-video`}
            style={{ marginTop: "41px", width: "fit-content" }}
          >
            ПЕРЕГЛЯНУТИ ЗАПИС
          </Link>
        </Header>
        <Main>
          <WatchVideo />
          <Features setFormToDisplay={setFormToDisplay} setStatus={setStatus} />
          <FAQ title={"Відповіді на поширені питання"} />
          <HaveQuetions formId="recVMV209hMG37wfw" />
        </Main>
        <div style={{ marginTop: "100px" }} />
        <Footer />
        <PopUp status={status} setStatus={setStatus}>
          {formToDisplay}
        </PopUp>
      </EventContext.Provider>
    </>
  );
};

export default EventPage;
