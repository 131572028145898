import Footer from "../../components/General/Footer/Footer";
import Header from "../../components/General/HeaderSection/Header/Header";
import Main from "../../components/General/Main";
import Content from "./Content";

const PartnersPage = () => {
  return (
    <>
      <Header>
        <h3>Інтернатура IT-Паросток </h3>
        <h1>Освітні Партнери</h1>
        <p>
          Мрієте про хорошу роботу, але бракує досвіду? Не впадайте у відчай,
          IT-Паросток відкриває безкоштовну інтернатуру для випускників курсів
          та ВУЗів!
        </p>
      </Header>
      <Main>
        <Content />
      </Main>
      <Footer />
    </>
  );
};

export default PartnersPage;
