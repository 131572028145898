import { Fragment, useEffect, useRef, useState } from "react";
import logo from "../../../logo.svg";
import more from "../../../assets/img/svg/more.svg";
import people from "../../../assets/img/intro/photos.png";

import { Link } from "react-router-dom";
import SideMenu from "../../../components/General/HeaderSection/SideMenu/SideMenu";
import { navItems } from "../../../components/General/HeaderSection/Header/Header";
import Navbar from "../../../components/General/HeaderSection/Header/Navbar/Navbar";
import BurgerMenu from "../../../components/General/HeaderSection/Header/BurgerMenu";
import styleGeneralHeader from "../../../components/General/HeaderSection/Header/style.module.scss";

const HeaderHome = () => {
  const [isSideMenu, setIsSideMenu] = useState(false);
  const [navbarWidth, setNavbarWidth] = useState(null);
  const [navbarHeight, setNavbarHeight] = useState(null);
  const navbarWrapper = useRef();
  const navbar = useRef();

  useEffect(() => {
    const element = navbarWrapper.current;
    const element2 = navbar.current;

    const observer = new ResizeObserver((entries) => {
      const newWidth = entries[0].contentRect.width;
      setNavbarWidth(newWidth);
    });
    const observer2 = new ResizeObserver((entries) => {
      const newHeight = entries[0].contentRect.height;
      setNavbarHeight(newHeight);
    });

    observer.observe(element);
    observer2.observe(element2);

    return () => {
      observer.unobserve(element);
      observer2.unobserve(element2);
    };
  }, []);

  return (
    <>
      <header
        id="intro"
        data-aos="fade-down"
        className={`pa-1 ${styleGeneralHeader["header--mainPage"]}`}
      >
        <div className="container">
          <div
            style={{ marginTop: "40px", height: navbarHeight }}
            ref={navbarWrapper}
          >
            <div
              className={styleGeneralHeader["navbar-wrapper"]}
              ref={navbar}
              style={{
                position: `${isSideMenu ? "fixed" : "relative"}`,
                zIndex: 101,
                top: `${isSideMenu ? "40px" : "0"}`,
                width: navbarWidth,
              }}
            >
              <div className={styleGeneralHeader["logo-wrapper--mainPage"]}>
                <Link to="/">
                  <img src={logo} alt="Logo" />
                </Link>
              </div>
              <Navbar />
              <BurgerMenu
                isSideMenu={isSideMenu}
                setIsSideMenu={setIsSideMenu}
              />
            </div>
          </div>
          <div className={styleGeneralHeader["header-content"]}>
            <div
              className={`${styleGeneralHeader["header-content__left"]}  ${styleGeneralHeader["header-content__left--mainPage"]}`}
            >
              <h3>Нова система дуальної освіти</h3>
              <h1>Трансформація освіти у IT-професію твоєї мрії</h1>
            </div>
            <div className={styleGeneralHeader["header-content__right"]}>
              <img src={people} alt="Photos" />
            </div>
          </div>
          <div className={styleGeneralHeader["to-footer__btn"]}>
            <a href="#footer">
              <img src={more} />
            </a>
          </div>
        </div>
      </header>
      <SideMenu
        setIsSideMenu={setIsSideMenu}
        isSideMenu={isSideMenu}
        navItems={navItems}
      />
    </>
  );
};

export default HeaderHome;
