import { Fragment, useContext } from "react";
import uuid from "react-uuid/uuid";
import { ContestData } from "./ContestOverviewPage";

import "./style.css";

const About = () => {
  const { contestData } = useContext(ContestData);

  const { Title, Subtitle, About } = contestData;
  const Timetable = contestData["Timetable"]
    ? JSON.parse(contestData["Timetable"])
    : null;

  return (
    <>
      <section
        style={{
          maxWidth: "1200px",
          width: "100%",
          margin: "0 auto",
          padding: "120px 0 144px 0",
          color: "#252A38",
        }}
      >
        <div
          className="flex flex-col justify-center items-center"
          style={{ padding: "0 25px 80px 25px", textAlign: "center" }}
        >
          <h1
            style={{
              fontSize: "56px",
              color: "#165AAA",
              fontWeight: 300,
              textAlign: "center",
            }}
          >
            {Title}
          </h1>
          <h3
            style={{
              color: "#EAC131",
              fontSize: "28px",
              marginTop: "10px",
              textAlign: "center",
              fontWeight: 600,
            }}
          >
            {Subtitle}
          </h3>
        </div>
        <div
          style={{
            padding: "0 25px",
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "42px",
            width: "100%",
          }}
        >
          <div
            style={{ maxWidth: "550px", width: "100%", whiteSpace: "pre-wrap" }}
          >
            <div style={{ fontWeight: 600, fontSize: "20px" }}>
              Інформація про конкурс
            </div>

            <div className="about-block">{About}</div>
          </div>
          {Timetable && (
            <div style={{ maxWidth: "550px", width: "100%" }}>
              <table
                className="table table-sm"
                style={{
                  borderRadius: "8px",
                  overflow: "hidden",
                  borderColor: "white",
                  borderSpacing: "3px",
                  borderCollapse: "separate",
                }}
              >
                <thead>
                  <tr>
                    <th
                      colSpan={2}
                      scope="col"
                      style={{ borderRadius: "8px 8px 0 0" }}
                      className="bg-yellow c-white p-3 text-center"
                    >
                      Етапи проведення конкурсу на найкращу презентацію
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Timetable.map(({ time, specification, index }) => {
                    return (
                      <Fragment key={uuid()}>
                        <tr>
                          <th
                            scope="row"
                            className="f-400 p-2 text-center"
                            style={{
                              backgroundColor: "rgba(22,90,170,.05)",
                              borderRight: "1px solid white",
                              borderRadius: `${
                                index === Timetable.length ? "" : "0 0 0 8px"
                              }`,
                            }}
                          >
                            {time}
                          </th>
                          <td
                            className="px-3 py-2"
                            style={{
                              backgroundColor: "rgba(224,224,224,.25)",
                              borderRadius: `${
                                index === Timetable.length ? "" : "0 0 8px 0"
                              }`,
                            }}
                          >
                            {specification}
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
                </tbody>
              </table>
              <div
                style={{
                  marginTop: "32px",
                  fontWeight: 400,
                  fontSize: "14px",
                  textAlign: "center",
                  color: "#252A38D9",
                }}
              >
                Дата регістрації конференції буде повідомлена заздалегіть, та
                кожний бажаючий зможе приеднатися до неї, та отримати відповіді
                на свої питання
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default About;
