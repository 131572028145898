import styles from "./style.module.scss";

export const TechProgram = () => {
  return (
    <div className={styles.container}>
      <header>
        <h1>Програма розвитку технічних проектів</h1>
        <section>
          Програма розвитку технічних проектів - це онлайн-навчання, спрямоване
          на розвиток ваших навичок та допомогу у перетворенні ваших
          пет-проектів у цінні продукти. Вона розроблена з урахуванням сучасних
          технологій, таких як розгортання у хмарних сервісах AWS та Azure, і
          надає практичний досвід для поліпшення вашого портфоліо.
        </section>
      </header>
      <div className={styles.main_block}>
        <div className={styles.title}>
          <h1>Про програму</h1>
          <p>Що ви отримаєте</p>
        </div>
        <div className={styles.section_block}>
          <div className={styles.left_content}>
            <div>
              <h2>Метою програми є Знайомство з практичними інструментами</h2>
              <p>
                для розширення функціональності ваших проектів, їх адаптації та
                оптимізації. Ви познайомитеся з реальними кейсами, які
                допоможуть вам усвідомити застосування цих інструментів.
              </p>
            </div>
            <div>
              <h2>Програма підходить вам, якщо</h2>
              <p>
                Ви хочете розвивати свої технічні навички та перетворити свої
                пет-проекти на цінні продукти
              </p>
              <p>
                У вас є цікаві ідеї та проекти, але не знаєте, як їх розвивати і
                масштабувати
              </p>
              <p>
                Вже стартували проект, але зіткнулись з технічними чи
                організаційними викликами
              </p>
              <p>
                Бажаєте покращити своє портфоліо та стати більш привабливим
                кандидатом для роботодавців у сфері IT
              </p>
            </div>
          </div>
          <div className={styles.right_content}>
            <div>
              <h2>Навчання в динамічному онлайн-форматі, під час якого ви</h2>
              <ul>
                <li>
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 0.669946C8.25413 1.10537 8.88147 1.73021 9.31991 2.4826C9.75834 3.23498 9.99268 4.08883 9.99971 4.95962C10.0067 5.8304 9.78623 6.68792 9.36001 7.44729C8.93378 8.20666 8.31662 8.84155 7.56962 9.2891C6.82262 9.73666 5.97168 9.98136 5.10105 9.99898C4.23041 10.0166 3.37027 9.80653 2.60578 9.38957C1.84128 8.9726 1.19894 8.36321 0.742333 7.6217C0.285731 6.8802 0.0307046 6.0323 0.00250006 5.16195L0 4.99995L0.00250006 4.83795C0.0305016 3.97444 0.281774 3.13293 0.73182 2.39544C1.18187 1.65796 1.81533 1.04967 2.57045 0.629887C3.32556 0.210103 4.17657 -0.00685539 5.0405 0.000165123C5.90443 0.00718564 6.7518 0.237945 7.5 0.669946ZM5 3.99995C4.76103 3.99994 4.52996 4.0855 4.34863 4.24115C4.1673 4.3968 4.0477 4.61223 4.0115 4.84845L4.0025 4.92545L4 4.99995L4.0025 5.07495C4.01709 5.26897 4.08797 5.45452 4.20645 5.60886C4.32493 5.7632 4.48586 5.87963 4.66952 5.94387C4.85318 6.00811 5.0516 6.01737 5.24044 5.97053C5.42929 5.92368 5.60037 5.82276 5.73272 5.68013C5.86506 5.53751 5.95293 5.35937 5.98554 5.16755C6.01815 4.97573 5.99409 4.77856 5.91631 4.60021C5.83853 4.42186 5.71042 4.27007 5.54766 4.16345C5.3849 4.05683 5.19457 4.00001 5 3.99995Z"
                      fill="#EAC131"
                    />
                  </svg>
                  <span>Оціните потенціал своїх проектів</span>
                </li>
                <li>
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 0.669946C8.25413 1.10537 8.88147 1.73021 9.31991 2.4826C9.75834 3.23498 9.99268 4.08883 9.99971 4.95962C10.0067 5.8304 9.78623 6.68792 9.36001 7.44729C8.93378 8.20666 8.31662 8.84155 7.56962 9.2891C6.82262 9.73666 5.97168 9.98136 5.10105 9.99898C4.23041 10.0166 3.37027 9.80653 2.60578 9.38957C1.84128 8.9726 1.19894 8.36321 0.742333 7.6217C0.285731 6.8802 0.0307046 6.0323 0.00250006 5.16195L0 4.99995L0.00250006 4.83795C0.0305016 3.97444 0.281774 3.13293 0.73182 2.39544C1.18187 1.65796 1.81533 1.04967 2.57045 0.629887C3.32556 0.210103 4.17657 -0.00685539 5.0405 0.000165123C5.90443 0.00718564 6.7518 0.237945 7.5 0.669946ZM5 3.99995C4.76103 3.99994 4.52996 4.0855 4.34863 4.24115C4.1673 4.3968 4.0477 4.61223 4.0115 4.84845L4.0025 4.92545L4 4.99995L4.0025 5.07495C4.01709 5.26897 4.08797 5.45452 4.20645 5.60886C4.32493 5.7632 4.48586 5.87963 4.66952 5.94387C4.85318 6.00811 5.0516 6.01737 5.24044 5.97053C5.42929 5.92368 5.60037 5.82276 5.73272 5.68013C5.86506 5.53751 5.95293 5.35937 5.98554 5.16755C6.01815 4.97573 5.99409 4.77856 5.91631 4.60021C5.83853 4.42186 5.71042 4.27007 5.54766 4.16345C5.3849 4.05683 5.19457 4.00001 5 3.99995Z"
                      fill="#EAC131"
                    />
                  </svg>
                  <span>
                    Дізнаєтеся, як аналізувати ринок та визначити потреби
                    користувачів
                  </span>
                </li>
                <li>
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 0.669946C8.25413 1.10537 8.88147 1.73021 9.31991 2.4826C9.75834 3.23498 9.99268 4.08883 9.99971 4.95962C10.0067 5.8304 9.78623 6.68792 9.36001 7.44729C8.93378 8.20666 8.31662 8.84155 7.56962 9.2891C6.82262 9.73666 5.97168 9.98136 5.10105 9.99898C4.23041 10.0166 3.37027 9.80653 2.60578 9.38957C1.84128 8.9726 1.19894 8.36321 0.742333 7.6217C0.285731 6.8802 0.0307046 6.0323 0.00250006 5.16195L0 4.99995L0.00250006 4.83795C0.0305016 3.97444 0.281774 3.13293 0.73182 2.39544C1.18187 1.65796 1.81533 1.04967 2.57045 0.629887C3.32556 0.210103 4.17657 -0.00685539 5.0405 0.000165123C5.90443 0.00718564 6.7518 0.237945 7.5 0.669946ZM5 3.99995C4.76103 3.99994 4.52996 4.0855 4.34863 4.24115C4.1673 4.3968 4.0477 4.61223 4.0115 4.84845L4.0025 4.92545L4 4.99995L4.0025 5.07495C4.01709 5.26897 4.08797 5.45452 4.20645 5.60886C4.32493 5.7632 4.48586 5.87963 4.66952 5.94387C4.85318 6.00811 5.0516 6.01737 5.24044 5.97053C5.42929 5.92368 5.60037 5.82276 5.73272 5.68013C5.86506 5.53751 5.95293 5.35937 5.98554 5.16755C6.01815 4.97573 5.99409 4.77856 5.91631 4.60021C5.83853 4.42186 5.71042 4.27007 5.54766 4.16345C5.3849 4.05683 5.19457 4.00001 5 3.99995Z"
                      fill="#EAC131"
                    />
                  </svg>
                  <span>
                    Розробите стратегію розвитку та масштабування своїх проектів
                  </span>
                </li>
                <li>
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 0.669946C8.25413 1.10537 8.88147 1.73021 9.31991 2.4826C9.75834 3.23498 9.99268 4.08883 9.99971 4.95962C10.0067 5.8304 9.78623 6.68792 9.36001 7.44729C8.93378 8.20666 8.31662 8.84155 7.56962 9.2891C6.82262 9.73666 5.97168 9.98136 5.10105 9.99898C4.23041 10.0166 3.37027 9.80653 2.60578 9.38957C1.84128 8.9726 1.19894 8.36321 0.742333 7.6217C0.285731 6.8802 0.0307046 6.0323 0.00250006 5.16195L0 4.99995L0.00250006 4.83795C0.0305016 3.97444 0.281774 3.13293 0.73182 2.39544C1.18187 1.65796 1.81533 1.04967 2.57045 0.629887C3.32556 0.210103 4.17657 -0.00685539 5.0405 0.000165123C5.90443 0.00718564 6.7518 0.237945 7.5 0.669946ZM5 3.99995C4.76103 3.99994 4.52996 4.0855 4.34863 4.24115C4.1673 4.3968 4.0477 4.61223 4.0115 4.84845L4.0025 4.92545L4 4.99995L4.0025 5.07495C4.01709 5.26897 4.08797 5.45452 4.20645 5.60886C4.32493 5.7632 4.48586 5.87963 4.66952 5.94387C4.85318 6.00811 5.0516 6.01737 5.24044 5.97053C5.42929 5.92368 5.60037 5.82276 5.73272 5.68013C5.86506 5.53751 5.95293 5.35937 5.98554 5.16755C6.01815 4.97573 5.99409 4.77856 5.91631 4.60021C5.83853 4.42186 5.71042 4.27007 5.54766 4.16345C5.3849 4.05683 5.19457 4.00001 5 3.99995Z"
                      fill="#EAC131"
                    />
                  </svg>
                  <span>Опануєте презентацію своїх ідей та результатів</span>
                </li>
              </ul>
            </div>
            <div>
              <h2>Розвиток свого проекту</h2>
              <p>
                Успішно виконавши всі завдання програми, ви отримаєте можливість
                перетворити свої проекти на продукти, які вразять рекрутерів та
                принесуть вам колосальний досвід у сучасних технологіях.
              </p>
            </div>
            <div>
              <h2>Можливість для подальшого зростання</h2>
              <p>
                Після успішного завершення програми, у вас буде можливість
                знайти нові можливості для розвитку та фінансування своїх
                проектів, а також залучити партнерів та інвесторів.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
