import { useState, useEffect } from "react";

const PopUp = ({ children, status, setStatus }) => {
  const clickHandler = (e) => {
    if (e.target.dataset.name === "popup-bg") {
      setStatus(false);
    }
  };
  const keyDownHandler = (e) => {
    if (e.keyCode === 27 || e.which === 27) {
      setStatus(false);
    }
  };

  useEffect(() => {
    const body = document.querySelector("body");
    if (status) {
      body.style.overflowY = "hidden";
      document.addEventListener("keydown", keyDownHandler);
      return () => document.removeEventListener("keydown", keyDownHandler);
    } else {
      body.style.overflowY = "auto";
    }
  }, [status]);

  return (
    <>
      <div
        onClick={clickHandler}
        data-name="popup-bg"
        style={{
          position: "fixed",
          zIndex: "103",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          overflowY: "scroll",

          display: "flex",
          justifyContent: "center",
          visibility: `${status ? "visible" : "hidden"}`,
          transition: "all 0.2s ease-in-out",
          opacity: `${status ? "1" : "0"}`,
          background: "rgb(0,0,0,0.7)",
          padding: "25px",
        }}
      >
        <div
          className={`${status ? "popup-top__animation" : ""}`}
          style={{
            position: "relative",
            marginTop: "30px",
            height: "fit-content",
          }}
        >
          <i
            onClick={() => setStatus(false)}
            className="material-icons"
            style={{
              cursor: "pointer",
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "red",
            }}
          >
            close
          </i>
          {children}
        </div>
      </div>
    </>
  );
};

export default PopUp;
