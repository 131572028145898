import style from "./style.module.scss";

const GrowthLevelsAccordionItem = (props) => {
  const {
    icon,
    setCurrentSlide,
    currentSlide,
    salary,
    index,
    totalBlocksCount,
    level,
  } = props;
  return (
    <>
      <div
        className={style["accordion__item-wrapper"]}
        style={{
          minWidth: `${currentSlide === index ? "51%" : "25%"}`,
          borderRight: `${
            !(index === totalBlocksCount - 1) ? "3px solid #fff" : "none"
          }`,
        }}
        onMouseEnter={() => {
          setCurrentSlide(index);
        }}
        onMouseLeave={() => {
          setCurrentSlide(null);
        }}
      >
        <div className={style["accordion__item"]}>
          {icon}
          <div className={style["accordion__item-content"]}>
            {currentSlide === index && (
              <h1 className={style["slow-appear-animation"]}>{salary}</h1>
            )}
            <h2>{level}</h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default GrowthLevelsAccordionItem;
