import CountupElement from "./CountupElement";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { Fragment, useState } from "react";
import uuid from "react-uuid";
import { useNavigate } from "react-router-dom";

const ToDonatePageBtn = () => {
  const navigate = useNavigate();

  return (
    <>
      <button
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "auto",
          });
          navigate("/donate");
        }}
        className="hover-yellow"
        style={{
          backgroundColor: "#252A38",
          color: "white",
          padding: "16px 32px",
          maxWidth: "340px",
          width: "100%",
          fontWeight: 700,
          borderRadius: "8px",
          textTransform: "uppercase",
        }}
      >
        задонатити
      </button>
    </>
  );
};

const CountupSection = () => {
  const [showCountup, setShowCountup] = useState(false);

  const countUpBlcoks = [
    {
      result: "127",
      sign: "",
      target: "Заявок",
      achievment: "до наглядової ради",
    },
    {
      result: "5",
      sign: "",
      target: "наглядової ради",
      achievment: "опрацьовано",
    },
    {
      result: "7",
      sign: "",
      target: "заявок",
      achievment: "кандидатів у журі",
    },
    {
      result: "5000",
      sign: "+",
      target: "заявок",
      achievment: "до інтернатур",
    },
    { result: "2046", sign: "", target: "інтернів", achievment: "приєдналось" },
    { result: "40", sign: "%", target: "отримало", achievment: "сертифікати" },
  ];

  return (
    <>
      <ScrollTrigger onEnter={() => setShowCountup(true)}>
        <section
          style={{
            padding: "40px 0 128px 0",
            maxWidth: "1200px",
            margin: "0 auto",
          }}
        >
          <div
            className="flex flex-col justify-center items-center"
            style={{ padding: "0 25px 120px 25px" }}
          >
            <h1
              style={{
                fontSize: "56px",
                color: "#165AAA",
                fontWeight: 300,
                textAlign: "center",
              }}
            >
              Наші досягнення
            </h1>
            <h3
              style={{ color: "#EAC131", fontSize: "28px", marginTop: "8px" }}
            >
              з березня 2022 року
            </h3>
          </div>
          <div
            className="flex w-full"
            style={{
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "20px",
              padding: "0 25px",
            }}
          >
            {countUpBlcoks.map((block) => {
              const { result, target, achievment, sign } = block;
              return (
                <Fragment key={uuid()}>
                  <CountupElement>
                    <h1
                      style={{
                        fontSize: "48px",
                        fontWeight: 500,
                        color: "#fff",
                      }}
                    >
                      {showCountup && (
                        <CountUp start={0} end={result} duration={5} />
                      )}
                      {sign}
                    </h1>
                    <div
                      style={{
                        fontWeight: 700,
                        fontSize: "20px",
                        color: "#EAC131",
                        marginTop: "24px",
                        textTransform: "uppercase",
                      }}
                    >
                      {target}
                    </div>
                    <div
                      style={{
                        fontWeight: 700,
                        fontSize: "20px",
                        color: "#E0E0E0",
                        marginTop: "24px",
                        textTransform: "uppercase",
                      }}
                    >
                      {achievment}
                    </div>
                  </CountupElement>
                </Fragment>
              );
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            <ToDonatePageBtn />
          </div>
        </section>
      </ScrollTrigger>
    </>
  );
};

export default CountupSection;
