import { Link } from "react-router-dom";
import img404 from "../../assets/img/errors/404.png";

const Block404 = () => {
  return (
    <>
      <section
        style={{
          maxWidth: "1200px",
          width: "100%",
          margin: "0 auto",

          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "120px 0 160px 0",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "0 25px",
          }}
        >
          <img src={img404} style={{ maxWidth: "259px" }} />
          <h1
            style={{
              fontWeight: 400,
              color: "#252A38",
              fontSize: "32px",
              textAlign: "center",
              marginTop: "64px",
            }}
          >
            Схоже, при завантаженні сторніки щось пішло не так.
          </h1>
          <Link
            to="/"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <button
              className="hover-yellow"
              style={{
                maxWidth: "340px",
                width: "100%",
                background: "#165AAA",
                padding: "13px 32px 12px 32px",
                borderRadius: "8px",
                marginTop: "77px",
                fontWeight: 700,
                color: "#fff",
                fontSize: "16px",
              }}
            >
              ПОВЕРНУТИСЬ НА ГОЛОВНУ
            </button>
          </Link>
        </div>
      </section>
    </>
  );
};

export default Block404;
