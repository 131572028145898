import style from "./style.module.scss";

const ExpectationsAccordionItem = (props) => {
  const {
    data: { svg, title, text },
    wrapperLength,
    currentSlide,
    setCurrentSlide,
    index,
    totalBlocksCount,
  } = props;
  return (
    <>
      <div
        className={style["accordion__item"]}
        style={{
          minWidth: `${currentSlide === index ? "76%" : "25%"}`,
          borderRight: `${
            !(index === totalBlocksCount - 1) ? "3px solid #165AAA" : "none"
          }`,
        }}
        onMouseEnter={() => {
          setCurrentSlide(index);
        }}
        onMouseLeave={() => {
          setCurrentSlide(null);
        }}
      >
        <div
          className={style["accordion__item-title__wrapper"]}
          style={{
            minWidth: `${wrapperLength / 4 - 40}px`,
            maxWidth: `${wrapperLength / 4 - 40}px`,
          }}
        >
          <div className={style["accordion__item-title"]}>
            {svg && (
              <img
                src={`https://drive.google.com/uc?export=view&id=${svg}`}
                alt="svg"
              />
            )}
            {title}
          </div>
        </div>
        <div
          className={style["accordion__item-text"]}
          style={{
            minWidth: `${(wrapperLength / 4) * 2 - 40}px`,
          }}
        >
          {text}
        </div>
      </div>
    </>
  );
};

export default ExpectationsAccordionItem;
