import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../App";

const DesignForGoodCard = ({ data }) => {
  const {
    likedContestsWorks,
    setLikedContestsWorks,
    isLikeClicked,
    setIsLikeClicked,
  } = useContext(GlobalContext);

  const { Fullname, Likes, Work_URL, Location } = data;

  const changeLikes = async () => {
    setIsLikeClicked(true);

    if (!likedContestsWorks.includes(Location)) {
      const likedDesign = [...likedContestsWorks, Location];
      setLikedContestsWorks(likedDesign);
    } else {
      const likedDesign = likedContestsWorks.filter(
        (workLocation) => workLocation !== Location
      );
      setLikedContestsWorks(likedDesign);
    }

    const likesInDB = await axios
      .get(
        `https://api-it-sprout.a-rogovsky1276.workers.dev/Contests_works/${Location}`
      )
      .then((res) => res.data)
      .then((res) => res.fields["Likes"]);

    await axios.patch(
      `https://api-it-sprout.a-rogovsky1276.workers.dev/Contests_works`,
      {
        records: [
          {
            id: Location,
            fields: {
              Likes: !likedContestsWorks.includes(Location)
                ? likesInDB + 1
                : likesInDB - 1,
            },
          },
        ],
      }
    );

    setIsLikeClicked(false);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          justifySelf: "center",
          display: "flex",
          flexDirection: "column",
          gap: "32px",
          color: "#252A38",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: "1 1 0%",
            background: "rgb(0,0,0,0.05)",
            borderRadius: "8px",
            overflow: "hidden",
            minHeight: "374px",
            maxHeight: "374px",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: `url("${Work_URL}")`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        />
        <div
          className="flex justify-between items-center"
          style={{ padding: "0 30px" }}
        >
          <div style={{ fontWeight: 600, fontSize: "24px" }}>
            {(() => {
              const fullNameArr = Fullname.split(" ");
              return `${fullNameArr[0]} ${fullNameArr[1][0] || ""}.`;
            })()}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <div
              style={{
                fontWeight: 700,
                fontSize: "28px",
                color: "#165AAA",
              }}
            >
              {Likes}
            </div>
            <i
              onClick={() => {
                changeLikes();
              }}
              className="material-icons"
              style={{
                pointerEvents: `${isLikeClicked ? "none" : "all"}`,
                fontSize: "28px",
                cursor: "pointer",
                userSelect: "none",
                color: `${
                  likedContestsWorks.includes(Location) ? "red" : "inherit"
                }`,
              }}
            >
              favorite
            </i>
          </div>
        </div>
      </div>
    </>
  );
};

export default DesignForGoodCard;
