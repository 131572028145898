import style from "../style.module.scss";

const AchievementsItem = (props) => {
  const {
    data: { result, target, achievment },
  } = props;

  return (
    <>
      <div className={style["achievements__list-item"]}>
        <h1>{result}</h1>
        <h3>{target}</h3>
        <h4>{achievment}</h4>
      </div>
    </>
  );
};

export default AchievementsItem;
