import { useContext } from "react";
import { Fragment, useEffect, useRef, useState } from "react";
import { PageContext } from "../../../InternshipPage";
import style from "./style.module.scss";
import useResizeObserver from "../../../../../custom-hooks/useResizeObserver";
import InternshipStagesAccordionItem from "./InternshipStagesAccordionItem";
import useWindowWidth from "../../../../../custom-hooks/useWindowWidth";

const blockColors = [
  "#1080FF",
  "#0072F5",
  "#0066DB",
  "#005AC2",
  "#004FA8",
  "#004390",
];

const InternshipStagesAccordion_more1028px = (props) => {
  const { blocks } = props;
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [wrapperRef, dimensions] = useResizeObserver();

  return (
    <>
      <div className={style["accordion-wrapper"]}>
        <div
          className={style["accordion"]}
          ref={wrapperRef}
          style={{
            transform: `translateX(-${
              selectedBlock
                ? (selectedBlock - 3) * (dimensions.width / blocks.length)
                : 0
            }px)`,
          }}
        >
          {blocks.map((block, index) => {
            return (
              <InternshipStagesAccordionItem
                key={index}
                index={index}
                data={block}
                bgColor={blockColors[index]}
                selectedBlock={selectedBlock}
                setSelectedBlock={setSelectedBlock}
                wrapperLength={dimensions.width}
                totalBlocksCount={blocks.length}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

const InternshipStagesAccordion_less1028px = (props) => {
  const { blocks } = props;
  return (
    <>
      <div className={style["blocks"]}>
        {blocks.map((block, index) => {
          const { title, text } = block;
          return (
            <div key={index} className={style["blocks__item"]}>
              <div
                className={style["blocks__item-content"]}
                style={{ background: blockColors[index] }}
              >
                <div className={style["blocks__item-title"]}>{title}</div>
                <div className={style["blocks__item-text"]}>{text}</div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const InternshipStagesAccordion = () => {
  const { pageData } = useContext(PageContext);
  const blocks = JSON.parse(pageData["InternshipStages_accordion"]);
  const width = useWindowWidth();
  if (width >= 1028) {
    return <InternshipStagesAccordion_more1028px blocks={blocks} />;
  } else {
    return <InternshipStagesAccordion_less1028px blocks={blocks} />;
  }
};

export default InternshipStagesAccordion;
