import "../../styles/privacy-table.css";

const Privacy = () => {
  return (
    <div style={{ maxWidth: "1321px", margin: "50px auto" }}>
      <table className="c34 privacy-table" style={{ width: "100%" }}>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c29">
              <span className="c1">
                &#1055;&#1054;&#1051;&#1030;&#1058;&#1048;&#1050;&#1040;
                &#1050;&#1054;&#1053;&#1060;&#1030;&#1044;&#1045;&#1053;&#1062;&#1030;&#1049;&#1053;&#1054;&#1057;&#1058;&#1030;
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c38 c40">
              <span className="c2">PRIVACY POLICY</span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c1">
                &#1054;&#1089;&#1090;&#1072;&#1085;&#1085;&#1108;
                &#1086;&#1085;&#1086;&#1074;&#1083;&#1077;&#1085;&#1085;&#1103;:
                18 &#1089;&#1110;&#1095;&#1085;&#1103; 2023 &#1088;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c17">
              <span className="c1">Last updated January 18, 2023</span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span>
                &#1062;&#1077;
                &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1077;&#1085;&#1085;&#1103;
                &#1087;&#1088;&#1086;
                &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1110;&#1089;&#1090;&#1100;
                &#1076;&#1083;&#1103;
                &#1041;&#1083;&#1072;&#1075;&#1086;&#1076;&#1110;&#1081;&#1085;&#1086;&#1111;
                &#1054;&#1088;&#1075;&#1072;&#1085;&#1110;&#1079;&#1072;&#1094;&#1110;&#1111;
                &laquo;&#1041;&#1083;&#1072;&#1075;&#1086;&#1076;&#1110;&#1081;&#1085;&#1080;&#1081;
                &nbsp;&#1060;&#1086;&#1085;&#1076; IT
                &#1055;&#1072;&#1088;&#1086;&#1089;&#1090;&#1086;&#1082;&raquo;
              </span>
              <span className="c2">&nbsp;</span>
              <span>(</span>
              <span className="c5">
                <a
                  className="c15"
                  href="https://www.google.com/url?q=https://it-sprout.org.ua/&amp;sa=D&amp;source=editors&amp;ust=1674814257871103&amp;usg=AOvVaw0SXBUBxNrNu9XH3I4H2oif"
                >
                  https://it-sprout.org.ua/
                </a>
              </span>
              <span>) (&laquo;</span>
              <span className="c2">
                &#1050;&#1086;&#1084;&#1087;&#1072;&#1085;&#1110;&#1103;
              </span>
              <span>&raquo;, &laquo;</span>
              <span className="c2">&#1084;&#1080;</span>
              <span>&raquo;, &laquo;</span>
              <span className="c2">&#1085;&#1072;&#1089;</span>
              <span>&raquo; &#1072;&#1073;&#1086; &laquo;</span>
              <span className="c2">&#1085;&#1072;&#1096;</span>
              <span>
                &raquo;) &#1086;&#1087;&#1080;&#1089;&#1091;&#1108;,
                &#1103;&#1082; &#1110; &#1095;&#1086;&#1084;&#1091;
                &#1084;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1079;&#1073;&#1080;&#1088;&#1072;&#1090;&#1080; ,
                &#1079;&#1073;&#1077;&#1088;&#1110;&#1075;&#1072;&#1090;&#1080;,
                &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1074;&#1072;&#1090;&#1080;
                &#1090;&#1072;/&#1072;&#1073;&#1086;
                &#1085;&#1072;&#1076;&#1072;&#1074;&#1072;&#1090;&#1080; (&quot;
              </span>
              <span className="c2">
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
              </span>
              <span>
                &quot;) &#1074;&#1072;&#1096;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                &#1082;&#1086;&#1083;&#1080; &#1074;&#1080;
                &#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1108;&#1090;&#1077;&#1089;&#1103;
                &#1085;&#1072;&#1096;&#1080;&#1084;&#1080;
                &#1089;&#1083;&#1091;&#1078;&#1073;&#1072;&#1084;&#1080; (&quot;
              </span>
              <span className="c2">
                &#1057;&#1077;&#1088;&#1074;&#1110;&#1089;&#1080;
              </span>
              <span>
                &quot;),
                &#1085;&#1072;&#1087;&#1088;&#1080;&#1082;&#1083;&#1072;&#1076;,
                &#1082;&#1086;&#1083;&#1080; &#1074;&#1080;:
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span>This privacy policy for &nbsp;</span>
              <span>
                Charitable Organization &laquo;Charitable Foundation &nbsp;IT
                Sprout&raquo;{" "}
              </span>
              <span>(</span>
              <span>
                <a
                  className="c15"
                  href="https://www.google.com/url?q=https://it-sprout.org.ua/&amp;sa=D&amp;source=editors&amp;ust=1674814257872000&amp;usg=AOvVaw2kEH2_WufxCNST0hq5y-iJ"
                >
                  https://it-sprout.org.ua/
                </a>
              </span>
              <span>) (&quot;</span>
              <span className="c2">Company</span>
              <span>,&quot; &quot;</span>
              <span className="c2">we</span>
              <span>,&quot; &quot;</span>
              <span className="c2">us</span>
              <span>,&quot; or &quot;</span>
              <span className="c2">our</span>
              <span>
                &quot;), describes how and why we might collect, store, use,
                and/or share (&quot;
              </span>
              <span className="c2">process</span>
              <span>
                &quot;) your information when you use our services (&quot;
              </span>
              <span className="c2">Services</span>
              <span className="c3">&quot;), such as when you:</span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_abbrqtpfalv-0 start">
              <li className="c8 c13 li-bullet-0">
                <span>
                  &#1074;&#1110;&#1076;&#1074;&#1110;&#1076;&#1091;&#1108;&#1090;&#1077;
                  &#1085;&#1072;&#1096;
                  &#1074;&#1077;&#1073;-&#1089;&#1072;&#1081;&#1090;
                  &#1079;&#1072;
                  &#1072;&#1076;&#1088;&#1077;&#1089;&#1086;&#1102;{" "}
                </span>
                <span className="c5">
                  <a
                    className="c15"
                    href="https://www.google.com/url?q=https://it-sprout.org.ua/&amp;sa=D&amp;source=editors&amp;ust=1674814257872822&amp;usg=AOvVaw0guTRKY0iASCqJ414BUrEE"
                  >
                    https://it-sprout.org.ua/
                  </a>
                </span>
                <span className="c3">
                  &nbsp;&#1072;&#1073;&#1086;
                  &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1080;&#1081;
                  &#1085;&#1072;&#1096;
                  &#1074;&#1077;&#1073;-&#1089;&#1072;&#1081;&#1090;,
                  &#1103;&#1082;&#1080;&#1081;
                  &#1087;&#1086;&#1089;&#1080;&#1083;&#1072;&#1108;&#1090;&#1100;&#1089;&#1103;
                  &#1085;&#1072; &#1094;&#1077;
                  &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1077;&#1085;&#1085;&#1103;
                  &#1087;&#1088;&#1086;
                  &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1110;&#1089;&#1090;&#1100;
                </span>
              </li>
              <li className="c8 c13 li-bullet-0">
                <span>
                  &#1042;&#1079;&#1072;&#1108;&#1084;&#1086;&#1076;&#1110;&#1103;&#1090;&#1080;
                  &#1079; &#1085;&#1072;&#1084;&#1080;
                  &#1110;&#1085;&#1096;&#1080;&#1084;&#1080;
                  &#1087;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1085;&#1080;&#1084;&#1080;
                  &#1089;&#1087;&#1086;&#1089;&#1086;&#1073;&#1072;&#1084;&#1080;,
                  &#1074;&#1082;&#1083;&#1102;&#1095;&#1085;&#1086; &#1079;
                  &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1080;&#1084;&#1080;
                  &#1087;&#1088;&#1086;&#1076;&#1072;&#1078;&#1072;&#1084;&#1080;,
                  &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;&#1086;&#1084;
                  &#1072;&#1073;&#1086;
                  &#1087;&#1086;&#1076;&#1110;&#1103;&#1084;&#1080;
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_2uildhs3da9w-0 start">
              <li className="c8 c13 li-bullet-0">
                <span className="c10">Visit our website at </span>
                <span>&nbsp;</span>
                <span className="c5">
                  <a
                    className="c15"
                    href="https://www.google.com/url?q=https://it-sprout.org.ua/&amp;sa=D&amp;source=editors&amp;ust=1674814257873906&amp;usg=AOvVaw0PHdCkIUBcUWVuvqvHgj15"
                  >
                    https://it-sprout.org.ua/
                  </a>
                </span>
                <span className="c16 c10">
                  , or any website of ours that links to this privacy notice
                </span>
              </li>
              <li className="c8 c13 li-bullet-0">
                <span className="c16 c10">
                  Engage with us in other related ways, including any sales,
                  marketing, or events
                </span>
              </li>
            </ul>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                &#1052;&#1072;&#1108;&#1090;&#1077;
                &#1079;&#1072;&#1087;&#1080;&#1090;&#1072;&#1085;&#1085;&#1103;
                &#1095;&#1080;
                &#1089;&#1091;&#1084;&#1085;&#1110;&#1074;&#1080;?
              </span>
              <span>
                &#1054;&#1079;&#1085;&#1072;&#1081;&#1086;&#1084;&#1083;&#1077;&#1085;&#1085;&#1103;
                &#1079; &#1094;&#1080;&#1084;
                &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1077;&#1085;&#1085;&#1103;&#1084;
                &#1087;&#1088;&#1086;
                &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1110;&#1089;&#1090;&#1100;
                &#1076;&#1086;&#1087;&#1086;&#1084;&#1086;&#1078;&#1077;
                &#1074;&#1072;&#1084;
                &#1079;&#1088;&#1086;&#1079;&#1091;&#1084;&#1110;&#1090;&#1080;
                &#1074;&#1072;&#1096;&#1110; &#1087;&#1088;&#1072;&#1074;&#1072;
                &#1090;&#1072; &#1074;&#1080;&#1073;&#1110;&#1088;
                &#1097;&#1086;&#1076;&#1086;
                &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;.
                &#1071;&#1082;&#1097;&#1086; &#1074;&#1080; &#1085;&#1077;
                &#1079;&#1075;&#1086;&#1076;&#1085;&#1110; &#1079;
                &#1085;&#1072;&#1096;&#1086;&#1102;
                &#1087;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1086;&#1102;,
                &#1073;&#1091;&#1076;&#1100;
                &#1083;&#1072;&#1089;&#1082;&#1072;, &#1085;&#1077;
                &#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1081;&#1090;&#1077;&#1089;&#1103;
                &#1085;&#1072;&#1096;&#1080;&#1084;&#1080;
                &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;&#1072;&#1084;&#1080;.
                &#1071;&#1082;&#1097;&#1086; &#1091; &#1074;&#1072;&#1089;
                &#1074;&#1089;&#1077; &#1097;&#1077; &#1108;
                &#1079;&#1072;&#1087;&#1080;&#1090;&#1072;&#1085;&#1085;&#1103;
                &#1095;&#1080;
                &#1089;&#1091;&#1084;&#1085;&#1110;&#1074;&#1080;,
                &#1079;&#1074;&rsquo;&#1103;&#1078;&#1110;&#1090;&#1100;&#1089;&#1103;
                &#1079; &#1085;&#1072;&#1084;&#1080; &#1079;&#1072;
                &nbsp;&#1072;&#1076;&#1088;&#1077;&#1089;&#1086;&#1102;{" "}
              </span>
              <span className="c5">
                <a className="c15" href="mailto:support@it-sprout.org.ua">
                  support@it-sprout.org.ua
                </a>
              </span>
              <span>&nbsp;</span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">Questions or concerns? </span>
              <span className="c10">
                Reading this privacy notice will help you understand your
                privacy rights and choices. If you do not agree with our
                policies and practices, please do not use our Services. If you
                still have any questions or concerns, please contact us at
              </span>
              <span className="c10">&nbsp;you may email us at </span>
              <span className="c5 c10">
                <a className="c15" href="mailto:support@it-sprout.org.ua">
                  support@it-sprout.org.ua
                </a>
              </span>
              <span className="c10">&nbsp;</span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c1">
                &#1056;&#1045;&#1047;&#1070;&#1052;&#1045;
                &#1050;&#1051;&#1070;&#1063;&#1054;&#1042;&#1048;&#1061;
                &#1055;&#1059;&#1053;&#1050;&#1058;&#1030;&#1042;
              </span>
            </p>
          </td>
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">SUMMARY OF KEY POINTS</span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c2">
                &#1059; &#1094;&#1100;&#1086;&#1084;&#1091;
                &#1082;&#1086;&#1088;&#1086;&#1090;&#1082;&#1086;&#1084;&#1091;
                &#1074;&#1080;&#1082;&#1083;&#1072;&#1076;&#1110;
                &#1085;&#1072;&#1074;&#1077;&#1076;&#1077;&#1085;&#1086;
                &#1082;&#1083;&#1102;&#1095;&#1086;&#1074;&#1110;
                &#1084;&#1086;&#1084;&#1077;&#1085;&#1090;&#1080; &#1079;
                &#1085;&#1072;&#1096;&#1086;&#1111;
                &#1087;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1080;
                &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c10 c2 c32">
                This summary provides key points from our privacy policy.
              </span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                &#1071;&#1082;&#1091;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1084;&#1080;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1108;&#1084;&#1086;?
              </span>
              <span>
                &nbsp;&#1050;&#1086;&#1083;&#1080; &#1074;&#1080;
                &#1074;&#1110;&#1076;&#1074;&#1110;&#1076;&#1091;&#1108;&#1090;&#1077;,
                &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1108;&#1090;&#1077;
                &#1072;&#1073;&#1086;
                &#1087;&#1077;&#1088;&#1077;&#1084;&#1110;&#1097;&#1091;&#1108;&#1090;&#1077;&#1089;&#1103;
                &#1074; &#1085;&#1072;&#1096;&#1080;&#1093;
                &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;&#1072;&#1093;,
                &#1084;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1079;&#1072;&#1083;&#1077;&#1078;&#1085;&#1086;
                &#1074;&#1110;&#1076; &#1090;&#1086;&#1075;&#1086;,
                &#1103;&#1082; &#1074;&#1080;
                &#1074;&#1079;&#1072;&#1108;&#1084;&#1086;&#1076;&#1110;&#1108;&#1090;&#1077;
                &#1079; &#1085;&#1072;&#1096;&#1080;&#1084;&#1080;
                &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;&#1072;&#1084;&#1080;,
                &#1074;&#1080;&#1073;&#1086;&#1088;&#1091;,
                &#1103;&#1082;&#1080;&#1081; &#1074;&#1080;
                &#1088;&#1086;&#1073;&#1080;&#1090;&#1077;, &#1072;
                &#1090;&#1072;&#1082;&#1086;&#1078;
                &#1087;&#1088;&#1086;&#1076;&#1091;&#1082;&#1090;&#1110;&#1074;
                &#1110; &#1092;&#1091;&#1085;&#1082;&#1094;&#1110;&#1081;,
                &#1103;&#1082;&#1110; &#1074;&#1080;
                &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1108;&#1090;&#1077;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">
                What personal information do we process?
              </span>
              <span className="c16 c10">
                &nbsp;When you visit, use, or navigate our Services, we may
                process personal information depending on how you interact with
                our Services, the choices you make, and the products and
                features you use.{" "}
              </span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                &#1063;&#1080;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1108;&#1084;&#1086;
                &#1084;&#1080;
                &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1091;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;?
              </span>
              <span>
                &nbsp;&#1052;&#1080; &#1085;&#1077;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1108;&#1084;&#1086;
                &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1091;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">
                Do we process any sensitive personal information?
              </span>
              <span className="c16 c10">
                &nbsp;We do not process sensitive personal information.
              </span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                &#1063;&#1080;
                &#1086;&#1090;&#1088;&#1080;&#1084;&#1091;&#1108;&#1084;&#1086;
                &#1084;&#1080;
                &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1074;&#1110;&#1076; &#1090;&#1088;&#1077;&#1090;&#1110;&#1093;
                &#1089;&#1090;&#1086;&#1088;&#1110;&#1085;?
              </span>
              <span>
                &nbsp;&#1052;&#1080; &#1085;&#1077;
                &#1086;&#1090;&#1088;&#1080;&#1084;&#1091;&#1108;&#1084;&#1086;
                &#1078;&#1086;&#1076;&#1085;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;
                &#1074;&#1110;&#1076; &#1090;&#1088;&#1077;&#1090;&#1110;&#1093;
                &#1086;&#1089;&#1110;&#1073;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">
                Do we receive any information from third parties?
              </span>
              <span className="c16 c10">
                &nbsp;We do not receive any information from third parties.
              </span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                &#1071;&#1082; &#1084;&#1080;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1108;&#1084;&#1086;
                &#1074;&#1072;&#1096;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;?
              </span>
              <span>
                &nbsp;&#1052;&#1080;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1108;&#1084;&#1086;
                &#1074;&#1072;&#1096;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                &#1097;&#1086;&#1073;
                &#1085;&#1072;&#1076;&#1072;&#1074;&#1072;&#1090;&#1080;,
                &#1087;&#1086;&#1082;&#1088;&#1072;&#1097;&#1091;&#1074;&#1072;&#1090;&#1080;
                &#1090;&#1072;
                &#1072;&#1076;&#1084;&#1110;&#1085;&#1110;&#1089;&#1090;&#1088;&#1091;&#1074;&#1072;&#1090;&#1080;
                &#1085;&#1072;&#1096;&#1110;
                &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080;,
                &#1089;&#1087;&#1110;&#1083;&#1082;&#1091;&#1074;&#1072;&#1090;&#1080;&#1089;&#1103;
                &#1079; &#1074;&#1072;&#1084;&#1080;, &#1076;&#1083;&#1103;
                &#1073;&#1077;&#1079;&#1087;&#1077;&#1082;&#1080; &#1090;&#1072;
                &#1079;&#1072;&#1087;&#1086;&#1073;&#1110;&#1075;&#1072;&#1085;&#1085;&#1103;
                &#1096;&#1072;&#1093;&#1088;&#1072;&#1081;&#1089;&#1090;&#1074;&#1091;,
                &#1072; &#1090;&#1072;&#1082;&#1086;&#1078;
                &#1076;&#1083;&#1103;
                &#1076;&#1086;&#1090;&#1088;&#1080;&#1084;&#1072;&#1085;&#1085;&#1103;
                &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1072;.
                &#1052;&#1080; &#1090;&#1072;&#1082;&#1086;&#1078;
                &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                &#1074;&#1072;&#1096;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1076;&#1083;&#1103; &#1110;&#1085;&#1096;&#1080;&#1093;
                &#1094;&#1110;&#1083;&#1077;&#1081; &#1079;&#1072;
                &#1074;&#1072;&#1096;&#1086;&#1102;
                &#1079;&#1075;&#1086;&#1076;&#1086;&#1102;. &#1052;&#1080;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1108;&#1084;&#1086;
                &#1074;&#1072;&#1096;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1083;&#1080;&#1096;&#1077; &#1090;&#1086;&#1076;&#1110;,
                &#1082;&#1086;&#1083;&#1080; &#1091; &#1085;&#1072;&#1089;
                &#1108; &#1074;&#1072;&#1075;&#1086;&#1084;&#1072;
                &#1079;&#1072;&#1082;&#1086;&#1085;&#1085;&#1072;
                &#1087;&#1110;&#1076;&#1089;&#1090;&#1072;&#1074;&#1072;
                &#1076;&#1083;&#1103; &#1094;&#1100;&#1086;&#1075;&#1086;.{" "}
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">
                How do we process your information?
              </span>
              <span className="c16 c10">
                &nbsp;We process your information to provide, improve, and
                administer our Services, communicate with you, for security and
                fraud prevention, and to comply with law. We may also process
                your information for other purposes with your consent. We
                process your information only when we have a valid legal reason
                to do so.
              </span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                &#1059; &#1103;&#1082;&#1080;&#1093;
                &#1089;&#1080;&#1090;&#1091;&#1072;&#1094;&#1110;&#1103;&#1093;
                &#1110; &#1103;&#1082;&#1080;&#1084;
                &#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1072;&#1084;
                &#1084;&#1080; &#1085;&#1072;&#1076;&#1072;&#1108;&#1084;&#1086;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;?
              </span>
              <span>
                &nbsp;&#1052;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1076;&#1110;&#1083;&#1080;&#1090;&#1080;&#1089;&#1103;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1108;&#1102;
                &#1074; &#1087;&#1077;&#1074;&#1085;&#1080;&#1093;
                &#1089;&#1080;&#1090;&#1091;&#1072;&#1094;&#1110;&#1103;&#1093;
                &#1110; &#1079;
                &#1087;&#1077;&#1074;&#1085;&#1080;&#1084;&#1080;
                &#1082;&#1072;&#1090;&#1077;&#1075;&#1086;&#1088;&#1110;&#1103;&#1084;&#1080;
                &#1090;&#1088;&#1077;&#1090;&#1110;&#1093;
                &#1089;&#1090;&#1086;&#1088;&#1110;&#1085;.{" "}
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">
                In what situations and with which types of parties do we share
                personal information?
              </span>
              <span className="c10">
                &nbsp;We may share information in specific situations and with
                specific categories of third parties.{" "}
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                &#1071;&#1082; &#1084;&#1080;
                &#1079;&#1072;&#1073;&#1077;&#1079;&#1087;&#1077;&#1095;&#1091;&#1108;&#1084;&#1086;
                &#1073;&#1077;&#1079;&#1087;&#1077;&#1082;&#1091;
                &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;?
              </span>
              <span>
                &nbsp;&#1059; &#1085;&#1072;&#1089; &#1108;
                &#1086;&#1088;&#1075;&#1072;&#1085;&#1110;&#1079;&#1072;&#1094;&#1110;&#1081;&#1085;&#1110;
                &#1090;&#1072;
                &#1090;&#1077;&#1093;&#1085;&#1110;&#1095;&#1085;&#1110;
                &#1087;&#1088;&#1086;&#1094;&#1077;&#1089;&#1080; &#1090;&#1072;
                &#1087;&#1088;&#1086;&#1094;&#1077;&#1076;&#1091;&#1088;&#1080;
                &#1076;&#1083;&#1103;
                &#1079;&#1072;&#1093;&#1080;&#1089;&#1090;&#1091;
                &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;.
                &#1054;&#1076;&#1085;&#1072;&#1082;
                &#1078;&#1086;&#1076;&#1085;&#1072;
                &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1072;
                &#1087;&#1077;&#1088;&#1077;&#1076;&#1072;&#1095;&#1072;
                &#1095;&#1077;&#1088;&#1077;&#1079;
                &#1030;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090;
                &#1072;&#1073;&#1086;
                &#1090;&#1077;&#1093;&#1085;&#1086;&#1083;&#1086;&#1075;&#1110;&#1103;
                &#1079;&#1073;&#1077;&#1088;&#1110;&#1075;&#1072;&#1085;&#1085;&#1103;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;
                &#1085;&#1077; &#1084;&#1086;&#1078;&#1077;
                &#1073;&#1091;&#1090;&#1080;
                &#1075;&#1072;&#1088;&#1072;&#1085;&#1090;&#1086;&#1074;&#1072;&#1085;&#1086;
                &#1085;&#1072; 100%
                &#1073;&#1077;&#1079;&#1087;&#1077;&#1095;&#1085;&#1086;&#1102;,
                &#1090;&#1086;&#1084;&#1091; &#1084;&#1080; &#1085;&#1077;
                &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1086;&#1073;&#1110;&#1094;&#1103;&#1090;&#1080;
                &#1072;&#1073;&#1086;
                &#1075;&#1072;&#1088;&#1072;&#1085;&#1090;&#1091;&#1074;&#1072;&#1090;&#1080;,
                &#1097;&#1086; &#1093;&#1072;&#1082;&#1077;&#1088;&#1080;,
                &#1082;&#1110;&#1073;&#1077;&#1088;&#1079;&#1083;&#1086;&#1095;&#1080;&#1085;&#1094;&#1110;
                &#1095;&#1080; &#1110;&#1085;&#1096;&#1110;
                &#1085;&#1077;&#1072;&#1074;&#1090;&#1086;&#1088;&#1080;&#1079;&#1086;&#1074;&#1072;&#1085;&#1110;
                &#1090;&#1088;&#1077;&#1090;&#1110;
                &#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1080; &#1085;&#1077;
                &#1079;&#1084;&#1086;&#1078;&#1091;&#1090;&#1100;
                &#1087;&#1086;&#1088;&#1091;&#1096;&#1080;&#1090;&#1080;
                &#1085;&#1072;&#1096;&#1091;
                &#1073;&#1077;&#1079;&#1087;&#1077;&#1082;&#1091; &#1090;&#1072;
                &#1085;&#1077;&#1085;&#1072;&#1083;&#1077;&#1078;&#1085;&#1080;&#1084;
                &#1095;&#1080;&#1085;&#1086;&#1084;
                &#1079;&#1073;&#1080;&#1088;&#1072;&#1090;&#1080;,
                &#1086;&#1090;&#1088;&#1080;&#1084;&#1091;&#1074;&#1072;&#1090;&#1080;
                &#1076;&#1086;&#1089;&#1090;&#1091;&#1087;,
                &#1074;&#1080;&#1082;&#1088;&#1072;&#1089;&#1090;&#1080;
                &#1095;&#1080; &#1079;&#1084;&#1110;&#1085;&#1080;&#1090;&#1080;
                &#1074;&#1072;&#1096;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">
                How do we keep your information safe?
              </span>
              <span className="c10">
                &nbsp;We have organizational and technical processes and
                procedures in place to protect your personal information.
                However, no electronic transmission over the internet or
                information storage technology can be guaranteed to be 100%
                secure, so we cannot promise or guarantee that hackers,
                cybercriminals, or other unauthorized third parties will not be
                able to defeat our security and improperly collect, access,
                steal, or modify your information.{" "}
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                &#1071;&#1082;&#1110; &#1074;&#1072;&#1096;&#1110;
                &#1087;&#1088;&#1072;&#1074;&#1072;?
              </span>
              <span>
                &nbsp;&#1047;&#1072;&#1083;&#1077;&#1078;&#1085;&#1086;
                &#1074;&#1110;&#1076; &#1090;&#1086;&#1075;&#1086;,
                &#1076;&#1077; &#1074;&#1080;
                &#1075;&#1077;&#1086;&#1075;&#1088;&#1072;&#1092;&#1110;&#1095;&#1085;&#1086;
                &#1088;&#1086;&#1079;&#1090;&#1072;&#1096;&#1086;&#1074;&#1072;&#1085;&#1110;,
                &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1080;&#1081;
                &#1079;&#1072;&#1082;&#1086;&#1085; &#1087;&#1088;&#1086;
                &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1110;&#1089;&#1090;&#1100;
                &#1084;&#1086;&#1078;&#1077;
                &#1086;&#1079;&#1085;&#1072;&#1095;&#1072;&#1090;&#1080;,
                &#1097;&#1086; &#1074;&#1080;
                &#1084;&#1072;&#1108;&#1090;&#1077;
                &#1087;&#1077;&#1074;&#1085;&#1110;
                &#1087;&#1088;&#1072;&#1074;&#1072; &#1097;&#1086;&#1076;&#1086;
                &#1089;&#1074;&#1086;&#1108;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;.{" "}
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">What are your rights?</span>
              <span className="c10">
                &nbsp;Depending on where you are located geographically, the
                applicable privacy law may mean you have certain rights
                regarding your personal information.{" "}
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                &#1071;&#1082; &#1074;&#1080;
                &#1088;&#1077;&#1072;&#1083;&#1110;&#1079;&#1091;&#1108;&#1090;&#1077;
                &#1089;&#1074;&#1086;&#1111;
                &#1087;&#1088;&#1072;&#1074;&#1072;?
              </span>
              <span>
                &nbsp;&#1053;&#1072;&#1081;&#1087;&#1088;&#1086;&#1089;&#1090;&#1110;&#1096;&#1080;&#1081;
                &#1089;&#1087;&#1086;&#1089;&#1110;&#1073;
                &#1089;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1072;&#1090;&#1080;&#1089;&#1103;
                &#1089;&#1074;&#1086;&#1111;&#1084;&#1080;
                &#1087;&#1088;&#1072;&#1074;&#1072;&#1084;&#1080; &ndash;
                &#1079;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1090;&#1080;&#1089;&#1103;
                &#1079; &#1085;&#1072;&#1084;&#1080;
                &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1086;&#1102;
                &#1087;&#1086;&#1096;&#1090;&#1086;&#1102;. &#1052;&#1080;
                &#1088;&#1086;&#1079;&#1075;&#1083;&#1103;&#1085;&#1077;&#1084;&#1086;
                &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1080;&#1081;
                &#1079;&#1072;&#1087;&#1080;&#1090; &#1110;
                &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1084;&#1086;
                &#1085;&#1072; &#1085;&#1100;&#1086;&#1075;&#1086;
                &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;
                &#1076;&#1086; &#1095;&#1080;&#1085;&#1085;&#1086;&#1075;&#1086;
                &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1072;
                &#1087;&#1088;&#1086; &#1079;&#1072;&#1093;&#1080;&#1089;&#1090;
                &#1076;&#1072;&#1085;&#1080;&#1093;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">How do you exercise your rights?</span>
              <span className="c10">
                &nbsp;The easiest way to exercise your rights is by contacting
                us via email. We will consider and act upon any request in
                accordance with applicable data protection laws.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span>
                &#1061;&#1086;&#1095;&#1077;&#1090;&#1077;
                &#1076;&#1110;&#1079;&#1085;&#1072;&#1090;&#1080;&#1089;&#1103;
                &#1073;&#1110;&#1083;&#1100;&#1096;&#1077; &#1087;&#1088;&#1086;
                &#1090;&#1077;, &#1097;&#1086; &#1084;&#1080;
                &#1088;&#1086;&#1073;&#1080;&#1084;&#1086; &#1079;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1108;&#1102;,
                &#1103;&#1082;&#1091;
                &#1079;&#1073;&#1080;&#1088;&#1072;&#1108;&#1084;&#1086;?
                &#1055;&#1077;&#1088;&#1077;&#1075;&#1083;&#1103;&#1085;&#1100;&#1090;&#1077;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1085;&#1080;&#1078;&#1095;&#1077;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                Want to learn more about what we do with any information we
                collect?
              </span>
              <span className="c10">Check out the information below.</span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c1">&#1047;&#1052;&#1030;&#1057;&#1058;</span>
            </p>
          </td>
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">TABLE OF CONTENTS</span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec1" className="c8">
              <span>
                1. &#1071;&#1050;&#1059;
                &#1030;&#1053;&#1060;&#1054;&#1056;&#1052;&#1040;&#1062;&#1030;&#1070;
                &#1052;&#1048;
                &#1047;&#1041;&#1048;&#1056;&#1040;&#1028;&#1052;&#1054;?
              </span>
            </a>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec1" className="c8">
              <span className="c10">1. WHAT INFORMATION DO WE COLLECT?</span>
            </a>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec2" className="c8">
              <span>
                2. &#1071;&#1050; &#1052;&#1048;
                &#1054;&#1041;&#1056;&#1054;&#1041;&#1051;&#1071;&#1028;&#1052;&#1054;
                &#1042;&#1040;&#1064;&#1059;
                &#1030;&#1053;&#1060;&#1054;&#1056;&#1052;&#1040;&#1062;&#1030;&#1070;?
              </span>
            </a>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec2" className="c8">
              <span className="c10">
                2. HOW DO WE PROCESS YOUR INFORMATION?
              </span>
            </a>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec3" className="c8">
              <span>
                3. &#1071;&#1050;&#1048;&#1052;&#1048;
                &#1055;&#1056;&#1040;&#1042;&#1054;&#1042;&#1048;&#1052;&#1048;
                &#1055;&#1030;&#1044;&#1057;&#1058;&#1040;&#1042;&#1040;&#1052;&#1048;
                &#1052;&#1048;
                &#1050;&#1045;&#1056;&#1059;&#1028;&#1052;&#1054;&#1057;&#1071;
                &#1044;&#1051;&#1071;
                &#1054;&#1041;&#1056;&#1054;&#1041;&#1050;&#1048;
                &#1042;&#1040;&#1064;&#1054;&#1031;
                &#1055;&#1045;&#1056;&#1057;&#1054;&#1053;&#1040;&#1051;&#1068;&#1053;&#1054;&#1031;
                &#1030;&#1053;&#1060;&#1054;&#1056;&#1052;&#1040;&#1062;&#1030;&#1031;?
              </span>
            </a>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec3" className="c8">
              <span className="c10">
                3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                INFORMATION?
              </span>
            </a>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec4" className="c8">
              <span>
                4. &#1050;&#1054;&#1051;&#1048; &#1030; &#1047;
                &#1050;&#1048;&#1052; &#1052;&#1048;
                &#1044;&#1030;&#1051;&#1048;&#1052;&#1054;&#1057;&#1071;
                &#1042;&#1040;&#1064;&#1054;&#1070;
                &#1054;&#1057;&#1054;&#1041;&#1048;&#1057;&#1058;&#1054;&#1070;
                &#1030;&#1053;&#1060;&#1054;&#1056;&#1052;&#1040;&#1062;&#1030;&#1028;&#1070;?
              </span>
            </a>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec4" className="c8">
              <span className="c10">
                4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </span>
            </a>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec5" className="c8">
              <span>
                5. &#1063;&#1048;
                &#1042;&#1048;&#1050;&#1054;&#1056;&#1048;&#1057;&#1058;&#1054;&#1042;&#1059;&#1028;&#1052;&#1054;
                &#1052;&#1048; &#1060;&#1040;&#1049;&#1051;&#1048; COOKIE
                &#1058;&#1040; &#1030;&#1053;&#1064;&#1030;
                &#1058;&#1045;&#1061;&#1053;&#1054;&#1051;&#1054;&#1043;&#1030;&#1031;
                &#1042;&#1030;&#1044;&#1057;&#1058;&#1045;&#1046;&#1045;&#1053;&#1053;&#1071;?{" "}
              </span>
            </a>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec5" className="c8">
              <span className="c10">
                5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? &nbsp;
              </span>
            </a>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec6" className="c8">
              <span>
                6. &#1071;&#1050; &#1044;&#1054;&#1042;&#1043;&#1054;
                &#1052;&#1048;
                &#1047;&#1041;&#1045;&#1056;&#1030;&#1043;&#1040;&#1028;&#1052;&#1054;
                &#1042;&#1040;&#1064;&#1059;
                &#1030;&#1053;&#1060;&#1054;&#1056;&#1052;&#1040;&#1062;&#1030;&#1070;?
              </span>
            </a>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec6" className="c8">
              <span className="c10">
                6. HOW LONG DO WE KEEP YOUR INFORMATION?
              </span>
            </a>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec7" className="c8">
              <span>
                7. &#1071;&#1050; &#1052;&#1048;
                &#1047;&#1040;&#1041;&#1045;&#1047;&#1055;&#1045;&#1063;&#1059;&#1028;&#1052;&#1054;
                &#1041;&#1045;&#1047;&#1055;&#1045;&#1050;&#1059;
                &#1042;&#1040;&#1064;&#1054;&#1031;
                &#1030;&#1053;&#1060;&#1054;&#1056;&#1052;&#1040;&#1062;&#1030;&#1031;?
              </span>
            </a>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec7" className="c8">
              <span className="c10">
                7. HOW DO WE KEEP YOUR INFORMATION SAFE?
              </span>
            </a>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec8" className="c8">
              <span>
                8. &#1063;&#1048; &#1052;&#1048;
                &#1047;&#1041;&#1048;&#1056;&#1040;&#1028;&#1052;&#1054;
                &#1030;&#1053;&#1060;&#1054;&#1056;&#1052;&#1040;&#1062;&#1030;&#1070;
                &#1042;&#1030;&#1044;
                &#1053;&#1045;&#1055;&#1054;&#1042;&#1053;&#1054;&#1051;&#1030;&#1058;&#1053;&#1030;&#1061;?
              </span>
            </a>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec8" className="c8">
              <span className="c10">
                8. DO WE COLLECT INFORMATION FROM MINORS?
              </span>
            </a>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec9" className="c8">
              <span>
                9. &#1071;&#1050;&#1030; &#1042;&#1040;&#1064;&#1030;
                &#1055;&#1056;&#1040;&#1042;&#1040; &#1053;&#1040;
                &#1050;&#1054;&#1053;&#1060;&#1030;&#1044;&#1045;&#1053;&#1062;&#1030;&#1049;&#1053;&#1030;&#1057;&#1058;&#1068;?
              </span>
            </a>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec9" className="c8">
              <span className="c10">9. WHAT ARE YOUR PRIVACY RIGHTS?</span>
            </a>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec10" className="c8">
              <span>
                10. &#1045;&#1051;&#1045;&#1052;&#1045;&#1053;&#1058;&#1048;
                &#1050;&#1045;&#1056;&#1059;&#1042;&#1040;&#1053;&#1053;&#1071;
                &#1060;&#1059;&#1053;&#1050;&#1062;&#1030;&#1071;&#1052;&#1048;
                &#1053;&#1045;
                &#1042;&#1030;&#1044;&#1057;&#1058;&#1045;&#1046;&#1059;&#1042;&#1040;&#1058;&#1048;
              </span>
            </a>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec10" className="c8">
              <span className="c10">
                10. CONTROLS FOR DO-NOT-TRACK FEATURES
              </span>
            </a>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec11" className="c8">
              <span>
                11. &#1063;&#1048; &#1052;&#1040;&#1070;&#1058;&#1068;
                &#1046;&#1048;&#1058;&#1045;&#1051;&#1030;
                &#1050;&#1040;&#1051;&#1030;&#1060;&#1054;&#1056;&#1053;&#1030;&#1031;
                &#1057;&#1055;&#1045;&#1062;&#1030;&#1040;&#1051;&#1068;&#1053;&#1030;
                &#1055;&#1056;&#1040;&#1042;&#1040; &#1053;&#1040;
                &#1050;&#1054;&#1053;&#1060;&#1030;&#1044;&#1045;&#1053;&#1062;&#1030;&#1049;&#1053;&#1030;&#1057;&#1058;&#1068;?
              </span>
            </a>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec11" className="c8">
              <span className="c10">
                11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </span>
            </a>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec12" className="c8">
              <span>
                12. &#1063;&#1048; &#1052;&#1048;
                &#1056;&#1054;&#1041;&#1048;&#1052;&#1054;
                &#1054;&#1053;&#1054;&#1042;&#1051;&#1045;&#1053;&#1053;&#1071;
                &#1062;&#1030;&#1028;&#1031;
                &#1055;&#1054;&#1051;&#1030;&#1058;&#1048;&#1050;&#1048;
                &#1050;&#1054;&#1053;&#1060;&#1030;&#1044;&#1045;&#1053;&#1062;&#1030;&#1049;&#1053;&#1054;&#1057;&#1058;&#1030;?
              </span>
            </a>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec12" className="c8">
              <span className="c10">
                12. DO WE MAKE UPDATES TO THIS PRIVACY POLICY?
              </span>
            </a>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec13" className="c8">
              <span>
                13. &#1071;&#1050; &#1042;&#1048;
                &#1052;&#1054;&#1046;&#1045;&#1058;&#1045;
                &#1047;&#1042;&#39;&#1071;&#1047;&#1040;&#1058;&#1048;&#1057;&#1071;
                &#1047; &#1053;&#1040;&#1052;&#1048;
                &#1065;&#1054;&#1044;&#1054; &#1062;&#1030;&#1028;&#1031;
                &#1055;&#1054;&#1051;&#1030;&#1058;&#1048;&#1050;&#1048;
                &#1050;&#1054;&#1053;&#1060;&#1030;&#1044;&#1045;&#1053;&#1062;&#1030;&#1049;&#1053;&#1054;&#1057;&#1058;&#1030;?
              </span>
            </a>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec13" className="c8">
              <span className="c10">
                13. HOW CAN YOU CONTACT US ABOUT THIS PRIVACY POLICY?
              </span>
            </a>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec14" className="c8">
              <span>
                14. &#1071;&#1050; &#1042;&#1048;
                &#1052;&#1054;&#1046;&#1045;&#1058;&#1045;
                &#1055;&#1045;&#1056;&#1045;&#1043;&#1051;&#1071;&#1053;&#1059;&#1058;&#1048;,
                &#1054;&#1053;&#1054;&#1042;&#1048;&#1058;&#1048;
                &#1040;&#1041;&#1054;
                &#1042;&#1048;&#1044;&#1040;&#1051;&#1048;&#1058;&#1048;
                &#1044;&#1040;&#1053;&#1030;, &#1071;&#1050;&#1030;
                &#1052;&#1048;
                &#1047;&#1041;&#1048;&#1056;&#1040;&#1028;&#1052;&#1054;
                &#1042;&#1030;&#1044; &#1042;&#1040;&#1057;?
              </span>
            </a>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <a href="#privacy_sec14" className="c8">
              <span>
                14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </span>
            </a>
          </td>
        </tr>
        <tr className="c4" id="privacy_sec1">
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                1. &#1071;&#1050;&#1059;
                &#1030;&#1053;&#1060;&#1054;&#1056;&#1052;&#1040;&#1062;&#1030;&#1070;
                &#1052;&#1048;
                &#1047;&#1041;&#1048;&#1056;&#1040;&#1028;&#1052;&#1054;?
              </span>
            </p>
          </td>
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">1. WHAT INFORMATION DO WE COLLECT?</span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                &#1054;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1072;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;,
                &#1103;&#1082;&#1091; &#1074;&#1080; &#1085;&#1072;&#1084;
                &#1088;&#1086;&#1079;&#1082;&#1088;&#1080;&#1074;&#1072;&#1108;&#1090;&#1077;
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">
                Personal information you disclose to us
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c2">
                &#1050;&#1086;&#1088;&#1086;&#1090;&#1082;&#1086;:{" "}
              </span>
              <span className="c11">
                &#1084;&#1080;
                &#1079;&#1073;&#1080;&#1088;&#1072;&#1108;&#1084;&#1086;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                &#1103;&#1082;&#1091; &#1074;&#1080; &#1085;&#1072;&#1084;
                &#1085;&#1072;&#1076;&#1072;&#1108;&#1090;&#1077;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c10 c2">In Short: </span>
              <span className="c11 c10">
                We collect personal information that you provide to us.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1052;&#1080;
                &#1079;&#1073;&#1080;&#1088;&#1072;&#1108;&#1084;&#1086;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                &#1103;&#1082;&#1091; &#1074;&#1080;
                &#1076;&#1086;&#1073;&#1088;&#1086;&#1074;&#1110;&#1083;&#1100;&#1085;&#1086;
                &#1085;&#1072;&#1076;&#1072;&#1108;&#1090;&#1077;
                &#1085;&#1072;&#1084;, &#1082;&#1086;&#1083;&#1080;
                &#1088;&#1077;&#1108;&#1089;&#1090;&#1088;&#1091;&#1108;&#1090;&#1077;&#1089;&#1103;
                &#1085;&#1072; &#1085;&#1072;&#1096;&#1086;&#1084;&#1091;
                &#1089;&#1072;&#1081;&#1090;&#1110;,
                &#1074;&#1080;&#1088;&#1072;&#1078;&#1072;&#1108;&#1090;&#1077;
                &#1079;&#1072;&#1094;&#1110;&#1082;&#1072;&#1074;&#1083;&#1077;&#1085;&#1110;&#1089;&#1090;&#1100;
                &#1074;
                &#1086;&#1090;&#1088;&#1080;&#1084;&#1072;&#1085;&#1085;&#1110;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;
                &#1087;&#1088;&#1086; &#1085;&#1072;&#1089;
                &#1072;&#1073;&#1086; &#1085;&#1072;&#1096;&#1110;
                &#1087;&#1088;&#1086;&#1076;&#1091;&#1082;&#1090;&#1080;
                &#1090;&#1072;
                &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080;,
                &#1082;&#1086;&#1083;&#1080; &#1074;&#1080;
                &#1079;&#1072;&#1087;&#1086;&#1074;&#1085;&#1102;&#1108;&#1090;&#1077;
                &#1092;&#1086;&#1088;&#1084;&#1091; &#1085;&#1072;
                &nbsp;&#1085;&#1072;&#1096;&#1086;&#1084;&#1091;
                &nbsp;&#1089;&#1072;&#1081;&#1090;&#1110; &#1072;&#1073;&#1086;
                &#1110;&#1085;&#1096;&#1080;&#1084;
                &#1095;&#1080;&#1085;&#1086;&#1084;,
                &#1082;&#1086;&#1083;&#1080;
                &#1079;&#1074;&#1077;&#1088;&#1090;&#1072;&#1108;&#1090;&#1077;&#1089;&#1103;
                &#1076;&#1086; &#1085;&#1072;&#1089;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c14 c38">
              <span className="c3">
                We collect personal information that you voluntarily provide to
                us when you register on our site, express interest in receiving
                information about us or our products and Services, when you fill
                out a form on our site, or otherwise contact us.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                &#1054;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1072;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;,
                &#1085;&#1072;&#1076;&#1072;&#1085;&#1072;
                &#1074;&#1072;&#1084;&#1080;.
              </span>
              <span className="c3">
                &nbsp;&#1054;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1072;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;,
                &#1103;&#1082;&#1091; &#1084;&#1080;
                &#1079;&#1073;&#1080;&#1088;&#1072;&#1108;&#1084;&#1086;,
                &#1079;&#1072;&#1083;&#1077;&#1078;&#1080;&#1090;&#1100;
                &#1074;&#1110;&#1076;
                &#1082;&#1086;&#1085;&#1090;&#1077;&#1082;&#1089;&#1090;&#1091;
                &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1074;&#1079;&#1072;&#1108;&#1084;&#1086;&#1076;&#1110;&#1111;
                &#1079; &#1085;&#1072;&#1084;&#1080; &#1090;&#1072;
                &#1057;&#1077;&#1088;&#1074;&#1110;&#1089;&#1072;&#1084;&#1080;,
                &#1074;&#1080;&#1073;&#1086;&#1088;&#1091;,
                &#1103;&#1082;&#1080;&#1081; &#1074;&#1080;
                &#1088;&#1086;&#1073;&#1080;&#1090;&#1077;, &#1072;
                &#1090;&#1072;&#1082;&#1086;&#1078;
                &#1087;&#1088;&#1086;&#1076;&#1091;&#1082;&#1090;&#1110;&#1074;
                &#1110; &#1092;&#1091;&#1085;&#1082;&#1094;&#1110;&#1081;,
                &#1103;&#1082;&#1110; &#1074;&#1080;
                &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1108;&#1090;&#1077;.
                &#1054;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1072;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;,
                &#1103;&#1082;&#1091; &#1084;&#1080;
                &#1079;&#1073;&#1080;&#1088;&#1072;&#1108;&#1084;&#1086;,
                &#1084;&#1086;&#1078;&#1077;
                &#1074;&#1082;&#1083;&#1102;&#1095;&#1072;&#1090;&#1080;
                &#1085;&#1072;&#1089;&#1090;&#1091;&#1087;&#1085;&#1077;:
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">
                Personal Information Provided by You.
              </span>
              <span className="c10">
                &nbsp;The personal information that we collect depends on the
                context of your interactions with us and the Services, the
                choices you make, and the products and features you use. The
                personal information we collect may include the following:
              </span>
            </p>
          </td>
        </tr>
        <tr className="c33">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_9rk60m9h59n0-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">&#1055;&#1030;&#1041;,</span>
              </li>
            </ul>
            <ul className="c6 lst-kix_h6680u8qfjum-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1085;&#1086;&#1084;&#1077;&#1088;&#1080;
                  &#1090;&#1077;&#1083;&#1077;&#1092;&#1086;&#1085;&#1110;&#1074;
                </span>
              </li>
            </ul>
            <ul className="c6 lst-kix_5d9eby7kfclh-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1072;&#1076;&#1088;&#1077;&#1089;&#1072;
                  &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1086;&#1111;
                  &#1087;&#1086;&#1096;&#1090;&#1080;,
                </span>
              </li>
            </ul>
            <ul className="c6 lst-kix_226zqd75mopx-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1087;&#1086;&#1096;&#1090;&#1086;&#1074;&#1072;
                  &#1072;&#1076;&#1088;&#1077;&#1089;&#1072;
                </span>
              </li>
            </ul>
            <ul className="c6 lst-kix_kubz4wivm0fy-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1056;&#1053;&#1054;&#1050;&#1055;&#1055;
                </span>
              </li>
            </ul>
            <ul className="c6 lst-kix_9yqduyn48xc-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1087;&#1086;&#1089;&#1080;&#1083;&#1072;&#1085;&#1085;&#1103;
                  &#1085;&#1072;
                  &#1089;&#1090;&#1086;&#1088;&#1110;&#1085;&#1082;&#1091;
                  Linkedin
                </span>
              </li>
            </ul>
            <ul className="c6 lst-kix_3t89l3ia54eb-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1092;&#1086;&#1090;&#1086;&#1075;&#1088;&#1072;&#1092;&#1110;&#1103;
                  &#1079; &#1074;&#1072;&#1096;&#1080;&#1084;
                  &#1079;&#1086;&#1073;&#1088;&#1072;&#1078;&#1077;&#1085;&#1085;&#1103;&#1084;
                </span>
              </li>
            </ul>
            <ul className="c6 lst-kix_8vq942nknyyx-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1074;&#1110;&#1076;&#1077;&#1086; &#1079;
                  &#1074;&#1072;&#1096;&#1080;&#1084;
                  &#1079;&#1086;&#1073;&#1088;&#1072;&#1078;&#1077;&#1085;&#1085;&#1103;&#1084;
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_hbb909yf20pu-0 start">
              <li className="c0 li-bullet-0">
                <span className="c16 c10">names</span>
              </li>
              <li className="c0 li-bullet-0">
                <span className="c16 c10">phone numbers</span>
              </li>
              <li className="c0 li-bullet-0">
                <span className="c16 c10">email addresses</span>
              </li>
              <li className="c0 li-bullet-0">
                <span className="c16 c10">mailing addresses</span>
              </li>
              <li className="c0 li-bullet-0">
                <span className="c16 c10">identification code</span>
              </li>
              <li className="c0 li-bullet-0">
                <span className="c10">link to the </span>
                <span className="c3">Linkedin page</span>
              </li>
              <li className="c0 li-bullet-0">
                <span className="c3">a photo with your image</span>
              </li>
              <li className="c0 li-bullet-0">
                <span>a video with your image</span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                &#1050;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1072;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;.
              </span>
              <span className="c3">
                &nbsp;&#1052;&#1080; &#1085;&#1077;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1108;&#1084;&#1086;
                &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">Sensitive Information.</span>
              <span className="c10">
                &nbsp;We do not process sensitive information.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1059;&#1089;&#1103;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1072;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;,
                &#1103;&#1082;&#1091; &#1074;&#1080;
                &#1085;&#1072;&#1076;&#1072;&#1108;&#1090;&#1077;
                &#1085;&#1072;&#1084;, &#1084;&#1072;&#1108;
                &#1073;&#1091;&#1090;&#1080;
                &#1087;&#1088;&#1072;&#1074;&#1076;&#1080;&#1074;&#1086;&#1102;,
                &#1087;&#1086;&#1074;&#1085;&#1086;&#1102; &#1090;&#1072;
                &#1090;&#1086;&#1095;&#1085;&#1086;&#1102;, &#1110;
                &#1074;&#1080; &#1087;&#1086;&#1074;&#1080;&#1085;&#1085;&#1110;
                &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1103;&#1090;&#1080;
                &#1085;&#1072;&#1089; &#1087;&#1088;&#1086;
                &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1110;
                &#1079;&#1084;&#1110;&#1085;&#1080;
                &#1090;&#1072;&#1082;&#1086;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                All personal information that you provide to us must be true,
                complete, and accurate, and you must notify us of any changes to
                such personal information.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                &#1030;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;,
                &#1097;&#1086;
                &#1079;&#1073;&#1080;&#1088;&#1072;&#1108;&#1090;&#1100;&#1089;&#1103;
                &#1072;&#1074;&#1090;&#1086;&#1084;&#1072;&#1090;&#1080;&#1095;&#1085;&#1086;
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2 c31">
                Information automatically collected
              </span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c2">
                &#1050;&#1086;&#1088;&#1086;&#1090;&#1082;&#1086;:{" "}
              </span>
              <span className="c11">
                &#1076;&#1077;&#1103;&#1082;&#1072;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;,
                &#1085;&#1072;&#1087;&#1088;&#1080;&#1082;&#1083;&#1072;&#1076;
                &#1074;&#1072;&#1096;&#1072;
                &#1072;&#1076;&#1088;&#1077;&#1089;&#1072;
                &#1030;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090;-&#1087;&#1088;&#1086;&#1090;&#1086;&#1082;&#1086;&#1083;&#1091;
                (IP) &#1110;/&#1072;&#1073;&#1086;
                &#1093;&#1072;&#1088;&#1072;&#1082;&#1090;&#1077;&#1088;&#1080;&#1089;&#1090;&#1080;&#1082;&#1080;
                &#1073;&#1088;&#1072;&#1091;&#1079;&#1077;&#1088;&#1072;
                &#1090;&#1072;
                &#1087;&#1088;&#1080;&#1089;&#1090;&#1088;&#1086;&#1102;,
                &#1079;&#1073;&#1080;&#1088;&#1072;&#1108;&#1090;&#1100;&#1089;&#1103;
                &#1072;&#1074;&#1090;&#1086;&#1084;&#1072;&#1090;&#1080;&#1095;&#1085;&#1086;,
                &#1082;&#1086;&#1083;&#1080; &#1074;&#1080;
                &#1074;&#1110;&#1076;&#1074;&#1110;&#1076;&#1091;&#1108;&#1090;&#1077;
                &#1085;&#1072;&#1096;&#1110;
                &#1057;&#1077;&#1088;&#1074;&#1110;&#1089;&#1080;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c10 c2">In Short: </span>
              <span className="c11 c10">
                Some information &mdash; such as your Internet Protocol (IP)
                address and/or browser and device characteristics &mdash; is
                collected automatically when you visit our Services.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1052;&#1080;
                &#1072;&#1074;&#1090;&#1086;&#1084;&#1072;&#1090;&#1080;&#1095;&#1085;&#1086;
                &#1079;&#1073;&#1080;&#1088;&#1072;&#1108;&#1084;&#1086;
                &#1087;&#1077;&#1074;&#1085;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                &#1082;&#1086;&#1083;&#1080; &#1074;&#1080;
                &#1074;&#1110;&#1076;&#1074;&#1110;&#1076;&#1091;&#1108;&#1090;&#1077;,
                &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1108;&#1090;&#1077;
                &#1072;&#1073;&#1086;
                &#1087;&#1077;&#1088;&#1077;&#1084;&#1110;&#1097;&#1091;&#1108;&#1090;&#1077;&#1089;&#1103;
                &#1057;&#1077;&#1088;&#1074;&#1110;&#1089;&#1072;&#1084;&#1080;.
                &#1062;&#1103;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;
                &#1085;&#1077;
                &#1088;&#1086;&#1079;&#1082;&#1088;&#1080;&#1074;&#1072;&#1108;
                &#1074;&#1072;&#1096;&#1091;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1110;&#1089;&#1090;&#1100;
                (&#1085;&#1072;&#1087;&#1088;&#1080;&#1082;&#1083;&#1072;&#1076;,
                &#1074;&#1072;&#1096;&#1077; &#1110;&#1084;&rsquo;&#1103;
                &#1095;&#1080;
                &#1082;&#1086;&#1085;&#1090;&#1072;&#1082;&#1090;&#1085;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;),
                &#1072;&#1083;&#1077; &#1084;&#1086;&#1078;&#1077;
                &#1074;&#1082;&#1083;&#1102;&#1095;&#1072;&#1090;&#1080;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1087;&#1088;&#1086;
                &#1087;&#1088;&#1080;&#1089;&#1090;&#1088;&#1110;&#1081; &#1110;
                &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1072;&#1085;&#1085;&#1103;,
                &#1103;&#1082;-&#1086;&#1090; &#1074;&#1072;&#1096;&#1091;
                IP-&#1072;&#1076;&#1088;&#1077;&#1089;&#1091;,
                &#1093;&#1072;&#1088;&#1072;&#1082;&#1090;&#1077;&#1088;&#1080;&#1089;&#1090;&#1080;&#1082;&#1080;
                &#1074;&#1077;&#1073;-&#1087;&#1077;&#1088;&#1077;&#1075;&#1083;&#1103;&#1076;&#1072;&#1095;&#1072;
                &#1090;&#1072;
                &#1087;&#1088;&#1080;&#1089;&#1090;&#1088;&#1086;&#1102;,
                &#1086;&#1087;&#1077;&#1088;&#1072;&#1094;&#1110;&#1081;&#1085;&#1091;
                &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1091;,
                &#1084;&#1086;&#1074;&#1085;&#1110;
                &#1085;&#1072;&#1083;&#1072;&#1096;&#1090;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;,
                URL-&#1072;&#1076;&#1088;&#1077;&#1089;&#1080;
                &#1087;&#1077;&#1088;&#1077;&#1093;&#1086;&#1076;&#1091;,
                &#1085;&#1072;&#1079;&#1074;&#1091;
                &#1087;&#1088;&#1080;&#1089;&#1090;&#1088;&#1086;&#1102;,
                &#1082;&#1088;&#1072;&#1111;&#1085;&#1091;,
                &#1084;&#1110;&#1089;&#1094;&#1077;&#1079;&#1085;&#1072;&#1093;&#1086;&#1076;&#1078;&#1077;&#1085;&#1085;&#1103;,
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1087;&#1088;&#1086; &#1090;&#1077;, &#1103;&#1082; &#1110;
                &#1082;&#1086;&#1083;&#1080; &#1074;&#1080;
                &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1108;&#1090;&#1077;
                &#1085;&#1072;&#1096;&#1110;
                &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080;, &#1072;
                &#1090;&#1072;&#1082;&#1086;&#1078; &#1110;&#1085;&#1096;&#1091;
                &#1090;&#1077;&#1093;&#1085;&#1110;&#1095;&#1085;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;.
                &#1062;&#1103;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;
                &#1074; &#1087;&#1077;&#1088;&#1096;&#1091;
                &#1095;&#1077;&#1088;&#1075;&#1091;
                &#1087;&#1086;&#1090;&#1088;&#1110;&#1073;&#1085;&#1072;
                &#1076;&#1083;&#1103;
                &#1079;&#1072;&#1073;&#1077;&#1079;&#1087;&#1077;&#1095;&#1077;&#1085;&#1085;&#1103;
                &#1073;&#1077;&#1079;&#1087;&#1077;&#1082;&#1080; &#1090;&#1072;
                &#1088;&#1086;&#1073;&#1086;&#1090;&#1080;
                &#1085;&#1072;&#1096;&#1080;&#1093;
                &#1057;&#1077;&#1088;&#1074;&#1110;&#1089;&#1110;&#1074;,
                &#1072; &#1090;&#1072;&#1082;&#1086;&#1078;
                &#1076;&#1083;&#1103;
                &#1074;&#1085;&#1091;&#1090;&#1088;&#1110;&#1096;&#1085;&#1100;&#1086;&#1111;
                &#1072;&#1085;&#1072;&#1083;&#1110;&#1090;&#1080;&#1082;&#1080;
                &#1090;&#1072;
                &#1079;&#1074;&#1110;&#1090;&#1085;&#1086;&#1089;&#1090;&#1110;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                We automatically collect certain information when you visit,
                use, or navigate the Services. This information does not reveal
                your specific identity (like your name or contact information)
                but may include device and usage information, such as your IP
                address, browser and device characteristics, operating system,
                language preferences, referring URLs, device name, country,
                location, information about how and when you use our Services,
                and other technical information. This information is primarily
                needed to maintain the security and operation of our Services,
                and for our internal analytics and reporting purposes.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1071;&#1082; &#1110;
                &#1073;&#1072;&#1075;&#1072;&#1090;&#1086;
                &#1082;&#1086;&#1084;&#1087;&#1072;&#1085;&#1110;&#1081;,
                &#1084;&#1080; &#1090;&#1072;&#1082;&#1086;&#1078;
                &#1079;&#1073;&#1080;&#1088;&#1072;&#1108;&#1084;&#1086;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1079;&#1072;
                &#1076;&#1086;&#1087;&#1086;&#1084;&#1086;&#1075;&#1086;&#1102;
                &#1092;&#1072;&#1081;&#1083;&#1110;&#1074; cookie &#1090;&#1072;
                &#1087;&#1086;&#1076;&#1110;&#1073;&#1085;&#1080;&#1093;
                &#1090;&#1077;&#1093;&#1085;&#1086;&#1083;&#1086;&#1075;&#1110;&#1081;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                Like many businesses, we also collect information through
                cookies and similar technologies.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1030;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;,
                &#1103;&#1082;&#1091; &#1084;&#1080;
                &#1079;&#1073;&#1080;&#1088;&#1072;&#1108;&#1084;&#1086;,
                &#1074;&#1082;&#1083;&#1102;&#1095;&#1072;&#1108;:
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">The information we collect includes:</span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_yo0xte6dz8u7-0 start">
              <li className="c0 li-bullet-0">
                <span className="c11">
                  &#1076;&#1072;&#1085;&#1110;
                  &#1078;&#1091;&#1088;&#1085;&#1072;&#1083;&#1091;
                  &#1090;&#1072;
                  &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1072;&#1085;&#1085;&#1103;.
                </span>
                <span className="c3">
                  &nbsp;&#1044;&#1072;&#1085;&#1110;
                  &#1078;&#1091;&#1088;&#1085;&#1072;&#1083;&#1091;
                  &#1090;&#1072;
                  &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1072;&#1085;&#1085;&#1103;
                  &mdash; &#1094;&#1077;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;,
                  &#1087;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1085;&#1072;
                  &#1079;
                  &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;&#1072;&#1084;&#1080;,
                  &#1076;&#1110;&#1072;&#1075;&#1085;&#1086;&#1089;&#1090;&#1080;&#1082;&#1086;&#1102;,
                  &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1072;&#1085;&#1085;&#1103;&#1084;
                  &#1110;
                  &#1087;&#1088;&#1086;&#1076;&#1091;&#1082;&#1090;&#1080;&#1074;&#1085;&#1110;&#1089;&#1090;&#1102;,
                  &#1103;&#1082;&#1091; &#1085;&#1072;&#1096;&#1110;
                  &#1089;&#1077;&#1088;&#1074;&#1077;&#1088;&#1080;
                  &#1072;&#1074;&#1090;&#1086;&#1084;&#1072;&#1090;&#1080;&#1095;&#1085;&#1086;
                  &#1079;&#1073;&#1080;&#1088;&#1072;&#1102;&#1090;&#1100;,
                  &#1082;&#1086;&#1083;&#1080; &#1074;&#1080;
                  &#1086;&#1090;&#1088;&#1080;&#1084;&#1091;&#1108;&#1090;&#1077;
                  &#1076;&#1086;&#1089;&#1090;&#1091;&#1087; &#1076;&#1086;
                  &#1085;&#1072;&#1096;&#1080;&#1093;
                  &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;
                  &#1072;&#1073;&#1086;
                  &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1108;&#1090;&#1077;
                  &#1111;&#1093;, &#1110; &#1103;&#1082;&#1091; &#1084;&#1080;
                  &#1079;&#1072;&#1087;&#1080;&#1089;&#1091;&#1108;&#1084;&#1086;
                  &#1091; &#1092;&#1072;&#1081;&#1083;&#1080;
                  &#1078;&#1091;&#1088;&#1085;&#1072;&#1083;&#1110;&#1074;.
                  &#1047;&#1072;&#1083;&#1077;&#1078;&#1085;&#1086;
                  &#1074;&#1110;&#1076; &#1090;&#1086;&#1075;&#1086;,
                  &#1103;&#1082; &#1074;&#1080;
                  &#1074;&#1079;&#1072;&#1108;&#1084;&#1086;&#1076;&#1110;&#1108;&#1090;&#1077;
                  &#1079; &#1085;&#1072;&#1084;&#1080;,
                  &#1076;&#1072;&#1085;&#1110;
                  &#1078;&#1091;&#1088;&#1085;&#1072;&#1083;&#1091;
                  &#1084;&#1086;&#1078;&#1091;&#1090;&#1100;
                  &#1074;&#1082;&#1083;&#1102;&#1095;&#1072;&#1090;&#1080;
                  &#1074;&#1072;&#1096;&#1091;
                  IP-&#1072;&#1076;&#1088;&#1077;&#1089;&#1091;,
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                  &#1087;&#1088;&#1086;
                  &#1087;&#1088;&#1080;&#1089;&#1090;&#1088;&#1110;&#1081;,
                  &#1090;&#1080;&#1087;
                  &#1073;&#1088;&#1072;&#1091;&#1079;&#1077;&#1088;&#1072;,
                  &#1072; &#1090;&#1072;&#1082;&#1086;&#1078;,
                  &#1085;&#1072;&#1087;&#1088;&#1080;&#1082;&#1083;&#1072;&#1076;,
                  &#1087;&#1086;&#1079;&#1085;&#1072;&#1095;&#1082;&#1080;
                  &#1076;&#1072;&#1090;&#1080;/&#1095;&#1072;&#1089;&#1091;,
                  &#1087;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1085;&#1110;
                  &#1079; &#1074;&#1072;&#1096;&#1080;&#1084;
                  &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1072;&#1085;&#1085;&#1103;&#1084;,
                  &#1089;&#1090;&#1086;&#1088;&#1110;&#1085;&#1082;&#1080;
                  &#1090;&#1072; &#1092;&#1072;&#1081;&#1083;&#1080;,
                  &#1103;&#1082;&#1110; &#1074;&#1080;
                  &#1087;&#1077;&#1088;&#1077;&#1075;&#1083;&#1103;&#1076;&#1072;&#1108;&#1090;&#1077;,
                  &#1087;&#1086;&#1096;&#1091;&#1082;&#1080; &#1090;&#1072;
                  &#1110;&#1085;&#1096;&#1110; &#1076;&#1110;&#1111;,
                  &#1103;&#1082;&#1110; &#1074;&#1080;
                  &#1074;&#1080;&#1082;&#1086;&#1085;&#1091;&#1108;&#1090;&#1077;,
                  &#1085;&#1072;&#1087;&#1088;&#1080;&#1082;&#1083;&#1072;&#1076;,
                  &#1103;&#1082;&#1110;
                  &#1092;&#1091;&#1085;&#1082;&#1094;&#1110;&#1111;
                  &#1074;&#1080;
                  &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1108;&#1090;&#1077;,
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                  &#1087;&#1088;&#1086; &#1087;&#1086;&#1076;&#1110;&#1111;
                  &#1087;&#1088;&#1080;&#1089;&#1090;&#1088;&#1086;&#1102;
                  (&#1090;&#1072;&#1082;&#1110; &#1103;&#1082;
                  &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1085;&#1072;
                  &#1072;&#1082;&#1090;&#1080;&#1074;&#1085;&#1110;&#1089;&#1090;&#1100;,
                  &#1079;&#1074;&#1110;&#1090;&#1080; &#1087;&#1088;&#1086;
                  &#1087;&#1086;&#1084;&#1080;&#1083;&#1082;&#1080;
                  (&#1110;&#1085;&#1086;&#1076;&#1110; &#1111;&#1093;
                  &#1085;&#1072;&#1079;&#1080;&#1074;&#1072;&#1102;&#1090;&#1100;
                  &laquo;&#1072;&#1074;&#1072;&#1088;&#1110;&#1081;&#1085;&#1080;&#1084;&#1080;
                  &#1076;&#1072;&#1084;&#1087;&#1072;&#1084;&#1080;&raquo;)
                  &#1090;&#1072;
                  &#1085;&#1072;&#1083;&#1072;&#1096;&#1090;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;
                  &#1072;&#1087;&#1072;&#1088;&#1072;&#1090;&#1085;&#1086;&#1075;&#1086;
                  &#1079;&#1072;&#1073;&#1077;&#1079;&#1087;&#1077;&#1095;&#1077;&#1085;&#1085;&#1103;).
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c18 c25">
              <span className="c16 c10"></span>
            </p>
            <ul className="c6 lst-kix_5l2q5sb5k938-0 start">
              <li className="c0 li-bullet-0">
                <span className="c11 c10">Log and Usage Data.</span>
                <span className="c16 c10">
                  &nbsp;Log and usage data is service-related, diagnostic,
                  usage, and performance information our servers automatically
                  collect when you access or use our Services and which we
                  record in log files. Depending on how you interact with us,
                  this log data may include your IP address, device information,
                  browser type, and settings and information about your activity
                  in the Services (such as the date/time stamps associated with
                  your usage, pages and files viewed, searches, and other
                  actions you take such as which features you use), device event
                  information (such as system activity, error reports (sometimes
                  called &quot;crash dumps&quot;), and hardware settings).
                </span>
              </li>
            </ul>
            <p className="c9">
              <span className="c1"></span>
            </p>
            <p className="c18 c25">
              <span className="c16 c10"></span>
            </p>
            <p className="c18 c23">
              <span className="c16 c10"></span>
            </p>
          </td>
        </tr>
        <tr className="c4" id="privacy_sec2">
          <td className="c7 flex flex-col" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                2. &#1071;&#1050; &#1052;&#1048;
                &#1054;&#1041;&#1056;&#1054;&#1041;&#1051;&#1071;&#1028;&#1052;&#1054;
                &#1042;&#1040;&#1064;&#1059;
                &#1030;&#1053;&#1060;&#1054;&#1056;&#1052;&#1040;&#1062;&#1030;&#1070;?
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">
                2. HOW DO WE PROCESS YOUR INFORMATION?
              </span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c2">
                &#1050;&#1086;&#1088;&#1086;&#1090;&#1082;&#1086;:{" "}
              </span>
              <span className="c11">
                &#1084;&#1080;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1108;&#1084;&#1086;
                &#1074;&#1072;&#1096;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                &#1097;&#1086;&#1073;
                &#1085;&#1072;&#1076;&#1072;&#1074;&#1072;&#1090;&#1080;,
                &#1087;&#1086;&#1082;&#1088;&#1072;&#1097;&#1091;&#1074;&#1072;&#1090;&#1080;
                &#1090;&#1072;
                &#1072;&#1076;&#1084;&#1110;&#1085;&#1110;&#1089;&#1090;&#1088;&#1091;&#1074;&#1072;&#1090;&#1080;
                &#1085;&#1072;&#1096;&#1110;
                &#1057;&#1077;&#1088;&#1074;&#1110;&#1089;&#1080;,
                &#1089;&#1087;&#1110;&#1083;&#1082;&#1091;&#1074;&#1072;&#1090;&#1080;&#1089;&#1103;
                &#1079; &#1074;&#1072;&#1084;&#1080;, &#1076;&#1083;&#1103;
                &#1073;&#1077;&#1079;&#1087;&#1077;&#1082;&#1080; &#1090;&#1072;
                &#1079;&#1072;&#1087;&#1086;&#1073;&#1110;&#1075;&#1072;&#1085;&#1085;&#1103;
                &#1096;&#1072;&#1093;&#1088;&#1072;&#1081;&#1089;&#1090;&#1074;&#1091;,
                &#1072; &#1090;&#1072;&#1082;&#1086;&#1078;
                &#1076;&#1083;&#1103;
                &#1076;&#1086;&#1090;&#1088;&#1080;&#1084;&#1072;&#1085;&#1085;&#1103;
                &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1072;.
                &#1052;&#1080; &#1090;&#1072;&#1082;&#1086;&#1078;
                &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                &#1074;&#1072;&#1096;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1076;&#1083;&#1103; &#1110;&#1085;&#1096;&#1080;&#1093;
                &#1094;&#1110;&#1083;&#1077;&#1081; &#1079;&#1072;
                &#1074;&#1072;&#1096;&#1086;&#1102;
                &#1079;&#1075;&#1086;&#1076;&#1086;&#1102;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c10 c2">In Short: </span>
              <span className="c21 c11 c10">
                We process your information to provide, improve, and administer
                our Services, communicate with you, for security and fraud
                prevention, and to comply with law. We may also process your
                information for other purposes with your consent.
              </span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                &#1052;&#1080;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1108;&#1084;&#1086;
                &#1074;&#1072;&#1096;&#1091;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1079; &#1088;&#1110;&#1079;&#1085;&#1080;&#1093;
                &#1087;&#1088;&#1080;&#1095;&#1080;&#1085;,
                &#1079;&#1072;&#1083;&#1077;&#1078;&#1085;&#1086;
                &#1074;&#1110;&#1076; &#1090;&#1086;&#1075;&#1086;,
                &#1103;&#1082; &#1074;&#1080;
                &#1074;&#1079;&#1072;&#1108;&#1084;&#1086;&#1076;&#1110;&#1108;&#1090;&#1077;
                &#1079; &#1085;&#1072;&#1096;&#1080;&#1084;&#1080;
                &#1057;&#1077;&#1088;&#1074;&#1110;&#1089;&#1072;&#1084;&#1080;,
                &#1079;&#1086;&#1082;&#1088;&#1077;&#1084;&#1072;:
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">
                We process your personal information for a variety of reasons,
                depending on how you interact with our Services, including:
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_nspq422yr55q-0 start">
              <li className="c0 li-bullet-0">
                <span className="c2">
                  &#1044;&#1083;&#1103;
                  &#1091;&#1082;&#1083;&#1072;&#1076;&#1077;&#1085;&#1085;&#1103;
                  &#1091;&#1075;&#1086;&#1076; &#1089;
                  &#1074;&#1072;&#1084;&#1080;.{" "}
                </span>
                <span className="c3">
                  &#1052;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                  &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                  &#1074;&#1072;&#1096;&#1091;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                  &#1076;&#1083;&#1103;
                  &#1092;&#1086;&#1088;&#1084;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;
                  &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1080;&#1093;
                  &#1091;&#1075;&#1086;&#1076; &#1110;&#1079;
                  &#1074;&#1072;&#1084;&#1080; &#1090;&#1072;
                  &#1085;&#1072;&#1076;&#1110;&#1089;&#1083;&#1072;&#1085;&#1085;&#1103;
                  &#1111;&#1093; &#1085;&#1072; &#1074;&#1072;&#1096;&#1091;
                  &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1091;
                  &#1072;&#1076;&#1088;&#1077;&#1089;&#1091;
                  &#1076;&#1083;&#1103;
                  &#1087;&#1110;&#1076;&#1087;&#1080;&#1089;&#1072;&#1085;&#1085;&#1103;.{" "}
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c14 c25">
              <span className="c3"></span>
            </p>
            <ul className="c6 lst-kix_qqnify3ukdh8-0 start">
              <li className="c14 c13 li-bullet-0">
                <span className="c2">To enter into agreements with you.</span>
                <span>
                  &nbsp;We may process your information to form relevant
                  agreements with you and send them to your email address for
                  signature.
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_zas8yfbhgr5k-0 start">
              <li className="c0 li-bullet-0">
                <span className="c2">
                  &#1044;&#1083;&#1103;
                  &#1087;&#1086;&#1083;&#1077;&#1075;&#1096;&#1077;&#1085;&#1085;&#1103;
                  &#1089;&#1090;&#1074;&#1086;&#1088;&#1077;&#1085;&#1085;&#1103;
                  &#1086;&#1073;&#1083;&#1110;&#1082;&#1086;&#1074;&#1086;&#1075;&#1086;
                  &#1079;&#1072;&#1087;&#1080;&#1089;&#1091; &#1090;&#1072;
                  &#1072;&#1074;&#1090;&#1077;&#1085;&#1090;&#1080;&#1092;&#1110;&#1082;&#1072;&#1094;&#1110;&#1111;
                  &#1090;&#1072; &#1110;&#1085;&#1096;&#1086;&#1075;&#1086;
                  &#1082;&#1077;&#1088;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;
                  &#1086;&#1073;&#1083;&#1110;&#1082;&#1086;&#1074;&#1080;&#1084;&#1080;
                  &#1079;&#1072;&#1087;&#1080;&#1089;&#1072;&#1084;&#1080;
                  &#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1074;&#1072;&#1095;&#1110;&#1074;.{" "}
                </span>
                <span className="c3">
                  &#1052;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                  &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                  &#1074;&#1072;&#1096;&#1091;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                  &#1097;&#1086;&#1073; &#1074;&#1080;
                  &#1084;&#1086;&#1075;&#1083;&#1080;
                  &#1089;&#1090;&#1074;&#1086;&#1088;&#1080;&#1090;&#1080;
                  &#1086;&#1073;&#1083;&#1110;&#1082;&#1086;&#1074;&#1080;&#1081;
                  &#1079;&#1072;&#1087;&#1080;&#1089; &#1110;
                  &#1074;&#1074;&#1110;&#1081;&#1090;&#1080; &#1074;
                  &#1085;&#1100;&#1086;&#1075;&#1086;, &#1072;
                  &#1090;&#1072;&#1082;&#1086;&#1078;
                  &#1087;&#1110;&#1076;&#1090;&#1088;&#1080;&#1084;&#1091;&#1074;&#1072;&#1090;&#1080;
                  &#1081;&#1086;&#1075;&#1086; &#1074;
                  &#1088;&#1086;&#1073;&#1086;&#1095;&#1086;&#1084;&#1091;
                  &#1089;&#1090;&#1072;&#1085;&#1110;.
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_57thzpynlhs3-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10 c2">
                  To facilitate account creation and authentication and
                  otherwise manage user accounts.{" "}
                </span>
                <span className="c16 c10">
                  We may process your information so you can create and log in
                  to your account, as well as keep your account in working
                  order.
                </span>
              </li>
            </ul>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_57thzpynlhs3-0">
              <li className="c0 li-bullet-0">
                <span className="c2">
                  &#1042;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1072;&#1090;&#1080;
                  &#1085;&#1072; &#1079;&#1072;&#1087;&#1080;&#1090;&#1080;
                  &#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1074;&#1072;&#1095;&#1110;&#1074;/&#1087;&#1088;&#1086;&#1087;&#1086;&#1085;&#1091;&#1074;&#1072;&#1090;&#1080;
                  &#1087;&#1110;&#1076;&#1090;&#1088;&#1080;&#1084;&#1082;&#1091;
                  &#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1074;&#1072;&#1095;&#1072;&#1084;.{" "}
                </span>
                <span className="c3">
                  &#1052;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                  &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                  &#1074;&#1072;&#1096;&#1091;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                  &#1097;&#1086;&#1073;
                  &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1089;&#1090;&#1080;
                  &#1085;&#1072; &#1074;&#1072;&#1096;&#1110;
                  &#1079;&#1072;&#1087;&#1080;&#1090;&#1080; &#1090;&#1072;
                  &#1074;&#1080;&#1088;&#1110;&#1096;&#1080;&#1090;&#1080;
                  &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1110;
                  &#1087;&#1086;&#1090;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1110;
                  &#1087;&#1088;&#1086;&#1073;&#1083;&#1077;&#1084;&#1080;,
                  &#1103;&#1082;&#1110;
                  &#1084;&#1086;&#1078;&#1091;&#1090;&#1100;
                  &#1074;&#1080;&#1085;&#1080;&#1082;&#1085;&#1091;&#1090;&#1080;
                  &#1091; &#1074;&#1072;&#1089; &#1110;&#1079;
                  &#1079;&#1072;&#1087;&#1080;&#1090;&#1072;&#1085;&#1086;&#1102;
                  &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;&#1086;&#1102;.
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_3f1n1i45d08e-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10 c2">
                  To respond to user inquiries/offer support to users.
                </span>
                <span className="c10">
                  We may process your information to respond to your inquiries
                  and solve any potential issues you might have with the
                  requested service.
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_3f1n1i45d08e-0">
              <li className="c0 li-bullet-0">
                <span className="c2">
                  &#1065;&#1086;&#1073;
                  &#1085;&#1072;&#1076;&#1110;&#1089;&#1083;&#1072;&#1090;&#1080;
                  &#1074;&#1072;&#1084;
                  &#1072;&#1076;&#1084;&#1110;&#1085;&#1110;&#1089;&#1090;&#1088;&#1072;&#1090;&#1080;&#1074;&#1085;&#1091;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;.{" "}
                </span>
                <span className="c3">
                  &#1052;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                  &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                  &#1074;&#1072;&#1096;&#1091;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                  &#1097;&#1086;&#1073;
                  &#1085;&#1072;&#1076;&#1089;&#1080;&#1083;&#1072;&#1090;&#1080;
                  &#1074;&#1072;&#1084;
                  &#1076;&#1086;&#1082;&#1083;&#1072;&#1076;&#1085;&#1091;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                  &#1087;&#1088;&#1086; &#1085;&#1072;&#1096;&#1110;
                  &#1087;&#1088;&#1086;&#1076;&#1091;&#1082;&#1090;&#1080;
                  &#1090;&#1072;
                  &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080;,
                  &#1079;&#1084;&#1110;&#1085;&#1080;
                  &#1085;&#1072;&#1096;&#1080;&#1093;
                  &#1091;&#1084;&#1086;&#1074; &#1110;
                  &#1087;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1080;
                  &#1090;&#1072; &#1110;&#1085;&#1096;&#1091;
                  &#1087;&#1086;&#1076;&#1110;&#1073;&#1085;&#1091;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;.
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_9bewfxrqvowv-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10 c2">
                  To send administrative information to you.{" "}
                </span>
                <span className="c10">
                  We may process your information to send you details about our
                  products and services, changes to our terms and policies, and
                  other similar information.
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_9bewfxrqvowv-0">
              <li className="c0 li-bullet-0">
                <span className="c2">
                  &#1065;&#1086;&#1073;
                  &#1079;&#1072;&#1087;&#1080;&#1090;&#1072;&#1090;&#1080;
                  &#1074;&#1110;&#1076;&#1075;&#1091;&#1082;.
                </span>
                <span className="c3">
                  &#1052;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                  &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                  &#1074;&#1072;&#1096;&#1091;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                  &#1082;&#1086;&#1083;&#1080; &#1094;&#1077;
                  &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1086;,
                  &#1097;&#1086;&#1073;
                  &#1086;&#1090;&#1088;&#1080;&#1084;&#1072;&#1090;&#1080;
                  &#1074;&#1110;&#1076;&#1075;&#1091;&#1082; &#1110;
                  &#1079;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1090;&#1080;&#1089;&#1103;
                  &#1079; &#1074;&#1072;&#1084;&#1080;
                  &#1097;&#1086;&#1076;&#1086;
                  &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1072;&#1085;&#1085;&#1103;
                  &#1074;&#1072;&#1084;&#1080;
                  &#1085;&#1072;&#1096;&#1080;&#1093;
                  &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;.
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_o7bvoeabj3yt-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10 c2">To request feedback. </span>
                <span className="c10">
                  We may process your information when necessary to request
                  feedback and to contact you about your use of our Services.
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_nxs5215frs6j-0 start">
              <li className="c0 li-bullet-0">
                <span className="c2">
                  &#1065;&#1086;&#1073;
                  &#1085;&#1072;&#1076;&#1089;&#1080;&#1083;&#1072;&#1090;&#1080;
                  &#1074;&#1072;&#1084;
                  &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;&#1086;&#1074;&#1110;
                  &#1090;&#1072;
                  &#1088;&#1077;&#1082;&#1083;&#1072;&#1084;&#1085;&#1110;
                  &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1077;&#1085;&#1085;&#1103;.{" "}
                </span>
                <span className="c3">
                  &#1052;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                  &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                  &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                  &#1103;&#1082;&#1091; &#1074;&#1080;
                  &#1085;&#1072;&#1076;&#1089;&#1080;&#1083;&#1072;&#1108;&#1090;&#1077;
                  &#1085;&#1072;&#1084;, &#1076;&#1083;&#1103;
                  &#1085;&#1072;&#1096;&#1080;&#1093;
                  &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;&#1086;&#1074;&#1080;&#1093;
                  &#1094;&#1110;&#1083;&#1077;&#1081;,
                  &#1103;&#1082;&#1097;&#1086; &#1094;&#1077;
                  &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1072;&#1108;
                  &#1074;&#1072;&#1096;&#1080;&#1084;
                  &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;&#1086;&#1074;&#1080;&#1084;
                  &#1091;&#1087;&#1086;&#1076;&#1086;&#1073;&#1072;&#1085;&#1085;&#1103;&#1084;.
                  &#1042;&#1080; &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;
                  &#1073;&#1091;&#1076;&#1100;-&#1082;&#1086;&#1083;&#1080;
                  &#1074;&#1110;&#1076;&#1084;&#1086;&#1074;&#1080;&#1090;&#1080;&#1089;&#1103;
                  &#1074;&#1110;&#1076; &#1085;&#1072;&#1096;&#1080;&#1093;
                  &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;&#1086;&#1074;&#1080;&#1093;
                  &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1080;&#1093;
                  &#1083;&#1080;&#1089;&#1090;&#1110;&#1074;.
                  &#1044;&#1083;&#1103;
                  &#1086;&#1090;&#1088;&#1080;&#1084;&#1072;&#1085;&#1085;&#1103;
                  &#1076;&#1086;&#1076;&#1072;&#1090;&#1082;&#1086;&#1074;&#1086;&#1111;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;
                  &#1076;&#1080;&#1074;. &laquo;&#1071;&#1050;&#1030;
                  &#1042;&#1040;&#1064;&#1030;
                  &#1055;&#1056;&#1040;&#1042;&#1040; &#1053;&#1040;
                  &#1050;&#1054;&#1053;&#1060;&#1030;&#1044;&#1045;&#1053;&#1062;&#1030;&#1049;&#1053;&#1030;&#1057;&#1058;&#1068;?&raquo;
                  &#1085;&#1080;&#1078;&#1095;&#1077;).
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_u70danlcsd9l-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10 c2">
                  To send you marketing and promotional communications.
                </span>
                <span className="c16 c10">
                  We may process the personal information you send to us for our
                  marketing purposes, if this is in accordance with your
                  marketing preferences. You can opt out of our marketing emails
                  at any time. For more information, see &quot;WHAT ARE YOUR
                  PRIVACY RIGHTS?&quot; below).
                </span>
              </li>
            </ul>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_u70danlcsd9l-0">
              <li className="c0 li-bullet-0">
                <span className="c2">
                  &#1044;&#1083;&#1103;
                  &#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1082;&#1080;
                  &#1074;&#1072;&#1084;
                  &#1094;&#1110;&#1083;&#1100;&#1086;&#1074;&#1086;&#1111;
                  &#1088;&#1077;&#1082;&#1083;&#1072;&#1084;&#1080;.
                </span>
                <span className="c3">
                  &nbsp;&#1052;&#1080;
                  &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                  &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                  &#1074;&#1072;&#1096;&#1091;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                  &#1076;&#1083;&#1103;
                  &#1088;&#1086;&#1079;&#1088;&#1086;&#1073;&#1082;&#1080;
                  &#1090;&#1072;
                  &#1074;&#1110;&#1076;&#1086;&#1073;&#1088;&#1072;&#1078;&#1077;&#1085;&#1085;&#1103;
                  &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1110;&#1079;&#1086;&#1074;&#1072;&#1085;&#1086;&#1075;&#1086;
                  &#1074;&#1084;&#1110;&#1089;&#1090;&#1091; &#1090;&#1072;
                  &#1088;&#1077;&#1082;&#1083;&#1072;&#1084;&#1080;
                  &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;
                  &#1076;&#1086; &#1074;&#1072;&#1096;&#1080;&#1093;
                  &#1110;&#1085;&#1090;&#1077;&#1088;&#1077;&#1089;&#1110;&#1074;,
                  &#1084;&#1110;&#1089;&#1094;&#1077;&#1079;&#1085;&#1072;&#1093;&#1086;&#1076;&#1078;&#1077;&#1085;&#1085;&#1103;
                  &#1090;&#1086;&#1097;&#1086;.
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_okmnt4uhbe44-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10 c2">
                  To deliver targeted advertising to you.
                </span>
                <span className="c10">
                  &nbsp;We may process your information to develop and display
                  personalized content and advertising tailored to your
                  interests, location, and more.
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_rni6mc53hfyo-0 start">
              <li className="c0 li-bullet-0">
                <span className="c2">
                  &#1065;&#1086;&#1073;
                  &#1079;&#1072;&#1093;&#1080;&#1089;&#1090;&#1080;&#1090;&#1080;
                  &#1085;&#1072;&#1096;&#1110;
                  &#1057;&#1077;&#1088;&#1074;&#1110;&#1089;&#1080;.
                </span>
                <span className="c3">
                  &nbsp;&#1052;&#1080;
                  &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                  &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                  &#1074;&#1072;&#1096;&#1091;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                  &#1074; &#1088;&#1072;&#1084;&#1082;&#1072;&#1093;
                  &#1085;&#1072;&#1096;&#1080;&#1093;
                  &#1079;&#1091;&#1089;&#1080;&#1083;&#1100;,
                  &#1097;&#1086;&#1073;
                  &#1079;&#1072;&#1073;&#1077;&#1079;&#1087;&#1077;&#1095;&#1080;&#1090;&#1080;
                  &#1073;&#1077;&#1079;&#1087;&#1077;&#1082;&#1091;
                  &#1090;&#1072;
                  &#1073;&#1077;&#1079;&#1087;&#1077;&#1082;&#1091;
                  &#1085;&#1072;&#1096;&#1080;&#1093;
                  &#1057;&#1077;&#1088;&#1074;&#1110;&#1089;&#1110;&#1074;,
                  &#1074;&#1082;&#1083;&#1102;&#1095;&#1072;&#1102;&#1095;&#1080;
                  &#1084;&#1086;&#1085;&#1110;&#1090;&#1086;&#1088;&#1080;&#1085;&#1075;
                  &#1090;&#1072;
                  &#1079;&#1072;&#1087;&#1086;&#1073;&#1110;&#1075;&#1072;&#1085;&#1085;&#1103;
                  &#1096;&#1072;&#1093;&#1088;&#1072;&#1081;&#1089;&#1090;&#1074;&#1091;.
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_ypcdd5an0ov6-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10 c2">To protect our Services.</span>
                <span className="c10">
                  &nbsp;We may process your information as part of our efforts
                  to keep our Services safe and secure, including fraud
                  monitoring and prevention.
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_ypcdd5an0ov6-0">
              <li className="c0 li-bullet-0">
                <span className="c2">
                  &#1065;&#1086;&#1073;
                  &#1074;&#1080;&#1079;&#1085;&#1072;&#1095;&#1080;&#1090;&#1080;
                  &#1090;&#1077;&#1085;&#1076;&#1077;&#1085;&#1094;&#1110;&#1111;
                  &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1072;&#1085;&#1085;&#1103;.
                </span>
                <span className="c3">
                  &nbsp;&#1052;&#1080;
                  &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                  &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                  &#1087;&#1088;&#1086; &#1090;&#1077;, &#1103;&#1082;
                  &#1074;&#1080;
                  &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1108;&#1090;&#1077;
                  &#1085;&#1072;&#1096;&#1110;
                  &#1057;&#1077;&#1088;&#1074;&#1110;&#1089;&#1080;,
                  &#1097;&#1086;&#1073; &#1082;&#1088;&#1072;&#1097;&#1077;
                  &#1079;&#1088;&#1086;&#1079;&#1091;&#1084;&#1110;&#1090;&#1080;,
                  &#1103;&#1082; &#1074;&#1086;&#1085;&#1080;
                  &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1102;&#1090;&#1100;&#1089;&#1103;,
                  &#1097;&#1086;&#1073; &#1084;&#1080;
                  &#1084;&#1086;&#1075;&#1083;&#1080; &#1111;&#1093;
                  &#1087;&#1086;&#1082;&#1088;&#1072;&#1097;&#1080;&#1090;&#1080;.
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_g6y9lbbh5uyb-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10 c2">To identify usage trends.</span>
                <span className="c10">
                  &nbsp;We may process information about how you use our
                  Services to better understand how they are being used so we
                  can improve them.
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_g6y9lbbh5uyb-0">
              <li className="c0 li-bullet-0">
                <span className="c2">
                  &#1065;&#1086;&#1073;
                  &#1074;&#1080;&#1079;&#1085;&#1072;&#1095;&#1080;&#1090;&#1080;
                  &#1077;&#1092;&#1077;&#1082;&#1090;&#1080;&#1074;&#1085;&#1110;&#1089;&#1090;&#1100;
                  &#1085;&#1072;&#1096;&#1080;&#1093;
                  &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;&#1086;&#1074;&#1080;&#1093;
                  &#1110;
                  &#1088;&#1077;&#1082;&#1083;&#1072;&#1084;&#1085;&#1080;&#1093;
                  &#1082;&#1072;&#1084;&#1087;&#1072;&#1085;&#1110;&#1081;.
                </span>
                <span className="c3">
                  &nbsp;&#1052;&#1080;
                  &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                  &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                  &#1074;&#1072;&#1096;&#1091;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                  &#1097;&#1086;&#1073; &#1082;&#1088;&#1072;&#1097;&#1077;
                  &#1079;&#1088;&#1086;&#1079;&#1091;&#1084;&#1110;&#1090;&#1080;,
                  &#1103;&#1082;
                  &#1087;&#1088;&#1086;&#1074;&#1086;&#1076;&#1080;&#1090;&#1080;
                  &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;&#1086;&#1074;&#1110;
                  &#1090;&#1072;
                  &#1088;&#1077;&#1082;&#1083;&#1072;&#1084;&#1085;&#1110;
                  &#1082;&#1072;&#1084;&#1087;&#1072;&#1085;&#1110;&#1111;,
                  &#1103;&#1082;&#1110; &#1108;
                  &#1085;&#1072;&#1081;&#1073;&#1110;&#1083;&#1100;&#1096;
                  &#1088;&#1077;&#1083;&#1077;&#1074;&#1072;&#1085;&#1090;&#1085;&#1080;&#1084;&#1080;
                  &#1076;&#1083;&#1103; &#1074;&#1072;&#1089;.
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_h0oqihqrwhoy-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10 c2">
                  To determine the effectiveness of our marketing and
                  promotional campaigns.
                </span>
                <span className="c10">
                  &nbsp;We may process your information to better understand how
                  to provide marketing and promotional campaigns that are most
                  relevant to you.
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_h0oqihqrwhoy-0">
              <li className="c0 li-bullet-0">
                <span className="c2">
                  &#1044;&#1083;&#1103;
                  &#1079;&#1073;&#1077;&#1088;&#1077;&#1078;&#1077;&#1085;&#1085;&#1103;
                  &#1072;&#1073;&#1086;
                  &#1079;&#1072;&#1093;&#1080;&#1089;&#1090;&#1091;
                  &#1078;&#1080;&#1090;&#1090;&#1108;&#1074;&#1086;
                  &#1074;&#1072;&#1078;&#1083;&#1080;&#1074;&#1080;&#1093;
                  &#1110;&#1085;&#1090;&#1077;&#1088;&#1077;&#1089;&#1110;&#1074;
                  &#1086;&#1089;&#1086;&#1073;&#1080;.
                </span>
                <span className="c3">
                  &nbsp;&#1052;&#1080;
                  &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                  &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                  &#1074;&#1072;&#1096;&#1091;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                  &#1082;&#1086;&#1083;&#1080; &#1094;&#1077;
                  &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1086;
                  &#1076;&#1083;&#1103;
                  &#1079;&#1073;&#1077;&#1088;&#1077;&#1078;&#1077;&#1085;&#1085;&#1103;
                  &#1072;&#1073;&#1086;
                  &#1079;&#1072;&#1093;&#1080;&#1089;&#1090;&#1091;
                  &#1078;&#1080;&#1090;&#1090;&#1108;&#1074;&#1086;
                  &#1074;&#1072;&#1078;&#1083;&#1080;&#1074;&#1080;&#1093;
                  &#1110;&#1085;&#1090;&#1077;&#1088;&#1077;&#1089;&#1110;&#1074;
                  &#1086;&#1089;&#1086;&#1073;&#1080;,
                  &#1085;&#1072;&#1087;&#1088;&#1080;&#1082;&#1083;&#1072;&#1076;,
                  &#1097;&#1086;&#1073;
                  &#1079;&#1072;&#1087;&#1086;&#1073;&#1110;&#1075;&#1090;&#1080;
                  &#1079;&#1072;&#1074;&#1076;&#1072;&#1085;&#1085;&#1102;
                  &#1096;&#1082;&#1086;&#1076;&#1080;
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_cy14hbez4uae-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10 c2">
                  To save or protect an individual&#39;s vital interest.
                </span>
                <span className="c10">
                  &nbsp;We may process your information when necessary to save
                  or protect an individual&rsquo;s vital interest, such as to
                  prevent harm.
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_cy14hbez4uae-0">
              <li className="c0 li-bullet-0">
                <span className="c2">
                  &#1044;&#1083;&#1103;
                  &#1087;&#1086;&#1082;&#1088;&#1072;&#1097;&#1077;&#1085;&#1085;&#1103;
                  &#1090;&#1072;
                  &#1087;&#1086;&#1087;&#1091;&#1083;&#1103;&#1088;&#1080;&#1079;&#1072;&#1094;&#1110;&#1111;
                  &#1085;&#1072;&#1096;&#1080;&#1093;
                  &#1057;&#1077;&#1088;&#1074;&#1110;&#1089;&#1110;&#1074;.
                </span>
                <span>
                  &#1052;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                  &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                  &#1074;&#1072;&#1096;&#1091;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                  &#1096;&#1083;&#1103;&#1093;&#1086;&#1084;
                  &#1088;&#1086;&#1079;&#1084;&#1110;&#1097;&#1077;&#1085;&#1085;&#1103;
                  &#1092;&#1086;&#1090;&#1086;&#1075;&#1088;&#1072;&#1092;&#1110;&#1081;
                  &#1090;&#1072; &#1074;&#1110;&#1076;&#1077;&#1086;
                  &#1110;&#1079; &#1074;&#1072;&#1096;&#1080;&#1084;
                  &#1079;&#1086;&#1073;&#1088;&#1072;&#1078;&#1077;&#1085;&#1085;&#1103;&#1084;
                  &#1085;&#1072; &#1085;&#1072;&#1096;&#1080;&#1093;
                  &#1074;&#1077;&#1073;-&#1089;&#1072;&#1081;&#1090;&#1072;&#1093;
                  (&#1089;&#1077;&#1088;&#1074;&#1110;&#1089;&#1072;&#1093;),
                  &#1090;&#1072; &#1074;&#1077;&#1073;-
                  &#1089;&#1072;&#1081;&#1090;&#1072;&#1093;
                  &#1085;&#1072;&#1096;&#1080;&#1093;
                  &#1087;&#1072;&#1088;&#1090;&#1085;&#1077;&#1088;&#1110;&#1074;.
                  &#1055;&#1088;&#1080; &#1094;&#1100;&#1086;&#1084;&#1091;
                  &#1084;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                  &#1087;&#1086;&#1082;&#1088;&#1072;&#1097;&#1091;&#1074;&#1072;&#1090;&#1080;
                  &#1103;&#1082;&#1110;&#1089;&#1090;&#1100;
                  &#1085;&#1072;&#1076;&#1072;&#1085;&#1080;&#1093;
                  &#1074;&#1072;&#1084;&#1080; &#1092;&#1086;&#1090;&#1086;
                  &#1090;&#1072; &#1074;&#1110;&#1076;&#1077;&#1086; &#1091;
                  &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1110;&#1081;
                  &#1089;&#1087;&#1086;&#1089;&#1110;&#1073;
                  &#1073;&#1077;&#1079;
                  &#1089;&#1087;&#1086;&#1090;&#1074;&#1086;&#1088;&#1077;&#1085;&#1085;&#1103;
                  &#1074;&#1072;&#1096;&#1086;&#1075;&#1086;
                  &#1079;&#1086;&#1073;&#1088;&#1072;&#1078;&#1077;&#1085;&#1085;&#1103;
                  (&#1094;&#1077; &#1084;&#1086;&#1078;&#1077;
                  &#1073;&#1091;&#1090;&#1080; &#1082;&#1088;&#1086;&#1087;,
                </span>
                <span className="c19">
                  &nbsp;&#1079;&#1072;&#1084;&#1110;&#1085;&#1072;
                  &#1092;&#1086;&#1085;&#1091;,
                  &#1103;&#1088;&#1082;&#1086;&#1089;&#1090;&#1110;,
                  &#1090;&#1086;&#1097;&#1086;
                </span>
                <span className="c3">).</span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c14 c25">
              <span className="c3"></span>
            </p>
            <ul className="c6 lst-kix_nd1negtgw5ha-0 start">
              <li className="c14 c13 li-bullet-0">
                <span className="c2">
                  To improve and popularize our Services.
                </span>
                <span className="c3">
                  &nbsp;We may process your information by placing photos and
                  videos with your image on our websites (services) and the
                  websites of our partners. At the same time, we can improve the
                  quality of the photos and videos provided by you in any way
                  without distorting your image (it can be cropping, replacing
                  the background, brightness, etc.)
                </span>
              </li>
            </ul>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4" id="privacy_sec3">
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                3. &#1071;&#1050;&#1048;&#1052;&#1048;
                &#1055;&#1056;&#1040;&#1042;&#1054;&#1042;&#1048;&#1052;&#1048;
                &#1055;&#1030;&#1044;&#1057;&#1058;&#1040;&#1042;&#1040;&#1052;&#1048;
                &#1052;&#1048;
                &#1050;&#1045;&#1056;&#1059;&#1028;&#1052;&#1054;&#1057;&#1071;
                &#1044;&#1051;&#1071;
                &#1054;&#1041;&#1056;&#1054;&#1041;&#1050;&#1048;
                &#1042;&#1040;&#1064;&#1054;&#1031;
                &#1055;&#1045;&#1056;&#1057;&#1054;&#1053;&#1040;&#1051;&#1068;&#1053;&#1054;&#1031;
                &#1030;&#1053;&#1060;&#1054;&#1056;&#1052;&#1040;&#1062;&#1030;&#1031;?
              </span>
            </p>
          </td>
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">
                3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
              </span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c2">
                &#1050;&#1086;&#1088;&#1086;&#1090;&#1082;&#1086;:{" "}
              </span>
              <span className="c11">
                &#1084;&#1080;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1108;&#1084;&#1086;
                &#1074;&#1072;&#1096;&#1091;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1083;&#1080;&#1096;&#1077; &#1090;&#1086;&#1076;&#1110;,
                &#1082;&#1086;&#1083;&#1080;
                &#1074;&#1074;&#1072;&#1078;&#1072;&#1108;&#1084;&#1086;
                &#1094;&#1077;
                &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1080;&#1084;
                &#1110; &#1084;&#1072;&#1108;&#1084;&#1086;
                &#1074;&#1072;&#1075;&#1086;&#1084;&#1110;
                &#1087;&#1088;&#1072;&#1074;&#1086;&#1074;&#1110;
                &#1087;&#1110;&#1076;&#1089;&#1090;&#1072;&#1074;&#1080;
                (&#1090;&#1086;&#1073;&#1090;&#1086;
                &#1087;&#1088;&#1072;&#1074;&#1086;&#1074;&#1110;
                &#1087;&#1110;&#1076;&#1089;&#1090;&#1072;&#1074;&#1080;)
                &#1076;&#1083;&#1103; &#1094;&#1100;&#1086;&#1075;&#1086;
                &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;
                &#1076;&#1086; &#1095;&#1080;&#1085;&#1085;&#1086;&#1075;&#1086;
                &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1072;,
                &#1085;&#1072;&#1087;&#1088;&#1080;&#1082;&#1083;&#1072;&#1076;,
                &#1079;&#1072; &#1074;&#1072;&#1096;&#1086;&#1102;
                &#1079;&#1075;&#1086;&#1076;&#1086;&#1102;,
                &#1076;&#1083;&#1103;
                &#1076;&#1086;&#1090;&#1088;&#1080;&#1084;&#1072;&#1085;&#1085;&#1103;
                &#1079;&#1072;&#1082;&#1086;&#1085;&#1110;&#1074;,
                &#1076;&#1083;&#1103;
                &#1085;&#1072;&#1076;&#1072;&#1085;&#1085;&#1103;
                &#1074;&#1072;&#1084; &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;
                &#1076;&#1083;&#1103;
                &#1087;&#1088;&#1080;&#1081;&#1085;&#1103;&#1090;&#1090;&#1103;
                &#1072;&#1073;&#1086;
                &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1103;
                &#1085;&#1072;&#1096;&#1080;&#1093;
                &#1076;&#1086;&#1075;&#1086;&#1074;&#1110;&#1088;&#1085;&#1080;&#1093;
                &#1079;&#1086;&#1073;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1085;&#1100;,
                &#1079;&#1072;&#1093;&#1080;&#1089;&#1090;&#1091;
                &#1074;&#1072;&#1096;&#1080;&#1093; &#1087;&#1088;&#1072;&#1074;
                &#1072;&#1073;&#1086;
                &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1103;
                &#1085;&#1072;&#1096;&#1080;&#1093;
                &#1079;&#1072;&#1082;&#1086;&#1085;&#1085;&#1080;&#1093;
                &#1076;&#1110;&#1083;&#1086;&#1074;&#1080;&#1093;
                &#1110;&#1085;&#1090;&#1077;&#1088;&#1077;&#1089;&#1110;&#1074;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c10 c2">In Short: </span>
              <span className="c11 c10 c21">
                We only process your personal information when we believe it is
                necessary and we have a valid legal reason (i.e., legal basis)
                to do so under applicable law, like with your consent, to comply
                with laws, to provide you with services to enter into or fulfill
                our contractual obligations, to protect your rights, or to
                fulfill our legitimate business interests.
              </span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c5 c11 c2">
                &#1071;&#1082;&#1097;&#1086; &#1074;&#1080;
                &#1087;&#1077;&#1088;&#1077;&#1073;&#1091;&#1074;&#1072;&#1108;&#1090;&#1077;
                &#1074; &#1028;&#1057; &#1072;&#1073;&#1086;
                &#1042;&#1077;&#1083;&#1080;&#1082;&#1086;&#1073;&#1088;&#1080;&#1090;&#1072;&#1085;&#1110;&#1111;,
                &#1094;&#1077;&#1081; &#1088;&#1086;&#1079;&#1076;&#1110;&#1083;
                &#1089;&#1090;&#1086;&#1089;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
                &#1074;&#1072;&#1089;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c5 c11 c10 c2">
                If you are located in the EU or UK, this section applies to you.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1047;&#1072;&#1075;&#1072;&#1083;&#1100;&#1085;&#1080;&#1081;
                &#1088;&#1077;&#1075;&#1083;&#1072;&#1084;&#1077;&#1085;&#1090;
                &#1079;&#1072;&#1093;&#1080;&#1089;&#1090;&#1091;
                &#1076;&#1072;&#1085;&#1080;&#1093; (GDPR) &#1110; GDPR
                &#1042;&#1077;&#1083;&#1080;&#1082;&#1086;&#1073;&#1088;&#1080;&#1090;&#1072;&#1085;&#1110;&#1111;
                &#1074;&#1080;&#1084;&#1072;&#1075;&#1072;&#1102;&#1090;&#1100;
                &#1074;&#1110;&#1076; &#1085;&#1072;&#1089;
                &#1087;&#1086;&#1103;&#1089;&#1085;&#1077;&#1085;&#1085;&#1103;
                &#1095;&#1080;&#1085;&#1085;&#1080;&#1093;
                &#1087;&#1088;&#1072;&#1074;&#1086;&#1074;&#1080;&#1093;
                &#1087;&#1110;&#1076;&#1089;&#1090;&#1072;&#1074;,
                &#1085;&#1072; &#1103;&#1082;&#1110; &#1084;&#1080;
                &#1089;&#1087;&#1080;&#1088;&#1072;&#1108;&#1084;&#1086;&#1089;&#1103;
                &#1076;&#1083;&#1103;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1082;&#1080;
                &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;.
                &#1058;&#1072;&#1082;&#1080;&#1084;
                &#1095;&#1080;&#1085;&#1086;&#1084;, &#1084;&#1080;
                &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1087;&#1086;&#1082;&#1083;&#1072;&#1076;&#1072;&#1090;&#1080;&#1089;&#1103;
                &#1085;&#1072; &#1090;&#1072;&#1082;&#1110;
                &#1087;&#1088;&#1072;&#1074;&#1086;&#1074;&#1110;
                &#1086;&#1089;&#1085;&#1086;&#1074;&#1080; &#1076;&#1083;&#1103;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1082;&#1080;
                &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;:
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                The General Data Protection Regulation (GDPR) and UK GDPR
                require us to explain the valid legal bases we rely on in order
                to process your personal information. As such, we may rely on
                the following legal bases to process your personal information:
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_z94dts7ye56z-0 start">
              <li className="c0 li-bullet-0">
                <span className="c2">
                  &#1047;&#1075;&#1086;&#1076;&#1072;.{" "}
                </span>
                <span className="c3">
                  &#1052;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                  &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                  &#1074;&#1072;&#1096;&#1091;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                  &#1103;&#1082;&#1097;&#1086; &#1074;&#1080;
                  &#1076;&#1072;&#1083;&#1080; &#1085;&#1072;&#1084;
                  &#1076;&#1086;&#1079;&#1074;&#1110;&#1083;
                  (&#1090;&#1086;&#1073;&#1090;&#1086;
                  &#1079;&#1075;&#1086;&#1076;&#1091;) &#1085;&#1072;
                  &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1072;&#1085;&#1085;&#1103;
                  &#1074;&#1072;&#1096;&#1086;&#1111;
                  &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;
                  &#1079; &#1087;&#1077;&#1074;&#1085;&#1086;&#1102;
                  &#1084;&#1077;&#1090;&#1086;&#1102;. &#1042;&#1080;
                  &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;
                  &#1074;&#1110;&#1076;&#1082;&#1083;&#1080;&#1082;&#1072;&#1090;&#1080;
                  &#1089;&#1074;&#1086;&#1102;
                  &#1079;&#1075;&#1086;&#1076;&#1091; &#1074;
                  &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1080;&#1081;
                  &#1095;&#1072;&#1089;.
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_g6ei6pfi92c0-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10 c2">Consent. </span>
                <span className="c10">
                  We may process your information if you have given us
                  permission (i.e., consent) to use your personal information
                  for a specific purpose. You can withdraw your consent at any
                  time.{" "}
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_jeuagx2mueb-0 start">
              <li className="c0 li-bullet-0">
                <span className="c2">
                  &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1103;
                  &#1082;&#1086;&#1085;&#1090;&#1088;&#1072;&#1082;&#1090;&#1091;.
                </span>
                <span className="c3">
                  &nbsp;&#1052;&#1080;
                  &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                  &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                  &#1074;&#1072;&#1096;&#1091;
                  &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                  &#1103;&#1082;&#1097;&#1086;
                  &#1074;&#1074;&#1072;&#1078;&#1072;&#1108;&#1084;&#1086;
                  &#1094;&#1077;
                  &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1080;&#1084;
                  &#1076;&#1083;&#1103;
                  &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1103;
                  &#1085;&#1072;&#1096;&#1080;&#1093;
                  &#1076;&#1086;&#1075;&#1086;&#1074;&#1110;&#1088;&#1085;&#1080;&#1093;
                  &#1079;&#1086;&#1073;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1085;&#1100;
                  &#1087;&#1077;&#1088;&#1077;&#1076;
                  &#1074;&#1072;&#1084;&#1080;,
                  &#1074;&#1082;&#1083;&#1102;&#1095;&#1072;&#1102;&#1095;&#1080;
                  &#1085;&#1072;&#1076;&#1072;&#1085;&#1085;&#1103;
                  &#1085;&#1072;&#1096;&#1080;&#1093;
                  &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;,
                  &#1072;&#1073;&#1086; &#1079;&#1072;
                  &#1074;&#1072;&#1096;&#1080;&#1084;
                  &#1079;&#1072;&#1087;&#1080;&#1090;&#1086;&#1084;
                  &#1076;&#1086;
                  &#1091;&#1082;&#1083;&#1072;&#1076;&#1077;&#1085;&#1085;&#1103;
                  &#1079; &#1074;&#1072;&#1084;&#1080;
                  &#1082;&#1086;&#1085;&#1090;&#1088;&#1072;&#1082;&#1090;&#1091;.
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_mzprgbudmfx1-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10 c2">Performance of a Contract.</span>
                <span className="c10">
                  &nbsp;We may process your personal information when we believe
                  it is necessary to fulfill our contractual obligations to you,
                  including providing our Services or at your request prior to
                  entering into a contract with you.
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_mzprgbudmfx1-0">
              <li className="c0 li-bullet-0">
                <span className="c2">
                  &#1047;&#1072;&#1082;&#1086;&#1085;&#1085;&#1110;
                  &#1110;&#1085;&#1090;&#1077;&#1088;&#1077;&#1089;&#1080;.
                </span>
                <span className="c3">
                  &nbsp;&#1052;&#1080;
                  &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                  &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                  &#1074;&#1072;&#1096;&#1091;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                  &#1103;&#1082;&#1097;&#1086;
                  &#1074;&#1074;&#1072;&#1078;&#1072;&#1108;&#1084;&#1086;,
                  &#1097;&#1086; &#1094;&#1077;
                  &#1086;&#1073;&#1169;&#1088;&#1091;&#1085;&#1090;&#1086;&#1074;&#1072;&#1085;&#1086;
                  &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1086;
                  &#1076;&#1083;&#1103;
                  &#1076;&#1086;&#1089;&#1103;&#1075;&#1085;&#1077;&#1085;&#1085;&#1103;
                  &#1085;&#1072;&#1096;&#1080;&#1093;
                  &#1079;&#1072;&#1082;&#1086;&#1085;&#1085;&#1080;&#1093;
                  &#1076;&#1110;&#1083;&#1086;&#1074;&#1080;&#1093;
                  &#1110;&#1085;&#1090;&#1077;&#1088;&#1077;&#1089;&#1110;&#1074;,
                  &#1110; &#1094;&#1110;
                  &#1110;&#1085;&#1090;&#1077;&#1088;&#1077;&#1089;&#1080;
                  &#1085;&#1077;
                  &#1087;&#1077;&#1088;&#1077;&#1074;&#1072;&#1078;&#1072;&#1102;&#1090;&#1100;
                  &#1074;&#1072;&#1096;&#1110;
                  &#1110;&#1085;&#1090;&#1077;&#1088;&#1077;&#1089;&#1080;
                  &#1090;&#1072;
                  &#1086;&#1089;&#1085;&#1086;&#1074;&#1085;&#1110;
                  &#1087;&#1088;&#1072;&#1074;&#1072; &#1090;&#1072;
                  &#1089;&#1074;&#1086;&#1073;&#1086;&#1076;&#1080;.
                  &#1053;&#1072;&#1087;&#1088;&#1080;&#1082;&#1083;&#1072;&#1076;,
                  &#1084;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                  &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                  &#1074;&#1072;&#1096;&#1091;
                  &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                  &#1076;&#1083;&#1103;
                  &#1076;&#1077;&#1103;&#1082;&#1080;&#1093; &#1110;&#1079;
                  &#1086;&#1087;&#1080;&#1089;&#1072;&#1085;&#1080;&#1093;
                  &#1094;&#1110;&#1083;&#1077;&#1081;, &#1097;&#1086;&#1073;:
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_yaj8yl3zixoi-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10 c2">Legitimate Interests.</span>
                <span className="c10">
                  &nbsp;We may process your information when we believe it is
                  reasonably necessary to achieve our legitimate business
                  interests and those interests do not outweigh your interests
                  and fundamental rights and freedoms. For example, we may
                  process your personal information for some of the purposes
                  described in order to:
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_yaj8yl3zixoi-0">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1085;&#1072;&#1076;&#1089;&#1080;&#1083;&#1072;&#1090;&#1080;
                  &#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1074;&#1072;&#1095;&#1072;&#1084;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                  &#1087;&#1088;&#1086;
                  &#1089;&#1087;&#1077;&#1094;&#1110;&#1072;&#1083;&#1100;&#1085;&#1110;
                  &#1087;&#1088;&#1086;&#1087;&#1086;&#1079;&#1080;&#1094;&#1110;&#1111;
                  &#1090;&#1072; &#1079;&#1085;&#1080;&#1078;&#1082;&#1080;
                  &#1085;&#1072; &#1085;&#1072;&#1096;&#1110;
                  &#1087;&#1088;&#1086;&#1076;&#1091;&#1082;&#1090;&#1080;
                  &#1090;&#1072;
                  &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080;;
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_7tnxb5n6vuyg-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  Send users information about special offers and discounts on
                  our products and services
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_7tnxb5n6vuyg-0">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1088;&#1086;&#1079;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                  &#1090;&#1072;
                  &#1074;&#1110;&#1076;&#1086;&#1073;&#1088;&#1072;&#1078;&#1072;&#1090;&#1080;
                  &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1110;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1081;
                  &#1110;
                  &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1080;&#1081;
                  &#1088;&#1077;&#1082;&#1083;&#1072;&#1084;&#1085;&#1080;&#1081;
                  &#1074;&#1084;&#1110;&#1089;&#1090; &#1076;&#1083;&#1103;
                  &#1085;&#1072;&#1096;&#1080;&#1093;
                  &#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1074;&#1072;&#1095;&#1110;&#1074;
                  (&#1074; &#1090;&#1086;&#1084;&#1091;
                  &nbsp;&#1095;&#1080;&#1089;&#1083;&#1110;
                  &#1096;&#1083;&#1103;&#1093;&#1086;&#1084;
                  &#1088;&#1086;&#1079;&#1084;&#1110;&#1097;&#1077;&#1085;&#1085;&#1103;
                  &#1092;&#1086;&#1090;&#1086;&#1075;&#1088;&#1072;&#1092;&#1110;&#1081;
                  &#1090;&#1072; &#1074;&#1110;&#1076;&#1077;&#1086;
                  &#1110;&#1079; &#1074;&#1072;&#1096;&#1080;&#1084;
                  &#1079;&#1086;&#1073;&#1088;&#1072;&#1078;&#1077;&#1085;&#1085;&#1103;&#1084;
                  &#1085;&#1072; &#1085;&#1072;&#1096;&#1080;&#1093;
                  &#1074;&#1077;&#1073;-&#1089;&#1072;&#1081;&#1090;&#1072;&#1093;
                  (&#1089;&#1077;&#1088;&#1074;&#1110;&#1089;&#1072;&#1093;),
                  &#1090;&#1072;
                  &#1074;&#1077;&#1073;-&#1089;&#1072;&#1081;&#1090;&#1072;&#1093;
                  &#1085;&#1072;&#1096;&#1080;&#1093;
                  &#1087;&#1072;&#1088;&#1090;&#1085;&#1077;&#1088;&#1110;&#1074;);
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_nfr9e5iftkbk-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  Develop and display personalized and relevant advertising
                  content for our users{" "}
                </span>
                <span>
                  (including by placing photos and videos with your image on our
                  websites (services), and websites of our partners);
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_nfr9e5iftkbk-0">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1072;&#1085;&#1072;&#1083;&#1110;&#1079;&#1091;&#1074;&#1072;&#1090;&#1080;,
                  &#1103;&#1082;
                  &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1102;&#1090;&#1100;&#1089;&#1103;
                  &#1085;&#1072;&#1096;&#1110;
                  &#1057;&#1077;&#1088;&#1074;&#1110;&#1089;&#1080;,
                  &#1097;&#1086;&#1073; &#1084;&#1080;
                  &#1084;&#1086;&#1075;&#1083;&#1080;
                  &#1087;&#1086;&#1082;&#1088;&#1072;&#1097;&#1080;&#1090;&#1080;
                  &#1111;&#1093;, &#1097;&#1086;&#1073;
                  &#1079;&#1072;&#1083;&#1091;&#1095;&#1072;&#1090;&#1080;
                  &#1081;
                  &#1091;&#1090;&#1088;&#1080;&#1084;&#1091;&#1074;&#1072;&#1090;&#1080;
                  &#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1074;&#1072;&#1095;&#1110;&#1074;
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_xt2v4ldv7w1h-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  Analyze how our Services are used so we can improve them to
                  engage and retain users
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_xt2v4ldv7w1h-0">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1055;&#1110;&#1076;&#1090;&#1088;&#1080;&#1084;&#1091;&#1074;&#1072;&#1090;&#1080;
                  &#1085;&#1072;&#1096;&#1091;
                  &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;&#1086;&#1074;&#1091;
                  &#1076;&#1110;&#1103;&#1083;&#1100;&#1085;&#1110;&#1089;&#1090;&#1100;
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_iq5tmbsyxz79-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">Support our marketing activities</span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_plvi2gw8xadg-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1044;&#1110;&#1072;&#1075;&#1085;&#1086;&#1089;&#1090;&#1091;&#1074;&#1072;&#1090;&#1080;
                  &#1087;&#1088;&#1086;&#1073;&#1083;&#1077;&#1084;&#1080;
                  &#1090;&#1072;/&#1072;&#1073;&#1086;
                  &#1079;&#1072;&#1087;&#1086;&#1073;&#1110;&#1075;&#1072;&#1090;&#1080;
                  &#1096;&#1072;&#1093;&#1088;&#1072;&#1081;&#1089;&#1100;&#1082;&#1080;&#1084;
                  &#1076;&#1110;&#1103;&#1084;
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_mgufq5oi8f7g-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  Diagnose problems and/or prevent fraudulent activities
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_ebzuu4em6ke4-0 start">
              <li className="c0 li-bullet-0">
                <span>
                  &#1047;&#1088;&#1086;&#1079;&#1091;&#1084;&#1110;&#1090;&#1080;,
                  &#1103;&#1082; &#1085;&#1072;&#1096;&#1110;
                  &#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1074;&#1072;&#1095;&#1110;
                  &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1102;&#1090;&#1100;
                  &#1085;&#1072;&#1096;&#1110;
                  &#1087;&#1088;&#1086;&#1076;&#1091;&#1082;&#1090;&#1080;
                  &#1090;&#1072;
                  &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080;,
                  &#1097;&#1086;&#1073; &#1084;&#1080;
                  &#1084;&#1086;&#1075;&#1083;&#1080;
                  &#1087;&#1086;&#1082;&#1088;&#1072;&#1097;&#1080;&#1090;&#1080;
                  &#1074;&#1079;&#1072;&#1108;&#1084;&#1086;&#1076;&#1110;&#1102;
                  &#1079; &#1085;&#1080;&#1084;&#1080;.
                </span>
                <span className="c2">&nbsp;</span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_ed6pwlnknxeu-0 start">
              <li className="c0 li-bullet-0">
                <span className="c16 c10">
                  Understand how our users use our products and services so we
                  can improve user experience
                </span>
              </li>
            </ul>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c18">
              <span className="c2">
                &#1070;&#1088;&#1080;&#1076;&#1080;&#1095;&#1085;&#1110;
                &#1079;&#1086;&#1073;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1085;&#1085;&#1103;.
              </span>
              <span className="c3">
                &nbsp;&#1052;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                &#1074;&#1072;&#1096;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                &#1103;&#1082;&#1097;&#1086;
                &#1074;&#1074;&#1072;&#1078;&#1072;&#1108;&#1084;&#1086;,
                &#1097;&#1086; &#1094;&#1077;
                &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1086;
                &#1076;&#1083;&#1103;
                &#1076;&#1086;&#1090;&#1088;&#1080;&#1084;&#1072;&#1085;&#1085;&#1103;
                &#1085;&#1072;&#1096;&#1080;&#1093;
                &#1102;&#1088;&#1080;&#1076;&#1080;&#1095;&#1085;&#1080;&#1093;
                &#1079;&#1086;&#1073;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1085;&#1100;,
                &#1085;&#1072;&#1087;&#1088;&#1080;&#1082;&#1083;&#1072;&#1076;,
                &#1076;&#1083;&#1103;
                &#1089;&#1087;&#1110;&#1074;&#1087;&#1088;&#1072;&#1094;&#1110;
                &#1079;
                &#1087;&#1088;&#1072;&#1074;&#1086;&#1086;&#1093;&#1086;&#1088;&#1086;&#1085;&#1085;&#1080;&#1084;&#1080;
                &#1086;&#1088;&#1075;&#1072;&#1085;&#1072;&#1084;&#1080;
                &#1072;&#1073;&#1086;
                &#1088;&#1077;&#1075;&#1091;&#1083;&#1102;&#1102;&#1095;&#1080;&#1084;&#1080;
                (&#1082;&#1086;&#1085;&#1090;&#1088;&#1086;&#1083;&#1102;&#1102;&#1095;&#1080;&#1084;&#1080;)
                &#1086;&#1088;&#1075;&#1072;&#1085;&#1072;&#1084;&#1080;,
                &#1088;&#1077;&#1072;&#1083;&#1110;&#1079;&#1072;&#1094;&#1110;&#1111;
                &#1072;&#1073;&#1086;
                &#1079;&#1072;&#1093;&#1080;&#1089;&#1090;&#1091;
                &#1085;&#1072;&#1096;&#1080;&#1093;
                &#1079;&#1072;&#1082;&#1086;&#1085;&#1085;&#1080;&#1093;
                &#1087;&#1088;&#1072;&#1074; &#1072;&#1073;&#1086;
                &#1088;&#1086;&#1079;&#1082;&#1088;&#1080;&#1090;&#1090;&#1103;
                &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;
                &#1103;&#1082; &#1076;&#1086;&#1082;&#1072;&#1079;&#1091;
                &#1074; &#1089;&#1091;&#1076;&#1086;&#1074;&#1086;&#1084;&#1091;
                &#1087;&#1088;&#1086;&#1094;&#1077;&#1089;&#1110;, &#1074;
                &#1103;&#1082;&#1080;&#1081; &#1084;&#1080;
                &#1079;&#1072;&#1083;&#1091;&#1095;&#1077;&#1085;&#1110;.{" "}
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c18">
              <span className="c10 c2">Legal Obligations.</span>
              <span className="c16 c10">
                &nbsp;We may process your information where we believe it is
                necessary for compliance with our legal obligations, such as to
                cooperate with a law enforcement body or regulator agency,
                exercise or defend our legal rights, or disclose your
                information as evidence in litigation in which we are involved.
              </span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_f0iru77xcl95-0 start">
              <li className="c0 li-bullet-0">
                <span className="c2">
                  &#1046;&#1080;&#1090;&#1090;&#1108;&#1074;&#1086;
                  &#1074;&#1072;&#1078;&#1083;&#1080;&#1074;&#1110;
                  &#1110;&#1085;&#1090;&#1077;&#1088;&#1077;&#1089;&#1080;.
                </span>
                <span className="c3">
                  &nbsp;&#1052;&#1080;
                  &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                  &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                  &#1074;&#1072;&#1096;&#1091;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                  &#1103;&#1082;&#1097;&#1086;
                  &#1074;&#1074;&#1072;&#1078;&#1072;&#1108;&#1084;&#1086;
                  &#1094;&#1077;
                  &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1080;&#1084;
                  &#1076;&#1083;&#1103;
                  &#1079;&#1072;&#1093;&#1080;&#1089;&#1090;&#1091;
                  &#1074;&#1072;&#1096;&#1080;&#1093;
                  &#1078;&#1080;&#1090;&#1090;&#1108;&#1074;&#1086;
                  &#1074;&#1072;&#1078;&#1083;&#1080;&#1074;&#1080;&#1093;
                  &#1110;&#1085;&#1090;&#1077;&#1088;&#1077;&#1089;&#1110;&#1074;
                  &#1072;&#1073;&#1086;
                  &#1078;&#1080;&#1090;&#1090;&#1108;&#1074;&#1086;
                  &#1074;&#1072;&#1078;&#1083;&#1080;&#1074;&#1080;&#1093;
                  &#1110;&#1085;&#1090;&#1077;&#1088;&#1077;&#1089;&#1110;&#1074;
                  &#1090;&#1088;&#1077;&#1090;&#1100;&#1086;&#1111;
                  &#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1080;,
                  &#1085;&#1072;&#1087;&#1088;&#1080;&#1082;&#1083;&#1072;&#1076;,
                  &#1091;
                  &#1089;&#1080;&#1090;&#1091;&#1072;&#1094;&#1110;&#1103;&#1093;,
                  &#1087;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1085;&#1080;&#1093;
                  &#1110;&#1079;
                  &#1087;&#1086;&#1090;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1102;
                  &#1079;&#1072;&#1075;&#1088;&#1086;&#1079;&#1086;&#1102;
                  &#1073;&#1077;&#1079;&#1087;&#1077;&#1094;&#1110;
                  &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1086;&#1111;
                  &#1086;&#1089;&#1086;&#1073;&#1080;.
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_lqgy7cyy9biz-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10 c2">Vital Interests.</span>
                <span className="c16 c10">
                  &nbsp;We may process your information where we believe it is
                  necessary to protect your vital interests or the vital
                  interests of a third party, such as situations involving
                  potential threats to the safety of any person.
                </span>
              </li>
            </ul>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1047;
                &#1102;&#1088;&#1080;&#1076;&#1080;&#1095;&#1085;&#1086;&#1111;
                &#1090;&#1086;&#1095;&#1082;&#1080; &#1079;&#1086;&#1088;&#1091;
                &#1084;&#1080;
                &#1079;&#1072;&#1079;&#1074;&#1080;&#1095;&#1072;&#1081; &#1108;
                &laquo;&#1082;&#1086;&#1085;&#1090;&#1088;&#1086;&#1083;&#1077;&#1088;&#1086;&#1084;
                &#1076;&#1072;&#1085;&#1080;&#1093;&raquo;
                &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;
                &#1076;&#1086;
                &#1108;&#1074;&#1088;&#1086;&#1087;&#1077;&#1081;&#1089;&#1100;&#1082;&#1080;&#1093;
                &#1079;&#1072;&#1082;&#1086;&#1085;&#1110;&#1074;
                &#1087;&#1088;&#1086; &#1079;&#1072;&#1093;&#1080;&#1089;&#1090;
                &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1080;&#1093;
                &#1076;&#1072;&#1085;&#1080;&#1093;,
                &#1086;&#1087;&#1080;&#1089;&#1072;&#1085;&#1080;&#1093; &#1091;
                &#1094;&#1110;&#1108;&#1111;
                &#1087;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1080;
                &nbsp;&#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;,
                &#1086;&#1089;&#1082;&#1110;&#1083;&#1100;&#1082;&#1080;
                &#1084;&#1080;
                &#1074;&#1080;&#1079;&#1085;&#1072;&#1095;&#1072;&#1108;&#1084;&#1086;
                &#1079;&#1072;&#1089;&#1086;&#1073;&#1080;
                &#1090;&#1072;/&#1072;&#1073;&#1086;
                &#1094;&#1110;&#1083;&#1110;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1082;&#1080;
                &#1076;&#1072;&#1085;&#1080;&#1093;, &#1103;&#1082;&#1110;
                &#1084;&#1080;
                &#1074;&#1080;&#1082;&#1086;&#1085;&#1091;&#1108;&#1084;&#1086;.
                &nbsp;&#1062;&#1103;
                &#1087;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1072;
                &nbsp;&#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;
                &#1085;&#1077;
                &#1089;&#1090;&#1086;&#1089;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;,
                &#1103;&#1082;&#1091; &#1084;&#1080;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1108;&#1084;&#1086;
                &#1103;&#1082;
                &laquo;&#1086;&#1073;&#1088;&#1086;&#1073;&#1085;&#1080;&#1082;
                &#1076;&#1072;&#1085;&#1080;&#1093;&raquo; &#1074;&#1110;&#1076;
                &#1110;&#1084;&#1077;&#1085;&#1110;
                &#1085;&#1072;&#1096;&#1080;&#1093;
                &#1082;&#1083;&#1110;&#1108;&#1085;&#1090;&#1110;&#1074;.
                &#1059; &#1090;&#1072;&#1082;&#1080;&#1093;
                &#1089;&#1080;&#1090;&#1091;&#1072;&#1094;&#1110;&#1103;&#1093;
                &#1082;&#1083;&#1110;&#1108;&#1085;&#1090;,
                &#1103;&#1082;&#1086;&#1084;&#1091; &#1084;&#1080;
                &#1085;&#1072;&#1076;&#1072;&#1108;&#1084;&#1086;
                &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080; &#1090;&#1072;
                &#1079; &#1103;&#1082;&#1080;&#1084; &#1084;&#1080;
                &#1091;&#1082;&#1083;&#1072;&#1083;&#1080;
                &#1091;&#1075;&#1086;&#1076;&#1091; &#1087;&#1088;&#1086;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1082;&#1091;
                &#1076;&#1072;&#1085;&#1080;&#1093;, &#1108;
                &laquo;&#1082;&#1086;&#1085;&#1090;&#1088;&#1086;&#1083;&#1077;&#1088;&#1086;&#1084;
                &#1076;&#1072;&#1085;&#1080;&#1093;&raquo;,
                &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1072;&#1083;&#1100;&#1085;&#1080;&#1084;
                &#1079;&#1072; &#1074;&#1072;&#1096;&#1091;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                &#1110; &#1084;&#1080; &#1083;&#1080;&#1096;&#1077;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1108;&#1084;&#1086;
                &#1074;&#1072;&#1096;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1074;&#1110;&#1076; &#1081;&#1086;&#1075;&#1086;
                &#1110;&#1084;&#1077;&#1085;&#1110;
                &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;
                &#1076;&#1086; &#1074;&#1072;&#1096;&#1080;&#1093;
                &#1110;&#1085;&#1089;&#1090;&#1088;&#1091;&#1082;&#1094;&#1110;&#1081;.
                &#1071;&#1082;&#1097;&#1086; &#1074;&#1080;
                &#1093;&#1086;&#1095;&#1077;&#1090;&#1077;
                &#1076;&#1110;&#1079;&#1085;&#1072;&#1090;&#1080;&#1089;&#1103;
                &#1073;&#1110;&#1083;&#1100;&#1096;&#1077; &#1087;&#1088;&#1086;
                &#1084;&#1077;&#1090;&#1086;&#1076;&#1080;
                &#1079;&#1072;&#1073;&#1077;&#1079;&#1087;&#1077;&#1095;&#1077;&#1085;&#1085;&#1103;
                &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;
                &#1085;&#1072;&#1096;&#1080;&#1093;
                &#1082;&#1083;&#1110;&#1108;&#1085;&#1090;&#1110;&#1074;,
                &#1074;&#1080; &#1087;&#1086;&#1074;&#1080;&#1085;&#1085;&#1110;
                &#1087;&#1088;&#1086;&#1095;&#1080;&#1090;&#1072;&#1090;&#1080;
                &#1111;&#1093;&#1085;&#1102;
                &#1087;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1091;
                &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;
                &#1090;&#1072;
                &#1089;&#1087;&#1088;&#1103;&#1084;&#1091;&#1074;&#1072;&#1090;&#1080;
                &#1111;&#1084;
                &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1110;
                &#1079;&#1072;&#1087;&#1080;&#1090;&#1072;&#1085;&#1085;&#1103;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                In legal terms, we are generally the &quot;data controller&quot;
                under European data protection laws of the personal information
                described in this privacy notice, since we determine the means
                and/or purposes of the data processing we perform. This privacy
                policy does not apply to the personal information we process as
                a &quot;data processor&quot; on behalf of our customers. In
                those situations, the customer that we provide services to and
                with whom we have entered into a data processing agreement is
                the &quot;data controller&quot; responsible for your personal
                information, and we merely process your information on their
                behalf in accordance with your instructions. If you want to know
                more about our customers&#39; privacy practices, you should read
                their privacy policies and direct any questions you have to
                them.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c5 c11 c2">
                &#1071;&#1082;&#1097;&#1086; &#1074;&#1080;
                &#1087;&#1077;&#1088;&#1077;&#1073;&#1091;&#1074;&#1072;&#1108;&#1090;&#1077;
                &#1074; &#1050;&#1072;&#1085;&#1072;&#1076;&#1110;,
                &#1094;&#1077;&#1081; &#1088;&#1086;&#1079;&#1076;&#1110;&#1083;
                &#1089;&#1090;&#1086;&#1089;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
                &#1074;&#1072;&#1089;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c5 c10 c2 c11">
                If you are located in Canada, this section applies to you.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1052;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                &#1074;&#1072;&#1096;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                &#1103;&#1082;&#1097;&#1086; &#1074;&#1080;
                &#1076;&#1072;&#1083;&#1080; &#1085;&#1072;&#1084;
                &#1086;&#1082;&#1088;&#1077;&#1084;&#1080;&#1081;
                &#1076;&#1086;&#1079;&#1074;&#1110;&#1083;
                (&#1090;&#1086;&#1073;&#1090;&#1086;
                &#1103;&#1074;&#1085;&#1091;
                &#1079;&#1075;&#1086;&#1076;&#1091;) &#1085;&#1072;
                &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1072;&#1085;&#1085;&#1103;
                &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;
                &#1076;&#1083;&#1103; &#1087;&#1077;&#1074;&#1085;&#1086;&#1111;
                &#1084;&#1077;&#1090;&#1080; &#1072;&#1073;&#1086; &#1074;
                &#1089;&#1080;&#1090;&#1091;&#1072;&#1094;&#1110;&#1103;&#1093;,
                &#1082;&#1086;&#1083;&#1080; &#1074;&#1072;&#1096;
                &#1076;&#1086;&#1079;&#1074;&#1110;&#1083;
                &#1084;&#1086;&#1078;&#1085;&#1072;
                &#1087;&#1088;&#1080;&#1087;&#1091;&#1089;&#1090;&#1080;&#1090;&#1080;
                (&#1090;&#1086;&#1073;&#1090;&#1086;
                &#1085;&#1077;&#1087;&#1088;&#1103;&#1084;&#1072;
                &#1079;&#1075;&#1086;&#1076;&#1072;). &#1042;&#1080;
                &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;
                &#1074;&#1110;&#1076;&#1082;&#1083;&#1080;&#1082;&#1072;&#1090;&#1080;
                &#1089;&#1074;&#1086;&#1102; &#1079;&#1075;&#1086;&#1076;&#1091;
                &#1074;
                &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1080;&#1081;
                &#1095;&#1072;&#1089;.{" "}
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c16 c10">
                We may process your information if you have given us specific
                permission (i.e., express consent) to use your personal
                information for a specific purpose, or in situations where your
                permission can be inferred (i.e., implied consent). You can
                withdraw your consent at any time.{" "}
              </span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1059; &#1076;&#1077;&#1103;&#1082;&#1080;&#1093;
                &#1074;&#1080;&#1085;&#1103;&#1090;&#1082;&#1086;&#1074;&#1080;&#1093;
                &#1074;&#1080;&#1087;&#1072;&#1076;&#1082;&#1072;&#1093;
                &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;
                &#1076;&#1086; &#1095;&#1080;&#1085;&#1085;&#1086;&#1075;&#1086;
                &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1072;
                &#1085;&#1072;&#1084; &#1084;&#1086;&#1078;&#1077;
                &#1073;&#1091;&#1090;&#1080;
                &#1076;&#1086;&#1079;&#1074;&#1086;&#1083;&#1077;&#1085;&#1086;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                &#1074;&#1072;&#1096;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1073;&#1077;&#1079; &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1079;&#1075;&#1086;&#1076;&#1080;,
                &#1079;&#1086;&#1082;&#1088;&#1077;&#1084;&#1072;,
                &#1085;&#1072;&#1087;&#1088;&#1080;&#1082;&#1083;&#1072;&#1076;:
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                In some exceptional cases, we may be legally permitted under
                applicable law to process your information without your consent,
                including, for example:
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_dhk0xu25pxbq-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1071;&#1082;&#1097;&#1086; &#1079;&#1073;&#1110;&#1088;
                  &#1103;&#1074;&#1085;&#1086;
                  &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1072;&#1108;
                  &#1110;&#1085;&#1090;&#1077;&#1088;&#1077;&#1089;&#1072;&#1084;
                  &#1086;&#1089;&#1086;&#1073;&#1080;, &#1072;
                  &#1079;&#1075;&#1086;&#1076;&#1091; &#1085;&#1077;
                  &#1084;&#1086;&#1078;&#1085;&#1072;
                  &#1086;&#1090;&#1088;&#1080;&#1084;&#1072;&#1090;&#1080;
                  &#1089;&#1074;&#1086;&#1108;&#1095;&#1072;&#1089;&#1085;&#1086;
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_vigikkere0ys-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  If collection is clearly in the interests of an individual and
                  consent cannot be obtained in a timely way
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_ax9yfte0pute-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1044;&#1083;&#1103;
                  &#1088;&#1086;&#1079;&#1089;&#1083;&#1110;&#1076;&#1091;&#1074;&#1072;&#1085;&#1100;
                  &#1074;&#1080;&#1103;&#1074;&#1083;&#1077;&#1085;&#1085;&#1103;
                  &#1110;
                  &#1079;&#1072;&#1087;&#1086;&#1073;&#1110;&#1075;&#1072;&#1085;&#1085;&#1103;
                  &#1096;&#1072;&#1093;&#1088;&#1072;&#1081;&#1089;&#1090;&#1074;&#1072;
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_6zbkgmwj0rkd-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  For investigations and fraud detection and prevention
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8 c25">
              <span className="c3"></span>
            </p>
            <ul className="c6 lst-kix_v7rkk39f0qwc-0 start">
              <li className="c8 c13 li-bullet-0">
                <span className="c3">
                  &#1044;&#1083;&#1103;
                  &#1076;&#1110;&#1083;&#1086;&#1074;&#1080;&#1093;
                  &#1086;&#1087;&#1077;&#1088;&#1072;&#1094;&#1110;&#1081;
                  &#1079;&#1072; &#1091;&#1084;&#1086;&#1074;&#1080;
                  &#1076;&#1086;&#1090;&#1088;&#1080;&#1084;&#1072;&#1085;&#1085;&#1103;
                  &#1087;&#1077;&#1074;&#1085;&#1080;&#1093;
                  &#1091;&#1084;&#1086;&#1074;
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_nm2hbiex9ryi-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  For business transactions provided certain conditions are met
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_obhun6idbt6x-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1071;&#1082;&#1097;&#1086; &#1094;&#1077;
                  &#1084;&#1110;&#1089;&#1090;&#1080;&#1090;&#1100;&#1089;&#1103;
                  &#1074;
                  &#1087;&#1086;&#1082;&#1072;&#1079;&#1072;&#1085;&#1085;&#1103;&#1093;
                  &#1089;&#1074;&#1110;&#1076;&#1082;&#1072;, &#1072;
                  &#1079;&#1073;&#1110;&#1088;
                  &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1080;&#1081;
                  &#1076;&#1083;&#1103;
                  &#1086;&#1094;&#1110;&#1085;&#1082;&#1080;,
                  &#1086;&#1073;&#1088;&#1086;&#1073;&#1082;&#1080;
                  &#1072;&#1073;&#1086;
                  &#1074;&#1088;&#1077;&#1075;&#1091;&#1083;&#1102;&#1074;&#1072;&#1085;&#1085;&#1103;
                  &#1089;&#1090;&#1088;&#1072;&#1093;&#1086;&#1074;&#1086;&#1111;
                  &#1087;&#1088;&#1077;&#1090;&#1077;&#1085;&#1079;&#1110;&#1111;
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_on5d3ghktmbx-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  If it is contained in a witness statement and the collection
                  is necessary to assess, process, or settle an insurance claim
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_on5d3ghktmbx-0">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1044;&#1083;&#1103;
                  &#1110;&#1076;&#1077;&#1085;&#1090;&#1080;&#1092;&#1110;&#1082;&#1072;&#1094;&#1110;&#1111;
                  &#1087;&#1086;&#1088;&#1072;&#1085;&#1077;&#1085;&#1080;&#1093;,
                  &#1093;&#1074;&#1086;&#1088;&#1080;&#1093;
                  &#1072;&#1073;&#1086;
                  &#1087;&#1086;&#1084;&#1077;&#1088;&#1083;&#1080;&#1093;
                  &#1086;&#1089;&#1110;&#1073; &#1110;
                  &#1089;&#1087;&#1110;&#1083;&#1082;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;
                  &#1079;
                  &#1085;&#1072;&#1081;&#1073;&#1083;&#1080;&#1078;&#1095;&#1080;&#1084;&#1080;
                  &#1088;&#1086;&#1076;&#1080;&#1095;&#1072;&#1084;&#1080;
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_uij6xyt73jbu-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  For identifying injured, ill, or deceased persons and
                  communicating with next of kin
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_fa7sa8fkdiit-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1071;&#1082;&#1097;&#1086; &#1084;&#1080;
                  &#1084;&#1072;&#1108;&#1084;&#1086;
                  &#1086;&#1073;&#1169;&#1088;&#1091;&#1085;&#1090;&#1086;&#1074;&#1072;&#1085;&#1110;
                  &#1087;&#1110;&#1076;&#1089;&#1090;&#1072;&#1074;&#1080;
                  &#1074;&#1074;&#1072;&#1078;&#1072;&#1090;&#1080;,
                  &#1097;&#1086; &#1086;&#1089;&#1086;&#1073;&#1072;
                  &#1073;&#1091;&#1083;&#1072;, &#1108; &#1095;&#1080;
                  &#1084;&#1086;&#1078;&#1077;
                  &#1089;&#1090;&#1072;&#1090;&#1080;
                  &#1078;&#1077;&#1088;&#1090;&#1074;&#1086;&#1102;
                  &#1092;&#1110;&#1085;&#1072;&#1085;&#1089;&#1086;&#1074;&#1080;&#1093;
                  &#1079;&#1083;&#1086;&#1074;&#1078;&#1080;&#1074;&#1072;&#1085;&#1100;.
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_7r9hr4o74imt-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  If we have reasonable grounds to believe an individual has
                  been, is, or may be victim of financial abuse
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_gpxr0a58c16i-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1071;&#1082;&#1097;&#1086;
                  &#1088;&#1086;&#1079;&#1091;&#1084;&#1085;&#1086;
                  &#1086;&#1095;&#1110;&#1082;&#1091;&#1074;&#1072;&#1090;&#1080;,
                  &#1097;&#1086; &#1079;&#1073;&#1110;&#1088; &#1110;
                  &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1072;&#1085;&#1085;&#1103;
                  &#1079;&#1072; &#1079;&#1075;&#1086;&#1076;&#1086;&#1102;
                  &#1087;&#1086;&#1089;&#1090;&#1072;&#1074;&#1080;&#1090;&#1100;
                  &#1087;&#1110;&#1076;
                  &#1079;&#1072;&#1075;&#1088;&#1086;&#1079;&#1091;
                  &#1076;&#1086;&#1089;&#1090;&#1091;&#1087;&#1085;&#1110;&#1089;&#1090;&#1100;
                  &#1072;&#1073;&#1086;
                  &#1090;&#1086;&#1095;&#1085;&#1110;&#1089;&#1090;&#1100;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;,
                  &#1072; &#1079;&#1073;&#1110;&#1088; &#1108;
                  &#1088;&#1086;&#1079;&#1091;&#1084;&#1085;&#1080;&#1084;
                  &#1076;&#1083;&#1103; &#1094;&#1110;&#1083;&#1077;&#1081;,
                  &#1087;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1085;&#1080;&#1093;
                  &#1110;&#1079;
                  &#1088;&#1086;&#1079;&#1089;&#1083;&#1110;&#1076;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;&#1084;
                  &#1087;&#1086;&#1088;&#1091;&#1096;&#1077;&#1085;&#1085;&#1103;
                  &#1091;&#1075;&#1086;&#1076;&#1080; &#1072;&#1073;&#1086;
                  &#1087;&#1086;&#1088;&#1091;&#1096;&#1077;&#1085;&#1085;&#1103;
                  &#1079;&#1072;&#1082;&#1086;&#1085;&#1110;&#1074;
                  &#1050;&#1072;&#1085;&#1072;&#1076;&#1080; &#1095;&#1080;
                  &#1087;&#1088;&#1086;&#1074;&#1110;&#1085;&#1094;&#1110;&#1111;.
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_v7o5zsdkvy4-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  If it is reasonable to expect collection and use with consent
                  would compromise the availability or the accuracy of the
                  information and the collection is reasonable for purposes
                  related to investigating a breach of an agreement or a
                  contravention of the laws of Canada or a province
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_v7o5zsdkvy4-0">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1071;&#1082;&#1097;&#1086;
                  &#1088;&#1086;&#1079;&#1075;&#1086;&#1083;&#1086;&#1096;&#1077;&#1085;&#1085;&#1103;
                  &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1086;
                  &#1076;&#1083;&#1103;
                  &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1103;
                  &#1079;&#1072;:
                  &#1087;&#1086;&#1074;&#1110;&#1089;&#1090;&#1082;&#1086;&#1102;
                  &#1074; &#1089;&#1091;&#1076;,
                  &#1086;&#1088;&#1076;&#1077;&#1088;&#1086;&#1084;,
                  &#1091;&#1093;&#1074;&#1072;&#1083;&#1086;&#1102;
                  &#1089;&#1091;&#1076;&#1091; &#1072;&#1073;&#1086;
                  &#1087;&#1088;&#1072;&#1074;&#1080;&#1083;&#1072;&#1084;&#1080;
                  &#1089;&#1091;&#1076;&#1091;, &#1097;&#1086;
                  &#1089;&#1090;&#1086;&#1089;&#1091;&#1102;&#1090;&#1100;&#1089;&#1103;
                  &#1085;&#1072;&#1076;&#1072;&#1085;&#1085;&#1103;
                  &#1079;&#1072;&#1087;&#1080;&#1089;&#1110;&#1074;.
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_2d18gtgbojgx-0 start">
              <li className="c0 li-bullet-0">
                <span className="c16 c10">
                  If disclosure is required to comply with a subpoena, warrant,
                  court order, or rules of the court relating to the production
                  of records
                </span>
              </li>
            </ul>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_t859swqqw1iy-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1103;&#1082;&#1097;&#1086; &#1074;&#1086;&#1085;&#1080;
                  &#1073;&#1091;&#1083;&#1080;
                  &#1089;&#1090;&#1074;&#1086;&#1088;&#1077;&#1085;&#1110;
                  &#1086;&#1089;&#1086;&#1073;&#1086;&#1102;
                  &#1087;&#1110;&#1076; &#1095;&#1072;&#1089; &#1111;&#1111;
                  &#1088;&#1086;&#1073;&#1086;&#1090;&#1080;,
                  &#1073;&#1110;&#1079;&#1085;&#1077;&#1089;&#1091;
                  &#1095;&#1080;
                  &#1087;&#1088;&#1086;&#1092;&#1077;&#1089;&#1110;&#1081;&#1085;&#1086;&#1111;
                  &#1076;&#1110;&#1103;&#1083;&#1100;&#1085;&#1086;&#1089;&#1090;&#1110;,
                  &#1110; &#1079;&#1073;&#1110;&#1088;
                  &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1072;&#1108;
                  &#1094;&#1110;&#1083;&#1103;&#1084;, &#1076;&#1083;&#1103;
                  &#1103;&#1082;&#1080;&#1093;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;
                  &#1073;&#1091;&#1083;&#1072;
                  &#1089;&#1090;&#1074;&#1086;&#1088;&#1077;&#1085;&#1072;
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_jt0fsvdj8181-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  If it was produced by an individual in the course of their
                  employment, business, or profession and the collection is
                  consistent with the purposes for which the information was
                  produced
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_jt0fsvdj8181-0">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1071;&#1082;&#1097;&#1086; &#1079;&#1073;&#1110;&#1088;
                  &#1087;&#1088;&#1080;&#1079;&#1085;&#1072;&#1095;&#1077;&#1085;&#1080;&#1081;
                  &#1074;&#1080;&#1082;&#1083;&#1102;&#1095;&#1085;&#1086;
                  &#1076;&#1083;&#1103;
                  &#1078;&#1091;&#1088;&#1085;&#1072;&#1083;&#1110;&#1089;&#1090;&#1089;&#1100;&#1082;&#1080;&#1093;,
                  &#1084;&#1080;&#1089;&#1090;&#1077;&#1094;&#1100;&#1082;&#1080;&#1093;
                  &#1072;&#1073;&#1086;
                  &#1083;&#1110;&#1090;&#1077;&#1088;&#1072;&#1090;&#1091;&#1088;&#1085;&#1080;&#1093;
                  &#1094;&#1110;&#1083;&#1077;&#1081;
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_3j8zkcdbd1ld-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  If the collection is solely for journalistic, artistic, or
                  literary purposes
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_3j8zkcdbd1ld-0">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1071;&#1082;&#1097;&#1086;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;
                  &#1108;
                  &#1079;&#1072;&#1075;&#1072;&#1083;&#1100;&#1085;&#1086;&#1076;&#1086;&#1089;&#1090;&#1091;&#1087;&#1085;&#1086;&#1102;
                  &#1090;&#1072;
                  &#1074;&#1080;&#1079;&#1085;&#1072;&#1095;&#1077;&#1085;&#1072;
                  &#1087;&#1088;&#1072;&#1074;&#1080;&#1083;&#1072;&#1084;&#1080;
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_fqf0dnmy4aoi-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  If the information is publicly available and is specified by
                  the regulations
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4" id="privacy_sec4">
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c1">
                4. &#1050;&#1054;&#1051;&#1048; &#1030; &#1047;
                &#1050;&#1048;&#1052; &#1052;&#1048;
                &#1044;&#1030;&#1051;&#1048;&#1052;&#1054;&#1057;&#1071;
                &#1042;&#1040;&#1064;&#1054;&#1070;
                &#1054;&#1057;&#1054;&#1041;&#1048;&#1057;&#1058;&#1054;&#1070;
                &#1030;&#1053;&#1060;&#1054;&#1056;&#1052;&#1040;&#1062;&#1030;&#1028;&#1070;?
              </span>
            </p>
          </td>
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">
                4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c2">
                &#1050;&#1086;&#1088;&#1086;&#1090;&#1082;&#1086;:
              </span>
              <span className="c11">
                &nbsp;&#1084;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1076;&#1110;&#1083;&#1080;&#1090;&#1080;&#1089;&#1103;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1108;&#1102;
                &#1074;
                &#1082;&#1086;&#1085;&#1082;&#1088;&#1077;&#1090;&#1085;&#1080;&#1093;
                &#1089;&#1080;&#1090;&#1091;&#1072;&#1094;&#1110;&#1103;&#1093;,
                &#1086;&#1087;&#1080;&#1089;&#1072;&#1085;&#1080;&#1093; &#1091;
                &#1094;&#1100;&#1086;&#1084;&#1091;
                &#1088;&#1086;&#1079;&#1076;&#1110;&#1083;&#1110;,
                &#1090;&#1072;/&#1072;&#1073;&#1086; &#1079;
                &#1090;&#1072;&#1082;&#1080;&#1084;&#1080;
                &#1082;&#1072;&#1090;&#1077;&#1075;&#1086;&#1088;&#1110;&#1103;&#1084;&#1080;
                &#1090;&#1088;&#1077;&#1090;&#1110;&#1093;
                &#1089;&#1090;&#1086;&#1088;&#1110;&#1085;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c10 c2">In Short:</span>
              <span className="c11 c10">
                &nbsp;We may share information in specific situations described
                in this section and/or with the following categories of third
                parties.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                &#1055;&#1086;&#1089;&#1090;&#1072;&#1095;&#1072;&#1083;&#1100;&#1085;&#1080;&#1082;&#1080;,
                &#1082;&#1086;&#1085;&#1089;&#1091;&#1083;&#1100;&#1090;&#1072;&#1085;&#1090;&#1080;,
                &#1087;&#1072;&#1088;&#1090;&#1085;&#1077;&#1088;&#1080;
                &#1090;&#1072; &#1110;&#1085;&#1096;&#1110;
                &#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1085;&#1110;
                &#1087;&#1086;&#1089;&#1090;&#1072;&#1095;&#1072;&#1083;&#1100;&#1085;&#1080;&#1082;&#1080;
                &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;.
              </span>
              <span>
                &nbsp;&#1052;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1085;&#1072;&#1076;&#1072;&#1074;&#1072;&#1090;&#1080;
                &#1074;&#1072;&#1096;&#1110; &#1076;&#1072;&#1085;&#1110;
                &#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1085;&#1110;&#1084;
                &#1087;&#1086;&#1089;&#1090;&#1072;&#1095;&#1072;&#1083;&#1100;&#1085;&#1080;&#1082;&#1072;&#1084;,
                &#1087;&#1086;&#1089;&#1090;&#1072;&#1095;&#1072;&#1083;&#1100;&#1085;&#1080;&#1082;&#1072;&#1084;
                &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;,
                &#1087;&#1072;&#1088;&#1090;&#1085;&#1077;&#1088;&#1072;&#1084;,
                &#1087;&#1110;&#1076;&#1088;&#1103;&#1076;&#1085;&#1080;&#1082;&#1072;&#1084;
                &#1072;&#1073;&#1086;
                &#1072;&#1075;&#1077;&#1085;&#1090;&#1072;&#1084; (&laquo;
              </span>
              <span className="c2">
                &#1090;&#1088;&#1077;&#1090;&#1110;
                &#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1080;
              </span>
              <span className="c3">
                &raquo;), &#1103;&#1082;&#1110;
                &#1085;&#1072;&#1076;&#1072;&#1102;&#1090;&#1100;
                &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080;
                &#1076;&#1083;&#1103; &#1085;&#1072;&#1089; (&#1079;
                &#1103;&#1082;&#1080;&#1084;&#1080; &#1084;&#1080;
                &#1084;&#1072;&#1108;&#1084;&#1086;
                &#1087;&#1072;&#1088;&#1090;&#1085;&#1077;&#1088;&#1089;&#1100;&#1082;&#1110;
                &#1074;&#1110;&#1076;&#1085;&#1086;&#1089;&#1080;&#1085;&#1080;)
                &#1072;&#1073;&#1086; &#1074;&#1110;&#1076;
                &#1085;&#1072;&#1096;&#1086;&#1075;&#1086;
                &#1110;&#1084;&#1077;&#1085;&#1110; &#1090;&#1072;
                &#1087;&#1086;&#1090;&#1088;&#1077;&#1073;&#1091;&#1102;&#1090;&#1100;
                &#1076;&#1086;&#1089;&#1090;&#1091;&#1087;&#1091; &#1076;&#1086;
                &#1090;&#1072;&#1082;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;
                &#1076;&#1083;&#1103;
                &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1103;
                &#1090;&#1072;&#1082;&#1086;&#1111;
                &#1088;&#1086;&#1073;&#1086;&#1090;&#1080;. &#1059;
                &#1085;&#1072;&#1089; &#1108;
                &#1082;&#1086;&#1085;&#1090;&#1088;&#1072;&#1082;&#1090;&#1080;
                (&#1072;&#1073;&#1086;
                &#1073;&#1091;&#1076;&#1091;&#1090;&#1100;
                &#1091;&#1082;&#1083;&#1072;&#1076;&#1077;&#1085;&#1110; &#1074;
                &#1084;&#1086;&#1084;&#1077;&#1085;&#1090;
                &#1087;&#1077;&#1088;&#1077;&#1076;&#1072;&#1095;&#1110;
                &#1076;&#1072;&#1085;&#1080;&#1093;) &#1079;
                &#1085;&#1072;&#1096;&#1080;&#1084;&#1080;
                &#1090;&#1088;&#1077;&#1090;&#1110;&#1084;&#1080;
                &#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1072;&#1084;&#1080;,
                &#1103;&#1082;&#1110;
                &#1087;&#1088;&#1080;&#1079;&#1085;&#1072;&#1095;&#1077;&#1085;&#1110;
                &#1076;&#1083;&#1103;
                &#1079;&#1072;&#1093;&#1080;&#1089;&#1090;&#1091;
                &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;.
                &#1062;&#1077;
                &#1086;&#1079;&#1085;&#1072;&#1095;&#1072;&#1108;,
                &#1097;&#1086; &#1074;&#1086;&#1085;&#1080; &#1085;&#1077;
                &#1084;&#1086;&#1078;&#1091;&#1090;&#1100;
                &#1085;&#1110;&#1095;&#1086;&#1075;&#1086;
                &#1088;&#1086;&#1073;&#1080;&#1090;&#1080; &#1079;
                &#1074;&#1072;&#1096;&#1086;&#1102;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1102;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1108;&#1102;,
                &#1103;&#1082;&#1097;&#1086; &#1084;&#1080; &#1085;&#1077;
                &#1076;&#1086;&#1088;&#1091;&#1095;&#1080;&#1084;&#1086;
                &#1111;&#1084; &#1094;&#1077;
                &#1079;&#1088;&#1086;&#1073;&#1080;&#1090;&#1080;.
                &#1042;&#1086;&#1085;&#1080; &#1090;&#1072;&#1082;&#1086;&#1078;
                &#1085;&#1077;
                &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1103;&#1090;&#1080;&#1084;&#1091;&#1090;&#1100;
                &#1074;&#1072;&#1096;&#1091;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1078;&#1086;&#1076;&#1085;&#1110;&#1081;
                &#1086;&#1088;&#1075;&#1072;&#1085;&#1110;&#1079;&#1072;&#1094;&#1110;&#1111;,
                &#1082;&#1088;&#1110;&#1084; &#1085;&#1072;&#1089;.
                &#1042;&#1086;&#1085;&#1080; &#1090;&#1072;&#1082;&#1086;&#1078;
                &#1079;&#1086;&#1073;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1091;&#1102;&#1090;&#1100;&#1089;&#1103;
                &#1079;&#1072;&#1093;&#1080;&#1097;&#1072;&#1090;&#1080;
                &#1076;&#1072;&#1085;&#1110;, &#1103;&#1082;&#1110;
                &#1074;&#1086;&#1085;&#1080;
                &#1079;&#1073;&#1077;&#1088;&#1110;&#1075;&#1072;&#1102;&#1090;&#1100;
                &#1074;&#1110;&#1076; &#1085;&#1072;&#1096;&#1086;&#1075;&#1086;
                &#1110;&#1084;&#1077;&#1085;&#1110;, &#1110;
                &#1079;&#1073;&#1077;&#1088;&#1110;&#1075;&#1072;&#1090;&#1080;
                &#1111;&#1093;
                &#1087;&#1088;&#1086;&#1090;&#1103;&#1075;&#1086;&#1084;
                &#1087;&#1077;&#1088;&#1110;&#1086;&#1076;&#1091;,
                &#1103;&#1082;&#1080;&#1081; &#1084;&#1080;
                &#1076;&#1072;&#1108;&#1084;&#1086;.
                &#1050;&#1072;&#1090;&#1077;&#1075;&#1086;&#1088;&#1110;&#1111;
                &#1090;&#1088;&#1077;&#1090;&#1110;&#1093;
                &#1086;&#1089;&#1110;&#1073;, &#1103;&#1082;&#1080;&#1084;
                &#1084;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1085;&#1072;&#1076;&#1072;&#1074;&#1072;&#1090;&#1080;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;:
                &#1110;&#1085;&#1089;&#1090;&#1088;&#1091;&#1084;&#1077;&#1085;&#1090;&#1080;
                &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;&#1091;
                &#1090;&#1072;
                &#1087;&#1088;&#1086;&#1076;&#1072;&#1078;&#1110;&#1074;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">
                Vendors, Consultants, and Other Third-Party Service Providers.
              </span>
              <span className="c10">
                &nbsp;We may share your data with third-party vendors, service
                providers, partners contractors, or agents (&quot;
              </span>
              <span className="c10 c2">third parties</span>
              <span className="c10">
                &quot;) who perform services for us (to whom we have partnership
                with) or on our behalf and require access to such information to
                do that work. We have contracts in place{" "}
              </span>
              <span>(or will be concluded at the time of data transfer)</span>
              <span className="c10">
                with our third parties, which are designed to help safeguard
                your personal information. This means that they cannot do
                anything with your personal information unless we have
                instructed them to do it. They will also not share your personal
                information with any organization apart from us. They also
                commit to protect the data they hold on our behalf and to retain
                it for the period we instruct. The categories of third parties
                we may share personal information with are as follows:
              </span>
              <span className="c10">Sales &amp; Marketing Tools</span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1053;&#1072;&#1084; &#1090;&#1072;&#1082;&#1086;&#1078;
                &#1084;&#1086;&#1078;&#1077;
                &#1079;&#1085;&#1072;&#1076;&#1086;&#1073;&#1080;&#1090;&#1080;&#1089;&#1103;
                &#1085;&#1072;&#1076;&#1072;&#1090;&#1080;
                &#1074;&#1072;&#1096;&#1091;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1074; &#1090;&#1072;&#1082;&#1080;&#1093;
                &#1089;&#1080;&#1090;&#1091;&#1072;&#1094;&#1110;&#1103;&#1093;:
                &#1052;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1085;&#1072;&#1076;&#1072;&#1074;&#1072;&#1090;&#1080;
                &#1072;&#1073;&#1086;
                &#1087;&#1077;&#1088;&#1077;&#1076;&#1072;&#1074;&#1072;&#1090;&#1080;
                &#1074;&#1072;&#1096;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1091; &#1079;&#1074;&rsquo;&#1103;&#1079;&#1082;&#1091;
                &#1079; &#1072;&#1073;&#1086; &#1087;&#1110;&#1076;
                &#1095;&#1072;&#1089;
                &#1087;&#1077;&#1088;&#1077;&#1075;&#1086;&#1074;&#1086;&#1088;&#1110;&#1074;
                &#1097;&#1086;&#1076;&#1086;
                &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1086;&#1075;&#1086;
                &#1079;&#1083;&#1080;&#1090;&#1090;&#1103;,
                &#1087;&#1088;&#1086;&#1076;&#1072;&#1078;&#1091;
                &#1072;&#1082;&#1090;&#1080;&#1074;&#1110;&#1074;
                &#1082;&#1086;&#1084;&#1087;&#1072;&#1085;&#1110;&#1111;,
                &#1092;&#1110;&#1085;&#1072;&#1085;&#1089;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;
                &#1072;&#1073;&#1086;
                &#1087;&#1088;&#1080;&#1076;&#1073;&#1072;&#1085;&#1085;&#1103;
                &#1074;&#1089;&#1100;&#1086;&#1075;&#1086; &#1072;&#1073;&#1086;
                &#1095;&#1072;&#1089;&#1090;&#1080;&#1085;&#1080;
                &#1085;&#1072;&#1096;&#1086;&#1075;&#1086;
                &#1073;&#1110;&#1079;&#1085;&#1077;&#1089;&#1091;
                &#1110;&#1085;&#1096;&#1110;&#1081;
                &#1082;&#1086;&#1084;&#1087;&#1072;&#1085;&#1110;&#1111;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c16 c10">
                We also may need to share your personal information in the
                following situations: We may share or transfer your information
                in connection with, or during negotiations of, any merger, sale
                of company assets, financing, or acquisition of all or a portion
                of our business to another company.
              </span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4" id="privacy_sec5">
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                5. &#1063;&#1048;
                &#1042;&#1048;&#1050;&#1054;&#1056;&#1048;&#1057;&#1058;&#1054;&#1042;&#1059;&#1028;&#1052;&#1054;
                &#1052;&#1048; &#1060;&#1040;&#1049;&#1051;&#1048; COOKIE
                &#1058;&#1040; &#1030;&#1053;&#1064;&#1030;
                &#1058;&#1045;&#1061;&#1053;&#1054;&#1051;&#1054;&#1043;&#1030;&#1031;
                &#1042;&#1030;&#1044;&#1057;&#1058;&#1045;&#1046;&#1045;&#1053;&#1053;&#1071;?
              </span>
            </p>
          </td>
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">
                5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c2">
                &#1050;&#1086;&#1088;&#1086;&#1090;&#1082;&#1086;:
              </span>
              <span className="c11">
                &nbsp;&#1084;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1074;&#1072;&#1090;&#1080;
                &#1092;&#1072;&#1081;&#1083;&#1080; cookie &#1090;&#1072;
                &#1110;&#1085;&#1096;&#1110;
                &#1090;&#1077;&#1093;&#1085;&#1086;&#1083;&#1086;&#1075;&#1110;&#1111;
                &#1074;&#1110;&#1076;&#1089;&#1090;&#1077;&#1078;&#1077;&#1085;&#1085;&#1103;
                &#1076;&#1083;&#1103; &#1079;&#1073;&#1086;&#1088;&#1091;
                &#1090;&#1072;
                &#1079;&#1073;&#1077;&#1088;&#1110;&#1075;&#1072;&#1085;&#1085;&#1103;
                &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c10 c2">In Short:</span>
              <span className="c11 c10">
                &nbsp;We may use cookies and other tracking technologies to
                collect and store your information.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1052;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1074;&#1072;&#1090;&#1080;
                &#1092;&#1072;&#1081;&#1083;&#1080; cookie &#1090;&#1072;
                &#1087;&#1086;&#1076;&#1110;&#1073;&#1085;&#1110;
                &#1090;&#1077;&#1093;&#1085;&#1086;&#1083;&#1086;&#1075;&#1110;&#1111;
                &#1074;&#1110;&#1076;&#1089;&#1090;&#1077;&#1078;&#1077;&#1085;&#1085;&#1103;
                (&#1085;&#1072;&#1087;&#1088;&#1080;&#1082;&#1083;&#1072;&#1076;,
                &#1074;&#1077;&#1073;-&#1084;&#1072;&#1103;&#1082;&#1080;
                &#1090;&#1072;
                &#1087;&#1110;&#1082;&#1089;&#1077;&#1083;&#1110;)
                &#1076;&#1083;&#1103;
                &#1076;&#1086;&#1089;&#1090;&#1091;&#1087;&#1091;
                &#1072;&#1073;&#1086;
                &#1079;&#1073;&#1077;&#1088;&#1110;&#1075;&#1072;&#1085;&#1085;&#1103;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;.{" "}
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                We may use cookies and similar tracking technologies (like web
                beacons and pixels) to access or store information.{" "}
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4" id="privacy_sec6">
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                6. &#1071;&#1050; &#1044;&#1054;&#1042;&#1043;&#1054;
                &#1052;&#1048;
                &#1047;&#1041;&#1045;&#1056;&#1030;&#1043;&#1040;&#1028;&#1052;&#1054;
                &#1042;&#1040;&#1064;&#1059;
                &#1030;&#1053;&#1060;&#1054;&#1056;&#1052;&#1040;&#1062;&#1030;&#1070;?
              </span>
            </p>
          </td>
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">
                6. HOW LONG DO WE KEEP YOUR INFORMATION?
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c2">
                &#1050;&#1086;&#1088;&#1086;&#1090;&#1082;&#1086;:{" "}
              </span>
              <span className="c11">
                &#1084;&#1080;
                &#1079;&#1073;&#1077;&#1088;&#1110;&#1075;&#1072;&#1108;&#1084;&#1086;
                &#1074;&#1072;&#1096;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1089;&#1090;&#1110;&#1083;&#1100;&#1082;&#1080;,
                &#1089;&#1082;&#1110;&#1083;&#1100;&#1082;&#1080;
                &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1086;
                &#1076;&#1083;&#1103;
                &#1076;&#1086;&#1089;&#1103;&#1075;&#1085;&#1077;&#1085;&#1085;&#1103;
                &#1094;&#1110;&#1083;&#1077;&#1081;,
                &#1074;&#1080;&#1082;&#1083;&#1072;&#1076;&#1077;&#1085;&#1080;&#1093;
                &#1091; &#1094;&#1110;&#1108;&#1111;
                &#1087;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1080;
                &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;,
                &#1103;&#1082;&#1097;&#1086; &#1110;&#1085;&#1096;&#1077;
                &#1085;&#1077;
                &#1074;&#1080;&#1084;&#1072;&#1075;&#1072;&#1108;&#1090;&#1100;&#1089;&#1103;
                &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1084;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c10 c2">In Short: </span>
              <span className="c11 c10">
                We keep your information for as long as necessary to fulfill the
                purposes outlined in this privacy policy unless otherwise
                required by law.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1052;&#1080;
                &#1079;&#1073;&#1077;&#1088;&#1110;&#1075;&#1072;&#1090;&#1080;&#1084;&#1077;&#1084;&#1086;
                &#1074;&#1072;&#1096;&#1091;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1083;&#1080;&#1096;&#1077; &#1076;&#1086;
                &#1090;&#1080;&#1093; &#1087;&#1110;&#1088;,
                &#1087;&#1086;&#1082;&#1080; &#1094;&#1077;
                &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1086;
                &#1076;&#1083;&#1103; &#1094;&#1110;&#1083;&#1077;&#1081;,
                &#1074;&#1080;&#1082;&#1083;&#1072;&#1076;&#1077;&#1085;&#1080;&#1093;
                &#1091; &#1094;&#1110;&#1108;&#1111;
                &#1087;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1080;
                &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;,
                &#1079;&#1072;
                &#1074;&#1080;&#1085;&#1103;&#1090;&#1082;&#1086;&#1084;
                &#1074;&#1080;&#1087;&#1072;&#1076;&#1082;&#1110;&#1074;,
                &#1082;&#1086;&#1083;&#1080;
                &#1076;&#1086;&#1074;&#1096;&#1080;&#1081;
                &#1087;&#1077;&#1088;&#1110;&#1086;&#1076;
                &#1079;&#1073;&#1077;&#1088;&#1110;&#1075;&#1072;&#1085;&#1085;&#1103;
                &#1074;&#1080;&#1084;&#1072;&#1075;&#1072;&#1108;&#1090;&#1100;&#1089;&#1103;
                &#1072;&#1073;&#1086;
                &#1076;&#1086;&#1079;&#1074;&#1086;&#1083;&#1103;&#1108;&#1090;&#1100;&#1089;&#1103;
                &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1086;&#1084;
                (&#1085;&#1072;&#1087;&#1088;&#1080;&#1082;&#1083;&#1072;&#1076;,
                &#1074;&#1080;&#1084;&#1086;&#1075;&#1072;&#1084;&#1080;
                &#1087;&#1086;&#1076;&#1072;&#1090;&#1082;&#1086;&#1074;&#1086;&#1075;&#1086;,
                &#1073;&#1091;&#1093;&#1075;&#1072;&#1083;&#1090;&#1077;&#1088;&#1089;&#1100;&#1082;&#1086;&#1075;&#1086;
                &#1086;&#1073;&#1083;&#1110;&#1082;&#1091; &#1095;&#1080;
                &#1110;&#1085;&#1096;&#1080;&#1084;&#1080;
                &#1087;&#1088;&#1072;&#1074;&#1086;&#1074;&#1080;&#1084;&#1080;
                &#1074;&#1080;&#1084;&#1086;&#1075;&#1072;&#1084;&#1080;).
                &#1046;&#1086;&#1076;&#1085;&#1072; &#1084;&#1077;&#1090;&#1072;
                &#1094;&#1100;&#1086;&#1075;&#1086;
                &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1077;&#1085;&#1085;&#1103;
                &#1085;&#1077; &#1074;&#1080;&#1084;&#1072;&#1075;&#1072;&#1108;
                &#1074;&#1110;&#1076; &#1085;&#1072;&#1089;
                &#1079;&#1073;&#1077;&#1088;&#1110;&#1075;&#1072;&#1090;&#1080;
                &#1074;&#1072;&#1096;&#1091;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1076;&#1086;&#1074;&#1096;&#1077;
                &#1090;&#1088;&#1080;&#1076;&#1094;&#1103;&#1090;&#1080;
                &#1096;&#1077;&#1089;&#1090;&#1080; (36)
                &#1084;&#1110;&#1089;&#1103;&#1094;&#1110;&#1074;
                &#1087;&#1110;&#1089;&#1083;&#1103;
                &#1087;&#1088;&#1080;&#1087;&#1080;&#1085;&#1077;&#1085;&#1085;&#1103;
                &#1076;&#1110;&#1111;
                &#1086;&#1073;&#1083;&#1110;&#1082;&#1086;&#1074;&#1086;&#1075;&#1086;
                &#1079;&#1072;&#1087;&#1080;&#1089;&#1091;
                &#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1074;&#1072;&#1095;&#1072;
                &#1072;&#1073;&#1086;
                &#1088;&#1086;&#1079;&#1110;&#1088;&#1074;&#1072;&#1085;&#1085;&#1103;
                &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;&#1075;&#1086;
                &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;
                &#1072;&#1073;&#1086;
                &#1074;&#1110;&#1076;&#1079;&#1080;&#1074;&#1091;
                &#1094;&#1110;&#1108;&#1111;
                &#1079;&#1075;&#1086;&#1076;&#1080;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span>
                We will only keep your personal information for as long as it is
                necessary for the purposes set out in this privacy policy,
                unless a longer retention period is required or permitted by law
                (such as tax, accounting, or other legal requirements).{" "}
              </span>
              <span className="c3">
                No purpose in this notice requires us to retain your personal
                information for longer than thirty-six (36) months after the
                user&#39;s account is terminated or the applicable agreement is
                terminated or this consent is revoked.
              </span>
            </p>
            <p className="c8 c25">
              <span className="c3"></span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1071;&#1082;&#1097;&#1086; &#1091; &#1085;&#1072;&#1089;
                &#1085;&#1077;&#1084;&#1072;&#1108;
                &#1087;&#1086;&#1090;&#1086;&#1095;&#1085;&#1086;&#1111;
                &#1079;&#1072;&#1082;&#1086;&#1085;&#1085;&#1086;&#1111;
                &#1082;&#1086;&#1084;&#1077;&#1088;&#1094;&#1110;&#1081;&#1085;&#1086;&#1111;
                &#1087;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1090;&#1080;
                &#1074;&#1072;&#1096;&#1091;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                &#1084;&#1080; &#1072;&#1073;&#1086;
                &#1074;&#1080;&#1076;&#1072;&#1083;&#1080;&#1084;&#1086;,
                &#1072;&#1073;&#1086;
                &#1079;&#1088;&#1086;&#1073;&#1080;&#1084;&#1086;
                &#1090;&#1072;&#1082;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1072;&#1085;&#1086;&#1085;&#1110;&#1084;&#1085;&#1086;&#1102;,
                &#1072;&#1073;&#1086;, &#1103;&#1082;&#1097;&#1086;
                &#1094;&#1077;
                &#1085;&#1077;&#1084;&#1086;&#1078;&#1083;&#1080;&#1074;&#1086;
                (&#1085;&#1072;&#1087;&#1088;&#1080;&#1082;&#1083;&#1072;&#1076;,
                &#1086;&#1089;&#1082;&#1110;&#1083;&#1100;&#1082;&#1080;
                &#1074;&#1072;&#1096;&#1072;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1072;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;
                &#1079;&#1073;&#1077;&#1088;&#1110;&#1075;&#1072;&#1083;&#1072;&#1089;&#1103;
                &#1074;
                &#1088;&#1077;&#1079;&#1077;&#1088;&#1074;&#1085;&#1080;&#1093;
                &#1072;&#1088;&#1093;&#1110;&#1074;&#1072;&#1093;),
                &#1084;&#1080;
                &#1073;&#1077;&#1079;&#1087;&#1077;&#1095;&#1085;&#1086;
                &#1073;&#1091;&#1076;&#1077;&#1084;&#1086;
                &#1079;&#1073;&#1077;&#1088;&#1110;&#1075;&#1072;&#1090;&#1080;
                &#1074;&#1072;&#1096;&#1091;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1090;&#1072;
                &#1110;&#1079;&#1086;&#1083;&#1102;&#1108;&#1084;&#1086;
                &#1111;&#1111; &#1074;&#1110;&#1076;
                &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1086;&#1111;
                &#1087;&#1086;&#1076;&#1072;&#1083;&#1100;&#1096;&#1086;&#1111;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1082;&#1080;,
                &#1076;&#1086;&#1082;&#1080; &#1085;&#1077;
                &#1089;&#1090;&#1072;&#1085;&#1077;
                &#1084;&#1086;&#1078;&#1083;&#1080;&#1074;&#1080;&#1084;
                &#1074;&#1080;&#1076;&#1072;&#1083;&#1077;&#1085;&#1085;&#1103;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                When we have no ongoing legitimate business need to process your
                personal information, we will either delete or anonymize such
                information, or, if this is not possible (for example, because
                your personal information has been stored in backup archives),
                then we will securely store your personal information and
                isolate it from any further processing until deletion is
                possible.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4" id="privacy_sec7">
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                7. &#1071;&#1050; &#1052;&#1048;
                &#1047;&#1040;&#1041;&#1045;&#1047;&#1055;&#1045;&#1063;&#1059;&#1028;&#1052;&#1054;
                &#1041;&#1045;&#1047;&#1055;&#1045;&#1050;&#1059;
                &#1042;&#1040;&#1064;&#1054;&#1031;
                &#1030;&#1053;&#1060;&#1054;&#1056;&#1052;&#1040;&#1062;&#1030;&#1031;?
              </span>
            </p>
          </td>
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">
                7. HOW DO WE KEEP YOUR INFORMATION SAFE?
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c2">
                &#1050;&#1086;&#1088;&#1086;&#1090;&#1082;&#1086;:{" "}
              </span>
              <span className="c11">
                &#1084;&#1080;
                &#1087;&#1088;&#1072;&#1075;&#1085;&#1077;&#1084;&#1086;
                &#1079;&#1072;&#1093;&#1080;&#1089;&#1090;&#1080;&#1090;&#1080;
                &#1074;&#1072;&#1096;&#1091;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1079;&#1072;
                &#1076;&#1086;&#1087;&#1086;&#1084;&#1086;&#1075;&#1086;&#1102;
                &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1080;
                &#1086;&#1088;&#1075;&#1072;&#1085;&#1110;&#1079;&#1072;&#1094;&#1110;&#1081;&#1085;&#1080;&#1093;
                &#1110;
                &#1090;&#1077;&#1093;&#1085;&#1110;&#1095;&#1085;&#1080;&#1093;
                &#1079;&#1072;&#1093;&#1086;&#1076;&#1110;&#1074;
                &#1073;&#1077;&#1079;&#1087;&#1077;&#1082;&#1080;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c10 c2">In Short: </span>
              <span className="c11 c10">
                We aim to protect your personal information through a system of
                organizational and technical security measures.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1052;&#1080;
                &#1079;&#1072;&#1087;&#1088;&#1086;&#1074;&#1072;&#1076;&#1080;&#1083;&#1080;
                &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1110;
                &#1090;&#1072; &#1088;&#1086;&#1079;&#1091;&#1084;&#1085;&#1110;
                &#1090;&#1077;&#1093;&#1085;&#1110;&#1095;&#1085;&#1110;
                &#1090;&#1072;
                &#1086;&#1088;&#1075;&#1072;&#1085;&#1110;&#1079;&#1072;&#1094;&#1110;&#1081;&#1085;&#1110;
                &#1079;&#1072;&#1093;&#1086;&#1076;&#1080;
                &#1073;&#1077;&#1079;&#1087;&#1077;&#1082;&#1080;,
                &#1089;&#1087;&#1088;&#1103;&#1084;&#1086;&#1074;&#1072;&#1085;&#1110;
                &#1085;&#1072; &#1079;&#1072;&#1093;&#1080;&#1089;&#1090;
                &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1086;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;,
                &#1103;&#1082;&#1091; &#1084;&#1080;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1108;&#1084;&#1086;.
                &#1054;&#1076;&#1085;&#1072;&#1082;,
                &#1085;&#1077;&#1079;&#1074;&#1072;&#1078;&#1072;&#1102;&#1095;&#1080;
                &#1085;&#1072; &#1085;&#1072;&#1096;&#1110;
                &#1079;&#1072;&#1093;&#1086;&#1076;&#1080;
                &#1073;&#1077;&#1079;&#1087;&#1077;&#1082;&#1080; &#1090;&#1072;
                &#1079;&#1091;&#1089;&#1080;&#1083;&#1083;&#1103;
                &#1097;&#1086;&#1076;&#1086;
                &#1079;&#1072;&#1093;&#1080;&#1089;&#1090;&#1091;
                &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;,
                &#1078;&#1086;&#1076;&#1085;&#1072;
                &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1072;
                &#1087;&#1077;&#1088;&#1077;&#1076;&#1072;&#1095;&#1072;
                &#1095;&#1077;&#1088;&#1077;&#1079;
                &#1030;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090;
                &#1072;&#1073;&#1086;
                &#1090;&#1077;&#1093;&#1085;&#1086;&#1083;&#1086;&#1075;&#1110;&#1103;
                &#1079;&#1073;&#1077;&#1088;&#1110;&#1075;&#1072;&#1085;&#1085;&#1103;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;
                &#1085;&#1077; &#1084;&#1086;&#1078;&#1077;
                &#1073;&#1091;&#1090;&#1080;
                &#1075;&#1072;&#1088;&#1072;&#1085;&#1090;&#1086;&#1074;&#1072;&#1085;&#1086;
                &#1085;&#1072; 100%
                &#1073;&#1077;&#1079;&#1087;&#1077;&#1095;&#1085;&#1086;&#1102;,
                &#1090;&#1086;&#1084;&#1091; &#1084;&#1080; &#1085;&#1077;
                &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1086;&#1073;&#1110;&#1094;&#1103;&#1090;&#1080;
                &#1072;&#1073;&#1086;
                &#1075;&#1072;&#1088;&#1072;&#1085;&#1090;&#1091;&#1074;&#1072;&#1090;&#1080;,
                &#1097;&#1086; &#1093;&#1072;&#1082;&#1077;&#1088;&#1080;,
                &#1082;&#1110;&#1073;&#1077;&#1088;&#1079;&#1083;&#1086;&#1095;&#1080;&#1085;&#1094;&#1110;
                &#1095;&#1080; &#1110;&#1085;&#1096;&#1110;
                &#1085;&#1077;&#1072;&#1074;&#1090;&#1086;&#1088;&#1080;&#1079;&#1086;&#1074;&#1072;&#1085;&#1110;
                &#1090;&#1088;&#1077;&#1090;&#1110;
                &#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1080; &#1085;&#1077;
                &#1084;&#1086;&#1078;&#1091;&#1090;&#1100;
                &#1087;&#1086;&#1088;&#1091;&#1096;&#1080;&#1090;&#1080;
                &#1085;&#1072;&#1096;&#1091;
                &#1073;&#1077;&#1079;&#1087;&#1077;&#1082;&#1091; &#1090;&#1072;
                &#1085;&#1077;&#1085;&#1072;&#1083;&#1077;&#1078;&#1085;&#1080;&#1084;
                &#1095;&#1080;&#1085;&#1086;&#1084;
                &#1079;&#1073;&#1080;&#1088;&#1072;&#1090;&#1080;,
                &#1086;&#1090;&#1088;&#1080;&#1084;&#1091;&#1074;&#1072;&#1090;&#1080;
                &#1076;&#1086;&#1089;&#1090;&#1091;&#1087;,
                &#1074;&#1080;&#1082;&#1088;&#1072;&#1076;&#1072;&#1090;&#1080;
                &#1072;&#1073;&#1086;
                &#1079;&#1084;&#1110;&#1085;&#1102;&#1074;&#1072;&#1090;&#1080;
                &#1074;&#1072;&#1096;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;.
                &#1053;&#1077;&#1079;&#1074;&#1072;&#1078;&#1072;&#1102;&#1095;&#1080;
                &#1085;&#1072; &#1090;&#1077;, &#1097;&#1086; &#1084;&#1080;
                &#1079;&#1088;&#1086;&#1073;&#1080;&#1084;&#1086;
                &#1074;&#1089;&#1077;
                &#1084;&#1086;&#1078;&#1083;&#1080;&#1074;&#1077;,
                &#1097;&#1086;&#1073;
                &#1079;&#1072;&#1093;&#1080;&#1089;&#1090;&#1080;&#1090;&#1080;
                &#1074;&#1072;&#1096;&#1091;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                &#1087;&#1077;&#1088;&#1077;&#1076;&#1072;&#1095;&#1072;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;
                &#1076;&#1086; &#1085;&#1072;&#1096;&#1080;&#1093;
                &#1057;&#1077;&#1088;&#1074;&#1110;&#1089;&#1110;&#1074;
                (&#1072; &nbsp;&#1090;&#1072;&#1082;&#1086;&#1078;
                &#1110;&#1079; &#1085;&#1080;&#1093;)
                &#1079;&#1076;&#1110;&#1081;&#1089;&#1085;&#1102;&#1108;&#1090;&#1100;&#1089;&#1103;
                &#1085;&#1072; &#1074;&#1072;&#1096;
                &#1074;&#1083;&#1072;&#1089;&#1085;&#1080;&#1081;
                &#1088;&#1080;&#1079;&#1080;&#1082;. &#1042;&#1080;
                &#1087;&#1086;&#1074;&#1080;&#1085;&#1085;&#1110;
                &#1086;&#1090;&#1088;&#1080;&#1084;&#1072;&#1090;&#1080;
                &#1076;&#1086;&#1089;&#1090;&#1091;&#1087; &#1076;&#1086;
                &#1057;&#1077;&#1088;&#1074;&#1110;&#1089;&#1110;&#1074;
                &#1083;&#1080;&#1096;&#1077; &#1074;
                &#1073;&#1077;&#1079;&#1087;&#1077;&#1095;&#1085;&#1086;&#1084;&#1091;
                &#1089;&#1077;&#1088;&#1077;&#1076;&#1086;&#1074;&#1080;&#1097;&#1110;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c16 c10">
                We have implemented appropriate and reasonable technical and
                organizational security measures designed to protect the
                security of any personal information we process. However,
                despite our safeguards and efforts to secure your information,
                no electronic transmission over the Internet or information
                storage technology can be guaranteed to be 100% secure, so we
                cannot promise or guarantee that hackers, cybercriminals, or
                other unauthorized third parties will not be able to defeat our
                security and improperly collect, access, steal, or modify your
                information. Although we will do our best to protect your
                personal information, transmission of personal information to
                and from our Services is at your own risk. You should only
                access the Services within a secure environment.
              </span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4" id="privacy_sec8">
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                8. &#1063;&#1048; &#1052;&#1048;
                &#1047;&#1041;&#1048;&#1056;&#1040;&#1028;&#1052;&#1054;
                &#1030;&#1053;&#1060;&#1054;&#1056;&#1052;&#1040;&#1062;&#1030;&#1070;
                &#1042;&#1030;&#1044;
                &#1053;&#1045;&#1055;&#1054;&#1042;&#1053;&#1054;&#1051;&#1030;&#1058;&#1053;&#1030;&#1061;?
              </span>
            </p>
          </td>
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">
                8. DO WE COLLECT INFORMATION FROM MINORS?
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c2">
                &#1050;&#1086;&#1088;&#1086;&#1090;&#1082;&#1086;:
              </span>
              <span className="c11">
                &nbsp;&#1084;&#1080;
                &#1089;&#1074;&#1110;&#1076;&#1086;&#1084;&#1086; &#1085;&#1077;
                &#1079;&#1073;&#1080;&#1088;&#1072;&#1108;&#1084;&#1086;
                &#1076;&#1072;&#1085;&#1110; &#1074;&#1110;&#1076;
                &#1076;&#1110;&#1090;&#1077;&#1081;
                &#1074;&#1110;&#1082;&#1086;&#1084; &#1076;&#1086; 18
                &#1088;&#1086;&#1082;&#1110;&#1074; &#1110; &#1085;&#1077;
                &#1087;&#1088;&#1086;&#1076;&#1072;&#1108;&#1084;&#1086;
                &#1111;&#1084; &#1076;&#1072;&#1085;&#1110;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c10 c2">In Short:</span>
              <span className="c11 c10">
                &nbsp;We do not knowingly collect data from or market to
                children under 18 years of age.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span>
                &#1052;&#1080; &#1089;&#1074;&#1110;&#1076;&#1086;&#1084;&#1086;
                &#1085;&#1077;
                &#1079;&#1073;&#1080;&#1088;&#1072;&#1108;&#1084;&#1086;
                &#1090;&#1072; &#1085;&#1077;
                &#1087;&#1088;&#1086;&#1076;&#1072;&#1108;&#1084;&#1086;
                &#1076;&#1072;&#1085;&#1110; &#1076;&#1110;&#1090;&#1103;&#1084;
                &#1074;&#1110;&#1082;&#1086;&#1084; &#1076;&#1086; 18
                &#1088;&#1086;&#1082;&#1110;&#1074;.
                &#1050;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1102;&#1095;&#1080;&#1089;&#1100;
                &#1057;&#1077;&#1088;&#1074;&#1110;&#1089;&#1072;&#1084;&#1080;,
                &#1074;&#1080;
                &#1087;&#1110;&#1076;&#1090;&#1074;&#1077;&#1088;&#1076;&#1078;&#1091;&#1108;&#1090;&#1077;,
                &#1097;&#1086; &#1074;&#1072;&#1084;
                &#1074;&#1080;&#1087;&#1086;&#1074;&#1085;&#1080;&#1083;&#1086;&#1089;&#1103;
                &#1087;&#1088;&#1080;&#1085;&#1072;&#1081;&#1084;&#1085;&#1110;
                18 &#1088;&#1086;&#1082;&#1110;&#1074;, &#1072;&#1073;&#1086;
                &#1097;&#1086; &#1074;&#1080; &#1108;
                &#1073;&#1072;&#1090;&#1100;&#1082;&#1086;&#1084;
                &#1072;&#1073;&#1086;
                &#1086;&#1087;&#1110;&#1082;&#1091;&#1085;&#1086;&#1084;
                &#1090;&#1072;&#1082;&#1086;&#1111;
                &#1085;&#1077;&#1087;&#1086;&#1074;&#1085;&#1086;&#1083;&#1110;&#1090;&#1085;&#1100;&#1086;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080; &#1090;&#1072;
                &#1087;&#1086;&#1075;&#1086;&#1076;&#1078;&#1091;&#1108;&#1090;&#1077;&#1089;&#1103;
                &#1085;&#1072;
                &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1072;&#1085;&#1085;&#1103;
                &#1057;&#1077;&#1088;&#1074;&#1110;&#1089;&#1110;&#1074;
                &#1085;&#1077;&#1087;&#1086;&#1074;&#1085;&#1086;&#1083;&#1110;&#1090;&#1085;&#1100;&#1086;&#1102;
                &#1086;&#1089;&#1086;&#1073;&#1086;&#1102;.
                &#1071;&#1082;&#1097;&#1086; &#1084;&#1080;
                &#1076;&#1110;&#1079;&#1085;&#1072;&#1108;&#1084;&#1086;&#1089;&#1103;,
                &#1097;&#1086; &#1073;&#1091;&#1083;&#1072;
                &#1079;&#1110;&#1073;&#1088;&#1072;&#1085;&#1072;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1072;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;
                &#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1074;&#1072;&#1095;&#1110;&#1074;
                &#1074;&#1110;&#1082;&#1086;&#1084; &#1076;&#1086; 18
                &#1088;&#1086;&#1082;&#1110;&#1074;, &#1084;&#1080;
                &#1076;&#1077;&#1072;&#1082;&#1090;&#1080;&#1074;&#1091;&#1108;&#1084;&#1086;
                &#1086;&#1073;&#1083;&#1110;&#1082;&#1086;&#1074;&#1080;&#1081;
                &#1079;&#1072;&#1087;&#1080;&#1089; &#1110;
                &#1074;&#1078;&#1080;&#1074;&#1077;&#1084;&#1086;
                &#1088;&#1086;&#1079;&#1091;&#1084;&#1085;&#1080;&#1093;
                &#1079;&#1072;&#1093;&#1086;&#1076;&#1110;&#1074;
                &#1076;&#1083;&#1103;
                &#1085;&#1077;&#1075;&#1072;&#1081;&#1085;&#1086;&#1075;&#1086;
                &#1074;&#1080;&#1076;&#1072;&#1083;&#1077;&#1085;&#1085;&#1103;
                &#1090;&#1072;&#1082;&#1080;&#1093;
                &#1076;&#1072;&#1085;&#1080;&#1093; &#1110;&#1079;
                &#1085;&#1072;&#1096;&#1080;&#1093;
                &#1079;&#1072;&#1087;&#1080;&#1089;&#1110;&#1074;.
                &#1071;&#1082;&#1097;&#1086; &#1074;&#1072;&#1084;
                &#1089;&#1090;&#1072;&#1083;&#1086;
                &#1074;&#1110;&#1076;&#1086;&#1084;&#1086; &#1087;&#1088;&#1086;
                &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1110;
                &#1076;&#1072;&#1085;&#1110;, &#1103;&#1082;&#1110;
                &#1084;&#1080; &#1084;&#1086;&#1075;&#1083;&#1080;
                &#1079;&#1110;&#1073;&#1088;&#1072;&#1090;&#1080;
                &#1074;&#1110;&#1076; &#1076;&#1110;&#1090;&#1077;&#1081;
                &#1074;&#1110;&#1082;&#1086;&#1084; &#1076;&#1086; 18
                &#1088;&#1086;&#1082;&#1110;&#1074;,
                &#1079;&#1074;&rsquo;&#1103;&#1078;&#1110;&#1090;&#1100;&#1089;&#1103;
                &#1079; &#1085;&#1072;&#1084;&#1080; &#1079;&#1072;
                &#1072;&#1076;&#1088;&#1077;&#1089;&#1086;&#1102;{" "}
              </span>
              <span className="c5">
                <a className="c15" href="mailto:support@it-sprout.org.ua">
                  support@it-sprout.org.ua
                </a>
              </span>
              <span className="c3">&nbsp;</span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                We do not knowingly solicit data from or market to children
                under 18 years of age. By using the Services, you represent that
                you are at least 18 or that you are the parent or guardian of
                such a minor and consent to such minor dependent&rsquo;s use of
                the Services. If we learn that personal information from users
                less than 18 years of age has been collected, we will deactivate
                the account and take reasonable measures to promptly delete such
                data from our records. If you become aware of any data we may
                have collected from children under age 18, please contact us at{" "}
              </span>
              <span className="c5">
                <a className="c15" href="mailto:support@it-sprout.org.ua">
                  support@it-sprout.org.ua
                </a>
              </span>
              <span>&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr className="c4" id="privacy_sec9">
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                9. &#1071;&#1050;&#1030; &#1042;&#1040;&#1064;&#1030;
                &#1055;&#1056;&#1040;&#1042;&#1040; &#1053;&#1040;
                &#1050;&#1054;&#1053;&#1060;&#1030;&#1044;&#1045;&#1053;&#1062;&#1030;&#1049;&#1053;&#1030;&#1057;&#1058;&#1068;?
              </span>
            </p>
          </td>
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">9. WHAT ARE YOUR PRIVACY RIGHTS?</span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c2">
                &#1050;&#1086;&#1088;&#1086;&#1090;&#1082;&#1086;:
              </span>
              <span className="c11">
                &nbsp;&#1091; &#1076;&#1077;&#1103;&#1082;&#1080;&#1093;
                &#1088;&#1077;&#1075;&#1110;&#1086;&#1085;&#1072;&#1093;,
                &#1085;&#1072;&#1087;&#1088;&#1080;&#1082;&#1083;&#1072;&#1076;
                &#1091;
                &#1028;&#1074;&#1088;&#1086;&#1087;&#1077;&#1081;&#1089;&#1100;&#1082;&#1110;&#1081;
                &#1077;&#1082;&#1086;&#1085;&#1086;&#1084;&#1110;&#1095;&#1085;&#1110;&#1081;
                &#1079;&#1086;&#1085;&#1110; (&#1028;&#1045;&#1047;),
                &#1057;&#1087;&#1086;&#1083;&#1091;&#1095;&#1077;&#1085;&#1086;&#1084;&#1091;
                &#1050;&#1086;&#1088;&#1086;&#1083;&#1110;&#1074;&#1089;&#1090;&#1074;&#1110;
                (&#1042;&#1077;&#1083;&#1080;&#1082;&#1086;&#1084;&#1091;
                &#1050;&#1086;&#1088;&#1086;&#1083;&#1110;&#1074;&#1089;&#1090;&#1074;&#1110;)
                &#1110; &#1050;&#1072;&#1085;&#1072;&#1076;&#1110;,
                &#1074;&#1080; &#1084;&#1072;&#1108;&#1090;&#1077;
                &#1087;&#1088;&#1072;&#1074;&#1072;, &#1103;&#1082;&#1110;
                &#1085;&#1072;&#1076;&#1072;&#1102;&#1090;&#1100;
                &#1074;&#1072;&#1084;
                &#1073;&#1110;&#1083;&#1100;&#1096;&#1080;&#1081;
                &#1076;&#1086;&#1089;&#1090;&#1091;&#1087; &#1076;&#1086;
                &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;
                &#1090;&#1072;
                &#1082;&#1086;&#1085;&#1090;&#1088;&#1086;&#1083;&#1100;
                &#1085;&#1072;&#1076; &#1085;&#1077;&#1102;. &#1042;&#1080;
                &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;
                &#1087;&#1077;&#1088;&#1077;&#1075;&#1083;&#1103;&#1085;&#1091;&#1090;&#1080;,
                &#1079;&#1084;&#1110;&#1085;&#1080;&#1090;&#1080;
                &#1072;&#1073;&#1086;
                &#1087;&#1088;&#1080;&#1087;&#1080;&#1085;&#1080;&#1090;&#1080;
                &#1076;&#1110;&#1102; &#1089;&#1074;&#1086;&#1075;&#1086;
                &#1086;&#1073;&#1083;&#1110;&#1082;&#1086;&#1074;&#1086;&#1075;&#1086;
                &#1079;&#1072;&#1087;&#1080;&#1089;&#1091; &#1074;
                &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1080;&#1081;
                &#1095;&#1072;&#1089;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c10 c2">In Short:</span>
              <span className="c21 c11 c10">
                &nbsp;In some regions, such as the European Economic Area (EEA),
                United Kingdom (UK), and Canada, you have rights that allow you
                greater access to and control over your personal information.
                You may review, change, or terminate your account at any time.
              </span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1059; &#1076;&#1077;&#1103;&#1082;&#1080;&#1093;
                &#1088;&#1077;&#1075;&#1110;&#1086;&#1085;&#1072;&#1093;
                (&#1085;&#1072;&#1087;&#1088;&#1080;&#1082;&#1083;&#1072;&#1076;,
                &nbsp;&#1042;&#1077;&#1083;&#1080;&#1082;&#1072;
                &#1041;&#1088;&#1080;&#1090;&#1072;&#1085;&#1110;&#1103;
                &#1050;&#1072;&#1085;&#1072;&#1076;&#1072;) &#1074;&#1080;
                &#1084;&#1072;&#1108;&#1090;&#1077;
                &#1087;&#1077;&#1074;&#1085;&#1110;
                &#1087;&#1088;&#1072;&#1074;&#1072;
                &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;
                &#1076;&#1086; &#1095;&#1080;&#1085;&#1085;&#1086;&#1075;&#1086;
                &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1072;
                &#1087;&#1088;&#1086; &#1079;&#1072;&#1093;&#1080;&#1089;&#1090;
                &#1076;&#1072;&#1085;&#1080;&#1093;. &#1062;&#1077;
                &#1084;&#1086;&#1078;&#1077;
                &#1074;&#1082;&#1083;&#1102;&#1095;&#1072;&#1090;&#1080;
                &#1087;&#1088;&#1072;&#1074;&#1086; (i)
                &#1074;&#1080;&#1084;&#1072;&#1075;&#1072;&#1090;&#1080;
                &#1076;&#1086;&#1089;&#1090;&#1091;&#1087;&#1091; &#1090;&#1072;
                &#1086;&#1090;&#1088;&#1080;&#1084;&#1072;&#1085;&#1085;&#1103;
                &#1082;&#1086;&#1087;&#1110;&#1111;
                &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;,
                (ii) &#1074;&#1080;&#1084;&#1072;&#1075;&#1072;&#1090;&#1080;
                &#1074;&#1080;&#1087;&#1088;&#1072;&#1074;&#1083;&#1077;&#1085;&#1085;&#1103;
                &#1072;&#1073;&#1086;
                &#1074;&#1080;&#1076;&#1072;&#1083;&#1077;&#1085;&#1085;&#1103;;
                (iii)
                &#1079;&#1072;&#1073;&#1086;&#1088;&#1086;&#1085;&#1103;&#1090;&#1080;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1082;&#1091;
                &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;,
                &#1090;&#1086;&#1097;&#1086;. &nbsp;&#1047;&#1072;
                &#1087;&#1077;&#1074;&#1085;&#1080;&#1093;
                &#1086;&#1073;&#1089;&#1090;&#1072;&#1074;&#1080;&#1085;
                &#1074;&#1080; &#1090;&#1072;&#1082;&#1086;&#1078;
                &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;
                &#1084;&#1072;&#1090;&#1080; &#1087;&#1088;&#1072;&#1074;&#1086;
                &#1079;&#1072;&#1087;&#1077;&#1088;&#1077;&#1095;&#1080;&#1090;&#1080;
                &#1087;&#1088;&#1086;&#1090;&#1080;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1082;&#1080;
                &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;.
                &#1042;&#1080; &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;
                &#1079;&#1088;&#1086;&#1073;&#1080;&#1090;&#1080;
                &#1090;&#1072;&#1082;&#1080;&#1081;
                &#1079;&#1072;&#1087;&#1080;&#1090;,
                &#1079;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1074;&#1096;&#1080;&#1089;&#1100;
                &#1079; &#1085;&#1072;&#1084;&#1080;,
                &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1102;&#1095;&#1080;
                &#1082;&#1086;&#1085;&#1090;&#1072;&#1082;&#1090;&#1085;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                &#1085;&#1072;&#1074;&#1077;&#1076;&#1077;&#1085;&#1091; &#1074;
                &#1088;&#1086;&#1079;&#1076;&#1110;&#1083;&#1110;
                &laquo;&#1071;&#1050; &#1042;&#1048;
                &#1052;&#1054;&#1046;&#1045;&#1058;&#1045;
                &#1047;&#1042;&rsquo;&#1071;&#1047;&#1040;&#1058;&#1048;&#1057;&#1071;
                &#1047; &#1053;&#1040;&#1052;&#1048;
                &#1065;&#1054;&#1044;&#1054; &#1062;&#1030;&#1028;&#1031;
                &#1055;&#1054;&#1051;&#1030;&#1058;&#1048;&#1050;&#1048;
                &#1050;&#1054;&#1053;&#1060;&#1030;&#1044;&#1045;&#1053;&#1062;&#1030;&#1049;&#1053;&#1054;&#1057;&#1058;&#1030;?&raquo;
                &#1085;&#1080;&#1078;&#1095;&#1077;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                In some regions (like the EEA, UK, and Canada), you have certain
                rights under applicable data protection laws. These may include
                the right (i) to request access and obtain a copy of your
                personal information, (ii) to request rectification or erasure;
                (iii) to restrict the processing of your personal information;
                and (iv) if applicable, to data portability. In certain
                circumstances, you may also have the right to object to the
                processing of your personal information. You can make such a
                request by contacting us by using the contact details provided
                in the section &quot;HOW CAN YOU CONTACT US ABOUT THIS
                NOTICE?&quot; below.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1052;&#1080;
                &#1088;&#1086;&#1079;&#1075;&#1083;&#1103;&#1085;&#1077;&#1084;&#1086;
                &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1080;&#1081;
                &#1079;&#1072;&#1087;&#1080;&#1090; &#1110;
                &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1084;&#1086;
                &#1085;&#1072; &#1085;&#1100;&#1086;&#1075;&#1086;
                &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;
                &#1076;&#1086; &#1095;&#1080;&#1085;&#1085;&#1086;&#1075;&#1086;
                &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1072;
                &#1087;&#1088;&#1086; &#1079;&#1072;&#1093;&#1080;&#1089;&#1090;
                &#1076;&#1072;&#1085;&#1080;&#1093;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                We will consider and act upon any request in accordance with
                applicable data protection laws.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c5 c2">
                &#1042;&#1110;&#1076;&#1082;&#1083;&#1080;&#1082;&#1072;&#1085;&#1085;&#1103;
                &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1079;&#1075;&#1086;&#1076;&#1080;:
              </span>
              <span className="c3">
                &nbsp;&#1103;&#1082;&#1097;&#1086; &#1084;&#1080;
                &#1087;&#1086;&#1082;&#1083;&#1072;&#1076;&#1072;&#1108;&#1084;&#1086;&#1089;&#1103;
                &#1085;&#1072; &#1074;&#1072;&#1096;&#1091;
                &#1079;&#1075;&#1086;&#1076;&#1091; &#1085;&#1072;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1082;&#1091;
                &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;,
                &#1103;&#1082;&#1072; &#1084;&#1086;&#1078;&#1077;
                &#1073;&#1091;&#1090;&#1080;
                &#1087;&#1088;&#1103;&#1084;&#1086;&#1102;
                &#1090;&#1072;/&#1072;&#1073;&#1086;
                &#1085;&#1077;&#1087;&#1088;&#1103;&#1084;&#1086;&#1102;
                &#1079;&#1075;&#1086;&#1076;&#1086;&#1102;
                &#1079;&#1072;&#1083;&#1077;&#1078;&#1085;&#1086;
                &#1074;&#1110;&#1076;
                &#1095;&#1080;&#1085;&#1085;&#1086;&#1075;&#1086;
                &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1072;,
                &#1074;&#1080; &#1084;&#1072;&#1108;&#1090;&#1077;
                &#1087;&#1088;&#1072;&#1074;&#1086;
                &#1074;&#1110;&#1076;&#1082;&#1083;&#1080;&#1082;&#1072;&#1090;&#1080;
                &#1089;&#1074;&#1086;&#1102; &#1079;&#1075;&#1086;&#1076;&#1091;
                &#1074;
                &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1080;&#1081;
                &#1095;&#1072;&#1089;. &#1042;&#1080;
                &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;
                &#1074;&#1110;&#1076;&#1082;&#1083;&#1080;&#1082;&#1072;&#1090;&#1080;
                &#1089;&#1074;&#1086;&#1102; &#1079;&#1075;&#1086;&#1076;&#1091;
                &#1074;
                &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1080;&#1081;
                &#1095;&#1072;&#1089;,
                &#1079;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1074;&#1096;&#1080;&#1089;&#1100;
                &#1079; &#1085;&#1072;&#1084;&#1080;,
                &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1102;&#1095;&#1080;
                &#1082;&#1086;&#1085;&#1090;&#1072;&#1082;&#1090;&#1085;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                &#1085;&#1072;&#1074;&#1077;&#1076;&#1077;&#1085;&#1091; &#1074;
                &#1088;&#1086;&#1079;&#1076;&#1110;&#1083;&#1110;
                &laquo;&#1071;&#1050; &#1042;&#1048;
                &#1052;&#1054;&#1046;&#1045;&#1058;&#1045;
                &#1047;&#1042;&rsquo;&#1071;&#1047;&#1040;&#1058;&#1048;&#1057;&#1071;
                &#1047; &#1053;&#1040;&#1052;&#1048;
                &#1065;&#1054;&#1044;&#1054; &#1062;&#1030;&#1028;&#1031;
                &#1055;&#1054;&#1051;&#1030;&#1058;&#1048;&#1050;&#1048;
                &#1050;&#1054;&#1053;&#1060;&#1030;&#1044;&#1045;&#1053;&#1062;&#1030;&#1049;&#1053;&#1054;&#1057;&#1058;&#1030;?&raquo;
                &#1085;&#1080;&#1078;&#1095;&#1077;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c5 c10 c2">Withdrawing your consent:</span>
              <span className="c10">
                &nbsp;If we are relying on your consent to process your personal
                information, which may be express and/or implied consent
                depending on the applicable law, you have the right to withdraw
                your consent at any time. You can withdraw your consent at any
                time by contacting us by using the contact details provided in
                the section &quot;HOW CAN YOU CONTACT US ABOUT THIS PRIVACY
                POLICY?&quot; below.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1054;&#1076;&#1085;&#1072;&#1082;
                &#1079;&#1072;&#1091;&#1074;&#1072;&#1078;&#1090;&#1077;,
                &#1097;&#1086; &#1094;&#1077; &#1085;&#1077;
                &#1074;&#1087;&#1083;&#1080;&#1085;&#1077; &#1085;&#1072;
                &#1079;&#1072;&#1082;&#1086;&#1085;&#1085;&#1110;&#1089;&#1090;&#1100;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1082;&#1080; &#1076;&#1086;
                &#1111;&#1111;
                &#1074;&#1110;&#1076;&#1082;&#1083;&#1080;&#1082;&#1072;&#1085;&#1085;&#1103;,
                &#1072; &#1090;&#1072;&#1082;&#1086;&#1078;,
                &#1103;&#1082;&#1097;&#1086; &#1094;&#1077;
                &#1076;&#1086;&#1079;&#1074;&#1086;&#1083;&#1103;&#1108;
                &#1095;&#1080;&#1085;&#1085;&#1077;
                &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1086;,
                &#1094;&#1077; &#1085;&#1077;
                &#1074;&#1087;&#1083;&#1080;&#1085;&#1077; &#1085;&#1072;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1082;&#1091;
                &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;,
                &#1103;&#1082;&#1072;
                &#1074;&#1080;&#1082;&#1086;&#1085;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
                &#1085;&#1072;
                &#1087;&#1110;&#1076;&#1089;&#1090;&#1072;&#1074;&#1110;
                &#1079;&#1072;&#1082;&#1086;&#1085;&#1085;&#1086;&#1111;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1082;&#1080;,
                &#1082;&#1088;&#1110;&#1084;
                &#1079;&#1075;&#1086;&#1076;&#1080;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                However, please note that this will not affect the lawfulness of
                the processing before its withdrawal nor, when applicable law
                allows, will it affect the processing of your personal
                information conducted in reliance on lawful processing grounds
                other than consent.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c5 c2">
                &#1042;&#1110;&#1076;&#1084;&#1086;&#1074;&#1072;
                &#1074;&#1110;&#1076;
                &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;&#1086;&#1074;&#1080;&#1093;
                &#1110;
                &#1088;&#1077;&#1082;&#1083;&#1072;&#1084;&#1085;&#1080;&#1093;
                &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1077;&#1085;&#1100;:{" "}
              </span>
              <span className="c3">
                &#1042;&#1080; &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;
                &#1073;&#1091;&#1076;&#1100;-&#1082;&#1086;&#1083;&#1080;
                &#1089;&#1082;&#1072;&#1089;&#1091;&#1074;&#1072;&#1090;&#1080;
                &#1087;&#1110;&#1076;&#1087;&#1080;&#1089;&#1082;&#1091;
                &#1085;&#1072; &#1085;&#1072;&#1096;&#1110;
                &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;&#1086;&#1074;&#1110;
                &#1090;&#1072;
                &#1088;&#1077;&#1082;&#1083;&#1072;&#1084;&#1085;&#1110;
                &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1077;&#1085;&#1085;&#1103;,
                &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1074;&#1096;&#1080;
                &laquo;&#1057;&#1058;&#1054;&#1055;&raquo; &#1072;&#1073;&#1086;
                &laquo;&#1057;&#1050;&#1040;&#1057;&#1059;&#1042;&#1040;&#1058;&#1048;
                &#1055;&#1030;&#1044;&#1055;&#1048;&#1057;&#1050;&#1059;&raquo;
                &#1085;&#1072;
                SMS-&#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1077;&#1085;&#1085;&#1103;,
                &#1103;&#1082;&#1110; &#1084;&#1080;
                &#1085;&#1072;&#1076;&#1089;&#1080;&#1083;&#1072;&#1108;&#1084;&#1086;,
                &#1072;&#1073;&#1086;
                &#1079;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1074;&#1096;&#1080;&#1089;&#1100;
                &#1079; &#1085;&#1072;&#1084;&#1080;,
                &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1102;&#1095;&#1080;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                &#1085;&#1072;&#1074;&#1077;&#1076;&#1077;&#1085;&#1091; &#1074;
                &#1088;&#1086;&#1079;&#1076;&#1110;&#1083;&#1110;
                &laquo;&#1071;&#1050; &#1063;&#1048;
                &#1052;&#1054;&#1046;&#1045;&#1058;&#1045; &#1042;&#1048;
                &#1047;&#1042;&#39;&#1071;&#1047;&#1040;&#1058;&#1048;&#1057;&#1071;
                &#1047; &#1053;&#1040;&#1052;&#1048;
                &#1065;&#1054;&#1044;&#1054; &#1062;&#1030;&#1028;&#1031;
                &#1055;&#1054;&#1051;&#1030;&#1058;&#1048;&#1050;&#1048;
                &#1050;&#1054;&#1053;&#1060;&#1030;&#1044;&#1045;&#1053;&#1062;&#1030;&#1049;&#1053;&#1054;&#1057;&#1058;&#1030;?&quot;
                &#1085;&#1080;&#1078;&#1095;&#1077;.
                &#1055;&#1110;&#1089;&#1083;&#1103;
                &#1094;&#1100;&#1086;&#1075;&#1086; &#1074;&#1072;&#1089;
                &#1073;&#1091;&#1076;&#1077;
                &#1074;&#1080;&#1076;&#1072;&#1083;&#1077;&#1085;&#1086; &#1079;
                &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;&#1086;&#1074;&#1080;&#1093;
                &#1089;&#1087;&#1080;&#1089;&#1082;&#1110;&#1074;.
                &#1054;&#1076;&#1085;&#1072;&#1082; &#1084;&#1080;
                &#1074;&#1089;&#1077; &#1086;&#1076;&#1085;&#1086;
                &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1089;&#1087;&#1110;&#1083;&#1082;&#1091;&#1074;&#1072;&#1090;&#1080;&#1089;&#1103;
                &#1079; &#1074;&#1072;&#1084;&#1080;,
                &#1085;&#1072;&#1087;&#1088;&#1080;&#1082;&#1083;&#1072;&#1076;,
                &#1097;&#1086;&#1073;
                &#1085;&#1072;&#1076;&#1089;&#1080;&#1083;&#1072;&#1090;&#1080;
                &#1074;&#1072;&#1084;
                &#1087;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1085;&#1110;
                &#1079;
                &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;&#1072;&#1084;&#1080;
                &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1077;&#1085;&#1085;&#1103;,
                &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1110;
                &#1076;&#1083;&#1103;
                &#1072;&#1076;&#1084;&#1110;&#1085;&#1110;&#1089;&#1090;&#1088;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;
                &#1090;&#1072;
                &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1072;&#1085;&#1085;&#1103;
                &#1074;&#1072;&#1096;&#1086;&#1075;&#1086;
                &#1086;&#1073;&#1083;&#1110;&#1082;&#1086;&#1074;&#1086;&#1075;&#1086;
                &#1079;&#1072;&#1087;&#1080;&#1089;&#1091;,
                &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1072;&#1090;&#1080;
                &#1085;&#1072; &#1079;&#1072;&#1087;&#1080;&#1090;&#1080;
                &#1097;&#1086;&#1076;&#1086;
                &#1086;&#1073;&#1089;&#1083;&#1091;&#1075;&#1086;&#1074;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;
                &#1072;&#1073;&#1086; &#1076;&#1083;&#1103;
                &#1110;&#1085;&#1096;&#1080;&#1093;
                &#1085;&#1077;&#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;&#1086;&#1074;&#1080;&#1093;
                &#1094;&#1110;&#1083;&#1077;&#1081;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c5 c10 c2">
                Opting out of marketing and promotional communications:{" "}
              </span>
              <span className="c10">
                You can unsubscribe from our marketing and promotional
                communications at any time by replying &quot;STOP&quot; or
                &quot;UNSUBSCRIBE&quot; to the SMS messages that we send, or by
                contacting us using the details provided in the section
                &quot;HOW CAN YOU CONTACT US ABOUT THIS NOTICE?&quot; below. You
                will then be removed from the marketing lists. However, we may
                still communicate with you &mdash; for example, to send you
                service-related messages that are necessary for the
                administration and use of your account, to respond to service
                requests, or for other non-marketing purposes.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                &#1030;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;
                &#1087;&#1088;&#1086;
                &#1086;&#1073;&#1083;&#1110;&#1082;&#1086;&#1074;&#1080;&#1081;
                &#1079;&#1072;&#1087;&#1080;&#1089;
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">Account Information</span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1071;&#1082;&#1097;&#1086; &#1074;&#1080; &#1074;
                &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1080;&#1081;
                &#1084;&#1086;&#1084;&#1077;&#1085;&#1090;
                &#1079;&#1072;&#1093;&#1086;&#1095;&#1077;&#1090;&#1077;
                &#1087;&#1077;&#1088;&#1077;&#1075;&#1083;&#1103;&#1085;&#1091;&#1090;&#1080;
                &#1095;&#1080; &#1079;&#1084;&#1110;&#1085;&#1080;&#1090;&#1080;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1091; &#1089;&#1074;&#1086;&#1108;&#1084;&#1091;
                &#1086;&#1073;&#1083;&#1110;&#1082;&#1086;&#1074;&#1086;&#1084;&#1091;
                &#1079;&#1072;&#1087;&#1080;&#1089;&#1110; &#1095;&#1080;
                &#1087;&#1088;&#1080;&#1087;&#1080;&#1085;&#1080;&#1090;&#1080;
                &#1076;&#1110;&#1102; &#1074;&#1072;&#1096;&#1086;&#1075;&#1086;
                &#1086;&#1073;&#1083;&#1110;&#1082;&#1086;&#1074;&#1086;&#1075;&#1086;
                &#1079;&#1072;&#1087;&#1080;&#1089;&#1091;, &#1074;&#1080;
                &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;:
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                If you would at any time like to review or change the
                information in your account or terminate your account, you can:
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_l8ulv694d2eq-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1047;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1090;&#1080;&#1089;&#1103;
                  &#1079; &#1085;&#1072;&#1084;&#1080;,
                  &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1102;&#1095;&#1080;
                  &#1085;&#1072;&#1076;&#1072;&#1085;&#1091;
                  &#1082;&#1086;&#1085;&#1090;&#1072;&#1082;&#1090;&#1085;&#1091;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;.
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_47e693rewf9o-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  Contact us using the contact information provided.
                </span>
              </li>
            </ul>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1055;&#1110;&#1089;&#1083;&#1103;
                &#1074;&#1072;&#1096;&#1086;&#1075;&#1086;
                &#1079;&#1072;&#1087;&#1080;&#1090;&#1091; &#1085;&#1072;
                &#1087;&#1088;&#1080;&#1087;&#1080;&#1085;&#1077;&#1085;&#1085;&#1103;
                &#1076;&#1110;&#1111; &#1074;&#1072;&#1096;&#1086;&#1075;&#1086;
                &#1086;&#1073;&#1083;&#1110;&#1082;&#1086;&#1074;&#1086;&#1075;&#1086;
                &#1079;&#1072;&#1087;&#1080;&#1089;&#1091; &#1084;&#1080;
                &#1076;&#1077;&#1072;&#1082;&#1090;&#1080;&#1074;&#1091;&#1108;&#1084;&#1086;
                &#1072;&#1073;&#1086;
                &#1074;&#1080;&#1076;&#1072;&#1083;&#1080;&#1084;&#1086;
                &#1074;&#1072;&#1096;
                &#1086;&#1073;&#1083;&#1110;&#1082;&#1086;&#1074;&#1080;&#1081;
                &#1079;&#1072;&#1087;&#1080;&#1089; &#1110;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1079; &#1085;&#1072;&#1096;&#1080;&#1093;
                &#1072;&#1082;&#1090;&#1080;&#1074;&#1085;&#1080;&#1093;
                &#1073;&#1072;&#1079; &#1076;&#1072;&#1085;&#1080;&#1093;.
                &#1054;&#1076;&#1085;&#1072;&#1082; &#1084;&#1080;
                &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1079;&#1073;&#1077;&#1088;&#1110;&#1075;&#1072;&#1090;&#1080;
                &#1087;&#1077;&#1074;&#1085;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1074; &#1085;&#1072;&#1096;&#1080;&#1093;
                &#1092;&#1072;&#1081;&#1083;&#1072;&#1093;,
                &#1097;&#1086;&#1073;
                &#1079;&#1072;&#1087;&#1086;&#1073;&#1110;&#1075;&#1090;&#1080;
                &#1096;&#1072;&#1093;&#1088;&#1072;&#1081;&#1089;&#1090;&#1074;&#1091;,
                &#1091;&#1089;&#1091;&#1085;&#1091;&#1090;&#1080;
                &#1087;&#1088;&#1086;&#1073;&#1083;&#1077;&#1084;&#1080;,
                &#1076;&#1086;&#1087;&#1086;&#1084;&#1086;&#1075;&#1090;&#1080;
                &#1074;
                &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1080;&#1093;
                &#1088;&#1086;&#1079;&#1089;&#1083;&#1110;&#1076;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;&#1093;,
                &#1079;&#1072;&#1073;&#1077;&#1079;&#1087;&#1077;&#1095;&#1080;&#1090;&#1080;
                &#1076;&#1086;&#1090;&#1088;&#1080;&#1084;&#1072;&#1085;&#1085;&#1103;
                &#1085;&#1072;&#1096;&#1080;&#1093;
                &#1102;&#1088;&#1080;&#1076;&#1080;&#1095;&#1085;&#1080;&#1093;
                &#1091;&#1084;&#1086;&#1074; &#1110;/&#1072;&#1073;&#1086;
                &#1076;&#1086;&#1090;&#1088;&#1080;&#1084;&#1072;&#1085;&#1085;&#1103;
                &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1080;&#1093;
                &#1102;&#1088;&#1080;&#1076;&#1080;&#1095;&#1085;&#1080;&#1093;
                &#1074;&#1080;&#1084;&#1086;&#1075;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                Upon your request to terminate your account, we will deactivate
                or delete your account and information from our active
                databases. However, we may retain some information in our files
                to prevent fraud, troubleshoot problems, assist with any
                investigations, enforce our legal terms and/or comply with
                applicable legal requirements.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c5 c2">
                &#1060;&#1072;&#1081;&#1083;&#1080; cookie &#1090;&#1072;
                &#1087;&#1086;&#1076;&#1110;&#1073;&#1085;&#1110;
                &#1090;&#1077;&#1093;&#1085;&#1086;&#1083;&#1086;&#1075;&#1110;&#1111;:
              </span>
              <span className="c3">
                &nbsp;&#1073;&#1110;&#1083;&#1100;&#1096;&#1110;&#1089;&#1090;&#1100;
                &#1074;&#1077;&#1073;-&#1073;&#1088;&#1072;&#1091;&#1079;&#1077;&#1088;&#1110;&#1074;
                &#1085;&#1072;&#1083;&#1072;&#1096;&#1090;&#1086;&#1074;&#1072;&#1085;&#1086;
                &#1085;&#1072; &#1087;&#1088;&#1080;&#1081;&#1086;&#1084;
                &#1092;&#1072;&#1081;&#1083;&#1110;&#1074; cookie &#1079;&#1072;
                &#1079;&#1072;&#1084;&#1086;&#1074;&#1095;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;&#1084;.
                &#1071;&#1082;&#1097;&#1086; &#1074;&#1080;
                &#1073;&#1072;&#1078;&#1072;&#1108;&#1090;&#1077;,
                &#1074;&#1080;
                &#1079;&#1072;&#1079;&#1074;&#1080;&#1095;&#1072;&#1081;
                &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;
                &#1085;&#1072;&#1083;&#1072;&#1096;&#1090;&#1091;&#1074;&#1072;&#1090;&#1080;
                &#1089;&#1074;&#1110;&#1081;
                &#1073;&#1088;&#1072;&#1091;&#1079;&#1077;&#1088; &#1085;&#1072;
                &#1074;&#1080;&#1076;&#1072;&#1083;&#1077;&#1085;&#1085;&#1103;
                &#1090;&#1072;
                &#1074;&#1110;&#1076;&#1093;&#1080;&#1083;&#1077;&#1085;&#1085;&#1103;
                &#1092;&#1072;&#1081;&#1083;&#1110;&#1074; cookie.
                &#1071;&#1082;&#1097;&#1086; &#1074;&#1080;
                &#1074;&#1080;&#1088;&#1110;&#1096;&#1080;&#1090;&#1077;
                &#1074;&#1080;&#1076;&#1072;&#1083;&#1080;&#1090;&#1080;
                &#1072;&#1073;&#1086;
                &#1074;&#1110;&#1076;&#1093;&#1080;&#1083;&#1080;&#1090;&#1080;
                &#1092;&#1072;&#1081;&#1083;&#1080; cookie, &#1094;&#1077;
                &#1084;&#1086;&#1078;&#1077;
                &#1074;&#1087;&#1083;&#1080;&#1085;&#1091;&#1090;&#1080;
                &#1085;&#1072; &#1087;&#1077;&#1074;&#1085;&#1110;
                &#1092;&#1091;&#1085;&#1082;&#1094;&#1110;&#1111;
                &#1072;&#1073;&#1086; &#1089;&#1083;&#1091;&#1078;&#1073;&#1080;
                &#1085;&#1072;&#1096;&#1080;&#1093;
                &#1057;&#1077;&#1088;&#1074;&#1110;&#1089;&#1110;&#1074;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c5 c10 c2">
                Cookies and similar technologies:
              </span>
              <span className="c16 c10">
                &nbsp;Most Web browsers are set to accept cookies by default. If
                you prefer, you can usually choose to set your browser to remove
                cookies and to reject cookies. If you choose to remove cookies
                or reject cookies, this could affect certain features or
                services of our Services.{" "}
              </span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span>
                &#1071;&#1082;&#1097;&#1086; &#1091; &#1074;&#1072;&#1089;
                &#1108;
                &#1079;&#1072;&#1087;&#1080;&#1090;&#1072;&#1085;&#1085;&#1103;
                &#1095;&#1080;
                &#1082;&#1086;&#1084;&#1077;&#1085;&#1090;&#1072;&#1088;&#1110;
                &#1097;&#1086;&#1076;&#1086; &#1074;&#1072;&#1096;&#1080;&#1093;
                &#1087;&#1088;&#1072;&#1074; &#1085;&#1072;
                &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1110;&#1089;&#1090;&#1100;,
                &#1074;&#1080; &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;
                &#1085;&#1072;&#1076;&#1110;&#1089;&#1083;&#1072;&#1090;&#1080;
                &#1085;&#1072;&#1084;
                &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1080;&#1081;
                &#1083;&#1080;&#1089;&#1090; &#1085;&#1072;
                &#1072;&#1076;&#1088;&#1077;&#1089;&#1091;{" "}
              </span>
              <span className="c5">
                <a className="c15" href="mailto:support@it-sprout.org.ua">
                  support@it-sprout.org.ua
                </a>
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c16 c10">
                If you have questions or comments about your privacy rights, you
                may email us at{" "}
              </span>
            </p>
            <p className="c8">
              <span className="c5">
                <a className="c15" href="mailto:support@it-sprout.org.ua">
                  support@it-sprout.org.ua
                </a>
              </span>
              <span>&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr className="c4" id="privacy_sec10">
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                10. &#1045;&#1051;&#1045;&#1052;&#1045;&#1053;&#1058;&#1048;
                &#1050;&#1045;&#1056;&#1059;&#1042;&#1040;&#1053;&#1053;&#1071;
                &#1060;&#1059;&#1053;&#1050;&#1062;&#1030;&#1071;&#1052;&#1048;
                &#1053;&#1045;
                &#1042;&#1030;&#1044;&#1057;&#1058;&#1045;&#1046;&#1059;&#1042;&#1040;&#1058;&#1048;
              </span>
            </p>
          </td>
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">
                10. CONTROLS FOR DO-NOT-TRACK FEATURES
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1041;&#1110;&#1083;&#1100;&#1096;&#1110;&#1089;&#1090;&#1100;
                &#1074;&#1077;&#1073;-&#1073;&#1088;&#1072;&#1091;&#1079;&#1077;&#1088;&#1110;&#1074;
                &#1110; &#1076;&#1077;&#1103;&#1082;&#1110;
                &#1084;&#1086;&#1073;&#1110;&#1083;&#1100;&#1085;&#1110;
                &#1086;&#1087;&#1077;&#1088;&#1072;&#1094;&#1110;&#1081;&#1085;&#1110;
                &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1080; &#1090;&#1072;
                &#1087;&#1088;&#1086;&#1075;&#1088;&#1072;&#1084;&#1080;
                &#1076;&#1083;&#1103;
                &#1084;&#1086;&#1073;&#1110;&#1083;&#1100;&#1085;&#1080;&#1093;
                &#1087;&#1088;&#1080;&#1089;&#1090;&#1088;&#1086;&#1111;&#1074;
                &#1074;&#1082;&#1083;&#1102;&#1095;&#1072;&#1102;&#1090;&#1100;
                &#1092;&#1091;&#1085;&#1082;&#1094;&#1110;&#1102;
                &#1079;&#1072;&#1073;&#1086;&#1088;&#1086;&#1085;&#1080;
                &#1074;&#1110;&#1076;&#1089;&#1090;&#1077;&#1078;&#1077;&#1085;&#1085;&#1103;
                (&laquo;DNT&raquo; Do not Track). &#1053;&#1072;
                &#1076;&#1072;&#1085;&#1086;&#1084;&#1091;
                &#1077;&#1090;&#1072;&#1087;&#1110; &#1085;&#1077;
                &#1088;&#1086;&#1079;&#1088;&#1086;&#1073;&#1083;&#1077;&#1085;&#1086;
                &#1108;&#1076;&#1080;&#1085;&#1086;&#1075;&#1086;
                &#1090;&#1077;&#1093;&#1085;&#1086;&#1083;&#1086;&#1075;&#1110;&#1095;&#1085;&#1086;&#1075;&#1086;
                &#1089;&#1090;&#1072;&#1085;&#1076;&#1072;&#1088;&#1090;&#1091;
                &#1076;&#1083;&#1103;
                &#1088;&#1086;&#1079;&#1087;&#1110;&#1079;&#1085;&#1072;&#1074;&#1072;&#1085;&#1085;&#1103;
                &#1090;&#1072;
                &#1088;&#1077;&#1072;&#1083;&#1110;&#1079;&#1072;&#1094;&#1110;&#1111;
                &#1089;&#1080;&#1075;&#1085;&#1072;&#1083;&#1110;&#1074; DNT.
                &#1058;&#1072;&#1082;&#1080;&#1084;
                &#1095;&#1080;&#1085;&#1086;&#1084;,
                &#1085;&#1072;&#1088;&#1072;&#1079;&#1110; &#1084;&#1080;
                &#1085;&#1077;
                &#1088;&#1077;&#1072;&#1075;&#1091;&#1108;&#1084;&#1086;
                &#1085;&#1072; &#1089;&#1080;&#1075;&#1085;&#1072;&#1083;&#1080;
                &#1073;&#1088;&#1072;&#1091;&#1079;&#1077;&#1088;&#1072; DNT
                &#1072;&#1073;&#1086;
                &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1080;&#1081;
                &#1110;&#1085;&#1096;&#1080;&#1081;
                &#1084;&#1077;&#1093;&#1072;&#1085;&#1110;&#1079;&#1084;,
                &#1103;&#1082;&#1080;&#1081;
                &#1072;&#1074;&#1090;&#1086;&#1084;&#1072;&#1090;&#1080;&#1095;&#1085;&#1086;
                &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1103;&#1108;
                &#1087;&#1088;&#1086; &#1074;&#1072;&#1096;
                &#1074;&#1080;&#1073;&#1110;&#1088; &#1085;&#1077;
                &#1074;&#1110;&#1076;&#1089;&#1090;&#1077;&#1078;&#1091;&#1074;&#1072;&#1090;&#1080;
                &#1074;&#1072;&#1089; &#1074;
                &#1030;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090;&#1110;.
                &#1071;&#1082;&#1097;&#1086; &#1073;&#1091;&#1076;&#1077;
                &#1087;&#1088;&#1080;&#1081;&#1085;&#1103;&#1090;&#1086;
                &#1089;&#1090;&#1072;&#1085;&#1076;&#1072;&#1088;&#1090;
                &#1086;&#1085;&#1083;&#1072;&#1081;&#1085;-&#1074;&#1110;&#1076;&#1089;&#1090;&#1077;&#1078;&#1077;&#1085;&#1085;&#1103;,
                &#1103;&#1082;&#1086;&#1075;&#1086; &#1084;&#1080;
                &#1087;&#1086;&#1074;&#1080;&#1085;&#1085;&#1110;
                &#1076;&#1086;&#1090;&#1088;&#1080;&#1084;&#1091;&#1074;&#1072;&#1090;&#1080;&#1089;&#1103;
                &#1074;
                &#1084;&#1072;&#1081;&#1073;&#1091;&#1090;&#1085;&#1100;&#1086;&#1084;&#1091;,
                &#1084;&#1080;
                &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1080;&#1084;&#1086;
                &#1074;&#1072;&#1089; &#1087;&#1088;&#1086; &#1094;&#1102;
                &#1087;&#1088;&#1072;&#1082;&#1090;&#1080;&#1082;&#1091; &#1074;
                &#1087;&#1077;&#1088;&#1077;&#1075;&#1083;&#1103;&#1085;&#1091;&#1090;&#1110;&#1081;
                &#1074;&#1077;&#1088;&#1089;&#1110;&#1111;
                &#1094;&#1100;&#1086;&#1075;&#1086;
                &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1077;&#1085;&#1085;&#1103;
                &#1087;&#1088;&#1086;
                &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1110;&#1089;&#1090;&#1100;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                Most web browsers and some mobile operating systems and mobile
                applications include a Do-Not-Track (&quot;DNT&quot;) feature or
                setting you can activate to signal your privacy preference not
                to have data about your online browsing activities monitored and
                collected. At this stage no uniform technology standard for
                recognizing and implementing DNT signals has been finalized. As
                such, we do not currently respond to DNT browser signals or any
                other mechanism that automatically communicates your choice not
                to be tracked online. If a standard for online tracking is
                adopted that we must follow in the future, we will inform you
                about that practice in a revised version of this privacy notice.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4" id="privacy_sec11">
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                11. &#1063;&#1048; &#1052;&#1040;&#1070;&#1058;&#1068;
                &#1046;&#1048;&#1058;&#1045;&#1051;&#1030;
                &#1050;&#1040;&#1051;&#1030;&#1060;&#1054;&#1056;&#1053;&#1030;&#1031;
                &#1057;&#1055;&#1045;&#1062;&#1030;&#1040;&#1051;&#1068;&#1053;&#1030;
                &#1055;&#1056;&#1040;&#1042;&#1040; &#1053;&#1040;
                &#1050;&#1054;&#1053;&#1060;&#1030;&#1044;&#1045;&#1053;&#1062;&#1030;&#1049;&#1053;&#1030;&#1057;&#1058;&#1068;?
              </span>
            </p>
          </td>
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">
                11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c2">
                &#1050;&#1086;&#1088;&#1086;&#1090;&#1082;&#1086;:{" "}
              </span>
              <span className="c11">
                &#1090;&#1072;&#1082;, &#1103;&#1082;&#1097;&#1086;
                &#1074;&#1080; &#1108;
                &#1088;&#1077;&#1079;&#1080;&#1076;&#1077;&#1085;&#1090;&#1086;&#1084;
                &#1050;&#1072;&#1083;&#1110;&#1092;&#1086;&#1088;&#1085;&#1110;&#1111;,
                &#1074;&#1072;&#1084;
                &#1085;&#1072;&#1076;&#1072;&#1102;&#1090;&#1100;&#1089;&#1103;
                &#1087;&#1077;&#1074;&#1085;&#1110;
                &#1087;&#1088;&#1072;&#1074;&#1072; &#1097;&#1086;&#1076;&#1086;
                &#1076;&#1086;&#1089;&#1090;&#1091;&#1087;&#1091; &#1076;&#1086;
                &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c10 c2">In Short: </span>
              <span className="c11 c10">
                Yes, if you are a resident of California, you are granted
                specific rights regarding access to your personal information.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1056;&#1086;&#1079;&#1076;&#1110;&#1083; 1798.83
                &#1062;&#1080;&#1074;&#1110;&#1083;&#1100;&#1085;&#1086;&#1075;&#1086;
                &#1082;&#1086;&#1076;&#1077;&#1082;&#1089;&#1091;
                &#1050;&#1072;&#1083;&#1110;&#1092;&#1086;&#1088;&#1085;&#1110;&#1111;,
                &#1090;&#1072;&#1082;&#1086;&#1078;
                &#1074;&#1110;&#1076;&#1086;&#1084;&#1080;&#1081; &#1103;&#1082;
                &#1079;&#1072;&#1082;&#1086;&#1085; &laquo;Shine The
                Light&raquo;,
                &#1076;&#1086;&#1079;&#1074;&#1086;&#1083;&#1103;&#1108;
                &#1085;&#1072;&#1096;&#1080;&#1084;
                &#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1074;&#1072;&#1095;&#1072;&#1084;,
                &#1103;&#1082;&#1110; &#1108;
                &#1078;&#1080;&#1090;&#1077;&#1083;&#1103;&#1084;&#1080;
                &#1050;&#1072;&#1083;&#1110;&#1092;&#1086;&#1088;&#1085;&#1110;&#1111;,
                &#1079;&#1072;&#1087;&#1080;&#1090;&#1091;&#1074;&#1072;&#1090;&#1080;
                &#1090;&#1072;
                &#1086;&#1090;&#1088;&#1080;&#1084;&#1091;&#1074;&#1072;&#1090;&#1080;
                &#1074;&#1110;&#1076; &#1085;&#1072;&#1089;
                &#1088;&#1072;&#1079; &#1085;&#1072; &#1088;&#1110;&#1082;
                &#1073;&#1077;&#1079;&#1082;&#1086;&#1096;&#1090;&#1086;&#1074;&#1085;&#1086;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1087;&#1088;&#1086;
                &#1082;&#1072;&#1090;&#1077;&#1075;&#1086;&#1088;&#1110;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;
                (&#1079;&#1072;
                &#1085;&#1072;&#1103;&#1074;&#1085;&#1086;&#1089;&#1090;&#1110;),
                &#1103;&#1082;&#1091; &#1084;&#1080;
                &#1085;&#1072;&#1076;&#1072;&#1108;&#1084;&#1086;
                &#1090;&#1088;&#1077;&#1090;&#1110;&#1084;
                &#1086;&#1089;&#1086;&#1073;&#1072;&#1084; &#1076;&#1083;&#1103;
                &#1094;&#1110;&#1083;&#1077;&#1081;
                &#1087;&#1088;&#1103;&#1084;&#1086;&#1075;&#1086;
                &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;&#1091;,
                &#1072; &#1090;&#1072;&#1082;&#1086;&#1078;
                &#1110;&#1084;&#1077;&#1085;&#1072; &#1081;
                &#1072;&#1076;&#1088;&#1077;&#1089;&#1080;
                &#1074;&#1089;&#1110;&#1093;
                &#1090;&#1088;&#1077;&#1090;&#1110;&#1093;
                &#1089;&#1090;&#1086;&#1088;&#1110;&#1085;,
                &#1103;&#1082;&#1080;&#1084; &#1084;&#1080;
                &#1085;&#1072;&#1076;&#1072;&#1083;&#1080;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1073;&#1077;&#1079;&#1087;&#1086;&#1089;&#1077;&#1088;&#1077;&#1076;&#1085;&#1100;&#1086;
                &#1079;&#1072;
                &#1087;&#1086;&#1087;&#1077;&#1088;&#1077;&#1076;&#1085;&#1110;&#1081;
                &#1082;&#1072;&#1083;&#1077;&#1085;&#1076;&#1072;&#1088;&#1085;&#1080;&#1081;
                &#1088;&#1110;&#1082;. &#1071;&#1082;&#1097;&#1086;
                &#1074;&#1080;
                &#1087;&#1088;&#1086;&#1078;&#1080;&#1074;&#1072;&#1108;&#1090;&#1077;
                &#1074;
                &#1050;&#1072;&#1083;&#1110;&#1092;&#1086;&#1088;&#1085;&#1110;&#1111;
                &#1090;&#1072; &#1073;&#1072;&#1078;&#1072;&#1108;&#1090;&#1077;
                &#1087;&#1086;&#1076;&#1072;&#1090;&#1080;
                &#1090;&#1072;&#1082;&#1080;&#1081;
                &#1079;&#1072;&#1087;&#1080;&#1090;,
                &#1085;&#1072;&#1076;&#1110;&#1096;&#1083;&#1110;&#1090;&#1100;
                &#1085;&#1072;&#1084; &#1089;&#1074;&#1110;&#1081;
                &#1079;&#1072;&#1087;&#1080;&#1090; &#1091;
                &#1087;&#1080;&#1089;&#1100;&#1084;&#1086;&#1074;&#1110;&#1081;
                &#1092;&#1086;&#1088;&#1084;&#1110;,
                &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1102;&#1095;&#1080;
                &#1082;&#1086;&#1085;&#1090;&#1072;&#1082;&#1090;&#1085;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                &#1085;&#1072;&#1074;&#1077;&#1076;&#1077;&#1085;&#1091;
                &#1085;&#1080;&#1078;&#1095;&#1077;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                California Civil Code Section 1798.83, also known as the
                &quot;Shine The Light&quot; law, permits our users who are
                California residents to request and obtain from us, once a year
                and free of charge, information about categories of personal
                information (if any) we disclosed to third parties for direct
                marketing purposes and the names and addresses of all third
                parties with which we shared personal information in the
                immediately preceding calendar year. If you are a California
                resident and would like to make such a request, please submit
                your request in writing to us using the contact information
                provided below.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1071;&#1082;&#1097;&#1086; &#1074;&#1072;&#1084;
                &#1084;&#1077;&#1085;&#1096;&#1077; 18
                &#1088;&#1086;&#1082;&#1110;&#1074;, &#1074;&#1080;
                &#1087;&#1088;&#1086;&#1078;&#1080;&#1074;&#1072;&#1108;&#1090;&#1077;
                &#1074;
                &#1050;&#1072;&#1083;&#1110;&#1092;&#1086;&#1088;&#1085;&#1110;&#1111;
                &#1090;&#1072; &#1084;&#1072;&#1108;&#1090;&#1077;
                &#1086;&#1073;&#1083;&#1110;&#1082;&#1086;&#1074;&#1080;&#1081;
                &#1079;&#1072;&#1087;&#1080;&#1089; &#1091;
                &#1057;&#1077;&#1088;&#1074;&#1110;&#1089;&#1072;&#1093;,
                &#1074;&#1080; &#1084;&#1072;&#1108;&#1090;&#1077;
                &#1087;&#1088;&#1072;&#1074;&#1086;
                &#1074;&#1080;&#1084;&#1072;&#1075;&#1072;&#1090;&#1080;
                &#1074;&#1080;&#1076;&#1072;&#1083;&#1077;&#1085;&#1085;&#1103;
                &#1085;&#1077;&#1073;&#1072;&#1078;&#1072;&#1085;&#1080;&#1093;
                &#1076;&#1072;&#1085;&#1080;&#1093;, &#1103;&#1082;&#1110;
                &#1074;&#1080;
                &#1087;&#1091;&#1073;&#1083;&#1110;&#1095;&#1085;&#1086;
                &#1086;&#1087;&#1091;&#1073;&#1083;&#1110;&#1082;&#1091;&#1108;&#1090;&#1077;
                &#1074;
                &#1057;&#1077;&#1088;&#1074;&#1110;&#1089;&#1072;&#1093;.
                &#1065;&#1086;&#1073; &#1087;&#1086;&#1076;&#1072;&#1090;&#1080;
                &#1079;&#1072;&#1087;&#1080;&#1090; &#1085;&#1072;
                &#1074;&#1080;&#1076;&#1072;&#1083;&#1077;&#1085;&#1085;&#1103;
                &#1090;&#1072;&#1082;&#1080;&#1093;
                &#1076;&#1072;&#1085;&#1080;&#1093;,
                &#1079;&#1074;&rsquo;&#1103;&#1078;&#1110;&#1090;&#1100;&#1089;&#1103;
                &#1079; &#1085;&#1072;&#1084;&#1080;,
                &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1102;&#1095;&#1080;
                &#1082;&#1086;&#1085;&#1090;&#1072;&#1082;&#1090;&#1085;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                &#1085;&#1072;&#1074;&#1077;&#1076;&#1077;&#1085;&#1091;
                &#1085;&#1080;&#1078;&#1095;&#1077;, &#1110;
                &#1074;&#1082;&#1072;&#1078;&#1110;&#1090;&#1100;
                &#1072;&#1076;&#1088;&#1077;&#1089;&#1091;
                &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1086;&#1111;
                &#1087;&#1086;&#1096;&#1090;&#1080;,
                &#1087;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1085;&#1091;
                &#1079; &#1074;&#1072;&#1096;&#1080;&#1084;
                &#1086;&#1073;&#1083;&#1110;&#1082;&#1086;&#1074;&#1080;&#1084;
                &#1079;&#1072;&#1087;&#1080;&#1089;&#1086;&#1084;, &#1110;
                &#1079;&#1072;&#1103;&#1074;&#1091; &#1087;&#1088;&#1086;
                &#1090;&#1077;, &#1097;&#1086; &#1074;&#1080;
                &#1087;&#1088;&#1086;&#1078;&#1080;&#1074;&#1072;&#1108;&#1090;&#1077;
                &#1074;
                &#1050;&#1072;&#1083;&#1110;&#1092;&#1086;&#1088;&#1085;&#1110;&#1111;.
                &#1052;&#1080;
                &#1087;&#1086;&#1076;&#1073;&#1072;&#1108;&#1084;&#1086;
                &#1087;&#1088;&#1086; &#1090;&#1077;, &#1097;&#1086;&#1073;
                &#1076;&#1072;&#1085;&#1110; &#1085;&#1077;
                &#1073;&#1091;&#1083;&#1080;
                &#1087;&#1091;&#1073;&#1083;&#1110;&#1095;&#1085;&#1086;
                &#1074;&#1110;&#1076;&#1086;&#1073;&#1088;&#1072;&#1078;&#1077;&#1085;&#1110;
                &#1074;
                &#1057;&#1077;&#1088;&#1074;&#1110;&#1089;&#1072;&#1093;,
                &#1072;&#1083;&#1077; &#1084;&#1072;&#1081;&#1090;&#1077;
                &#1085;&#1072; &#1091;&#1074;&#1072;&#1079;&#1110;,
                &#1097;&#1086; &#1076;&#1072;&#1085;&#1110; &#1085;&#1077;
                &#1084;&#1086;&#1078;&#1091;&#1090;&#1100;
                &#1073;&#1091;&#1090;&#1080;
                &#1087;&#1086;&#1074;&#1085;&#1110;&#1089;&#1090;&#1102;
                &#1074;&#1080;&#1076;&#1072;&#1083;&#1077;&#1085;&#1110; &#1079;
                &#1091;&#1089;&#1110;&#1093; &#1085;&#1072;&#1096;&#1080;&#1093;
                &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;
                (&#1085;&#1072;&#1087;&#1088;&#1080;&#1082;&#1083;&#1072;&#1076;,
                &#1088;&#1077;&#1079;&#1077;&#1088;&#1074;&#1085;&#1110;
                &#1082;&#1086;&#1087;&#1110;&#1111;
                &#1090;&#1086;&#1097;&#1086;).
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                If you are under 18 years of age, reside in California, and have
                a registered account with Services, you have the right to
                request removal of unwanted data that you publicly post on the
                Services. To request removal of such data, please contact us
                using the contact information provided below and include the
                email address associated with your account and a statement that
                you reside in California. We will make sure the data is not
                publicly displayed on the Services, but please be aware that the
                data may not be completely or comprehensively removed from all
                our systems (e.g., backups, etc.).
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                &#1055;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1077;&#1085;&#1085;&#1103;
                &#1087;&#1088;&#1086;
                &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1110;&#1089;&#1090;&#1100;
                CCPA
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">CCPA Privacy Notice</span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1050;&#1086;&#1076;&#1077;&#1082;&#1089;
                &#1087;&#1088;&#1072;&#1074;&#1080;&#1083;
                &#1050;&#1072;&#1083;&#1110;&#1092;&#1086;&#1088;&#1085;&#1110;&#1111;
                &#1074;&#1080;&#1079;&#1085;&#1072;&#1095;&#1072;&#1108;
                &laquo;&#1088;&#1077;&#1079;&#1080;&#1076;&#1077;&#1085;&#1090;&#1072;&raquo;
                &#1103;&#1082;:
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                The California Code of Regulations defines a
                &quot;resident&quot; as:
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8 c24">
              <span className="c3">
                (1) &#1082;&#1086;&#1078;&#1085;&#1091;
                &#1086;&#1089;&#1086;&#1073;&#1091;, &#1103;&#1082;&#1072;
                &#1087;&#1077;&#1088;&#1077;&#1073;&#1091;&#1074;&#1072;&#1108;
                &#1074; &#1096;&#1090;&#1072;&#1090;&#1110;
                &#1050;&#1072;&#1083;&#1110;&#1092;&#1086;&#1088;&#1085;&#1110;&#1103;
                &#1085;&#1077; &#1079;
                &#1090;&#1080;&#1084;&#1095;&#1072;&#1089;&#1086;&#1074;&#1086;&#1102;
                &#1095;&#1080;
                &#1090;&#1080;&#1084;&#1095;&#1072;&#1089;&#1086;&#1074;&#1086;&#1102;
                &#1084;&#1077;&#1090;&#1086;&#1102;, &#1110;
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8 c24">
              <span className="c10">
                (1) every individual who is in the State of California for other
                than a temporary or transitory purpose and
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8 c24">
              <span className="c3">
                (2) &#1082;&#1086;&#1078;&#1085;&#1091;
                &#1086;&#1089;&#1086;&#1073;&#1091;, &#1103;&#1082;&#1072;
                &#1087;&#1088;&#1086;&#1078;&#1080;&#1074;&#1072;&#1108; &#1074;
                &#1096;&#1090;&#1072;&#1090;&#1110;
                &#1050;&#1072;&#1083;&#1110;&#1092;&#1086;&#1088;&#1085;&#1110;&#1103;,
                &#1103;&#1082;&#1072;
                &#1087;&#1077;&#1088;&#1077;&#1073;&#1091;&#1074;&#1072;&#1108;
                &#1079;&#1072; &#1084;&#1077;&#1078;&#1072;&#1084;&#1080;
                &#1096;&#1090;&#1072;&#1090;&#1091;
                &#1050;&#1072;&#1083;&#1110;&#1092;&#1086;&#1088;&#1085;&#1110;&#1103;
                &#1079;
                &#1090;&#1080;&#1084;&#1095;&#1072;&#1089;&#1086;&#1074;&#1086;&#1102;
                &#1072;&#1073;&#1086;
                &#1090;&#1080;&#1084;&#1095;&#1072;&#1089;&#1086;&#1074;&#1086;&#1102;
                &#1084;&#1077;&#1090;&#1086;&#1102;
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8 c24">
              <span className="c10">
                (2) every individual who is domiciled in the State of California
                who is outside the State of California for a temporary or
                transitory purpose
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1059;&#1089;&#1110; &#1110;&#1085;&#1096;&#1110;
                &#1086;&#1089;&#1086;&#1073;&#1080;
                &#1074;&#1080;&#1079;&#1085;&#1072;&#1095;&#1072;&#1102;&#1090;&#1100;&#1089;&#1103;
                &#1103;&#1082;
                &laquo;&#1085;&#1077;&#1088;&#1077;&#1079;&#1080;&#1076;&#1077;&#1085;&#1090;&#1080;&raquo;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                All other individuals are defined as &quot;non-residents.&quot;
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1071;&#1082;&#1097;&#1086; &#1094;&#1077;
                &#1074;&#1080;&#1079;&#1085;&#1072;&#1095;&#1077;&#1085;&#1085;&#1103;
                &laquo;&#1088;&#1077;&#1079;&#1080;&#1076;&#1077;&#1085;&#1090;&#1072;&raquo;
                &#1089;&#1090;&#1086;&#1089;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
                &#1074;&#1072;&#1089;, &#1084;&#1080;
                &#1087;&#1086;&#1074;&#1080;&#1085;&#1085;&#1110;
                &#1076;&#1086;&#1090;&#1088;&#1080;&#1084;&#1091;&#1074;&#1072;&#1090;&#1080;&#1089;&#1103;
                &#1087;&#1077;&#1074;&#1085;&#1080;&#1093;
                &#1087;&#1088;&#1072;&#1074; &#1110;
                &#1079;&#1086;&#1073;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1085;&#1100;
                &#1097;&#1086;&#1076;&#1086; &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                If this definition of &quot;resident&quot; applies to you, we
                must adhere to certain rights and obligations regarding your
                personal information.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1052;&#1080; &nbsp;&#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1079;&#1073;&#1080;&#1088;&#1072;&#1090;&#1080;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                &#1082;&#1086;&#1083;&#1080; &#1074;&#1080;
                &#1089;&#1087;&#1110;&#1083;&#1082;&#1091;&#1108;&#1090;&#1077;&#1089;&#1103;
                &#1079; &#1085;&#1072;&#1084;&#1080;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;,
                &#1086;&#1085;&#1083;&#1072;&#1081;&#1085;,
                &#1072;&#1073;&#1086;
                &#1090;&#1077;&#1083;&#1077;&#1092;&#1086;&#1085;&#1086;&#1084;
                &#1095;&#1080;
                &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1086;&#1102;
                &#1087;&#1086;&#1096;&#1090;&#1086;&#1102; &#1074;
                &#1082;&#1086;&#1085;&#1090;&#1077;&#1082;&#1089;&#1090;&#1110;:
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                We may also collect other personal information outside of these
                categories through instances where you interact with us in
                person, online, or by phone or mail in the context of:
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_pm3z956b1wl3-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1086;&#1090;&#1088;&#1080;&#1084;&#1072;&#1085;&#1085;&#1103;
                  &#1076;&#1086;&#1087;&#1086;&#1084;&#1086;&#1075;&#1080;
                  &#1095;&#1077;&#1088;&#1077;&#1079;
                  &#1085;&#1072;&#1096;&#1110;
                  &#1082;&#1072;&#1085;&#1072;&#1083;&#1080;
                  &#1087;&#1110;&#1076;&#1090;&#1088;&#1080;&#1084;&#1082;&#1080;
                  &#1082;&#1083;&#1110;&#1108;&#1085;&#1090;&#1110;&#1074;;
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_eqstnvdk6ude-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  Receiving help through our customer support channels;
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_eqstnvdk6ude-0">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1059;&#1095;&#1072;&#1089;&#1090;&#1100; &#1074;
                  &#1086;&#1087;&#1080;&#1090;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;&#1093;
                  &#1072;&#1073;&#1086;
                  &#1082;&#1086;&#1085;&#1082;&#1091;&#1088;&#1089;&#1072;&#1093;
                  &#1082;&#1083;&#1110;&#1108;&#1085;&#1090;&#1110;&#1074;;
                  &#1090;&#1072;
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_skog5ko1fowe-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  Participation in customer surveys or contests; and
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_skog5ko1fowe-0">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1089;&#1087;&#1088;&#1080;&#1103;&#1085;&#1085;&#1103;
                  &#1085;&#1072;&#1076;&#1072;&#1085;&#1085;&#1102;
                  &#1085;&#1072;&#1096;&#1080;&#1093;
                  &#1055;&#1086;&#1089;&#1083;&#1091;&#1075; &#1110;
                  &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1110;
                  &#1085;&#1072; &#1074;&#1072;&#1096;&#1110;
                  &#1079;&#1072;&#1087;&#1080;&#1090;&#1080;.
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_pm3z956b1wl3-0">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  Facilitation in the delivery of our Services and to respond to
                  your inquiries.
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                &#1071;&#1082; &#1084;&#1080;
                &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1108;&#1084;&#1086;
                &#1090;&#1072;
                &#1076;&#1110;&#1083;&#1080;&#1084;&#1086;&#1089;&#1103;
                &#1074;&#1072;&#1096;&#1086;&#1102;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1102;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1108;&#1102;?
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2 c10">
                How do we use and share your personal information?
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1052;&#1080;
                &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1108;&#1084;&#1086;
                &#1090;&#1072; &#1085;&#1072;&#1076;&#1072;&#1108;&#1084;&#1086;
                &#1074;&#1072;&#1096;&#1091;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1079;&#1072;
                &#1076;&#1086;&#1087;&#1086;&#1084;&#1086;&#1075;&#1086;&#1102;:
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                We use and shares your personal information through:
              </span>
            </p>
          </td>
        </tr>
        <tr className="c22">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_jvh2q4vini3w-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1062;&#1110;&#1083;&#1100;&#1086;&#1074;&#1080;&#1093;
                  &#1092;&#1072;&#1081;&#1083;&#1110;&#1074;
                  cookie/&#1052;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;&#1086;&#1074;&#1080;&#1093;
                  &#1092;&#1072;&#1081;&#1083;&#1110;&#1074; cookie
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_b0dsox6p4b1o-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">Targeting cookies/Marketing cookies</span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_b0dsox6p4b1o-0">
              <li className="c28 c13 li-bullet-0">
                <span className="c3">
                  &#1052;&#1072;&#1103;&#1082;&#1110;&#1074;/&#1055;&#1110;&#1082;&#1089;&#1077;&#1083;&#1110;&#1074;/&#1058;&#1077;&#1075;&#1110;&#1074;
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_lq9qhoczzw9d-0 start">
              <li className="c13 c28 li-bullet-0">
                <span className="c10">Beacons/Pixels/Tags</span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1044;&#1086;&#1076;&#1072;&#1090;&#1082;&#1086;&#1074;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1087;&#1088;&#1086; &#1085;&#1072;&#1096;&#1110;
                &#1089;&#1087;&#1086;&#1089;&#1086;&#1073;&#1080;
                &#1079;&#1073;&#1086;&#1088;&#1091; &#1090;&#1072;
                &#1086;&#1073;&#1084;&#1110;&#1085;&#1091;
                &#1076;&#1072;&#1085;&#1080;&#1084;&#1080;
                &#1084;&#1086;&#1078;&#1085;&#1072;
                &#1079;&#1085;&#1072;&#1081;&#1090;&#1080; &#1074;
                &#1094;&#1110;&#1108;&#1111;
                &#1087;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1080;
                &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                More information about our data collection and sharing practices
                can be found in this privacy policy.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span>
                &#1042;&#1080; &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;
                &#1079;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1090;&#1080;&#1089;&#1103;
                &#1079; &#1085;&#1072;&#1084;&#1080;
                &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1086;&#1102;
                &#1087;&#1086;&#1096;&#1090;&#1086;&#1102; &#1079;&#1072;
                &#1072;&#1076;&#1088;&#1077;&#1089;&#1086;&#1102;
              </span>
              <span className="c5">
                <a className="c15" href="mailto:support@it-sprout.org.ua">
                  support@it-sprout.org.ua
                </a>
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">You may contact us by email at </span>
              <span className="c5">
                <a className="c15" href="mailto:support@it-sprout.org.ua">
                  support@it-sprout.org.ua
                </a>
              </span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1071;&#1082;&#1097;&#1086; &#1074;&#1080;
                &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1108;&#1090;&#1077;
                &#1091;&#1087;&#1086;&#1074;&#1085;&#1086;&#1074;&#1072;&#1078;&#1077;&#1085;&#1086;&#1075;&#1086;
                &#1072;&#1075;&#1077;&#1085;&#1090;&#1072;,
                &#1097;&#1086;&#1073;
                &#1089;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1072;&#1090;&#1080;&#1089;&#1103;
                &#1089;&#1074;&#1086;&#1111;&#1084;
                &#1087;&#1088;&#1072;&#1074;&#1086;&#1084;
                &#1074;&#1110;&#1076;&#1084;&#1086;&#1074;&#1080;&#1090;&#1080;&#1089;&#1103;,
                &#1084;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1074;&#1110;&#1076;&#1093;&#1080;&#1083;&#1080;&#1090;&#1080;
                &#1079;&#1072;&#1087;&#1080;&#1090;,
                &#1103;&#1082;&#1097;&#1086;
                &#1091;&#1087;&#1086;&#1074;&#1085;&#1086;&#1074;&#1072;&#1078;&#1077;&#1085;&#1080;&#1081;
                &#1072;&#1075;&#1077;&#1085;&#1090; &#1085;&#1077;
                &#1085;&#1072;&#1076;&#1072;&#1089;&#1090;&#1100;
                &#1076;&#1086;&#1082;&#1072;&#1079;
                &#1090;&#1086;&#1075;&#1086;, &#1097;&#1086;
                &#1074;&#1110;&#1085; &#1073;&#1091;&#1074;
                &#1085;&#1072;&#1083;&#1077;&#1078;&#1085;&#1080;&#1084;
                &#1095;&#1080;&#1085;&#1086;&#1084;
                &#1091;&#1087;&#1086;&#1074;&#1085;&#1086;&#1074;&#1072;&#1078;&#1077;&#1085;&#1080;&#1081;
                &#1076;&#1110;&#1103;&#1090;&#1080; &#1074;&#1110;&#1076;
                &#1074;&#1072;&#1096;&#1086;&#1075;&#1086;
                &#1110;&#1084;&#1077;&#1085;&#1110;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                If you are using an authorized agent to exercise your right to
                opt out we may deny a request if the authorized agent does not
                submit proof that they have been validly authorized to act on
                your behalf.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1052;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1088;&#1086;&#1079;&#1082;&#1088;&#1080;&#1074;&#1072;&#1090;&#1080;
                &#1074;&#1072;&#1096;&#1091;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1085;&#1072;&#1096;&#1080;&#1084;
                &#1087;&#1086;&#1089;&#1090;&#1072;&#1095;&#1072;&#1083;&#1100;&#1085;&#1080;&#1082;&#1072;&#1084;
                &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;
                &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;
                &#1076;&#1086;
                &#1087;&#1080;&#1089;&#1100;&#1084;&#1086;&#1074;&#1086;&#1075;&#1086;
                &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;
                &#1084;&#1110;&#1078; &#1085;&#1072;&#1084;&#1080;
                &#1090;&#1072; &#1082;&#1086;&#1078;&#1085;&#1080;&#1084;
                &#1087;&#1086;&#1089;&#1090;&#1072;&#1095;&#1072;&#1083;&#1100;&#1085;&#1080;&#1082;&#1086;&#1084;
                &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;.
                &#1050;&#1086;&#1078;&#1077;&#1085;
                &#1087;&#1086;&#1089;&#1090;&#1072;&#1095;&#1072;&#1083;&#1100;&#1085;&#1080;&#1082;
                &#1087;&#1086;&#1089;&#1083;&#1091;&#1075; &#1108;
                &#1082;&#1086;&#1084;&#1077;&#1088;&#1094;&#1110;&#1081;&#1085;&#1086;&#1102;
                &#1086;&#1088;&#1075;&#1072;&#1085;&#1110;&#1079;&#1072;&#1094;&#1110;&#1108;&#1102;,
                &#1103;&#1082;&#1072;
                &#1086;&#1073;&#1088;&#1086;&#1073;&#1083;&#1103;&#1108;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1074;&#1110;&#1076; &#1085;&#1072;&#1096;&#1086;&#1075;&#1086;
                &#1110;&#1084;&#1077;&#1085;&#1110;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                We may disclose your personal information with our service
                providers pursuant to a written contract between us and each
                service provider. Each service provider is a for-profit entity
                that processes the information on our behalf.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1052;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1074;&#1072;&#1090;&#1080;
                &#1074;&#1072;&#1096;&#1091;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1076;&#1083;&#1103;
                &#1074;&#1083;&#1072;&#1089;&#1085;&#1080;&#1093;
                &#1073;&#1110;&#1079;&#1085;&#1077;&#1089;-&#1094;&#1110;&#1083;&#1077;&#1081;,
                &#1085;&#1072;&#1087;&#1088;&#1080;&#1082;&#1083;&#1072;&#1076;
                &#1076;&#1083;&#1103;
                &#1087;&#1088;&#1086;&#1074;&#1077;&#1076;&#1077;&#1085;&#1085;&#1103;
                &#1074;&#1085;&#1091;&#1090;&#1088;&#1110;&#1096;&#1085;&#1110;&#1093;
                &#1076;&#1086;&#1089;&#1083;&#1110;&#1076;&#1078;&#1077;&#1085;&#1100;
                &#1076;&#1083;&#1103;
                &#1090;&#1077;&#1093;&#1085;&#1086;&#1083;&#1086;&#1075;&#1110;&#1095;&#1085;&#1086;&#1075;&#1086;
                &#1088;&#1086;&#1079;&#1074;&#1080;&#1090;&#1082;&#1091;
                &#1090;&#1072;
                &#1076;&#1077;&#1084;&#1086;&#1085;&#1089;&#1090;&#1088;&#1072;&#1094;&#1110;&#1111;.
                &#1062;&#1077; &#1085;&#1077;
                &#1074;&#1074;&#1072;&#1078;&#1072;&#1108;&#1090;&#1100;&#1089;&#1103;
                &laquo;&#1087;&#1088;&#1086;&#1076;&#1072;&#1078;&#1077;&#1084;&raquo;
                &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                We may use your personal information for our own business
                purposes, such as for undertaking internal research for
                technological development and demonstration. This is not
                considered to be &quot;selling&quot; of your personal
                information.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1055;&#1088;&#1086;&#1090;&#1103;&#1075;&#1086;&#1084;
                &#1086;&#1089;&#1090;&#1072;&#1085;&#1085;&#1110;&#1093;
                &#1076;&#1074;&#1072;&#1085;&#1072;&#1076;&#1094;&#1103;&#1090;&#1080;
                (12) &#1084;&#1110;&#1089;&#1103;&#1094;&#1110;&#1074;
                &#1084;&#1080; &#1085;&#1077;
                &#1087;&#1088;&#1086;&#1076;&#1072;&#1074;&#1072;&#1083;&#1080;
                &#1078;&#1086;&#1076;&#1085;&#1086;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;
                &#1090;&#1088;&#1077;&#1090;&#1110;&#1084;
                &#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1072;&#1084; &#1079;
                &#1076;&#1110;&#1083;&#1086;&#1074;&#1086;&#1102; &#1095;&#1080;
                &#1082;&#1086;&#1084;&#1077;&#1088;&#1094;&#1110;&#1081;&#1085;&#1086;&#1102;
                &#1084;&#1077;&#1090;&#1086;&#1102;.{" "}
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                We have not sold any personal information to third parties for a
                business or commercial purpose in the preceding twelve (12)
                months.{" "}
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                &#1042;&#1072;&#1096;&#1110; &#1087;&#1088;&#1072;&#1074;&#1072;
                &#1097;&#1086;&#1076;&#1086; &#1074;&#1072;&#1096;&#1080;&#1093;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1080;&#1093;
                &#1076;&#1072;&#1085;&#1080;&#1093;
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">
                Your rights with respect to your personal data
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c5">
                &#1055;&#1088;&#1072;&#1074;&#1086;
                &#1074;&#1080;&#1084;&#1072;&#1075;&#1072;&#1090;&#1080;
                &#1074;&#1080;&#1076;&#1072;&#1083;&#1077;&#1085;&#1085;&#1103;
                &#1076;&#1072;&#1085;&#1080;&#1093; &mdash;
                &#1047;&#1072;&#1087;&#1080;&#1090; &#1085;&#1072;
                &#1074;&#1080;&#1076;&#1072;&#1083;&#1077;&#1085;&#1085;&#1103;
              </span>
            </p>
            <p className="c8">
              <span className="c3">
                &#1042;&#1080; &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;
                &#1074;&#1080;&#1084;&#1072;&#1075;&#1072;&#1090;&#1080;
                &#1074;&#1080;&#1076;&#1072;&#1083;&#1077;&#1085;&#1085;&#1103;
                &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;.
                &#1071;&#1082;&#1097;&#1086; &#1074;&#1080;
                &#1087;&#1086;&#1087;&#1088;&#1086;&#1089;&#1080;&#1090;&#1077;
                &#1085;&#1072;&#1089;
                &#1074;&#1080;&#1076;&#1072;&#1083;&#1080;&#1090;&#1080;
                &#1074;&#1072;&#1096;&#1091;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                &#1084;&#1080;
                &#1079;&#1072;&#1076;&#1086;&#1074;&#1086;&#1083;&#1100;&#1085;&#1080;&#1084;&#1086;
                &#1074;&#1072;&#1096; &#1079;&#1072;&#1087;&#1080;&#1090;
                &#1110; &#1074;&#1080;&#1076;&#1072;&#1083;&#1080;&#1084;&#1086;
                &#1074;&#1072;&#1096;&#1091;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                &#1103;&#1082;&#1097;&#1086; &#1110;&#1085;&#1096;&#1077;
                &#1085;&#1077;
                &#1074;&#1080;&#1084;&#1072;&#1075;&#1072;&#1108;&#1090;&#1100;&#1089;&#1103;
                &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1084;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c5 c10 c35">
                Right to request deletion of the data &mdash; Request to delete
              </span>
            </p>
            <p className="c8">
              <span className="c10">
                You can ask for the deletion of your personal information. If
                you ask us to delete your personal information, we will respect
                your request and delete your personal information, subject to
                certain exceptions provided by law.{" "}
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c5">
                &#1055;&#1088;&#1072;&#1074;&#1086; &#1085;&#1072;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &mdash; &#1047;&#1072;&#1087;&#1080;&#1090; &#1085;&#1072;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c5 c10">
                Right to be informed &mdash; Request to know
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1047;&#1072;&#1083;&#1077;&#1078;&#1085;&#1086;
                &#1074;&#1110;&#1076;
                &#1086;&#1073;&#1089;&#1090;&#1072;&#1074;&#1080;&#1085;
                &#1074;&#1080; &#1084;&#1072;&#1108;&#1090;&#1077;
                &#1087;&#1088;&#1072;&#1074;&#1086;
                &#1079;&#1085;&#1072;&#1090;&#1080;:
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                Depending on the circumstances, you have a right to know:
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_i5ypuvue9xp0-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1095;&#1080;
                  &#1079;&#1073;&#1080;&#1088;&#1072;&#1108;&#1084;&#1086;
                  &#1084;&#1080; &#1090;&#1072;
                  &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1108;&#1084;&#1086;
                  &#1074;&#1072;&#1096;&#1091;
                  &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;;
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_n8720bvdvhl9-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  whether we collect and use your personal information;
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_n8720bvdvhl9-0">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1082;&#1072;&#1090;&#1077;&#1075;&#1086;&#1088;&#1110;&#1111;
                  &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;,
                  &#1103;&#1082;&#1091; &#1084;&#1080;
                  &#1079;&#1073;&#1080;&#1088;&#1072;&#1108;&#1084;&#1086;;
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_1orm71gyxx7p-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  the categories of personal information that we collect;
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_dwjb29xu92zm-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1094;&#1110;&#1083;&#1110;, &#1076;&#1083;&#1103;
                  &#1103;&#1082;&#1080;&#1093;
                  &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
                  &#1079;&#1110;&#1073;&#1088;&#1072;&#1085;&#1072;
                  &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1072;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;;
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_umh1xsmzhtcj-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  the purposes for which the collected personal information is
                  used;
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_umh1xsmzhtcj-0">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1095;&#1080;
                  &#1087;&#1088;&#1086;&#1076;&#1072;&#1108;&#1084;&#1086;
                  &#1084;&#1080; &#1074;&#1072;&#1096;&#1091;
                  &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                  &#1090;&#1088;&#1077;&#1090;&#1110;&#1084;
                  &#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1072;&#1084;;
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_ggi1zeae8z3o-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  whether we sell your personal information to third parties;
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_3041tectroi-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1082;&#1072;&#1090;&#1077;&#1075;&#1086;&#1088;&#1110;&#1111;
                  &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;,
                  &#1103;&#1082;&#1091; &#1084;&#1080;
                  &#1087;&#1088;&#1086;&#1076;&#1072;&#1083;&#1080;
                  &#1072;&#1073;&#1086;
                  &#1088;&#1086;&#1079;&#1082;&#1088;&#1080;&#1083;&#1080;
                  &#1079;
                  &#1082;&#1086;&#1084;&#1077;&#1088;&#1094;&#1110;&#1081;&#1085;&#1086;&#1102;
                  &#1084;&#1077;&#1090;&#1086;&#1102;;
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_vp4o8yk81f88-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  the categories of personal information that we sold or
                  disclosed for a business purpose;
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_l94yyzd5r839-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1082;&#1072;&#1090;&#1077;&#1075;&#1086;&#1088;&#1110;&#1111;
                  &#1090;&#1088;&#1077;&#1090;&#1110;&#1093;
                  &#1089;&#1090;&#1086;&#1088;&#1110;&#1085;,
                  &#1103;&#1082;&#1080;&#1084;
                  &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1072;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;
                  &#1073;&#1091;&#1083;&#1072;
                  &#1087;&#1088;&#1086;&#1076;&#1072;&#1085;&#1072;
                  &#1072;&#1073;&#1086;
                  &#1088;&#1086;&#1079;&#1082;&#1088;&#1080;&#1090;&#1072;
                  &#1079;
                  &#1082;&#1086;&#1084;&#1077;&#1088;&#1094;&#1110;&#1081;&#1085;&#1086;&#1102;
                  &#1084;&#1077;&#1090;&#1086;&#1102;;{" "}
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_apiv49108caf-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  the categories of third parties to whom the personal
                  information was sold or disclosed for a business purpose;{" "}
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_apiv49108caf-0">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1090;&#1072;&#1082;&#1086;&#1078;
                  &#1076;&#1110;&#1083;&#1086;&#1074;&#1072;
                  &#1072;&#1073;&#1086;
                  &#1082;&#1086;&#1084;&#1077;&#1088;&#1094;&#1110;&#1081;&#1085;&#1072;
                  &#1084;&#1077;&#1090;&#1072;
                  &#1079;&#1073;&#1086;&#1088;&#1091; &#1072;&#1073;&#1086;
                  &#1087;&#1088;&#1086;&#1076;&#1072;&#1078;&#1091;
                  &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;.
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_tvjd1uxpifum-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  the business or commercial purpose for collecting or selling
                  personal information
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1042;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;
                &#1076;&#1086; &#1095;&#1080;&#1085;&#1085;&#1086;&#1075;&#1086;
                &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1072;
                &#1084;&#1080; &#1085;&#1077;
                &#1079;&#1086;&#1073;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1085;&#1110;
                &#1085;&#1072;&#1076;&#1072;&#1074;&#1072;&#1090;&#1080;
                &#1095;&#1080;
                &#1074;&#1080;&#1076;&#1072;&#1083;&#1103;&#1090;&#1080;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1087;&#1088;&#1086;
                &#1089;&#1087;&#1086;&#1078;&#1080;&#1074;&#1072;&#1095;&#1072;,
                &#1103;&#1082;&#1072; &#1073;&#1091;&#1083;&#1072;
                &#1076;&#1077;&#1110;&#1076;&#1077;&#1085;&#1090;&#1080;&#1092;&#1110;&#1082;&#1086;&#1074;&#1072;&#1085;&#1072;
                &#1091;
                &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1100;
                &#1085;&#1072; &#1079;&#1072;&#1087;&#1080;&#1090;
                &#1089;&#1087;&#1086;&#1078;&#1080;&#1074;&#1072;&#1095;&#1072;,
                &#1072;&#1073;&#1086;
                &#1087;&#1086;&#1074;&#1090;&#1086;&#1088;&#1085;&#1086;
                &#1110;&#1076;&#1077;&#1085;&#1090;&#1080;&#1092;&#1110;&#1082;&#1091;&#1074;&#1072;&#1090;&#1080;
                &#1110;&#1085;&#1076;&#1080;&#1074;&#1110;&#1076;&#1091;&#1072;&#1083;&#1100;&#1085;&#1110;
                &#1076;&#1072;&#1085;&#1110; &#1076;&#1083;&#1103;
                &#1087;&#1077;&#1088;&#1077;&#1074;&#1110;&#1088;&#1082;&#1080;
                &#1079;&#1072;&#1087;&#1080;&#1090;&#1091;
                &#1089;&#1087;&#1086;&#1078;&#1080;&#1074;&#1072;&#1095;&#1072;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                In accordance with applicable law, we are not obligated to
                provide or delete consumer information that is de-identified in
                response to a consumer request or to re-identify individual data
                to verify a consumer request.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c5">
                &#1055;&#1088;&#1072;&#1074;&#1086; &#1085;&#1072;
                &#1085;&#1077;&#1076;&#1080;&#1089;&#1082;&#1088;&#1080;&#1084;&#1110;&#1085;&#1072;&#1094;&#1110;&#1102;
                &#1097;&#1086;&#1076;&#1086;
                &#1088;&#1077;&#1072;&#1083;&#1110;&#1079;&#1072;&#1094;&#1110;&#1111;
                &#1087;&#1088;&#1072;&#1074;
                &#1089;&#1087;&#1086;&#1078;&#1080;&#1074;&#1072;&#1095;&#1072;
                &#1085;&#1072;
                &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1110;&#1089;&#1090;&#1100;
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c5 c10">
                Right to Non-Discrimination for the Exercise of a
                Consumer&rsquo;s Privacy Rights
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1052;&#1080; &#1085;&#1077;
                &#1073;&#1091;&#1076;&#1077;&#1084;&#1086;
                &#1076;&#1080;&#1089;&#1082;&#1088;&#1080;&#1084;&#1110;&#1085;&#1091;&#1074;&#1072;&#1090;&#1080;
                &#1074;&#1072;&#1089;, &#1103;&#1082;&#1097;&#1086;
                &#1074;&#1080;
                &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1108;&#1090;&#1077;
                &#1089;&#1074;&#1086;&#1111; &#1087;&#1088;&#1072;&#1074;&#1072;
                &#1085;&#1072;
                &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1110;&#1089;&#1090;&#1100;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                We will not discriminate against you if you exercise your
                privacy rights.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c5">
                &#1055;&#1088;&#1086;&#1094;&#1077;&#1089;
                &#1087;&#1077;&#1088;&#1077;&#1074;&#1110;&#1088;&#1082;&#1080;
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c5 c10">Verification process</span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1054;&#1090;&#1088;&#1080;&#1084;&#1072;&#1074;&#1096;&#1080;
                &#1074;&#1072;&#1096; &#1079;&#1072;&#1087;&#1080;&#1090;,
                &#1085;&#1072;&#1084;
                &#1087;&#1086;&#1090;&#1088;&#1110;&#1073;&#1085;&#1086;
                &#1073;&#1091;&#1076;&#1077;
                &#1087;&#1110;&#1076;&#1090;&#1074;&#1077;&#1088;&#1076;&#1080;&#1090;&#1080;
                &#1074;&#1072;&#1096;&#1091;
                &#1086;&#1089;&#1086;&#1073;&#1091;, &#1097;&#1086;&#1073;
                &#1074;&#1080;&#1079;&#1085;&#1072;&#1095;&#1080;&#1090;&#1080;,
                &#1097;&#1086; &#1074;&#1080; &#1090;&#1072;
                &#1089;&#1072;&#1084;&#1072;
                &#1086;&#1089;&#1086;&#1073;&#1072;, &#1087;&#1088;&#1086;
                &#1103;&#1082;&#1091; &#1084;&#1080;
                &#1084;&#1072;&#1108;&#1084;&#1086;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1074; &#1085;&#1072;&#1096;&#1110;&#1081;
                &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1110;.
                &#1058;&#1072;&#1082;&#1072;
                &#1087;&#1077;&#1088;&#1077;&#1074;&#1110;&#1088;&#1082;&#1072;
                &#1074;&#1080;&#1084;&#1072;&#1075;&#1072;&#1108;
                &#1085;&#1072;&#1096; &#1079;&#1072;&#1087;&#1088;&#1086;&#1089;
                &#1076;&#1086; &#1074;&#1072;&#1089;
                &#1085;&#1072;&#1076;&#1072;&#1090;&#1080;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                &#1097;&#1086;&#1073; &#1084;&#1080;
                &#1084;&#1086;&#1075;&#1083;&#1080;
                &#1087;&#1086;&#1088;&#1110;&#1074;&#1085;&#1103;&#1090;&#1080;
                &#1111;&#1111; &#1079;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1108;&#1102;,
                &#1103;&#1082;&#1091; &#1074;&#1080; &#1085;&#1072;&#1084;
                &#1085;&#1072;&#1076;&#1072;&#1083;&#1080;
                &#1088;&#1072;&#1085;&#1110;&#1096;&#1077;.
                &#1053;&#1072;&#1087;&#1088;&#1080;&#1082;&#1083;&#1072;&#1076;,
                &#1079;&#1072;&#1083;&#1077;&#1078;&#1085;&#1086;
                &#1074;&#1110;&#1076; &#1090;&#1080;&#1087;&#1091;
                &#1079;&#1072;&#1087;&#1080;&#1090;&#1091;,
                &#1103;&#1082;&#1080;&#1081; &#1074;&#1080;
                &#1087;&#1086;&#1076;&#1072;&#1108;&#1090;&#1077;,
                &#1084;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1087;&#1086;&#1087;&#1088;&#1086;&#1089;&#1080;&#1090;&#1080;
                &#1074;&#1072;&#1089; &#1085;&#1072;&#1076;&#1072;&#1090;&#1080;
                &#1087;&#1077;&#1074;&#1085;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                &#1097;&#1086;&#1073; &#1084;&#1080;
                &#1084;&#1086;&#1075;&#1083;&#1080;
                &#1087;&#1086;&#1088;&#1110;&#1074;&#1085;&#1103;&#1090;&#1080;
                &#1085;&#1072;&#1076;&#1072;&#1085;&#1091;
                &#1074;&#1072;&#1084;&#1080;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1079;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1108;&#1102;,
                &#1103;&#1082;&#1091; &#1084;&#1080; &#1074;&#1078;&#1077;
                &#1084;&#1072;&#1108;&#1084;&#1086;, &#1072;&#1073;&#1086;
                &#1084;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1079;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1090;&#1080;&#1089;&#1103;
                &#1079; &#1074;&#1072;&#1084;&#1080; &#1079;&#1072;
                &#1076;&#1086;&#1087;&#1086;&#1084;&#1086;&#1075;&#1086;&#1102;
                &#1084;&#1077;&#1090;&#1086;&#1076;&#1091;
                &#1079;&#1074;&rsquo;&#1103;&#1079;&#1082;&#1091;
                (&#1085;&#1072;&#1087;&#1088;&#1080;&#1082;&#1083;&#1072;&#1076;,
                &#1090;&#1077;&#1083;&#1077;&#1092;&#1086;&#1085;&#1091;
                &#1072;&#1073;&#1086;
                &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1086;&#1111;
                &#1087;&#1086;&#1096;&#1090;&#1080;), &#1103;&#1082;&#1110;
                &#1074;&#1080; &#1085;&#1072;&#1076;&#1072;&#1083;&#1080;
                &#1085;&#1072;&#1084;
                &#1088;&#1072;&#1085;&#1110;&#1096;&#1077;. &#1052;&#1080;
                &#1090;&#1072;&#1082;&#1086;&#1078;
                &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1074;&#1072;&#1090;&#1080;
                &#1110;&#1085;&#1096;&#1110;
                &#1084;&#1077;&#1090;&#1086;&#1076;&#1080;
                &#1087;&#1077;&#1088;&#1077;&#1074;&#1110;&#1088;&#1082;&#1080;
                &#1079;&#1072;&#1083;&#1077;&#1078;&#1085;&#1086;
                &#1074;&#1110;&#1076;
                &#1086;&#1073;&#1089;&#1090;&#1072;&#1074;&#1080;&#1085;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                Upon receiving your request, we will need to verify your
                identity to determine you are the same person about whom we have
                the information in our system. These verification efforts
                require us to ask you to provide information so that we can
                match it with information you have previously provided us. For
                instance, depending on the type of request you submit, we may
                ask you to provide certain information so that we can match the
                information you provide with the information we already have on
                file, or we may contact you through a communication method
                (e.g., phone or email) that you have previously provided to us.
                We may also use other verification methods as the circumstances
                dictate.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1052;&#1080;
                &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1074;&#1072;&#1090;&#1080;&#1084;&#1077;&#1084;&#1086;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                &#1085;&#1072;&#1076;&#1072;&#1085;&#1091; &#1091;
                &#1074;&#1072;&#1096;&#1086;&#1084;&#1091;
                &#1079;&#1072;&#1087;&#1080;&#1090;&#1110;,
                &#1083;&#1080;&#1096;&#1077; &#1076;&#1083;&#1103;
                &#1087;&#1110;&#1076;&#1090;&#1074;&#1077;&#1088;&#1076;&#1078;&#1077;&#1085;&#1085;&#1103;
                &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080; &#1095;&#1080;
                &#1087;&#1086;&#1074;&#1085;&#1086;&#1074;&#1072;&#1078;&#1077;&#1085;&#1100;
                &#1076;&#1083;&#1103;
                &#1087;&#1086;&#1076;&#1072;&#1085;&#1085;&#1103;
                &#1079;&#1072;&#1087;&#1080;&#1090;&#1091;.
                &#1053;&#1072;&#1089;&#1082;&#1110;&#1083;&#1100;&#1082;&#1080;
                &#1094;&#1077;
                &#1084;&#1086;&#1078;&#1083;&#1080;&#1074;&#1086;,
                &#1084;&#1080; &#1073;&#1091;&#1076;&#1077;&#1084;&#1086;
                &#1091;&#1085;&#1080;&#1082;&#1072;&#1090;&#1080;
                &#1079;&#1072;&#1087;&#1088;&#1086;&#1089;&#1091;
                &#1074;&#1110;&#1076; &#1074;&#1072;&#1089;
                &#1076;&#1086;&#1076;&#1072;&#1090;&#1082;&#1086;&#1074;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;
                &#1079; &#1084;&#1077;&#1090;&#1086;&#1102;
                &#1087;&#1077;&#1088;&#1077;&#1074;&#1110;&#1088;&#1082;&#1080;.
                &#1055;&#1088;&#1086;&#1090;&#1077;,
                &#1103;&#1082;&#1097;&#1086; &#1084;&#1080; &#1085;&#1077;
                &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1087;&#1110;&#1076;&#1090;&#1074;&#1077;&#1088;&#1076;&#1080;&#1090;&#1080;
                &#1074;&#1072;&#1096;&#1091; &#1086;&#1089;&#1086;&#1073;&#1091;
                &#1085;&#1072; &#1086;&#1089;&#1085;&#1086;&#1074;&#1110;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;,
                &#1103;&#1082;&#1091; &#1084;&#1080; &#1074;&#1078;&#1077;
                &#1079;&#1073;&#1077;&#1088;&#1110;&#1075;&#1072;&#1108;&#1084;&#1086;,
                &#1084;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1074;&#1080;&#1084;&#1072;&#1075;&#1072;&#1090;&#1080;
                &#1074;&#1110;&#1076; &#1074;&#1072;&#1089;
                &#1085;&#1072;&#1076;&#1072;&#1090;&#1080;
                &#1076;&#1086;&#1076;&#1072;&#1090;&#1082;&#1086;&#1074;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1079; &#1084;&#1077;&#1090;&#1086;&#1102;
                &#1087;&#1077;&#1088;&#1077;&#1074;&#1110;&#1088;&#1082;&#1080;
                &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080; &#1090;&#1072; &#1079;
                &#1084;&#1077;&#1090;&#1086;&#1102;
                &#1073;&#1077;&#1079;&#1087;&#1077;&#1082;&#1080; &#1095;&#1080;
                &#1079;&#1072;&#1087;&#1086;&#1073;&#1110;&#1075;&#1072;&#1085;&#1085;&#1103;
                &#1096;&#1072;&#1093;&#1088;&#1072;&#1081;&#1089;&#1090;&#1074;&#1091;.
                &#1052;&#1080;
                &#1074;&#1080;&#1076;&#1072;&#1083;&#1080;&#1084;&#1086;
                &#1090;&#1072;&#1082;&#1091;
                &#1076;&#1086;&#1076;&#1072;&#1090;&#1082;&#1086;&#1074;&#1086;
                &#1085;&#1072;&#1076;&#1072;&#1085;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
                &#1097;&#1086;&#1081;&#1085;&#1086;
                &#1079;&#1072;&#1074;&#1077;&#1088;&#1096;&#1080;&#1084;&#1086;
                &#1074;&#1072;&#1096;&#1091;
                &#1087;&#1077;&#1088;&#1077;&#1074;&#1110;&#1088;&#1082;&#1091;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c16 c10">
                We will only use personal information provided in your request
                to verify your identity or authority to make the request. To the
                extent possible, we will avoid requesting additional information
                from you for the purposes of verification. However, if we cannot
                verify your identity from the information already maintained by
                us, we may request that you provide additional information for
                the purposes of verifying your identity and for security or
                fraud-prevention purposes. We will delete such additionally
                provided information as soon as we finish verifying you.
              </span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c5">
                &#1030;&#1085;&#1096;&#1110; &#1087;&#1088;&#1072;&#1074;&#1072;
                &#1085;&#1072;
                &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1110;&#1089;&#1090;&#1100;
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c5 c10">Other privacy rights</span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_m1ku1l468j4q-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1042;&#1080; &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;
                  &#1079;&#1072;&#1087;&#1077;&#1088;&#1077;&#1095;&#1091;&#1074;&#1072;&#1090;&#1080;
                  &#1087;&#1088;&#1086;&#1090;&#1080;
                  &#1086;&#1073;&#1088;&#1086;&#1073;&#1082;&#1080;
                  &#1074;&#1072;&#1096;&#1086;&#1111;
                  &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;.
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_k29siexa056z-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  You may object to the processing of your personal information.
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_57vpcwc1826-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1042;&#1080; &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;
                  &#1074;&#1080;&#1084;&#1072;&#1075;&#1072;&#1090;&#1080;
                  &#1074;&#1080;&#1087;&#1088;&#1072;&#1074;&#1083;&#1077;&#1085;&#1085;&#1103;
                  &#1089;&#1074;&#1086;&#1111;&#1093;
                  &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1080;&#1093;
                  &#1076;&#1072;&#1085;&#1080;&#1093;,
                  &#1103;&#1082;&#1097;&#1086; &#1074;&#1086;&#1085;&#1080;
                  &#1085;&#1077;&#1074;&#1110;&#1088;&#1085;&#1110;
                  &#1072;&#1073;&#1086;
                  &#1073;&#1110;&#1083;&#1100;&#1096;&#1077; &#1085;&#1077;
                  &#1072;&#1082;&#1090;&#1091;&#1072;&#1083;&#1100;&#1085;&#1110;,
                  &#1072;&#1073;&#1086;
                  &#1087;&#1088;&#1086;&#1089;&#1080;&#1090;&#1080;
                  &#1086;&#1073;&#1084;&#1077;&#1078;&#1080;&#1090;&#1080;
                  &#1086;&#1073;&#1088;&#1086;&#1073;&#1082;&#1091;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;.
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_mz4jj1ji1br-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  You may request correction of your personal data if it is
                  incorrect or no longer relevant, or ask to restrict the
                  processing of the information.
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_hcd0ni8v16s-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1042;&#1080; &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;
                  &#1087;&#1088;&#1080;&#1079;&#1085;&#1072;&#1095;&#1080;&#1090;&#1080;
                  &#1091;&#1087;&#1086;&#1074;&#1085;&#1086;&#1074;&#1072;&#1078;&#1077;&#1085;&#1086;&#1075;&#1086;
                  &#1072;&#1075;&#1077;&#1085;&#1090;&#1072;
                  &#1076;&#1083;&#1103;
                  &#1087;&#1086;&#1076;&#1072;&#1085;&#1085;&#1103;
                  &#1079;&#1072;&#1087;&#1080;&#1090;&#1091;
                  &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;
                  &#1076;&#1086; CCPA &#1074;&#1110;&#1076;
                  &#1074;&#1072;&#1096;&#1086;&#1075;&#1086;
                  &#1110;&#1084;&#1077;&#1085;&#1110;. &#1052;&#1080;
                  &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                  &#1074;&#1110;&#1076;&#1093;&#1080;&#1083;&#1080;&#1090;&#1080;
                  &#1079;&#1072;&#1087;&#1080;&#1090; &#1074;&#1110;&#1076;
                  &#1091;&#1087;&#1086;&#1074;&#1085;&#1086;&#1074;&#1072;&#1078;&#1077;&#1085;&#1086;&#1075;&#1086;
                  &#1072;&#1075;&#1077;&#1085;&#1090;&#1072;,
                  &#1103;&#1082;&#1080;&#1081; &#1085;&#1077;
                  &#1085;&#1072;&#1076;&#1072;&#1074;
                  &#1076;&#1086;&#1082;&#1072;&#1079;&#1110;&#1074;
                  &#1090;&#1086;&#1075;&#1086;, &#1097;&#1086;
                  &#1074;&#1110;&#1085; &#1073;&#1091;&#1074;
                  &#1085;&#1072;&#1083;&#1077;&#1078;&#1085;&#1080;&#1084;
                  &#1095;&#1080;&#1085;&#1086;&#1084;
                  &#1091;&#1087;&#1086;&#1074;&#1085;&#1086;&#1074;&#1072;&#1078;&#1077;&#1085;&#1080;&#1081;
                  &#1076;&#1110;&#1103;&#1090;&#1080; &#1074;&#1110;&#1076;
                  &#1074;&#1072;&#1096;&#1086;&#1075;&#1086;
                  &#1110;&#1084;&#1077;&#1085;&#1110;
                  &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;
                  &#1076;&#1086; CCPA.
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_f4h4kxjz5uma-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  You can designate an authorized agent to make a request under
                  the CCPA on your behalf. We may deny a request from an
                  authorized agent that does not submit proof that they have
                  been validly authorized to act on your behalf in accordance
                  with the CCPA.
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_fodw5d99r513-0 start">
              <li className="c0 li-bullet-0">
                <span className="c3">
                  &#1042;&#1080; &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;
                  &#1087;&#1086;&#1087;&#1088;&#1086;&#1089;&#1080;&#1090;&#1080;
                  &#1074;&#1110;&#1076;&#1084;&#1086;&#1074;&#1080;&#1090;&#1080;&#1089;&#1103;
                  &#1074;&#1110;&#1076;
                  &#1084;&#1072;&#1081;&#1073;&#1091;&#1090;&#1085;&#1100;&#1086;&#1075;&#1086;
                  &#1087;&#1088;&#1086;&#1076;&#1072;&#1078;&#1091;
                  &#1074;&#1072;&#1096;&#1086;&#1111;
                  &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                  &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;
                  &#1090;&#1088;&#1077;&#1090;&#1110;&#1084;
                  &#1086;&#1089;&#1086;&#1073;&#1072;&#1084;.
                  &#1054;&#1090;&#1088;&#1080;&#1084;&#1072;&#1074;&#1096;&#1080;
                  &#1079;&#1072;&#1087;&#1080;&#1090; &#1085;&#1072;
                  &#1074;&#1110;&#1076;&#1084;&#1086;&#1074;&#1091;,
                  &#1084;&#1080;
                  &#1079;&#1072;&#1076;&#1086;&#1074;&#1086;&#1083;&#1100;&#1085;&#1080;&#1084;&#1086;
                  &#1079;&#1072;&#1087;&#1080;&#1090;
                  &#1103;&#1082;&#1085;&#1072;&#1081;&#1096;&#1074;&#1080;&#1076;&#1096;&#1077;,
                  &#1072;&#1083;&#1077; &#1085;&#1077;
                  &#1087;&#1110;&#1079;&#1085;&#1110;&#1096;&#1077;
                  &#1085;&#1110;&#1078; &#1095;&#1077;&#1088;&#1077;&#1079;
                  &#1087;&rsquo;&#1103;&#1090;&#1085;&#1072;&#1076;&#1094;&#1103;&#1090;&#1100;
                  (15) &#1076;&#1085;&#1110;&#1074; &#1110;&#1079;
                  &#1076;&#1072;&#1090;&#1080;
                  &#1087;&#1086;&#1076;&#1072;&#1085;&#1085;&#1103;
                  &#1079;&#1072;&#1087;&#1080;&#1090;&#1091;.
                </span>
              </li>
            </ul>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <ul className="c6 lst-kix_ytcvq0r53vtg-0 start">
              <li className="c0 li-bullet-0">
                <span className="c10">
                  You may request to opt out from future selling of your
                  personal information to third parties. Upon receiving an
                  opt-out request, we will act upon the request as soon as
                  feasibly possible, but no later than fifteen (15) days from
                  the date of the request submission.
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span>
                &#1065;&#1086;&#1073;
                &#1089;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1072;&#1090;&#1080;&#1089;&#1103;
                &#1094;&#1080;&#1084;&#1080;
                &#1087;&#1088;&#1072;&#1074;&#1072;&#1084;&#1080;,
                &#1074;&#1080; &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;
                &#1079;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1090;&#1080;&#1089;&#1103;
                &#1079; &#1085;&#1072;&#1084;&#1080;
                &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1086;&#1102;
                &#1087;&#1086;&#1096;&#1090;&#1086;&#1102; &#1079;&#1072;
                &#1072;&#1076;&#1088;&#1077;&#1089;&#1086;&#1102;
              </span>
              <span className="c5">
                <a className="c15" href="mailto:support@it-sprout.org.ua">
                  support@it-sprout.org.ua
                </a>
              </span>
              <span className="c3">. </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                To exercise these rights, you can contact us by email at{" "}
              </span>
              <span className="c5">
                <a className="c15" href="mailto:support@it-sprout.org.ua">
                  support@it-sprout.org.ua
                </a>
              </span>
              <span className="c10">. </span>
            </p>
          </td>
        </tr>
        <tr className="c4" id="privacy_sec12">
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                12. &#1063;&#1048; &#1052;&#1048;
                &#1056;&#1054;&#1041;&#1048;&#1052;&#1054;
                &#1054;&#1053;&#1054;&#1042;&#1051;&#1045;&#1053;&#1053;&#1071;
                &#1062;&#1030;&#1028;&#1031;
                &#1055;&#1054;&#1051;&#1030;&#1058;&#1048;&#1050;&#1048;
                &#1050;&#1054;&#1053;&#1060;&#1030;&#1044;&#1045;&#1053;&#1062;&#1030;&#1049;&#1053;&#1054;&#1057;&#1058;&#1030;?
              </span>
            </p>
          </td>
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">
                12. DO WE MAKE UPDATES TO THIS PRIVACY POLICY?
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c2">
                &#1050;&#1086;&#1088;&#1086;&#1090;&#1082;&#1086;:{" "}
              </span>
              <span className="c11">
                &#1090;&#1072;&#1082;, &#1084;&#1080;
                &#1073;&#1091;&#1076;&#1077;&#1084;&#1086;
                &#1086;&#1085;&#1086;&#1074;&#1083;&#1102;&#1074;&#1072;&#1090;&#1080;
                &#1094;&#1102;
                &#1087;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1091;
                &nbsp;&#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;,
                &#1103;&#1082;&#1097;&#1086; &#1094;&#1077;
                &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1086;,
                &#1097;&#1086;&#1073;
                &#1076;&#1086;&#1090;&#1088;&#1080;&#1084;&#1091;&#1074;&#1072;&#1090;&#1080;&#1089;&#1103;
                &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1080;&#1093;
                &#1079;&#1072;&#1082;&#1086;&#1085;&#1110;&#1074;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c11 c10 c2">In Short: </span>
              <span className="c11 c10">
                Yes, we will update this notice as necessary to stay compliant
                with relevant laws.
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c3">
                &#1052;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1095;&#1072;&#1089; &#1074;&#1110;&#1076;
                &#1095;&#1072;&#1089;&#1091;
                &#1086;&#1085;&#1086;&#1074;&#1083;&#1102;&#1074;&#1072;&#1090;&#1080;
                &#1094;&#1102;
                &#1087;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1091;
                &nbsp;&#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;.
                &#1054;&#1085;&#1086;&#1074;&#1083;&#1077;&#1085;&#1072;
                &#8203;&#8203;&#1074;&#1077;&#1088;&#1089;&#1110;&#1103;
                &#1073;&#1091;&#1076;&#1077;
                &#1087;&#1086;&#1079;&#1085;&#1072;&#1095;&#1077;&#1085;&#1072;
                &#1086;&#1085;&#1086;&#1074;&#1083;&#1077;&#1085;&#1086;&#1102;
                &#1076;&#1072;&#1090;&#1086;&#1102;
                &laquo;&#1055;&#1077;&#1088;&#1077;&#1075;&#1083;&#1103;&#1085;&#1091;&#1090;&#1072;&raquo;,
                &#1110; &#1086;&#1085;&#1086;&#1074;&#1083;&#1077;&#1085;&#1072;
                &#8203;&#8203;&#1074;&#1077;&#1088;&#1089;&#1110;&#1103;
                &#1085;&#1072;&#1073;&#1091;&#1076;&#1077;
                &#1095;&#1080;&#1085;&#1085;&#1086;&#1089;&#1090;&#1110;,
                &#1097;&#1086;&#1081;&#1085;&#1086;
                &#1089;&#1090;&#1072;&#1085;&#1077;
                &#1076;&#1086;&#1089;&#1090;&#1091;&#1087;&#1085;&#1086;&#1102;.
                &#1071;&#1082;&#1097;&#1086; &#1084;&#1080;
                &#1074;&#1085;&#1077;&#1089;&#1077;&#1084;&#1086;
                &#1089;&#1091;&#1090;&#1090;&#1108;&#1074;&#1110;
                &#1079;&#1084;&#1110;&#1085;&#1080; &#1074; &#1094;&#1102;
                &#1087;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1091;
                &nbsp;&#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;,
                &#1084;&#1080; &#1084;&#1086;&#1078;&#1077;&#1084;&#1086;
                &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1080;&#1090;&#1080;
                &#1074;&#1072;&#1089;,
                &#1088;&#1086;&#1079;&#1084;&#1110;&#1089;&#1090;&#1080;&#1074;&#1096;&#1080;
                &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1077;&#1085;&#1085;&#1103;
                &#1087;&#1088;&#1086; &#1090;&#1072;&#1082;&#1110;
                &#1079;&#1084;&#1110;&#1085;&#1080; &#1085;&#1072;
                &#1087;&#1086;&#1084;&#1110;&#1090;&#1085;&#1086;&#1084;&#1091;
                &#1084;&#1110;&#1089;&#1094;&#1110;, &#1072;&#1073;&#1086;
                &#1073;&#1077;&#1079;&#1087;&#1086;&#1089;&#1077;&#1088;&#1077;&#1076;&#1085;&#1100;&#1086;
                &#1085;&#1072;&#1076;&#1110;&#1089;&#1083;&#1072;&#1074;&#1096;&#1080;
                &#1074;&#1072;&#1084;
                &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1077;&#1085;&#1085;&#1103;.
                &#1056;&#1072;&#1076;&#1080;&#1084;&#1086; &#1074;&#1072;&#1084;
                &#1095;&#1072;&#1089;&#1090;&#1110;&#1096;&#1077;
                &#1087;&#1077;&#1088;&#1077;&#1075;&#1083;&#1103;&#1076;&#1072;&#1090;&#1080;
                &#1094;&#1102;
                &#1087;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1091;
                &nbsp;&#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;,
                &#1097;&#1086;&#1073; &#1073;&#1091;&#1090;&#1080; &#1074;
                &#1082;&#1091;&#1088;&#1089;&#1110;
                &#1090;&#1086;&#1075;&#1086;, &#1103;&#1082; &#1084;&#1080;
                &#1079;&#1072;&#1093;&#1080;&#1097;&#1072;&#1108;&#1084;&#1086;
                &#1074;&#1072;&#1096;&#1091;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c16 c10">
                We may update this privacy policy from time to time. The updated
                version will be indicated by an updated &quot;Revised&quot; date
                and the updated version will be effective as soon as it is
                accessible. If we make material changes to this privacy policy,
                we may notify you either by prominently posting a notice of such
                changes or by directly sending you a notification. We encourage
                you to review this privacy policy frequently to be informed of
                how we are protecting your information.
              </span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4" id="privacy_sec13">
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                13. &#1071;&#1050; &#1042;&#1048;
                &#1052;&#1054;&#1046;&#1045;&#1058;&#1045;
                &#1047;&#1042;&#39;&#1071;&#1047;&#1040;&#1058;&#1048;&#1057;&#1071;
                &#1047; &#1053;&#1040;&#1052;&#1048;
                &#1065;&#1054;&#1044;&#1054; &#1062;&#1030;&#1028;&#1031;
                &#1055;&#1054;&#1051;&#1030;&#1058;&#1048;&#1050;&#1048;
                &#1050;&#1054;&#1053;&#1060;&#1030;&#1044;&#1045;&#1053;&#1062;&#1030;&#1049;&#1053;&#1054;&#1057;&#1058;&#1030;?
              </span>
            </p>
          </td>
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c31 c10 c2">
                13. HOW CAN YOU CONTACT US ABOUT THIS PRIVACY POLICY?
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span>
                &#1071;&#1082;&#1097;&#1086; &#1091; &#1074;&#1072;&#1089;
                &#1108;
                &#1079;&#1072;&#1087;&#1080;&#1090;&#1072;&#1085;&#1085;&#1103;
                &#1095;&#1080;
                &#1082;&#1086;&#1084;&#1077;&#1085;&#1090;&#1072;&#1088;&#1110;
                &#1097;&#1086;&#1076;&#1086; &#1094;&#1110;&#1108;&#1111;
                &#1087;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1080;
                &nbsp;&#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;,
                &#1074;&#1080; &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;
                &#1085;&#1072;&#1076;&#1110;&#1089;&#1083;&#1072;&#1090;&#1080;
                &#1085;&#1072;&#1084;
                &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1080;&#1081;
                &#1083;&#1080;&#1089;&#1090; &#1085;&#1072;
                &#1072;&#1076;&#1088;&#1077;&#1089;&#1091;{" "}
              </span>
              <span className="c5">
                <a className="c15" href="mailto:admin@it-sprout.org.ua">
                  admin@it-sprout.org.ua
                </a>
              </span>
              <span className="c3">
                &nbsp;&#1072;&#1073;&#1086;
                &#1087;&#1086;&#1096;&#1090;&#1086;&#1102; &#1085;&#1072;
                &#1072;&#1076;&#1088;&#1077;&#1089;&#1091;:
              </span>
            </p>
            <p className="c8">
              <span className="c3">
                &#1041;&#1083;&#1072;&#1075;&#1086;&#1076;&#1110;&#1081;&#1085;&#1072;
                &#1054;&#1088;&#1075;&#1072;&#1085;&#1110;&#1079;&#1072;&#1094;&#1110;&#1103;
                &laquo;&#1041;&#1083;&#1072;&#1075;&#1086;&#1076;&#1110;&#1081;&#1085;&#1080;&#1081;
                &nbsp;&#1060;&#1086;&#1085;&#1076; IT
                &#1055;&#1072;&#1088;&#1086;&#1089;&#1090;&#1086;&#1082;&raquo;,
                &#1110;&#1076;&#1077;&#1085;&#1090;&#1080;&#1092;&#1110;&#1082;&#1072;&#1094;&#1110;&#1081;&#1085;&#1080;&#1081;
                &nbsp;&#1082;&#1086;&#1076;
                &#1102;&#1088;&#1080;&#1076;&#1080;&#1095;&#1085;&#1086;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080; 44550531,
                &#1059;&#1082;&#1088;&#1072;&#1111;&#1085;&#1072;, 65007,
                &#1054;&#1076;&#1077;&#1089;&#1100;&#1082;&#1072;
                &#1086;&#1073;&#1083;&#1072;&#1089;&#1090;&#1100;,
                &#1084;&#1110;&#1089;&#1090;&#1086;
                &#1054;&#1076;&#1077;&#1089;&#1072;,
                &#1055;&#1088;&#1080;&#1084;&#1086;&#1088;&#1089;&#1100;&#1082;&#1080;&#1081;
                &#1088;&#1072;&#1081;&#1086;&#1085;,
                &#1074;&#1091;&#1083;&#1080;&#1094;&#1103;
                &#1057;&#1090;&#1072;&#1088;&#1086;&#1087;&#1086;&#1088;&#1090;&#1086;&#1092;&#1088;&#1072;&#1085;&#1082;&#1110;&#1074;&#1089;&#1100;&#1082;&#1072;,
                107 &#1082;&#1074;&#1072;&#1088;&#1090;&#1080;&#1088;&#1072; 21.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                If you have questions or comments about this notice, you may
                email us at
              </span>
              <span className="c5">
                <a className="c15" href="mailto:support@it-sprout.org.ua">
                  support@it-sprout.org.ua
                </a>
              </span>
              <span className="c10 c16">&nbsp;or by post to: </span>
            </p>
            <p className="c8">
              <span>
                Charitable Organization &laquo;Charitable Foundation &nbsp;IT
                Sprout&raquo;,
              </span>
              <span>
                Identification code of the legal entity 44550531; 65007, Odessa,
                Odessa region, Staroportofrankivska Street 107 apartment 21
              </span>
            </p>
            <p className="c8 c25">
              <span className="c16 c10"></span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4" id="privacy_sec14">
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c2">
                14. &#1071;&#1050; &#1042;&#1048;
                &#1052;&#1054;&#1046;&#1045;&#1058;&#1045;
                &#1055;&#1045;&#1056;&#1045;&#1043;&#1051;&#1071;&#1053;&#1059;&#1058;&#1048;,
                &#1054;&#1053;&#1054;&#1042;&#1048;&#1058;&#1048;
                &#1040;&#1041;&#1054;
                &#1042;&#1048;&#1044;&#1040;&#1051;&#1048;&#1058;&#1048;
                &#1044;&#1040;&#1053;&#1030;, &#1071;&#1050;&#1030;
                &#1052;&#1048;
                &#1047;&#1041;&#1048;&#1056;&#1040;&#1028;&#1052;&#1054;
                &#1042;&#1030;&#1044; &#1042;&#1040;&#1057;?
              </span>
            </p>
          </td>
          <td className="c7 pt-10" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10 c2">
                14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span>
                &#1042;&#1080; &#1084;&#1072;&#1108;&#1090;&#1077;
                &#1087;&#1088;&#1072;&#1074;&#1086;
                &#1074;&#1080;&#1084;&#1072;&#1075;&#1072;&#1090;&#1080;
                &#1076;&#1086;&#1089;&#1090;&#1091;&#1087;&#1091; &#1076;&#1086;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;,
                &#1103;&#1082;&#1091; &#1084;&#1080;
                &#1079;&#1073;&#1080;&#1088;&#1072;&#1108;&#1084;&#1086;
                &#1074;&#1110;&#1076; &#1074;&#1072;&#1089;,
                &#1079;&#1084;&#1110;&#1085;&#1080;&#1090;&#1080; &#1094;&#1102;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
                &#1072;&#1073;&#1086;
                &#1074;&#1080;&#1076;&#1072;&#1083;&#1080;&#1090;&#1080;
                &#1111;&#1111;. &#1065;&#1086;&#1073;
                &#1087;&#1086;&#1076;&#1072;&#1090;&#1080;
                &#1079;&#1072;&#1087;&#1080;&#1090; &#1085;&#1072;
                &#1087;&#1077;&#1088;&#1077;&#1075;&#1083;&#1103;&#1076;,
                &#1086;&#1085;&#1086;&#1074;&#1083;&#1077;&#1085;&#1085;&#1103;
                &#1072;&#1073;&#1086;
                &#1074;&#1080;&#1076;&#1072;&#1083;&#1077;&#1085;&#1085;&#1103;
                &#1074;&#1072;&#1096;&#1086;&#1111;
                &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1111;
                &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;,
                &#1074;&#1080; &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;
                &#1085;&#1072;&#1076;&#1110;&#1089;&#1083;&#1072;&#1090;&#1080;
                &#1085;&#1072;&#1084;
                &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1080;&#1081;
                &#1083;&#1080;&#1089;&#1090; &#1085;&#1072;
                &#1072;&#1076;&#1088;&#1077;&#1089;&#1091;{" "}
              </span>
              <span className="c5">
                <a className="c15" href="mailto:support@it-sprout.org.ua">
                  support@it-sprout.org.ua
                </a>
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c8">
              <span className="c10">
                You have the right to request access to the personal information
                we collect from you, change that information, or delete it. To
                request to review, update, or delete your personal information,
                you may email us at{" "}
              </span>
              <span className="c5">
                <a className="c15" href="mailto:support@it-sprout.org.ua">
                  support@it-sprout.org.ua
                </a>
              </span>
              <span className="c10">&nbsp;</span>
            </p>
            <p className="c9">
              <span className="c1"></span>
            </p>
          </td>
        </tr>
        <tr className="c4">
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c36">
              <span>
                &#1062;&#1103;
                &#1087;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1072;
                &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;
                &#1089;&#1082;&#1083;&#1072;&#1076;&#1077;&#1085;&#1072;
                &#1091;&#1082;&#1088;&#1072;&#1111;&#1085;&#1089;&#1100;&#1082;&#1086;&#1102;
                &#1090;&#1072;
                &#1072;&#1085;&#1075;&#1083;&#1110;&#1081;&#1089;&#1100;&#1082;&#1086;&#1102;
                &#1084;&#1086;&#1074;&#1072;&#1084;&#1080;. &#1059;
                &#1088;&#1072;&#1079;&#1110;
                &#1074;&#1080;&#1085;&#1080;&#1082;&#1085;&#1077;&#1085;&#1085;&#1103;
                &#1089;&#1091;&#1087;&#1077;&#1088;&#1077;&#1095;&#1086;&#1082;
                &#1074; &#1111;&#1111;
                &#1090;&#1083;&#1091;&#1084;&#1072;&#1095;&#1077;&#1085;&#1085;&#1110;,
                &#1087;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1085;&#1080;&#1093;
                &#1079;
                &#1087;&#1077;&#1088;&#1077;&#1082;&#1083;&#1072;&#1076;&#1086;&#1084;,
                &#1079;&#1072;&#1074;&#1078;&#1076;&#1080;
                &#1079;&#1072;&#1089;&#1090;&#1086;&#1089;&#1086;&#1074;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
                &#1091;&#1082;&#1088;&#1072;&#1111;&#1085;&#1089;&#1100;&#1082;&#1072;
                &#1074;&#1077;&#1088;&#1089;&#1110;&#1103;.
              </span>
            </p>
          </td>
          <td className="c7" colSpan="1" rowSpan="1">
            <p className="c14">
              <span>
                This Privacy Policy is made in Ukrainian and English. In case of
                disputes in the interpretation of it related to the translation,
                the Ukrainian version is always used.
              </span>
            </p>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default Privacy;
