import { useRef } from "react";
import useIsViewport from "../../../custom-hooks/useIsViewport";

import style from "./style.module.scss";
import FooterTop from "./FooterTop";
import FooterBottom from "./FooterBottom";

const Footer = () => {
  const block = useRef();
  useIsViewport(block);
  return (
    <footer className={style["footer"]} ref={block} id="footer">
      <FooterTop />
      <FooterBottom />
    </footer>
  );
};

export default Footer;
