import { Link } from "react-router-dom";

const DiscoverMoreBtn = () => {
  return (
    <>
      <Link to="/internships" onClick={() => window.scrollTo(0, 0)}>
        <button
          className="blue-hover"
          style={{
            padding: "12px 15px",
            borderRadius: "8px",
            textTransform: "uppercase",
            fontSize: "14px",
            fontWeight: 700,
          }}
        >
          Дізнатися більше
        </button>
      </Link>
    </>
  );
};

export default DiscoverMoreBtn;
