import style from "./style.module.scss";

const QualifyingRoundAccordionItem = (props) => {
  const {
    data: { title, text },
    currentElement,
    setCurrentElement,
    index,
  } = props;

  return (
    <>
      <div
        className={`${style["accordion__item"]}`}
        onMouseEnter={() => setCurrentElement(index)}
        onMouseLeave={() => setCurrentElement(null)}
        style={{
          width: `${currentElement === index ? "50%" : "25%"}`,
        }}
      >
        <div className={style["accordion__item-title__wrapper"]}>
          <div
            className={style["accordion__item-title"]}
            style={{
              transform: `${currentElement === index ? "rotate(-90deg)" : ""}`,
            }}
          >
            {title}
          </div>
        </div>
        {currentElement === index && (
          <p
            className={`${style["slow-appear-animation"]} ${style["accordion__item-text"]}`}
          >
            {text}
          </p>
        )}
      </div>
    </>
  );
};

export default QualifyingRoundAccordionItem;
