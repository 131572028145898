import image from "../../assets/img/error.png";
import { Link } from "react-router-dom";
import Header from "../../components/General/HeaderSection/Header/Header";
import Footer from "../HomePage/Footer/Footer";
import Main from "../../components/General/Main";
import Block404 from "./404";
import HeaderHome from "../HomePage/Header/HeaderHome";

const ErrorPage = () => {
  return (
    <>
      <HeaderHome />
      <Main>
        <Block404 />
      </Main>
      <Footer />
    </>
  );
};

export default ErrorPage;
