import uuid from "react-uuid";
import style from "./style.module.scss";
import SideMenuNavItem from "./SideMenuNavItem";

const SideMenu = (props) => {
  const { setIsSideMenu, isSideMenu, navItems } = props;
  return (
    <>
      <div
        className={style["side-menu"]}
        style={{
          transform: `${isSideMenu ? "translateX(0)" : "translateX(100%)"}`,
        }}
      >
        <div className={style["navbar__wrapper"]}>
          <ul className={style["navbar"]}>
            {navItems.map((item) => {
              const { link, title } = item;
              return (
                <SideMenuNavItem
                  setIsSideMenu={setIsSideMenu}
                  key={uuid()}
                  link={link}
                >
                  {title}
                </SideMenuNavItem>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
