import DiscoverMoreBtn from "./DiscoverMoreBtn";
import style from "./style.module.scss";

const FooterTop = () => {
  return (
    <>
      <div className={style["footer__top-wrapper"]}>
        <div className={style["footer__top"]}>
          <div className={style["footer__top-left"]}>
            <h1>Приєднуйся до безкоштовної інтернатури!</h1>
            <div className={style["text-content__wrapper"]}>
              <div className={style["text-content"]}>
                Безкоштовний інтенсив: трансформація у професію майбутнього лише
                за шість місяців.
              </div>
              <div className={style["text-content"]}>
                Наша інтернатура дає унікальні переваги, що допоможуть вам у
                подальшому кар’єрному зростанні.
              </div>
              <DiscoverMoreBtn />
            </div>
          </div>
          <div className={style["footer__top-right"]}>
            <h1>Наша адреса:</h1>
            <div className={style["text-content__wrapper"]}>
              <div className={style["text-content"]}>
                65007, Україна, Одесса,
                <br />
                Старопортофранкивська 107/21
              </div>
              <div className={style["text-content"]}>
                Понеділок - П’ятниця
                <br />з 9:00 до 18:00
              </div>
            </div>
          </div>
        </div>
        <div className={style["footer__top-bottom"]}>
          <h1>БЛАГОДІЙНА ОРГАНІЗАЦІЯ БЛАГОДІЙНИЙ ФОНД ІТ ПАРОСТОК</h1>
          <h3>
            IT-паросток – новий механізм дуальної освіти й бізнесу,
            <br /> який трансформує практику і стажування в єдину екосистему
          </h3>
        </div>
      </div>
    </>
  );
};

export default FooterTop;
