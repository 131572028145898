import useArrowPagination from "../../custom-hooks/useArrowPagination";
import uuid from "react-uuid/uuid";
import style from "./style.module.css";
import button from "../../styles/modules/Button.module.css";
import { Fragment } from "react";

const Publishers = () => {
  const [publishers, setCurrentPage, isLeftBtn, isRightBtn] =
    useArrowPagination("Publishers", "", 9);

  return (
    <>
      <section
        style={{
          maxWidth: "1200px",
          width: "100%",
          margin: "0 auto",
          padding: `${
            isLeftBtn || isRightBtn ? "120px 0 160px 0" : "120px 0 200px 0"
          }`,
        }}
      >
        <div
          id="media-partners"
          className="flex flex-col justify-center items-center"
          style={{ padding: "0 25px 80px 25px", textAlign: "center" }}
        >
          <h1 style={{ fontSize: "56px", color: "#165AAA", fontWeight: 300 }}>
            Про нас пишуть
          </h1>
          <h3 style={{ color: "#EAC131", fontSize: "28px", marginTop: "10px" }}>
            дізнайтесь більше про нашу діяльність
          </h3>
        </div>
        <div>
          <div className={style["publishers-table"]}>
            {publishers &&
              publishers.map((publisher) => {
                const { Text, Image, Url } = publisher;

                return (
                  <Fragment key={uuid()}>
                    <a
                      href={Url}
                      target="_blank"
                      className="flex flex-col justify-center"
                      style={{
                        height: "276px",
                        backgroundColor: "#fff",
                        justifySelf: "center",
                        height: "276px",
                        maxWidth: "320px",
                        borderRadius: "16px",
                        width: "100%",
                        padding: "40px 48px 64px 48px",
                        border: "3px solid #EAC131",
                        borderBottom: "8px solid #EAC131 ",
                      }}
                    >
                      <div>
                        <img src={Image[0].url} />
                      </div>
                      <div
                        style={{
                          fontWeight: 600,
                          color: "#252A38",
                          marginTop: "24px",
                        }}
                      >
                        {Text}
                      </div>
                    </a>
                  </Fragment>
                );
              })}
          </div>
          {isLeftBtn || isRightBtn ? (
            <div
              className="flex justify-center"
              style={{
                padding: "40px 60px",
                gap: "8px",
                marginTop: "60px",
              }}
            >
              {isLeftBtn ? (
                <a href="#media-partners">
                  <button
                    className={button["arrow-pagination"]}
                    onClick={() => {
                      setCurrentPage((prev) => prev - 1);
                    }}
                  >
                    Назад
                  </button>
                </a>
              ) : (
                <div />
              )}
              {isRightBtn ? (
                <a href="#media-partners">
                  <button
                    className={button["arrow-pagination"]}
                    onClick={() => {
                      setCurrentPage((prev) => prev + 1);
                    }}
                  >
                    Вперед
                  </button>
                </a>
              ) : (
                <div />
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </section>
    </>
  );
};

export default Publishers;
