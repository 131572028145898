import "./ModulesMap.scss";

export const PulsedCircle = ({ children }) => {
  return (
    <div className="outer-circle">
      <div className="middle-circle">
        <div className="inner-circle">
          <div className="number-circle">
            <span className="number">{children}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
