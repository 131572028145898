import axios from "axios";
import { useEffect, useState } from "react";
import { useMemo } from "react";

const useArrowPagination = (tableName, params, limit, useEffectParams = []) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [offsets, setOffsets] = useState([null]);
  const [records, setRecords] = useState(null);

  const isLeftBtn = useMemo(() => {
    return currentPage !== 1;
  }, [currentPage]);

  const isRightBtn = useMemo(() => {
    return offsets[currentPage] !== undefined;
  }, [currentPage, offsets]);

  const fetchData = async () => {
    const offset = offsets[currentPage - 1];

    const url = `https://api-it-sprout.a-rogovsky1276.workers.dev/${tableName}?${params}limit=${limit}&${
      offset ? `offset=${offset}` : ""
    }`;

    try {
      const data = await axios.get(url).then((res) => res.data);
      setRecords(data.records.map((record) => record.fields));
      if (
        offsets.length === 1 ||
        (offsets.length !== 1 && offsets.at(-1) !== undefined)
      ) {
        setOffsets((prev) => [...prev, data.offset]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, ...useEffectParams]);

  return [records, setCurrentPage, isLeftBtn, isRightBtn];
};

export default useArrowPagination;
