import pic1 from "../../assets/img/svg/icons/feat-001.svg";
import pic2 from "../../assets/img/svg/icons/feat-002.svg";
import pic3 from "../../assets/img/svg/icons/feat-003.svg";
import RateForm from "../../components/Forms/RateForm/RateForm";
import SendInviteForm from "../../components/Forms/SendInviteForm/SendInviteForm";
import DownloadMaterials from "../../components/Forms/DownloadMaterials/DownloadMaterials";
import { useContext } from "react";
import { EventContext } from "./EventPage";
import useWindowWidth from "../../custom-hooks/useWindowWidth";
import uuid from "react-uuid";

const Features = ({ setFormToDisplay, setStatus }) => {
  const width = useWindowWidth();

  const { pageData } = useContext(EventContext);
  const { Material, Total_requests, Join_webinar, Join_internship } = pageData;

  const defaultBlock = {
    "value": "",
    "description": "",
  };

  const block1 = pageData["Block_1"]
    ? JSON.parse(pageData["Block_1"])
    : defaultBlock;
  const block2 = pageData["Block_2"]
    ? JSON.parse(pageData["Block_2"])
    : defaultBlock;

  const block3 = pageData["Block_3"]
    ? JSON.parse(pageData["Block_3"])
    : defaultBlock;

  const blocksArr = [block1, block2, block3];

  return (
    <section
      id="features"
      className="bg-darkblue"
      style={{ padding: "120px 0" }}
    >
      <h2 className="p-title c-yellow" style={{ padding: "0 0 104px 0" }}>
        Матеріали до заходу
      </h2>
      <div className="container">
        <div
          className="row"
          style={{
            rowGap: "30px",
            columnGap: "10px",
            justifyContent: "center",
          }}
        >
          <div
            className="col-md-4"
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "360px",
              width: "100%",
            }}
          >
            <div className="text-center">
              <img className="p-2" src={pic1} alt="На постійній основі" />
              <h5 className="c-yellow py-3">Інвайт на безкоштовне навчання</h5>
            </div>
            <p className="mx-3 c-white text-center">
              Маєте знайомого який шукає роботу, подаріть йому інвайт щоб він
              мав згоду пройти безкошковне навчання
            </p>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                flex: "1 1 0%",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <button
                onClick={() => {
                  setFormToDisplay(<SendInviteForm setStatus={setStatus} />);
                  setStatus(true);
                }}
                style={{
                  textTransform: "uppercase",
                  background: "#EAC131",
                  marginTop: "24px",
                  padding: "13px 32px 12px 32px",
                  borderRadius: "8px",
                }}
              >
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: "16px",
                    color: "#252A38",
                  }}
                >
                  отримати
                </span>
              </button>
            </div>
          </div>
          <div
            className="col-md-4"
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "360px",
              width: "100%",
            }}
          >
            <div className="text-center">
              <img className="p-2" src={pic2} alt="Вільний слухач" />
              <h5 className="c-yellow py-3">
                Завантажити слайди та презентації
              </h5>
            </div>
            <p className="mx-3 c-white text-center">
              Якщо захід мав додаткові матеріали - завантажуйте їх собі та
              дивіться у гарній якості
            </p>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                flex: "1 1 0%",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <button
                onClick={() => {
                  setFormToDisplay(
                    <DownloadMaterials
                      setStatus={setStatus}
                      toShare={Material}
                    />
                  );
                  setStatus(true);
                }}
                style={{
                  textTransform: "uppercase",
                  background: "#EAC131",
                  marginTop: "24px",
                  padding: "13px 32px 12px 32px",
                  borderRadius: "8px",
                }}
              >
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: "16px",
                    color: "#252A38",
                  }}
                >
                  отримати
                </span>
              </button>
            </div>
          </div>
          <div
            className="col-md-4"
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "360px",
              width: "100%",
            }}
          >
            <div className="text-center">
              <img className="p-2" src={pic3} alt="Вільний слухач" />
              <h5 className="c-yellow py-3">
                Оцініть користь від нашого заходу
              </h5>
            </div>
            <div
              style={{ textAlign: "center", fontWeight: 400, fontSize: "32px" }}
            >
              ⭐⭐⭐⭐⭐
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                flex: "1 1 0%",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <button
                onClick={() => {
                  setFormToDisplay(<RateForm setStatus={setStatus} />);
                  setStatus(true);
                }}
                style={{
                  textTransform: "uppercase",
                  background: "#EAC131",
                  marginTop: "24px",
                  padding: "13px 32px 12px 32px",
                  borderRadius: "8px",
                }}
              >
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: "16px",
                    color: "#252A38",
                  }}
                >
                  отримати
                </span>
              </button>
            </div>
          </div>
        </div>
        <h3 className="p-title c-white">
          Досягнення IT-Паросток у Воєнний Час
        </h3>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: `${width > 1000 ? "repeat(3,1fr)" : "1fr"}`,
            gap: "20px",
          }}
        >
          {blocksArr.map((block) => {
            const { value, description } = block;
            return (
              <div
                key={uuid()}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: "1 1 0%",
                  maxWidth: "380px",
                  width: "100%",
                  justifySelf: "center",
                }}
              >
                <div
                  className="bg-blue r-16 text-center pt-5 pb-4"
                  style={{ borderTop: "8px solid #EAC131", height: "100%" }}
                >
                  <div className="text-center">
                    <h3
                      className="c-white"
                      style={{ fontSize: "64px", fontWeight: 500 }}
                    >
                      {value}
                    </h3>
                    <h5
                      className="c-yellow pt-2 pb-3"
                      style={{
                        fontSize: "24px",
                        fontWeight: 500,
                        color: "#EAC131",
                      }}
                    >
                      {description.split(" ")[0]}
                    </h5>
                  </div>
                  <p
                    className="mx-3 c-white"
                    style={{
                      textTransform: "uppercase",
                      fontWeight: "600",
                      fontSize: "20px",
                    }}
                  >
                    {description.split(" ").slice(1).join(" ")}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Features;
