import style from "./style.module.scss";

const BurgerMenu = (props) => {
  const { setIsSideMenu, isSideMenu } = props;
  return (
    <>
      <button
        className={style["burger-menu"]}
        onClick={() => setIsSideMenu((prev) => !prev)}
      >
        <div
          className={`${style["hamburger"]} ${
            isSideMenu ? style["is-active"] : ""
          }`}
          id={style["hamburger-1"]}
        >
          <span className={style["line"]}></span>
          <span className={style["line"]}></span>
          <span className={style["line"]}></span>
        </div>
      </button>
    </>
  );
};

export default BurgerMenu;
