import React, { useContext, useEffect, useState } from "react";
import { truncateString } from "../../../functions";
import { Link } from "react-router-dom";
import uuid from "react-uuid";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import Slider from "react-slick";
import "./slick.css";
import "./slick-theme.css";
import InternshipsItem from "../../InternshipList/Internships/InternshipsItem";

const InternshipCardsSlider = ({ markdownText }) => {
  const [internshipsCards, setInternshipsCards] = useState(null);

  const fetchData = async () => {
    const status = encodeURIComponent(`Status = 'Active'`);
    try {
      let data = await axios
        .get(
          `https://api-it-sprout.a-rogovsky1276.workers.dev/Internships?filterByFormula=${status}`
        )
        .then((res) => res.data)
        .then((data) => data.records);
      data = data.map((record) => record.fields);
      data = data.map((record) => {
        return {
          link: record["URL"],
          title: record["URL"].split("/").reverse()[0],
          description: record["Description"],
        };
      });
      setInternshipsCards(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          width: "16px",
          height: "30px",
          backgroundSize: "cover",
        }}
        onClick={onClick}
      />
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          // backgroundColor: "white !important",
          width: "16px",
          height: "30px",
          // backgroundRepeat: "no-repeat",
          // backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          // scss: {
          //   marginRight: "10px",
          // },
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <>
      <section className="container">
        <div
          className="w-full flex flex-col custom-scroll"
          style={{ margin: "120px 0" }}
        >
          <div className="w-full flex justify-center text-center">
            <h1
              style={{
                fontSize: "56px",
                color: "#165AAA",
                lineHeight: "68px",
                fontWeight: 300,
                width: "fit-content",
              }}
            >
              Напрямки Інтернатур
            </h1>
          </div>
          {internshipsCards && (
            <Slider
              className={""}
              {...settings}
              style={{
                padding: "0 10px",
                maxWidth: "1132px",
                width: "100%",
                margin: "106px auto 0 auto",
              }}
            >
              {internshipsCards.map((internship) => {
                return <InternshipsItem data={internship} key={uuid()} />;
              })}
            </Slider>
          )}
        </div>
      </section>
    </>
  );
};

export default InternshipCardsSlider;
