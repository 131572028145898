import React, { useContext, useEffect, useState } from "react";
import { removeQueryParam, updateObj } from "../../functions";
import axios from "axios";
import { ContestData } from "../ContestOverviewPage/ContestOverviewPage";
import { Element, scroller } from "react-scroll";
import { useLocation } from "react-router-dom";
import { parseDate } from "../../functions";

const Registration = () => {
  const { contestData } = useContext(ContestData);
  const location = useLocation();
  const [form, setForm] = useState({
    "Fullname": "",
    "Email": "",
    "Telephone": "",
    "Work_URL": "",
    "Likes": 0,
    "Contest_ID": contestData["Location"],
    "Contests": [contestData["Location"]],
  });
  const [isResult, setIsResult] = useState(null);

  const submitForm = async () => {
    try {
      const data = await axios
        .post(
          "https://api-it-sprout.a-rogovsky1276.workers.dev/Contests_works",
          {
            records: [
              {
                fields: form,
              },
            ],
          }
        )
        .then((res) => res.data);
      if (data.data.error) {
        setIsResult(false);
      } else {
        setForm({
          "Fullname": "",
          "Email": "",
          "Telephone": "",
          "Work_URL": "",
          "Likes": 0,
          "Contest_ID": contestData["Location"],
          "Contests": [contestData["Location"]],
        });
        setIsResult(true);
      }
    } catch (e) {
      console.log(e);
      setIsResult(false);
    }
  };

  useEffect(() => {
    if (isResult !== null) {
      const timeOut = setTimeout(() => {
        setIsResult(null);
      }, 2000);
      return () => clearTimeout(timeOut);
    }
  }, [isResult]);

  useEffect(() => {
    const targetElementId = new URLSearchParams(location.search).get(
      "scrollTo"
    );
    if (targetElementId) {
      scroller.scrollTo(targetElementId, {
        offset: -150,
        duration: 400,
        delay: 0,
      });
      removeQueryParam("scrollTo");
    }
  }, [location]);

  const Timetable = contestData["Timetable"]
    ? JSON.parse(contestData["Timetable"])
    : null;

  const endDateStr = (stringDate) => {
    const endDate = parseDate(stringDate);
    const todayDate = new Date();

    const oneDay = 24 * 60 * 60 * 1000;

    const diffDays = Math.round(
      Math.abs((endDate.getTime() - todayDate.getTime()) / oneDay)
    );

    if (diffDays === 1) {
      return `${diffDays} день`;
    } else if (diffDays === 2 || diffDays === 3 || diffDays === 4) {
      return `${diffDays} дні`;
    } else {
      return `${diffDays} днів`;
    }
  };

  return (
    <>
      <section style={{ maxWidth: "1200px", margin: "0 auto" }}>
        <div style={{ padding: "120px 25px" }}>
          <div
            className="flex flex-col justify-center items-center"
            style={{ padding: "60px 0" }}
          >
            <h1 style={{ fontSize: "56px", color: "#165AAA", fontWeight: 300 }}>
              Реєстрація
            </h1>
            <h3
              style={{
                color: "#EAC131",
                fontSize: "28px",
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              Заповніть форму реєстрації та приєднайтеся до змагань
            </h3>
          </div>
          <div
            className="contest-registration__wrapper"
            style={{
              minHeight: "624px",
              backgroundColor: "#D9D9D980",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "56px",
              borderRadius: "24px",
              color: "#252A38",
            }}
          >
            <div
              style={{
                rowGap: "24px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flex: "1 1 0%",
              }}
            >
              <div style={{ fontSize: "24px" }}>
                Зареєструйтесь на участь у конкурсі "{contestData["Title"]}"
                прямо зараз та отримайте можливість реалізувати свій творчий
                потенціал.
              </div>
              <i style={{ fontSize: "24px" }}>
                Не втрачайте шансу заявити про себе! До кінця реєстрації
                залишилось{" "}
                {Timetable &&
                  `${
                    Timetable.find(
                      (el) =>
                        el.specification.toLowerCase() ===
                        "Завершення прийому робіт".toLowerCase()
                    )?.time !== undefined
                      ? endDateStr(
                          Timetable.find(
                            (el) =>
                              el.specification.toLowerCase() ===
                              "Завершення прийому робіт".toLowerCase()
                          )?.time
                        )
                      : ""
                  }`}
                .
              </i>
            </div>
            <Element name="contest-registr">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  submitForm();
                }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "466px",
                  width: "100%",
                }}
              >
                <input
                  type="text"
                  style={{
                    width: "100%",
                    fontWeight: 400,
                    height: "80px",
                    borderRadius: "16px",
                    padding: "25.5px 24px",
                    fontSize: "24px",
                  }}
                  value={form["Fullname"]}
                  onChange={(e) =>
                    setForm((prev) =>
                      updateObj(prev, "Fullname", e.target.value)
                    )
                  }
                  required
                  placeholder="Ваше ім'я та прізвище"
                />
                <input
                  type="email"
                  style={{
                    width: "100%",
                    fontWeight: 400,
                    height: "80px",
                    borderRadius: "16px",
                    padding: "25.5px 24px",
                    fontSize: "24px",
                    marginTop: "24px",
                  }}
                  value={form["Email"]}
                  onChange={(e) =>
                    setForm((prev) => updateObj(prev, "Email", e.target.value))
                  }
                  required
                  placeholder="Електронна адреса"
                />
                <input
                  type="tel"
                  style={{
                    width: "100%",
                    fontWeight: 400,
                    height: "80px",
                    borderRadius: "16px",
                    padding: "25.5px 24px",
                    fontSize: "24px",
                    marginTop: "24px",
                  }}
                  value={form["Telephone"]}
                  onChange={(e) =>
                    setForm((prev) =>
                      updateObj(prev, "Telephone", e.target.value)
                    )
                  }
                  required
                  placeholder="Номер телефону"
                />
                <input
                  type="text"
                  style={{
                    width: "100%",
                    fontWeight: 400,
                    height: "80px",
                    borderRadius: "16px",
                    padding: "25.5px 24px",
                    fontSize: "24px",
                    marginTop: "24px",
                  }}
                  value={form["Work_URL"]}
                  onChange={(e) =>
                    setForm((prev) =>
                      updateObj(prev, "Work_URL", e.target.value)
                    )
                  }
                  required
                  placeholder="Посилання на роботу"
                />
                <button
                  type="submit"
                  style={{
                    width: "100%",
                    padding: "0 24px",
                    height: "80px",
                    backgroundColor: `${
                      isResult === null ? "#165AAA" : isResult ? "green" : "red"
                    }`,
                    fontWeight: 600,
                    color: "#fff",
                    fontSize: "24px",
                    borderRadius: "16px",
                    marginTop: "24px",
                  }}
                >
                  {isResult === null
                    ? "Надіслати"
                    : isResult
                    ? "Успішно"
                    : "Помилка"}
                </button>
              </form>
            </Element>
          </div>
        </div>
      </section>
    </>
  );
};

export default Registration;
