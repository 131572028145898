export const removeSpaces = (str) => {
  let arr = str.split("");
  arr = arr.filter((element, index) => {
    if (element !== " ") return element;
    if (element === " " && arr[index - 1] !== element) return element;
  });
  if (arr.at(-1) === " ") arr.length = arr.length - 1;
  return arr.join("");
};

export const formatTelephone = (str) => {
  str = str
    .split("")
    .filter((el) => el !== " ")
    .join("");
  const result = `${str[0] || ""}${str[1] || ""}${str[2] || ""}${
    str[3] || ""
  } ${str[4] || ""}${str[5] || ""} ${str[6] || ""}${str[7] || ""}${
    str[8] || ""
  } ${str[9] || ""}${str[10] || ""} ${str[11] || ""}${str[12] || ""}`.trim();

  return result;
};

export const updateObj = (obj, key, value) => {
  const copy = { ...obj };
  copy[key] = value;
  return copy;
};

export const removeQueryParam = (key) => {
  const url = new URL(window.location.href);
  url.searchParams.delete(key);
  const newUrl = url.toString();
  window.history.pushState({ path: newUrl }, "", newUrl);
};

export const truncateString = (str) => {
  if (str.length > 28) {
    return str.slice(0, 23) + "...";
  }
  return str;
};

export const parseDate = (dateStr) => {
  const months = {
    "січня": 0,
    "лютого": 1,
    "березня": 2,
    "квітня": 3,
    "травня": 4,
    "червня": 5,
    "липня": 6,
    "серпня": 7,
    "вересня": 8,
    "жовтня": 9,
    "листопада": 10,
    "грудня": 11,
  };

  const dateParts = dateStr.split(" ");
  const month = months[dateParts[1]];
  const day = parseInt(dateParts[0]);

  const year = new Date().getFullYear();

  return new Date(year, month, day);
};
