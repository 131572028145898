import { useRef } from "react";
import useIsViewport from "../../../custom-hooks/useIsViewport";
import GrowthLevelsAccordion from "./GrowthLevelsAccordion/GrowthLevelsAccordion";
import style from "./style.module.scss";

const GrowthLevels = () => {
  const block = useRef();
  useIsViewport(block);
  return (
    <section ref={block} id="growth_levels" className={style["section"]}>
      <div className={style["section__inner-content"]}>
        <div className={style["section__title-wrapper"]}>
          <h1>Рівні твого зростання</h1>
        </div>
        <div className={style["growthLevels-accordion__wrapper"]}>
          <GrowthLevelsAccordion />
        </div>
      </div>
    </section>
  );
};

export default GrowthLevels;
