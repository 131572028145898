import { Route, Routes } from "react-router-dom";
import { createContext, useState, useEffect } from "react";

import "./App.scss";
import "./styles/custom.sass";

import ErrorPage from "./pages/ErrorPage/ErrorPage";
import PrivacyPage from "./pages/PrivacyPage/PrivacyPage";
import InternshipPage from "./pages/InternshipPage/InternshipPage";
import InternshipListPage from "./pages/InternshipList/InternshipListPage";

import MediaPage from "./pages/MediaPage/MediaPage";
import ContestListPage from "./pages/ContestListPage/ContestListPage";
import ContestReviwPage from "./pages/ContestReviewPage/ContestReviwPage";
import SupervisoryBoardPage from "./pages/SupervisoryBoardPage/SupervisoryBoardPage";
import ContestOverviewPage from "./pages/ContestOverviewPage/ContestOverviewPage";
import ContestRegistrationPage from "./pages/ContestRegistrationPage/ContestRegistrationPage";
import EventPage from "./pages/EventPage/EventPage";
import ThankPage from "./pages/ThankPage/ThankPage";
import AllGraduatesPage from "./pages/AllGraduatesPage/AllGraduatesPage";
import PartnersPage from "./pages/PartnersPage/PartnersPage";
import HomePage from "./pages/HomePage/HomePage";
import InvestPage from "./pages/InvestPage";

export const GlobalContext = createContext();

const App = () => {
  const [isThankPage, setIsThankPage] = useState(false);

  const [likedContestsWorks, setLikedContestsWorks] = useState(
    localStorage.getItem("likedContestsWorks")
      ? JSON.parse(localStorage.getItem("likedContestsWorks"))
      : []
  );

  useEffect(() => {
    localStorage.setItem(
      "likedContestsWorks",
      JSON.stringify(likedContestsWorks)
    );
  }, [likedContestsWorks]);

  const [isLikeClicked, setIsLikeClicked] = useState(null);

  return (
    <>
      <GlobalContext.Provider
        value={{
          likedContestsWorks,
          setLikedContestsWorks,
          isLikeClicked,
          setIsLikeClicked,
        }}
      >
        <Routes>
          <Route
            path="/"
            element={<HomePage setIsThankPage={setIsThankPage} />}
          />
          <Route path="/internship/:option" element={<InternshipPage />} />
          <Route path="/internships" element={<InternshipListPage />} />
          <Route path="/privacy-policy" element={<PrivacyPage />} />
          <Route path="/media" element={<MediaPage />} />
          <Route path="/supervisory-board" element={<SupervisoryBoardPage />} />
          <Route path="/graduates" element={<AllGraduatesPage />} />
          <Route path="/partners" element={<PartnersPage />} />
          <Route path="/events/:id" element={<EventPage />} />
          <Route path="/contest-list" element={<ContestListPage />} />
          <Route path="/donate" element={<InvestPage />} />
          <Route
            path="/contest/:contestID/review"
            element={<ContestReviwPage />}
          />
          <Route path="/invest-page" element={<InvestPage />} />
          <Route
            path="/contest/:contestID/overview"
            element={<ContestOverviewPage />}
          />
          <Route
            path="/contest/:contestID/registration"
            element={<ContestRegistrationPage />}
          />
          {isThankPage && (
            <Route
              path="/thank-page"
              element={<ThankPage setIsThankPage={setIsThankPage} />}
            />
          )}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </GlobalContext.Provider>
    </>
  );
};

export default App;
