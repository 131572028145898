import { useEffect, useContext } from "react";
import { PageContext } from "../pages/InternshipPage/InternshipPage";
import useScrollPosition from "./useScrollPosition";

const useIsViewport = (element) => {
  const distanceToTop = useScrollPosition();
  const { setSelectedNavItem } = useContext(PageContext) || {
    setSelectedNavItem: null,
  };

  useEffect(() => {
    if (
      distanceToTop + 250 >= element.current.offsetTop &&
      distanceToTop <=
        element.current.offsetTop + element.current.clientHeight - 250
    ) {
      if (setSelectedNavItem) {
        setSelectedNavItem("#" + element.current.id);
      }
    }
  }, [distanceToTop, element, setSelectedNavItem]);
};

export default useIsViewport;
