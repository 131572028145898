import uuid from "react-uuid/uuid";
import style from "../style.module.scss";
import { navItems } from "../Header";
import NavbarItem from "./NavbarItem";

const Navbar = () => {
  return (
    <>
      <nav className={style["navbar"]}>
        <ul className={style["navbar__list"]}>
          {navItems.map((item) => {
            const { link, title } = item;
            return (
              <NavbarItem key={uuid()} link={link}>
                {title}
              </NavbarItem>
            );
          })}
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
