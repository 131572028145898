import Privacy from "./Privacy";
import Footer from "../../components/General/Footer/Footer";
import HeaderHome from "../HomePage/Header/HeaderHome";

const PrivacyPage = () => {
  return (
    <>
      <HeaderHome />
      <Privacy />
      <Footer />
    </>
  );
};

export default PrivacyPage;
